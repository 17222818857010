import styled from "@emotion/styled";
import React, { FC } from "react";
import { Link } from "react-router-dom";

import { Divider } from "components";

import { usePageTracking } from "hooks";

import { DOCS_ROUTES, ROUTES } from "types";

import { PageContent } from "./PageContent";
import { PageHeader } from "./PageHeader";

const FaqContainer = styled(PageContent)`
  max-width: 1000px;
`;

const Question = styled.h3`
  margin: 2.5rem 0 1rem 0;
  font-size: 16px;
  font-weight: 600;
`;

const Answer = styled.h4`
  font-weight: 400;
  margin: 1rem;
  color: var(--color-textSecondary);
`;

const List = styled.ol`
  padding: 0.5rem 0 0rem 2rem;

  & li:not(:last-of-type) {
    margin-bottom: 0.5rem;
  }
`;

const questions = [
  {
    question: "How do you calculate OD and road volumes?",
    answer: (
      <div>
        We use three primary types of data:
        <List>
          <li>
            Anonymized GPS location data collected from smartphone apps like weather or news. This data source, called
            Location-Based Services (LBS), covers approximately 10% to 15% of the US population, depending on location
            and time of year. These data are cleansed and segmented into individual trips with departure and arrival
            locations and times, the route used, likely home location, mode, and trip purpose. These provide the core
            sample of observed travel.
          </li>
          <li>
            Two million road volume counts from federal, state, and local government sources. These counts come from a
            mix of permanent and temporary count stations. We collect, clean, and locate these counts on a nationwide
            OSM network.
          </li>
          <li>
            Public reference data, including census and employment data, points of interest, airport enplanements, and
            other significant attractors such as universities, schools, arenas, and major parks.
          </li>
        </List>
      </div>
    ),
  },
  {
    question: "How accurate are your OD and road volumes?",
    answer: (
      <>
        We compare our data against trusted reference data, such as the National Household Travel Survey. You can find a
        detailed evaluation of our data quality{" "}
        <Link to={`${ROUTES.Documentation}/${DOCS_ROUTES.DataQuality}`}>here</Link>.
      </>
    ),
  },
  {
    question: "Which year do you cover with your OD and road volumes?",
    answer:
      "We provide annual releases for the USA and Canada. The current datasets are for 2019 and 2022. 2023 data will be available in the summer of 2024.",
  },
  {
    question: "Do your OD and road volumes include transit or bike trips?",
    answer:
      "OD flows and roadway volume represent vehicular travel on roadways for all modes (excluding active modes such as pedestrian and bike).",
  },
  {
    question: "How do I integrate your OD volumes into my traffic model?",
    answer: (
      <>
        We have a dedicated <Link to={`${ROUTES.Documentation}/${DOCS_ROUTES.Tutorials}`}>video tutorial</Link> on that
        topic, showing how to export OD data from Studio and integrate that data into your traffic model.
      </>
    ),
  },
];

export const Faq: FC = () => {
  usePageTracking();

  return (
    <>
      <PageHeader title="Frequently asked questions" />{" "}
      <FaqContainer>
        {questions.map(({ question, answer }) => (
          <>
            <Question>{question}</Question>
            <Answer>{answer}</Answer>
            <Divider />
          </>
        ))}
      </FaqContainer>
    </>
  );
};
