import React, { FC } from "react";
import styled from "@emotion/styled";
import { Icon, IconName } from "@blueprintjs/core";

export interface InfoCardProps {
  icon: IconName;
  text: string;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Card = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 16px;
  background-color: var(--color-purple-50);
  display: flex;
  align-items: center;
  justify-content: center;

  & .bp4-icon > svg:not([fill]) {
    fill: var(--color-purple-500);
  }
`;

const Text = styled.h4`
  color: #6b7280;
  margin: 1rem;
  text-align: center;
  font-weight: 400;
`;

export const InfoCard: FC<InfoCardProps> = ({ icon, text }) => (
  <Container>
    <Card>
      <Icon icon={icon} />
    </Card>

    <Text>{text}</Text>
  </Container>
);
