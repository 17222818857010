import styled from "@emotion/styled";
import { FolderOpenOutlined } from "@mui/icons-material";
import React, { forwardRef, useMemo } from "react";

import { Button, CardContainer, Popover, PopoverMenuItem } from "components";

import { Permissions } from "types";

import { Handle } from "./Handle";
import { PopoverControlButton } from "./PopoverControlButton";

export interface ContainerProps {
  children: React.ReactNode;
  itemCount?: number;
  label?: string;
  style?: React.CSSProperties;
  hover?: boolean;
  handleProps?: React.HTMLAttributes<any>;
  shadow?: boolean;
  placeholder?: boolean;
  permissions: Permissions;
  onRename?(): void;
  onDelete?(): void;
  onAddNewDataset?(): void;
  onUploadZoning?(): void;
}

const ContainerWrapper = styled(CardContainer)`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  box-sizing: border-box;
  appearance: none;
  outline: none;
  margin: 0 0 30px 0;
  padding: 0 1rem;
  border-radius: 5px;
  height: fit-content;
  transition: background-color 350ms ease;
`;

const ContainerHeader = styled.div`
  display: grid;
  grid-template-columns: 42.5px 25px 1fr 30px;
  grid-template-rows: 1fr;
  align-items: center;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  position: relative;
  height: 59px;
`;

const AddingSourceContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ContainerTitle = styled.h3`
  font-weight: 600;
  margin-left: 12px;
`;

const AddResourceButton = styled(Button)`
  margin: 2rem;
`;

const EmptyContainerTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-text);
  font-size: 18px;
  font-weight: 700;
  padding-top: 45px;
`;

const EmptyContainerBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--color-textSecondary);
  padding-top: 15px;
`;

const DatasetTableHeader = styled.div`
  display: grid;
  grid-template-columns: 20px 220px 1fr 80px 80px 80px 80px 110px 90px 30px;
  grid-template-rows: 1fr;
  column-gap: 24px;
  align-items: center;
  background-color: var(--color-gray-50);
  color: var(--color-textSecondary);
  height: 36px;
  font-size: 12px;
  border: 1px solid var(--color-gray-200);
  border-radius: 8px;
`;

const DatasetTable = styled.ul`
  display: grid;
  grid-template-columns: repeat(var(--columns, 1), 1fr);
  list-style: none;
`;

const EmptyDatasetPlaceholder = () => (
  <>
    <EmptyContainerTitle>Start by creating a dataset</EmptyContainerTitle>
    <EmptyContainerBody>
      Creating datasets in folders keeps your projects organized. You can reorder datasets and move them between
      folders.
    </EmptyContainerBody>
  </>
);

export const Container = forwardRef<HTMLDivElement, ContainerProps>(
  (
    {
      children,
      handleProps,
      hover,
      label,
      placeholder,
      style,
      shadow,
      itemCount,
      permissions,
      onDelete,
      onRename,
      onAddNewDataset,
      onUploadZoning,
      ...props
    }: ContainerProps,
    ref,
  ) => {
    const popoverMenuItems: PopoverMenuItem[] = useMemo(
      () => [
        {
          children: "Rename",
          disabled: !permissions?.rename.allow,
          onClick: () => {
            if (onRename) onRename();
          },
          tooltipProps: {
            title: permissions?.rename.reason,
            disableHoverListener: permissions?.rename.allow,
          },
        },
        {
          children: "Delete",
          disabled: !permissions?.delete.allow,
          onClick: () => {
            if (onDelete) onDelete();
          },
          tooltipProps: {
            title: permissions?.delete.reason,
            disableHoverListener: permissions?.delete.allow,
          },
        },
      ],
      [permissions, onRename, onDelete],
    );

    return (
      <ContainerWrapper
        {...props}
        ref={ref as any}
        style={
          {
            ...style,
            "--columns": 1,
          } as React.CSSProperties
        }
      >
        <ContainerHeader>
          <Handle {...handleProps} color="#9CA3AF" />
          <FolderOpenOutlined color="secondary" />
          <ContainerTitle>{label}</ContainerTitle>
          <Popover control={PopoverControlButton} menuItems={popoverMenuItems} />
        </ContainerHeader>
        {typeof itemCount === "number" && itemCount > 0 && (
          <>
            <DatasetTableHeader>
              <div />

              <div>Name</div>
              <div>Area of interest</div>
              <div>Type</div>
              <div>Time period</div>
              <div>Created</div>
              <div>Modified</div>
              <div>Status</div>
            </DatasetTableHeader>
            <DatasetTable>{children}</DatasetTable>
          </>
        )}
        {itemCount === 0 && <EmptyDatasetPlaceholder />}
        <AddingSourceContainer>
          <AddResourceButton
            color="whiteShadow"
            leadingIcon="plus"
            disabled={!permissions?.createdataset?.allow}
            onClick={onAddNewDataset}
          >
            New Dataset
          </AddResourceButton>
          {permissions?.zoningupload?.allow && (
            <AddResourceButton
              color="whiteShadow"
              leadingIcon="plus"
              disabled={!permissions?.createdataset?.allow}
              onClick={onUploadZoning}
            >
              Upload Zoning
            </AddResourceButton>
          )}
        </AddingSourceContainer>
      </ContainerWrapper>
    );
  },
);
