import styled from "@emotion/styled";
import React, { FC } from "react";

import { Badge } from "components";

import { ExtendedQueryType, QueryType } from "types";

export interface ODPopupProps {
  count: number;
  type?: QueryType | ExtendedQueryType;
  countByDensity?: number;
  gateId?: string;
  flowInfo?: string;
}

const Header = styled.div`
  padding: 0.25rem 1rem;
  border-bottom: 1px solid var(--color-border);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`;

const Info = styled.div`
  text-align: right;
  padding: 0.25rem 1rem;
  border-top: 1px solid var(--color-border);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`;

const GateId = styled.h3`
  font-weight: 500;
`;

const CountContainer = styled.div`
  min-width: 150px;
  gap: 0.4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0.5rem 1rem;
`;

const TripsCount = styled.h3`
  font-weight: 400;
  margin-right: 1rem;
`;

export const ODPopup: FC<ODPopupProps> = ({ count, countByDensity, type, gateId, flowInfo }) => {
  return (
    <>
      {gateId && (
        <Header>
          <GateId>Gate {gateId}</GateId>
        </Header>
      )}
      <CountContainer>
        {type ? (
          <TripsCount>
            {type === ExtendedQueryType.INTERNAL
              ? "Internal & Round Trips"
              : type === QueryType.INCOMING
              ? "Incoming Trips"
              : "Outgoing Trips"}
          </TripsCount>
        ) : null}
        <Badge color="secondary">{count}</Badge>
      </CountContainer>
      {countByDensity !== undefined && countByDensity > 0 && <Info>{`${countByDensity} per square mile`}</Info>}
      {flowInfo && <Info>{flowInfo}</Info>}
    </>
  );
};
