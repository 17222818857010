import { UniqueIdentifier } from "@dnd-kit/core";
import protobuf from "protobufjs";

import {
  AddGateArguments,
  AddGateResponse,
  AoiExportRequest,
  CatalogItem,
  CorridorEdgeCounts,
  CorridorHeatmapConfiguration,
  CorridorHeatmapConfigurationRequestParams,
  CorridorMetadata,
  CorridorNodeCounts,
  Counts,
  CountsByZoneId,
  CreateDatasetPayload,
  CustomDataset,
  CustomDatasetRepository,
  CustomZoningSelectorItemsResponse,
  DatasetCountsArguments,
  DatasetCountsByZoneIdArguments,
  DatasetFolder,
  DatasetFolders,
  DatasetFoldersResponse,
  DatasetGate,
  DatasetMetadata,
  DatasetZoneDetailsArguments,
  EdgesRangeRequest,
  ExportJobs,
  FocusAreaItem,
  Gate,
  GateCoordinates,
  GateCoordinatesArguments,
  GenerateGatesArguments,
  MeasureRange,
  MeasureRangeRequest,
  MeasureRangeResponse,
  NewODDatasetConfigPayload,
  NumZonesResponse, //Export
  ODCountsArguments,
  ODCountsByZoneIdArguments,
  ODDatasetComputation,
  ODDatasetConfig,
  ODDatasetExportRequest,
  ODDatasetValidation,
  ODMeasureRange,
  ODMetadata,
  ODMetadataArguments,
  OdNumZonesRequest,
  PreparedZoningConfigRequest,
  RoadSegmentDetails,
  RoadSegmentIdsArguments,
  RoadSegmentIdsWithFactype,
  RoadSegmentsDetailsArguments,
  RoadVmtMetadata,
  RoadVmtMetadataRequest,
  RoadVmtZoneCounts,
  RoadVmtZoneCountsRequest,
  RoadVmtZoneDetails,
  RoadVmtZoneDetailsRequest,
  RoadsMetadata,
  RoadsMetadataArguments,
  RoadsVolumes,
  RoadsVolumesArguments,
  SelectLinkConfig,
  SelectLinkConfigCreationRequest,
  SelectLinkConfigUpdateRequest,
  SelectLinkSegmentCountsRequest,
  ServiceOverlay,
  ShapesInputFormat,
  SubareaPolygon,
  SubareaPolygonArguments,
  SubareaState,
  SubareaStateArguments,
  UpdateODDatasetConfigPayload,
  UploadZoningResponse,
  ZoneDetails,
  ZoneDetailsArguments,
  ZoneIdAreaPairs,
  ZoneIds,
  ZoneIdsArguments,
  Zoning,
  ZoningItem,
} from "types";

import RestHandler from "./RestHandler";
import {
  getVolumesProtobuf,
  mergeLicensedAreaAndDatasetItems,
  mergeZoningLevelsCounts,
  parseCounts,
  parseNumbers,
  parseZoneIds,
} from "./helper";

export interface AnalyticsApiType {
  getGateDetails(datasetId: string, config: DatasetZoneDetailsArguments): Promise<ZoneDetails>;
  getZoneDetails(config: ZoneDetailsArguments): Promise<ZoneDetails>;
  getODIds(levels: string[], config: ZoneIdsArguments): Promise<ZoneIds>;
  getODCounts(levels: string[], config: ODCountsArguments): Promise<Counts>;
  getODCountsByZoneId(config: ODCountsByZoneIdArguments): Promise<CountsByZoneId>;
  getODMetadata(config: ODMetadataArguments): Promise<ODMetadata>;
  getSubareaState(config: SubareaStateArguments): Promise<SubareaState>;
  getSubareaPolygon(config: SubareaPolygonArguments): Promise<SubareaPolygon>;
  getGeneratedGates(config: GenerateGatesArguments): Promise<Gate[]>;
  getAddGate(config: AddGateArguments): Promise<AddGateResponse>;
  getGateCoordinates: (config: GateCoordinatesArguments) => Promise<GateCoordinates>;
  getRoadsMetadata(config: RoadsMetadataArguments): Promise<RoadsMetadata>;
  getRoadsVolumes(config: RoadsVolumesArguments): Promise<RoadsVolumes>;
  getRoadSegmentIds(config: RoadSegmentIdsArguments): Promise<RoadSegmentIdsWithFactype>;
  getSegmentsDetails(config: RoadSegmentsDetailsArguments): Promise<RoadSegmentDetails[]>;
  getFocusAreasAndDatasets(): Promise<FocusAreaItem[]>;

  // Datasets
  getDatasetCounts(datasetId: string, levels: string[], config: DatasetCountsArguments): Promise<Counts>;
  getDatasetIds(datasetId: string, levels: string[], config: ZoneIdsArguments): Promise<ZoneIds>;
  getDatasetCountsByZoneId(datasetId: string, config: DatasetCountsByZoneIdArguments): Promise<CountsByZoneId>;
  getDatasetMetadata(datasetId: string): Promise<DatasetMetadata>;
  getDatasetGates(datasetId: string): Promise<DatasetGate[]>;
  getDatasetFolders(): Promise<DatasetFolders>;
  addDatasetFolder(folderName: string): Promise<DatasetFolder>;
  renameDatasetFolder(folderId: UniqueIdentifier, folderName: string): Promise<DatasetFolder>;
  deleteDatasetFolder(folderId: UniqueIdentifier): Promise<boolean>;
  changeFolderIndex(folderId: UniqueIdentifier, index: number): Promise<CustomDatasetRepository>;
  addDatasetInFolder(config: CreateDatasetPayload): Promise<CustomDataset>;
  renameDataset(datasetId: UniqueIdentifier, datasetName: string): Promise<CustomDataset>;
  deleteDataset(datasetId: UniqueIdentifier): Promise<boolean>;
  changeCatalogItemIndex(
    folderId: UniqueIdentifier,
    catalogItemId: UniqueIdentifier,
    index: number,
  ): Promise<CatalogItem[]>;
  copyDataset(
    datasetId: UniqueIdentifier,
    datasetName: string,
    timePeriod: string,
  ): Promise<{ copiedDatasetId: string; items: CatalogItem[] }>;
  getODDatasetConfig(datasetId: UniqueIdentifier): Promise<ODDatasetConfig>;
  updateODDatasetConfig(datasetId: string, config: UpdateODDatasetConfigPayload): Promise<ODDatasetConfig>;
  createODDatasetConfig(config: NewODDatasetConfigPayload): Promise<ODDatasetConfig>;
  validateODDatasetConfig(datasetConfigId: string): Promise<ODDatasetValidation>;
  computeODDataset(datasetConfigId: string, notifyByEmail: boolean): Promise<ODDatasetComputation>;
  cancelODDatasetComputation(datasetConfigId: string): Promise<ODDatasetConfig>;
  getFocusAreas(customZoningId?: string | null, forDatasetCreation?: boolean): Promise<FocusAreaItem[]>;
  getRoadsMeasureRange(request: MeasureRangeRequest): Promise<MeasureRange>;
  getODMeasureRange(levels: string[], request: MeasureRangeRequest): Promise<ODMeasureRange>;
  getDatasetMeasureRange(datasetId: string, levels: string[], request: MeasureRangeRequest): Promise<ODMeasureRange>;

  // Custom zoning
  uploadZoningShapefiles(shapefiles: Blob, formats: ShapesInputFormat): Promise<UploadZoningResponse>;
  prepareZoning(config: PreparedZoningConfigRequest): Promise<UploadZoningResponse>;
  createZoning(zoningId: string, folderId: string, name: string, description: string): Promise<any>;
  deleteZoning(zoningId: string): Promise<boolean>;
  deleteCustomZoning(zoningId: string): Promise<boolean>;
  getCustomZoningSelectorList(): Promise<CustomZoningSelectorItemsResponse>;
  editZoning(zoningItemId: string, name: string, description: string): Promise<ZoningItem>;
  getZoning(zoningId: string): Promise<Zoning>;

  //Export
  getExportJobs(): Promise<ExportJobs>;
  addDatasetExportJob(datasetId: string, request: ODDatasetExportRequest): Promise<string>;
  addAoiExportJob(request: AoiExportRequest): Promise<string>;
  getNumZones(request: OdNumZonesRequest): Promise<NumZonesResponse>;

  // Mapbox Geocoding API
  getGeocoding(request: { searchText: string; token: string; proximity: string }): Promise<any>;

  // Select link analysis
  getSelectLinkSegmentCounts(config: SelectLinkSegmentCountsRequest): Promise<RoadsVolumes>;
  listSelectLinkConfigs(): Promise<SelectLinkConfig[]>;
  createSelectLinkConfig(request: SelectLinkConfigCreationRequest): Promise<SelectLinkConfig>;
  fetchSelectLinkConfig(configId: string): Promise<SelectLinkConfig>;
  updateSelectLinkConfig(configId: string, request: SelectLinkConfigUpdateRequest): Promise<SelectLinkConfig>;
  deleteSelectLinkConfig(configId: string): Promise<SelectLinkConfig>;

  // Corridor Discovery
  fetchCorridorMetadata(config: any): Promise<CorridorMetadata>;
  fetchCorridorEdgeIds(config: any): Promise<number[]>;
  fetchCorridorEdgeCounts(config: any): Promise<CorridorEdgeCounts>;
  fetchCorridorEdgeAvailableRange(config: EdgesRangeRequest): Promise<MeasureRangeResponse>;
  fetchCorridorEdgeDetails(config: any): Promise<any>;
  fetchCorridorNodeIds(config: any): Promise<number[]>;
  fetchCorridorNodeCounts(config: any): Promise<CorridorNodeCounts>;
  fetchCorridorHeatmapConfiguration(
    config: CorridorHeatmapConfigurationRequestParams,
  ): Promise<CorridorHeatmapConfiguration>;
  fetchServiceOverlayLayers(): Promise<ServiceOverlay[]>;

  // Road VMT Analysis
  fetchRoadVmtMetadata(config: RoadVmtMetadataRequest): Promise<RoadVmtMetadata>;
  fetchRoadVmtZoneCounts(level: string, config: RoadVmtZoneCountsRequest): Promise<RoadVmtZoneCounts>;
  fetchRoadVmtZoneDetails(config: RoadVmtZoneDetailsRequest): Promise<RoadVmtZoneDetails>;
}

export default function AnalyticsApi(restHandler: RestHandler) {
  return {
    async getDatasetCounts(datasetId: string, levels: string[], config: DatasetCountsArguments): Promise<Counts> {
      const allBodies = await Promise.all(
        levels.map((level) =>
          restHandler
            .postForBinary(`od/datasets/${datasetId}/counts`, {
              ...config,
              level: level,
            })
            .then((res) => {
              return parseCounts(res, level, "OdCountWithIsGate");
            }),
        ),
      );

      return mergeZoningLevelsCounts(allBodies);
    },

    async getDatasetCountsByZoneId(datasetId: string, config: DatasetCountsByZoneIdArguments): Promise<CountsByZoneId> {
      const counts = await restHandler.postForBinary(`od/datasets/${datasetId}/counts`, config).then((res) => {
        return parseCounts(res, config.selectedId, "OdCountWithIsGate");
      });

      return {
        counts,
        selectedZoneId: config.selectedId,
      };
    },

    async getDatasetIds(datasetId: string, levels: string[], config: ZoneIdsArguments): Promise<ZoneIds> {
      const allBodies = (await Promise.all(
        levels.map((level) =>
          restHandler.post(`od/datasets/${datasetId}/zone-ids/json`, {
            ...config,
            level: level,
          }),
        ),
      )) as ZoneIdAreaPairs[];

      return allBodies.reduce((zoneIds: ZoneIds, body: ZoneIdAreaPairs, i) => {
        zoneIds[levels[i]] = parseZoneIds(body);
        return zoneIds;
      }, {});
    },

    async getDatasetMetadata(datasetId: string): Promise<DatasetMetadata> {
      const body = await restHandler.get<DatasetMetadata>(`od/datasets/${datasetId}/metadata`);
      return body;
    },

    async getDatasetGates(datasetId: string): Promise<DatasetGate[]> {
      const body = await restHandler.get<DatasetGate[]>(`od/datasets/${datasetId}/gates`);
      return body;
    },

    async getGateDetails(datasetId: string, config: DatasetZoneDetailsArguments): Promise<ZoneDetails> {
      const body = await restHandler.post(`od/datasets/${datasetId}/selection-details`, config);
      return body as ZoneDetails;
    },

    /*
     Get zone details for a single zone
     Return details for a single selected zone, to be shown in an information sidebar or popup
     */
    async getZoneDetails(config: ZoneDetailsArguments): Promise<ZoneDetails> {
      const body = await restHandler.post(`od/zone-details`, config);
      return body as ZoneDetails;
    },

    /*
     Get incoming/outgoing counts for entitled zones in the area
     For all zones in the entitlement, get the outgoing or incoming trip counts for a specified filter configuration
     */
    async getODCounts(levels: string[], config: ODCountsArguments): Promise<Counts> {
      const allBodies = await Promise.all(
        levels.map((level) =>
          restHandler
            .postForBinary(`od/zone-counts`, {
              ...config,
              level: level,
            })
            .then((res) => {
              return parseCounts(res, level, "OdCount");
            }),
        ),
      );

      return mergeZoningLevelsCounts(allBodies);
    },

    async getODCountsByZoneId(config: ODCountsByZoneIdArguments): Promise<CountsByZoneId> {
      const counts = await restHandler.postForBinary(`od/zone-counts`, config).then((res) => {
        return parseCounts(res, config.selectedZoneId, "OdCount");
      });

      return {
        counts,
        selectedZoneId: config.selectedZoneId,
      };
    },

    async getODIds(levels: string[], config: ZoneIdsArguments): Promise<ZoneIds> {
      const allBodies = (await Promise.all(
        levels.map((level) =>
          restHandler.post(`od/zone-ids/json`, {
            ...config,
            level: level,
          }),
        ),
      )) as ZoneIdAreaPairs[];

      return allBodies.reduce((zoneIds: ZoneIds, body: ZoneIdAreaPairs, i) => {
        zoneIds[levels[i]] = parseZoneIds(body);

        return zoneIds;
      }, {});
    },

    /*
     Return metadata needed to create the filter UI and render the OD map content
    */
    async getODMetadata(config: ODMetadataArguments): Promise<ODMetadata> {
      const body = await restHandler.post(`od/metadata`, config);
      return body as ODMetadata;
    },

    /*
     While editing the sub-area geometry, the selected zones as well as the road segments that are candidates for gates (intersecting boundary, selected facility types) should be highlighted. This endpoint returns both the zone ids and the from-two segment ids of the road features
    */
    async getSubareaState(config: SubareaStateArguments): Promise<SubareaState> {
      const body = await restHandler.post(`od/config/subarea-state`, config);
      return body as SubareaState;
    },

    /*
     Return polygon for a given subarea
    */
    async getSubareaPolygon(config: SubareaPolygonArguments): Promise<SubareaPolygon> {
      const body: any = await restHandler.post(`/od/config/subarea-polygon`, config);

      return body as SubareaPolygon;
    },

    /*
     Return possible gates for a given subarea
    */
    async getGeneratedGates(config: GenerateGatesArguments): Promise<Gate[]> {
      const body: any = await restHandler.post(`/od/config/gates/generate`, config);
      return body.gates as Gate[];
    },

    /*
     Return new Gate and list of updated, unchanged and deleted gates
    */
    async getAddGate(config: AddGateArguments): Promise<AddGateResponse> {
      const body: any = await restHandler.post(`/od/config/gates/add`, config);
      return body as AddGateResponse;
    },

    /*
     Return Gate new centroid coordinates based on his segments
    */
    async getGateCoordinates(config: GateCoordinatesArguments): Promise<GateCoordinates> {
      const body: any = await restHandler.post(`/od/config/gates/getcoordinates`, config);
      return body as GateCoordinates;
    },

    /*
     Return metadata needed to create the filter UI and render the roads map content
    */
    async getRoadsMetadata(config: RoadsMetadataArguments): Promise<RoadsMetadata> {
      const body = await restHandler.post(`roads/metadata`, config);
      return body as RoadsMetadata;
    },

    /*
     Return volumes for road segments
    */
    async getRoadsVolumes(config: RoadsVolumesArguments): Promise<RoadsVolumes> {
      const response = getVolumesProtobuf(restHandler, "roads/segment-counts", config, "SegmentCount");
      return response.then(
        (res) =>
          ({
            measure: config.measure,
            segmentVolumes: res?.volumes,
            maxVolume: res?.maxVolume,
            minVolume: res?.minVolume,
          } as RoadsVolumes),
      );
    },

    /*
     Return road segment ids needed to filter the road segments by id
    */
    async getRoadSegmentIds(config: RoadSegmentIdsArguments): Promise<RoadSegmentIdsWithFactype> {
      return await restHandler.postForBinary(`roads/segment-ids`, config).then((res) => {
        const segmentIdsWithFactype: RoadSegmentIdsWithFactype = {};
        const buffer = new Uint8Array(res);
        const reader = protobuf.Reader.create(buffer);

        return Promise.resolve(
          protobuf
            .load("/SegmentIds.proto")
            .then((root: any) => {
              const SegmentCount = root.lookupType("analytics.clickhouse.SegmentIds");

              while (reader.pos < reader.len) {
                const msg = SegmentCount.decodeDelimited(reader);
                segmentIdsWithFactype[msg.segmentId] = {
                  reverseSegmentId: msg.segmentIdReverse,
                  factype: msg.roadClass,
                };
              }

              return segmentIdsWithFactype;
            })
            .catch((err: any) => {
              throw new Error(err);
            }),
        );
      });
    },

    // Get road segment details for a single segment
    async getSegmentsDetails(config: RoadSegmentsDetailsArguments): Promise<RoadSegmentDetails[]> {
      const body: any = await restHandler.post(`roads/segment-details`, config);
      return body?.segments as RoadSegmentDetails[];
    },

    // Get dataset folders structure
    async getDatasetFolders(): Promise<DatasetFolders> {
      const body: DatasetFoldersResponse = await restHandler.get(
        "catalog/folder-v2?recursive=true&include-permissions=true",
      );

      const folders: CustomDatasetRepository = {};

      for (const folder of body.folders) {
        folders[folder.folderId] = {
          folderName: folder.folderName,
          items: folder?.items || [],
          permissions: folder.permissions,
        };
      }

      return Promise.resolve({ folders, permissions: body.permissions });
    },

    // Create dataset folder
    async addDatasetFolder(folderName: string): Promise<DatasetFolder> {
      const body = (await restHandler.post(`catalog/folder`, {
        folderName,
      })) as DatasetFolder;

      return Promise.resolve(body);
    },

    // Rename dataset folder
    async renameDatasetFolder(folderId: UniqueIdentifier, folderName: string): Promise<DatasetFolder> {
      const body = (await restHandler.put(`catalog/folder/${folderId}`, {
        folderName,
      })) as DatasetFolder;

      return Promise.resolve(body);
    },

    // Delete dataset folder
    async deleteDatasetFolder(folderId: UniqueIdentifier): Promise<boolean> {
      const body = (await restHandler.delete(`catalog/folder/${folderId}`)) as DatasetFolder;

      if (body.folderId === folderId) {
        return Promise.resolve(true);
      }

      return Promise.resolve(false);
    },

    // Change dataset folder index
    async changeFolderIndex(folderId: UniqueIdentifier, index: number): Promise<CustomDatasetRepository> {
      const body = (await restHandler.put(`catalog/folder/${folderId}/index?recursive=true`, {
        index,
      })) as { updatedFolderList: DatasetFolder[] };

      const folders: CustomDatasetRepository = {};

      for (const folder of body.updatedFolderList) {
        folders[folder.folderId] = {
          folderName: folder.folderName,
          items: folder?.items || [],
          permissions: folder.permissions,
        };
      }

      return Promise.resolve(folders);
    },

    // Create dataset
    async addDatasetInFolder(config: CreateDatasetPayload): Promise<CustomDataset> {
      const body = (await restHandler.post(`catalog/dataset`, config)) as CustomDataset;

      return body;
    },

    // Rename dataset
    async renameDataset(datasetId: UniqueIdentifier, datasetName: string): Promise<CustomDataset> {
      const body = (await restHandler.put(`catalog/dataset/${datasetId}`, {
        datasetName,
      })) as CustomDataset;

      return body;
    },

    // Delete dataset
    async deleteDataset(datasetId: UniqueIdentifier): Promise<boolean> {
      const body = (await restHandler.delete(`catalog/dataset/${datasetId}`)) as CustomDataset;

      if (body.id === datasetId) {
        return Promise.resolve(true);
      }

      return Promise.resolve(false);
    },

    // Change dataset index
    async changeCatalogItemIndex(
      folderId: UniqueIdentifier,
      catalogItemId: UniqueIdentifier,
      index: number,
    ): Promise<CatalogItem[]> {
      const body = (await restHandler.put(`catalog/item/${catalogItemId}/index?recursive=true`, {
        folderId,
        index,
      })) as { updatedTargetFolderItemList: CatalogItem[] };

      return body.updatedTargetFolderItemList;
    },

    // Copy dataset
    async copyDataset(
      datasetId: UniqueIdentifier,
      datasetName: string,
      timePeriod: string,
    ): Promise<{ copiedDatasetId: string; items: CatalogItem[] }> {
      const body: any = await restHandler.post(`catalog/dataset/${datasetId}/copy`, {
        datasetName,
        timePeriod,
      });

      return { copiedDatasetId: body.datasetId, items: body.items };
    },

    // Get OD dataset configuration
    async getODDatasetConfig(datasetId: UniqueIdentifier): Promise<ODDatasetConfig> {
      const body = (await restHandler.get(`od/config/${datasetId}`)) as ODDatasetConfig;

      return body;
    },

    // Update OD dataset configuration
    async updateODDatasetConfig(datasetId: string, config: UpdateODDatasetConfigPayload): Promise<ODDatasetConfig> {
      const body = (await restHandler.put(`od/config/${datasetId}`, config)) as ODDatasetConfig;

      return body;
    },

    // Create OD dataset configuration
    async createODDatasetConfig(configBase: NewODDatasetConfigPayload): Promise<ODDatasetConfig> {
      const body = (await restHandler.post("od/config", configBase)) as ODDatasetConfig;

      return body;
    },

    // Validate the persisted configuration
    async validateODDatasetConfig(datasetConfigId: string): Promise<ODDatasetValidation> {
      const body: any = await restHandler.post(`od/config/${datasetConfigId}/validation`, {});

      return body as ODDatasetValidation;
    },

    // Compute OD dataset
    async computeODDataset(datasetConfigId: string, notifyByEmail: boolean): Promise<ODDatasetComputation> {
      const body: any = await restHandler.post(`od/config/${datasetConfigId}/computation`, {
        disableEmailNotifications: !notifyByEmail,
      });

      return body as ODDatasetComputation;
    },

    // Cancel OD dataset computation
    async cancelODDatasetComputation(datasetConfigId: string): Promise<ODDatasetConfig> {
      const body: any = await restHandler.delete(`od/config/${datasetConfigId}/computation`);

      return body as ODDatasetConfig;
    },

    // Get measure range for roads
    async getRoadsMeasureRange(request: MeasureRangeRequest): Promise<MeasureRange> {
      const body: any = await restHandler.post("/roads/measure-range", request);

      return body.range;
    },

    // Get measure range for OD
    async getODMeasureRange(levels: string[], request: MeasureRangeRequest): Promise<ODMeasureRange> {
      const allBodies: any = await Promise.all(
        levels.map((level) => restHandler.post("/od/measure-range", { ...request, level })),
      );

      return levels.reduce((obj: { [key: string]: any }, level, i: number) => {
        obj[level] = allBodies[i].range;
        return obj;
      }, {});
    },

    // Get measure range for dataset
    async getDatasetMeasureRange(
      datasetId: string,
      levels: string[],
      request: MeasureRangeRequest,
    ): Promise<ODMeasureRange> {
      const allBodies: any = await Promise.all(
        levels.map((level) => restHandler.post(`/od/datasets/${datasetId}/measure-range`, { ...request, level })),
      );

      return levels.reduce((obj: { [key: string]: any }, level, i: number) => {
        obj[level] = allBodies[i].range;
        return obj;
      }, {});
    },

    // ----- EXPORT -------

    // Get a list of export jobs for the logged in user
    async getExportJobs(): Promise<ExportJobs> {
      const response = await restHandler.post("export/list-jobs", {});
      return response as ExportJobs;
    },

    // Add new dataset export job to the queue
    async addDatasetExportJob(datasetId: string, request: ODDatasetExportRequest): Promise<string> {
      const response = await restHandler.post(`export/dataset/${datasetId}`, request);
      return response as string;
    },

    // Add new aoi export job to the queue
    async addAoiExportJob(request: AoiExportRequest): Promise<string> {
      const response = await restHandler.post("export/aoi", request);
      return response as string;
    },

    // Get number of zones for the area of interest within entitled area of the user
    async getNumZones(request: OdNumZonesRequest): Promise<NumZonesResponse> {
      const response = await restHandler.post("od/num-zones", request);
      return response as NumZonesResponse;
    },

    async getGeocoding(request: { searchText: string; token: string; proximity: string }): Promise<any> {
      const { searchText, token, proximity } = request;
      const response = await fetch(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${searchText}.json?access_token=${token}&autocomplete=true&country=US&language=en&types=region,place,postcode,locality,neighborhood,address&proximity=${proximity}&limit=8`,
      );

      return response.json();
    },

    async getFocusAreasAndDatasets(): Promise<FocusAreaItem[]> {
      const urls = [
        "dashboard/licensed-area-item?includeGeometry=true&includeAreaUnits=true",
        "dashboard/dataset-item?includeGeometry=true&includeAreaUnits=true",
      ];
      const focusAreaItems = await Promise.all(urls.map(async (url) => await restHandler.get(url)));

      return mergeLicensedAreaAndDatasetItems(focusAreaItems) as FocusAreaItem[];
    },

    async getFocusAreas(customZoningId?: string | null, forDatasetCreation?: boolean): Promise<FocusAreaItem[]> {
      const focusAreaItems: any = await restHandler.get(
        `dashboard/licensed-area-item?includeAreaUnits=true` +
          (customZoningId ? `&customZoningId=${customZoningId}` : ``) +
          (forDatasetCreation ? `&forDatasetCreation=true` : ``),
      );
      return mergeLicensedAreaAndDatasetItems([focusAreaItems]) as FocusAreaItem[];
    },

    async uploadZoningShapefiles(shapefiles: Blob, formats: ShapesInputFormat): Promise<UploadZoningResponse> {
      const response = await restHandler.post(`zoning/staged?format=${formats}`, shapefiles, {
        headers: { "content-type": "application/octet-stream" },
      });

      return response as UploadZoningResponse;
    },

    async prepareZoning(config: PreparedZoningConfigRequest): Promise<UploadZoningResponse> {
      const response = await restHandler.post(`zoning/prepared`, config);

      return response as UploadZoningResponse;
    },

    async createZoning(zoningId: string, folderId: string, name: string, description: string): Promise<any> {
      const response = await restHandler.post(`/catalog/zoning`, {
        preparedZoningId: zoningId,
        zoningName: name,
        folderId,
        description,
      });

      return response;
    },

    async deleteZoning(zoningId: string): Promise<boolean> {
      const body: any = await restHandler.delete(`zoning/${zoningId}`);

      return body?.zoningId === zoningId;
    },

    async deleteCustomZoning(zoningId: string): Promise<boolean> {
      const body: any = await restHandler.delete(`catalog/zoning/${zoningId}`);

      return body?.zoningId === zoningId;
    },

    async editZoning(zoningItemId: string, name: string, description: string): Promise<ZoningItem> {
      const body: any = await restHandler.put(`catalog/zoning/${zoningItemId}`, {
        zoningName: name,
        description,
      });

      return body as ZoningItem;
    },

    async getZoning(zoningId: string): Promise<Zoning> {
      const body: any = await restHandler.get(`zoning/${zoningId}`);

      return body as Zoning;
    },

    async getCustomZoningSelectorList(): Promise<CustomZoningSelectorItemsResponse> {
      const body: CustomZoningSelectorItemsResponse = await restHandler.get(`od/config/custom-zoning`);

      return body;
    },

    // Return segment counts for select link analysis
    async getSelectLinkSegmentCounts(config: SelectLinkSegmentCountsRequest): Promise<RoadsVolumes> {
      const response = getVolumesProtobuf(restHandler, "select-link/segment-counts", config, "SelectLinkSegmentCount");
      return response.then(
        (res) =>
          ({
            measure: config.measure,
            segmentVolumes: res?.volumes,
            maxVolume: res?.maxVolume,
            minVolume: res?.minVolume,
          } as RoadsVolumes),
      );
    },

    async listSelectLinkConfigs(): Promise<SelectLinkConfig[]> {
      const response = await restHandler.get("select-link/config", {});
      return response as SelectLinkConfig[];
    },

    async createSelectLinkConfig(request: SelectLinkConfigCreationRequest): Promise<SelectLinkConfig> {
      const response = await restHandler.post("select-link/config", request);
      return response as SelectLinkConfig;
    },

    async fetchSelectLinkConfig(configId: string): Promise<SelectLinkConfig> {
      const response = await restHandler.get(`select-link/config/${configId}`);
      return response as SelectLinkConfig;
    },

    async updateSelectLinkConfig(configId: string, request: SelectLinkConfigUpdateRequest): Promise<SelectLinkConfig> {
      const response = await restHandler.put(`select-link/config/${configId}`, request);
      return response as SelectLinkConfig;
    },

    async deleteSelectLinkConfig(configId: string): Promise<SelectLinkConfig> {
      const response = await restHandler.delete(`select-link/config/${configId}`);
      return response as SelectLinkConfig;
    },

    // Corridor Discovery
    async fetchCorridorMetadata(config: any): Promise<CorridorMetadata> {
      const body = await restHandler.post(`corridor/metadata`, config);
      return body as CorridorMetadata;
    },

    async fetchCorridorEdgeIds(config: any): Promise<number[]> {
      const counts = await restHandler.postForBinary(`corridor/edge-ids`, config).then((res) => {
        return parseNumbers(res, "CorridorEdgeIds", "edgeId");
      });

      return counts as number[];
    },

    async fetchCorridorEdgeCounts(config: any): Promise<CorridorEdgeCounts> {
      const response = getVolumesProtobuf(restHandler, "corridor/edge-counts", config, "CorridorEdgeCount", "edgeId");

      return response.then((res) => ({
        volumes: res?.volumes,
        maxVolume: res?.maxVolume,
        minVolume: res?.minVolume,
      }));
    },

    async fetchCorridorEdgeAvailableRange(config: EdgesRangeRequest): Promise<MeasureRangeResponse> {
      const body: any = await restHandler.post(`corridor/edge-counts-range`, config);

      return body;
    },

    async fetchCorridorNodeIds(config: any): Promise<number[]> {
      const counts = await restHandler.postForBinary(`corridor/node-ids`, config).then((res) => {
        return parseNumbers(res, "CorridorNodeIds", "nodeId");
      });

      return counts as number[];
    },

    async fetchCorridorNodeCounts(config: any): Promise<CorridorNodeCounts> {
      const response = getVolumesProtobuf(restHandler, "corridor/node-counts", config, "CorridorNodeCount", "nodeId");

      return response.then((res) => ({
        volumes: res?.volumes,
        maxVolume: res?.maxVolume,
      }));
    },

    async fetchCorridorHeatmapConfiguration(
      config: CorridorHeatmapConfigurationRequestParams,
    ): Promise<CorridorHeatmapConfiguration> {
      const body = await restHandler.post(`corridor/heatmap-configuration`, config);

      return body as CorridorHeatmapConfiguration;
    },

    async fetchServiceOverlayLayers(): Promise<ServiceOverlay[]> {
      const body: any = await restHandler.get("overlays/service-layer");
      return body?.featureServiceLayers as ServiceOverlay[];
    },

    async fetchCorridorEdgeDetails(config: any): Promise<any> {
      const body = await restHandler.post(`corridor/edge-details`, config);
      return body as any;
    },

    // Road VMT Analysis
    async fetchRoadVmtMetadata(config: RoadVmtMetadataRequest): Promise<RoadVmtMetadata> {
      const body = await restHandler.post(`road-vmt/metadata`, config);
      return body as RoadVmtMetadata;
    },

    async fetchRoadVmtZoneCounts(level: string, config: RoadVmtZoneCountsRequest): Promise<RoadVmtZoneCounts> {
      const { zones, availableRange } = await restHandler.postForBinary(`road-vmt/zone-counts`, config).then((res) => {
        return parseCounts(res, level, "RoadVmtZoneCount");
      });

      return {
        zones,
        availableRange,
      } as RoadVmtZoneCounts;
    },

    async fetchRoadVmtZoneDetails(config: RoadVmtZoneDetailsRequest): Promise<RoadVmtZoneDetails> {
      const body = await restHandler.post(`road-vmt/zone-details`, config);
      return body as RoadVmtZoneDetails;
    },
  };
}
