import React, { FC } from "react";
import styled from "@emotion/styled";
import { Collapse as BpCollapse, Icon } from "@blueprintjs/core";

import { QualityPointType } from "./qualityPoints";

export interface QualityPointProps extends QualityPointType {
  isOpen: boolean;
  handleToggle: () => void;
}

const QualityPointContainer = styled.div`
  padding: 0.5rem;
  margin: 0.5rem 0;
  border-bottom: 1px solid var(--color-border);
`;

const QualityPointControl = styled.div<{ isOpen: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  font-weight: ${({ isOpen }) => (isOpen ? 700 : 500)};
  color: ${({ isOpen }) => (isOpen ? "color: var(--color-text)" : "var(--color-textSecondary)")};
  cursor: pointer;
  & .bp4-icon > svg:not([fill]) {
    color: var(--color-gray-400);
  }
  &:hover {
    color: var(--color-text);
  }
`;

const Collapse = styled(BpCollapse)`
  color: var(--color-textSecondary);
  & .bp4-collapse-body {
    margin-top: 1.5rem;
  }
`;

const SubTitle = styled.p`
  font-weight: 500;
  margin-bottom: 1rem;
`;

export const QualityPoint: FC<QualityPointProps> = ({ children, title, subtitle, isOpen, handleToggle }) => {
  return (
    <QualityPointContainer>
      <QualityPointControl isOpen={isOpen} onClick={handleToggle}>
        {title} <Icon icon={isOpen ? "chevron-up" : "chevron-down"} />
      </QualityPointControl>
      <Collapse isOpen={isOpen}>
        <SubTitle>{subtitle}</SubTitle>
        {children}
      </Collapse>
    </QualityPointContainer>
  );
};
