import styled from "@emotion/styled";

export const PageContainer = styled.div`
  position: fixed;
  display: grid;
  grid-template-columns: auto minmax(700px, 1200px) auto;
  grid-template-rows: calc(100% - 64px);
  width: 100%;
  height: calc(100% - 100px);
  padding: 1rem 4rem 3rem 4rem;
  overflow-y: auto;
  background-color: var(--color-gray-50);
`;
