import styled from "@emotion/styled";
import { Alert } from "@mui/material";
import React, { useState } from "react";

import { Button, Dialog, FlexContainer, Input, TextArea } from "components";

import { SelectLinkAnalysisDetails } from "components/pages/analytics/select-link/types";

import { useAppSelector } from "hooks";

import { FocusAreaItem } from "types";

import { AreaOfFocusSelector } from "../../components/organisms/area-of-focus-selector/AreaOfFocusSelector";
import { TimePeriodSelector } from "../../components/organisms/time-period-selector/TimePeriodSelector";

interface Props {
  title?: string;
  buttonLabel?: string;
  areas: FocusAreaItem[];
  defaultAreaId: string;
  configId?: string;
  initialName?: string;
  initialDescription?: string;
  errorMessage?: string | null;
  onSubmit: (params: SelectLinkAnalysisDetails) => void;
  onClose: () => void;
}

const Label = styled.label`
  font-size: 14px;
  margin: 10px 0;
  color: #2a2e33;
`;

const SubduedLabel = styled.label`
  font-size: 11px;
  margin: 10px 4px;
  color: #6a6d70;
`;

const TitleText = styled.div`
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 24px;
  text-align: center;
`;

const SubmitButton = styled(Button)`
  margin-top: 24px;
`;

export const EditSelectLinkOptionsDialog = ({
  areas,
  defaultAreaId,
  configId,
  initialName,
  initialDescription,
  errorMessage,
  onSubmit,
  onClose,
}: Props) => {
  return (
    <SelectLinkOptionsDialog
      title="Rename Select Link analysis"
      buttonLabel="Save analysis"
      areas={areas}
      defaultAreaId={defaultAreaId}
      configId={configId}
      initialName={initialName}
      initialDescription={initialDescription}
      onSubmit={onSubmit}
      onClose={onClose}
    />
  );
};

export const SelectLinkOptionsDialog = ({
  title,
  buttonLabel,
  areas,
  defaultAreaId,
  configId,
  initialName,
  initialDescription,
  errorMessage,
  onSubmit,
  onClose,
}: Props) => {
  const [focusAreaId, setFocusAreaId] = useState(defaultAreaId);
  const [analysisName, setAnalysisName] = useState(initialName || "");
  const [description, setDescription] = useState(initialDescription || "");
  const [timePeriod, setTimePeriod] = useState("");

  const availableTimePeriods = areas.find((area) => area.id === focusAreaId)?.timePeriods || [];

  const loading = useAppSelector((state) => state.selectLink.loading);

  const handleAreaChange = (areaId: string) => {
    setFocusAreaId(areaId);
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAnalysisName(e.target.value);
  };

  const handleDescriptionChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setDescription(e.target.value);
  };

  return (
    <Dialog isOpen onClose={onClose}>
      <TitleText>{title}</TitleText>

      {errorMessage && (
        <Alert severity="error" sx={{ mt: 2 }}>
          {errorMessage}
        </Alert>
      )}

      <div>
        <FlexContainer style={{ alignItems: "baseline" }}>
          <Label>Analysis name</Label>
          <SubduedLabel>(unique)</SubduedLabel>
        </FlexContainer>
        <Input value={analysisName} onChange={handleNameChange} disabled={loading || !areas.length} />

        <FlexContainer style={{ alignItems: "baseline" }}>
          <Label>Analysis description</Label>
          <SubduedLabel>(optional)</SubduedLabel>
        </FlexContainer>
        <TextArea
          style={{ width: "100%" }}
          id="description"
          value={description}
          onChange={handleDescriptionChange}
          disabled={loading || !areas.length}
        />
      </div>

      <AreaOfFocusSelector
        areas={areas}
        defaultSelectedAreaId={defaultAreaId}
        onAreaChange={handleAreaChange}
        disabled={loading || !!configId}
      />
      <TimePeriodSelector
        timePeriods={availableTimePeriods}
        selectedTimePeriod={timePeriod}
        setSelectedTimePeriod={setTimePeriod}
        disabled={loading || !!configId}
      />
      <SubmitButton
        color="primaryLight"
        onClick={() => onSubmit({ analysisName, timePeriod, description, focusAreaId, configId })}
        disabled={!analysisName}
        loading={loading}
      >
        {buttonLabel}
      </SubmitButton>
    </Dialog>
  );
};
