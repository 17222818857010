import { IconName } from "@blueprintjs/core";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import React, { FC } from "react";

import { Badge } from "components";

export interface VolumeCountProps {
  icon: IconName | null;
  count: number;
  couldBeAdded?: boolean;
  disabled?: boolean;
  onClick?: () => void;
}

const Container = styled.td`
  padding: 0 0.25rem;
`;

const StyledBadge = styled(Badge, {
  shouldForwardProp: (prop) => prop !== "addable",
})<{ addable?: boolean; disabled?: boolean }>`
  text-align: right;
  width: 100%;
  margin: 0.25rem 0;
  color: ${({ disabled }) => (disabled ? "#b3b3b3" : "var(--color-text)")};
  background-color: ${({ disabled }) => disabled && "#e0e0e0"};

  & .bp4-icon {
    margin-right: 0.5rem;
    & > svg:not([fill]) {
      fill: ${({ disabled }) => (disabled ? "#b3b3b3" : "var(--color-secondary)")};
    }
  }

  ${({ addable = false }) =>
    addable
      ? css`
          &:hover,
          &:focus {
            border-color: #183462;
            background-color: #d7dde6 !important;
          }
        `
      : undefined}
`;

export const VolumeCount: FC<VolumeCountProps> = ({ icon, count, couldBeAdded, disabled, onClick }) => {
  return (
    <Container onClick={onClick}>
      <StyledBadge icon={icon} color="secondary" addable={couldBeAdded} disabled={disabled}>
        {(count || 0).toLocaleString("en-US")}
      </StyledBadge>
    </Container>
  );
};
