import styled from "@emotion/styled";
import { Dispatch, FC, SetStateAction, memo } from "react";

import { RoadAnalytics, leftSideDirectionsIcons } from "features";

import { ODAnalytics } from "features/analytics/ODAnalytics";

import { Loader, RightSidebarPanel } from "components";

import { useAppSelector } from "hooks";

import { DataState } from "store/interfaces";

import {
  FlowsSettings,
  HoveredFlow,
  QueryType,
  RoadSegmentDetails,
  SelectedArea,
  SelectedVolume,
  ZoningLevel,
} from "types";

interface AnalyticsPanelProps {
  filterLoading: boolean;
  isPedestriansMode: boolean;
  selectedRoadVolume: SelectedVolume | null;
  selectedRoadVolumeId: string | null;
  selectedZone: SelectedArea | null;
  queryType: QueryType;
  hoveredFlow: HoveredFlow | null;
  flowsSettings: FlowsSettings;
  zoningLevels: ZoningLevel[] | undefined;
  setHoveredFlow: Dispatch<SetStateAction<HoveredFlow | null>>;
  setFlowsSettings: Dispatch<SetStateAction<FlowsSettings>>;
  handleChangeRoadVolumeId: (id: string) => void;
  handleCloseRightSidebar: () => void;
}

const RightSidebarLoaderWrapper = styled.div`
  height: 80%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const AnalyticsPanel: FC<AnalyticsPanelProps> = memo(
  ({
    filterLoading,
    isPedestriansMode,
    selectedRoadVolume,
    selectedRoadVolumeId,
    selectedZone,
    queryType,
    hoveredFlow,
    flowsSettings,
    zoningLevels,
    setHoveredFlow,
    setFlowsSettings,
    handleChangeRoadVolumeId,
    handleCloseRightSidebar,
  }) => {
    const selectedFocusArea = useAppSelector((state) => state.global.selectedFocusArea);
    const measure = useAppSelector((state) => state.analytics.measure);

    const ODMetadata = useAppSelector((state) => state.analytics.ODMetadata);
    const roadsMetadata = useAppSelector((state) => state.analytics.roadsMetadata);
    const datasetMetadata = useAppSelector((state) => state.analytics.datasetMetadata);

    const ODCounts = useAppSelector((state) => state.analytics.ODCounts);
    const datasetCounts = useAppSelector((state) => state.analytics.datasetCounts);

    const roadSegmentsDetails = useAppSelector((state) => state.analytics.roadSegmentsDetails);
    const zoneDetails = useAppSelector((state) => state.analytics.zoneDetails);

    const getRoadSegmentSubtitle = (
      isShowRightSidebarForRoads: boolean | null,
      roadSegmentsDetailsData: Record<string, RoadSegmentDetails>,
      selectedRoadVolumeId: string | null,
    ) => {
      if (!selectedRoadVolumeId) {
        return "";
      }

      if (isPedestriansMode) {
        return `Annual average daily trips ${
          isShowRightSidebarForRoads && roadSegmentsDetailsData
            ? Object.values(roadSegmentsDetailsData)
                .reduce((partialSum, data) => partialSum + data.summary.filteredTotal, 0)
                .toLocaleString("en-US")
            : "..."
        }`;
      }

      return `Annual average daily trips ${
        isShowRightSidebarForRoads && roadSegmentsDetailsData
          ? roadSegmentsDetailsData[selectedRoadVolumeId]?.summary.filteredTotal.toLocaleString("en-US")
          : "..."
      }`;
    };

    const isShowRightSidebarForRoads =
      selectedRoadVolume && roadSegmentsDetails.state === DataState.AVAILABLE && roadSegmentsDetails?.data;
    const isSegmentDetailsLoading =
      selectedRoadVolume && (roadSegmentsDetails.state === DataState.LOADING || filterLoading);

    const isShowRightSidebarForOD =
      selectedZone &&
      zoneDetails.state === DataState.AVAILABLE &&
      (selectedFocusArea?.datasetId
        ? datasetCounts.state === DataState.AVAILABLE
        : ODCounts.state === DataState.AVAILABLE) &&
      zoneDetails;

    const isODDetailsLoading = zoneDetails.state === DataState.LOADING;

    return (
      <>
        <RightSidebarPanel
          title={isSegmentDetailsLoading ? "Loading..." : (selectedRoadVolume?.streetName as string)}
          subtitle={getRoadSegmentSubtitle(
            isShowRightSidebarForRoads && !isSegmentDetailsLoading,
            roadSegmentsDetails.data!,
            selectedRoadVolumeId,
          )}
          cardType="road-segment"
          onClose={handleCloseRightSidebar}
          isOpen={!!isShowRightSidebarForRoads || !!isSegmentDetailsLoading}
        >
          {!isSegmentDetailsLoading && isShowRightSidebarForRoads && selectedRoadVolumeId && (
            <RoadAnalytics
              breakdowns={roadSegmentsDetails.data[selectedRoadVolumeId]?.summary.breakdowns}
              selectedRoadVolumeId={selectedRoadVolumeId}
              selectedRoadVolume={selectedRoadVolume}
              roadMetadataDimensions={
                roadsMetadata.data?.measures.find((m) => m.columnName === measure)?.dimensions || []
              }
              leftSideDirectionsIcons={leftSideDirectionsIcons}
              selectedSegmentId={selectedRoadVolumeId}
              changeRoadVolumeId={handleChangeRoadVolumeId}
              isPedestriansMode={isPedestriansMode}
            />
          )}
          {isSegmentDetailsLoading && (
            <RightSidebarLoaderWrapper>
              <Loader />
            </RightSidebarLoaderWrapper>
          )}
        </RightSidebarPanel>

        <RightSidebarPanel
          title={
            zoneDetails?.data?.zoneId
              ? `Selected ${zoneDetails.data?.isGate ? "Gate" : "Zone"} (${zoneDetails.data.zoneId})`
              : "Loading..."
          }
          subtitle={`Annual average daily trips ${
            zoneDetails?.data?.summaries[queryType]?.filteredTotal.toLocaleString("en-US") || "..."
          }`}
          cardType="od"
          onClose={handleCloseRightSidebar}
          isOpen={!!isShowRightSidebarForOD || !!isODDetailsLoading}
        >
          {isShowRightSidebarForOD && (
            <ODAnalytics
              isCustomDataset={!!selectedFocusArea?.datasetId}
              selectedZone={selectedZone}
              zoneDetails={zoneDetails.data}
              flowsDirection={queryType}
              flowsSettings={flowsSettings}
              zoningLevels={zoningLevels}
              setFlowsSettings={setFlowsSettings}
              hoveredFlow={hoveredFlow}
              setHoveredFlow={setHoveredFlow}
              ODMetadataDimensions={
                selectedFocusArea?.datasetId
                  ? datasetMetadata.data?.measures[0].dimensions
                  : ODMetadata.data?.measures[0].dimensions
              }
            />
          )}
          {isODDetailsLoading && (
            <RightSidebarLoaderWrapper>
              <Loader />
            </RightSidebarLoaderWrapper>
          )}
        </RightSidebarPanel>
      </>
    );
  },
);
