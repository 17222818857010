import { useAuth0 } from "@auth0/auth0-react";
import styled from "@emotion/styled";
import mapboxgl, { LngLatBounds, LngLatLike, Marker } from "mapbox-gl";
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import {
  GeocodingSearch,
  LayersData,
  MainRoadsColorIds,
  MainRoadsLayerIds,
  ZONE_BORDERS_LAYER_NAME,
  ZONE_SOURCE_ID,
  addBoundariesLayer,
  buildFilters,
  getAvailableZoomLevels,
  getBounds,
  getLayerFromZoom,
  initOD,
  initRoadSegments,
} from "features";

import { NewExportDialog } from "features/export";
import { MapControlsPanel } from "features/map-visualization/MapControlsPanel";
import { MapBoxStyles } from "features/map-visualization/styles";
import { EmptyResultWarning } from "features/select-link/EmptyResultWarning";
import { SelectLinkConfigPanel } from "features/select-link/SelectLinkConfigPanel";
import {
  SELECTED_ZONE_SOURCE_ID,
  SelectLinkLayerIds,
  SelectLinkRoads,
  initSelectLinkRoads,
  initSelectedZones,
} from "features/select-link/selectLinkMap";
import { SegmentMarker } from "features/select-link/types";

import {
  Loader,
  MapErrorPage,
  ODPopup,
  ODPopupProps,
  Popup,
  RightSidebar,
  RoadsHoverPopup,
  RoadsHoverPopupProps,
  SpinnerOverlay,
  ToggleButtons,
  VolumePopupContent,
  getMapHomeControl,
} from "components";

import { useAppDispatch, useAppSelector, usePageTracking } from "hooks";

import { DataState } from "store/interfaces";
import { analyticsActions } from "store/sections/analytics";
import { exportActions } from "store/sections/export";
import { mapActions } from "store/sections/map";
import { selectLinkActions } from "store/sections/selectLink";

import {
  Counts,
  FiltersType,
  FocusAreaItem,
  MapVisualizationType,
  MeasureType,
  QueryType,
  SegmentsGroup,
  SelectLinkConfigUpdateRequest,
  SelectLinkPredicateLogic,
  SelectedArea,
  SelectedSegmentConfig,
  SelectedVolume,
  Volume,
} from "types";

import { addCustomGAEvent } from "utils/addCustomGAEvent";
import { getSelectedSegmentConfig } from "utils/ui";

import { SelectLinkAnalysisMode, SelectLinkAnalysisOptions, ZoneSelectionMode } from "./types";
import {
  DefaultMinCount,
  addSegmentToGroups,
  buildSegmentsPredicate,
  buildZonesPredicate,
  deleteMarkerForSegmentAndRemoveFromMap,
  deleteSegmentFromGroup,
  segmentExistsInGroups,
  updateFeatureState,
} from "./utils";

import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import "mapbox-gl/dist/mapbox-gl.css";

/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable import/no-webpack-loader-syntax */
(mapboxgl as any).workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

/* eslint-enable @typescript-eslint/no-var-requires */
/* eslint-enable import/no-webpack-loader-syntax */

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN as string;

const SelectLinkPageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const Mapbox = styled.div`
  height: 100%;
`;

const RightSidebarLoaderWrapper = styled.div`
  height: 80%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SelectHighlightFeature = "selectHighlight";
const PermanentHighlightedFeature = "permanentSelectHighlight";
const HoverHighlightFeature = "hoverHighlight";

export const SelectLinkPage: FC = () => {
  const dispatch = useAppDispatch();
  const { user } = useAuth0();
  const { configId } = useParams();

  // General and Map
  const [measure, setMeasure] = useState<MeasureType>(MeasureType.AADT);
  const [mapLoaded, setMapLoaded] = useState(false);
  const [, setMapRender] = useState(false);
  const [baseStylesUpdated, setBaseStylesUpdated] = useState<boolean>(false);
  const [isExportDialogOpen, setIsExportDialogOpen] = useState(false);
  const searchMarker = useRef(new Marker({ scale: 0.8, offset: [0, -22], color: "blue" }));

  // Roads and volumes
  const [showRoadVolumes, setShowRoadVolumes] = useState<boolean>(true);
  const [volumeProps, setVolumeProps] = useState<Volume[]>([]);
  const [hoveredSegmentId, setHoveredSegmentId] = useState<string | null>(null);
  const [roadHoverProps, setRoadHoverProps] = useState<RoadsHoverPopupProps | null>(null);
  const [roadsLayerId, setRoadsLayerId] = useState<string>("");

  // OD
  const [selectedOrigins, setSelectedOrigins] = useState<SelectedArea[]>([]);
  const [selectedDestinations, setSelectedDestinations] = useState<SelectedArea[]>([]);
  const [showZones, setShowZones] = useState<boolean>(true);
  const [ODPopupProps, setODPopupProps] = useState<ODPopupProps | null>(null);
  const [, setColorScale] = useState<any | null>(null);

  // Select link
  const [selectedLinkGroups, setSelectedLinkGroups] = useState<SegmentsGroup[]>([]);
  const [isConfigPanelOpen, setIsConfigPanelOpen] = useState(true);
  const [selectLinkHoverProps, setSelectLinkHoverProps] = useState<RoadsHoverPopupProps | null>(null);
  const [selectLinkPredicateLogic, setSelectLinkPredicateLogic] = useState<SelectLinkPredicateLogic>(
    SelectLinkPredicateLogic.And,
  );
  const [selectLinkOptions, setSelectLinkOptions] = useState<SelectLinkAnalysisOptions>({ minVolume: DefaultMinCount });
  const [selectLinkMarkers, setSelectLinkMarkers] = useState<SegmentMarker[]>([]);
  const [maxSegmentsGroupId, setMaxSegmentsGroupId] = useState<number>(0);
  const [isEmptyResultWarningOpen, setIsEmptyResultWarningOpen] = useState(false);

  usePageTracking();

  const isTokenLoaded = useAppSelector((state) => state.analytics.authorizationTokenLoaded);

  // General
  const userOrganizationName = useAppSelector((state) => state.license.user.data?.organization?.name);
  const geocodingSearchResults = useAppSelector((state) => state.analytics.geocodingSearch);
  const selectedFocusArea = useAppSelector((state) => state.global.selectedFocusArea);
  const timePeriod = useAppSelector((state) => state.global.timePeriod);
  const mode = useAppSelector((state) => state.analytics.mapVisualizationMode);

  //Map
  const baseMapStyle = useAppSelector((state) => state.map.baseMapStyle);
  const savedBounds = useAppSelector((state) => state.map.mapBounds);
  const colorScheme = useAppSelector((state) => state.map.colorScheme);

  //Export
  const newExport = useAppSelector((state) => state.export.newExport);

  //Datasets
  const datasetMetadata = useAppSelector((state) => state.analytics.datasetMetadata);

  // Roads and volumes
  const roadsMetadata = useAppSelector((state) => state.analytics.roadsMetadata);
  const roadsVolumes = useAppSelector((state) => state.analytics.roadsVolumes);
  const roadSegmentIds = useAppSelector((state) => state.analytics.roadSegmentIds);
  const roadSegmentIdsByRoadClass = useAppSelector((state) => state.analytics.roadSegmentIdsByRoadClass);
  const selectedRoadVolume = useAppSelector((state) => state.analytics.selectedRoadVolume);
  const currentRoadFilters = useAppSelector((state) => state.analytics.roadFilters);

  // OD
  const ODMetadata = useAppSelector((state) => state.analytics.ODMetadata);
  const ODCounts = useAppSelector((state) => state.analytics.ODCounts);
  const ODIds = useAppSelector((state) => state.analytics.ODIds);
  const queryType = useAppSelector((state) => state.analytics.queryType);

  // Select link analysis
  const selectLinkAnalysisMode = useAppSelector((state) => state.selectLink.selectLinkAnalysisMode);
  const selectLinkSegmentCounts = useAppSelector((state) => state.selectLink.selectLinkSegmentCounts);
  const selectLinkConfig = useAppSelector((state) => state.selectLink.selectLinkConfig);

  // Refs
  const mapContainer: any = useRef(null);
  const map: any = useRef(null);
  const roadsPopupRef = useRef<HTMLDivElement>(null);
  const roadsVolumesRef = useRef<HTMLDivElement>(null);
  const showRoadVolumesRef = useRef<boolean>(true);
  const clearFunction = useRef<() => void>();
  const showZonesRef = useRef<boolean>(true);
  const ODPopupRef = useRef<HTMLDivElement>(null);
  const emptyResultWarningPopupRef = useRef<HTMLDivElement>(null);

  // Refs select link
  const zoneSelectionModeRef = useRef<string>(ZoneSelectionMode.Origins);
  const activeSegmentsGroupRef = useRef<string>("");
  const selectedOriginsRef = useRef<SelectedArea[]>([]);
  const selectedDestinationsRef = useRef<SelectedArea[]>([]);
  const clearSelectedZonesFunction = useRef<() => void>();
  const selectLinkVolumesRef = useRef<HTMLDivElement>(null);
  const clearSelectLinkFunction = useRef<() => void>();

  const zoneLayers = useMemo(() => ODMetadata.data?.tileService.layers, [ODMetadata.data?.tileService.layers]);

  const zoningLevels = useMemo(() => ODMetadata.data?.zoningLevels, [ODMetadata.data?.zoningLevels]);

  const ODError = useMemo(
    () =>
      (ODMetadata.state === DataState.ERROR && ODMetadata.error.status !== 403) ||
      ODIds.state === DataState.ERROR ||
      ODCounts.state === DataState.ERROR,
    [ODMetadata.state, ODMetadata.error, ODIds.state, ODCounts.state],
  );

  const roadsError = useMemo(
    () =>
      (roadsMetadata.state === DataState.ERROR && roadsMetadata.error.status !== 403) ||
      roadSegmentIds.state === DataState.ERROR ||
      roadsVolumes.state === DataState.ERROR,
    [roadsMetadata.state, roadsMetadata.error, roadSegmentIds.state, roadsVolumes.state],
  );

  const mapError = useMemo(() => ODError || roadsError, [ODError, roadsError]);

  const filterLoading = useMemo(() => {
    return (
      datasetMetadata.state === DataState.LOADING ||
      roadSegmentIds.state === DataState.LOADING ||
      roadsMetadata.state === DataState.LOADING ||
      roadsVolumes.state === DataState.LOADING ||
      ODMetadata.state === DataState.LOADING
    );
  }, [datasetMetadata.state, roadSegmentIds.state, roadsMetadata.state, roadsVolumes.state, ODMetadata.state]);

  const isSelectLinkQueryRunning = useMemo(() => {
    return selectLinkSegmentCounts.state === DataState.LOADING;
  }, [selectLinkSegmentCounts.state]);

  const areSelectLinkResultsAvailable = useMemo(() => {
    return selectLinkSegmentCounts.state === DataState.AVAILABLE;
  }, [selectLinkSegmentCounts.state]);

  const areSelectLinkResultsEmpty = useMemo(() => {
    return (selectLinkSegmentCounts?.data?.segmentVolumes?.size || 0) === 0;
  }, [selectLinkSegmentCounts.data]);

  const areConfigChangesPending = useMemo(() => {
    if (selectLinkConfig.data) {
      const currentConfig = {
        segmentGroups: selectedLinkGroups,
        origins: selectedOrigins,
        destinations: selectedDestinations,
        predicateLogic: selectLinkPredicateLogic,
        minVolume: selectLinkOptions.minVolume,
      };
      const lastSavedConfig = {
        segmentGroups: selectLinkConfig.data.segmentsGroups,
        origins: selectLinkConfig.data.origins,
        destinations: selectLinkConfig.data.destinations,
        predicateLogic: selectLinkConfig.data.segmentsGroupsOp,
        minVolume: selectLinkConfig.data.minCount,
      };
      const areConfigsEqual = JSON.stringify(lastSavedConfig) === JSON.stringify(currentConfig);
      return !areConfigsEqual;
    }
    return false;
  }, [
    selectLinkConfig.data,
    selectedLinkGroups,
    selectedOrigins,
    selectedDestinations,
    selectLinkPredicateLogic,
    selectLinkOptions.minVolume,
  ]);

  const shouldDisableMapControls = useMemo(
    () => isSelectLinkQueryRunning || selectLinkAnalysisMode === SelectLinkAnalysisMode.Results,
    [isSelectLinkQueryRunning, selectLinkAnalysisMode],
  );

  const handleSelectRoadVolume = useCallback(
    (selectedRoadVolume: SelectedVolume | null) => {
      dispatch(analyticsActions.setSelectedRoadVolume(selectedRoadVolume));
    },
    [dispatch],
  );

  const handleSelectRoadVolumeId = useCallback(
    (selectedRoadVolumeId: string | null) => {
      dispatch(analyticsActions.setSelectedRoadVolumeId(selectedRoadVolumeId));
    },
    [dispatch],
  );

  const getRoadSegmentIds = useCallback(
    (areas: string[]) => {
      if (timePeriod) {
        dispatch(
          analyticsActions.fetchSegmentIds({
            timePeriod,
            areaOfInterest: areas,
            onlyFromTo: true,
            includeReverse: true,
            compression: "gzip",
          }),
        );
      }
    },
    [timePeriod, dispatch],
  );

  const updateFeatureStateForRoads = useCallback(
    (sourceId: string, segmentId: string | null, stateName: string, status?: boolean, layer?: string) => {
      const layerId = layer || roadsLayerId;
      if (segmentId && layerId && layerId !== "") {
        updateFeatureState(map.current, sourceId, layerId, segmentId, stateName, status);
      }
    },
    [roadsLayerId],
  );

  const updateFeatureStateForZone = useCallback(
    (sourceId: string, zone: SelectedArea, zoneMode: string, stateName: string, status?: boolean, layer?: string) => {
      const zoneLayerId = layer || (getLayerFromZoom(map.current.getZoom(), zoneLayers)?.name as string);

      if (map.current.getSource(sourceId)) {
        updateFeatureState(
          map.current,
          sourceId,
          zoneLayerId,
          zone.id,
          stateName + (zoneMode === ZoneSelectionMode.Origins ? "Origin" : "Destination"),
          status,
        );
      }
    },
    [zoneLayers],
  );

  /**
   * Toggles visibility of zones on the map.
   */
  const toggleZonesLayersVisibility = useCallback(
    (isVisible: boolean, toggleBorders?: Boolean) => {
      zoneLayers?.forEach((layer) => {
        if (map.current.getLayer(layer.name)) {
          map.current.setLayoutProperty(layer.name, "visibility", isVisible ? "visible" : "none");
          if (toggleBorders) {
            map.current.setLayoutProperty(
              `${ZONE_BORDERS_LAYER_NAME} ${layer.name}`,
              "visibility",
              isVisible ? "visible" : "none",
            );
          }
        }
      });
    },
    [zoneLayers],
  );

  const deleteSelectedZonesFromFeatureState = useCallback(
    (zoneToDelete: SelectedArea) => {
      // delete feature from the main source (where interactive selection occurs), as well as from selected zones source
      [ZONE_SOURCE_ID, SELECTED_ZONE_SOURCE_ID].forEach((sourceId) => {
        // because deletion is possible from the list on the different zoom level, need to go through all layers
        zoneLayers?.forEach((zoneLayer) => {
          // because deletion is possible by unclicking, need to remove the feature in both modes
          [ZoneSelectionMode.Origins, ZoneSelectionMode.Destinations].forEach((zsm) => {
            updateFeatureStateForZone(sourceId, zoneToDelete, zsm, PermanentHighlightedFeature, false, zoneLayer.name);
          });
        });
      });
    },
    [zoneLayers, updateFeatureStateForZone],
  );

  const handleDeleteSelectedZone = useCallback(
    (zoneToDelete: SelectedArea) => {
      const zoneMode = zoneSelectionModeRef.current;
      if (zoneMode === ZoneSelectionMode.Origins) {
        const newZones = selectedOriginsRef.current.filter((zone) => zone.id !== zoneToDelete.id);
        setSelectedOrigins(newZones);
        selectedOriginsRef.current = newZones;
      } else {
        const newZones = selectedDestinationsRef.current.filter((zone) => zone.id !== zoneToDelete.id);
        setSelectedDestinations(newZones);
        selectedDestinationsRef.current = newZones;
      }
      deleteSelectedZonesFromFeatureState(zoneToDelete);
    },
    [deleteSelectedZonesFromFeatureState],
  );

  const handleSelectZoneInternal = useCallback(
    (selectedZones: SelectedArea[], selectedZone: SelectedArea, zoneMode: string) => {
      if (!selectedZones.find((zone) => zone.id === selectedZone.id)) {
        const newZones = [...selectedZones, selectedZone];
        if (zoneMode === ZoneSelectionMode.Origins) {
          setSelectedOrigins(newZones);
          selectedOriginsRef.current = newZones;
        } else {
          setSelectedDestinations(newZones);
          selectedDestinationsRef.current = newZones;
        }
        updateFeatureStateForZone(ZONE_SOURCE_ID, selectedZone, zoneMode, PermanentHighlightedFeature, true);
      }
    },
    [updateFeatureStateForZone],
  );

  const handleSelectZone = useCallback(
    (selectedZone: SelectedArea | null) => {
      if (!selectedZone) return;

      const findZone = (zones: SelectedArea[], zoneToFind: SelectedArea): SelectedArea | undefined => {
        return zones.find((zone) => zone.id === zoneToFind.id);
      };

      const zoneSelectionMode = zoneSelectionModeRef.current;

      // when trying to select O that has already been selected as D, or other way aorund, nothing should happen
      const existingZoneInAnotherMode =
        zoneSelectionMode === ZoneSelectionMode.Origins
          ? findZone(selectedDestinationsRef.current, selectedZone)
          : findZone(selectedOriginsRef.current, selectedZone);

      if (existingZoneInAnotherMode) return;

      const existingZoneInSameMode =
        zoneSelectionMode === ZoneSelectionMode.Origins
          ? findZone(selectedOriginsRef.current, selectedZone)
          : findZone(selectedDestinationsRef.current, selectedZone);

      // if already selected, delete; if not, add
      if (existingZoneInSameMode) {
        handleDeleteSelectedZone(selectedZone);
      } else {
        handleSelectZoneInternal(
          zoneSelectionMode === ZoneSelectionMode.Origins
            ? selectedOriginsRef.current
            : selectedDestinationsRef.current,
          selectedZone,
          zoneSelectionMode,
        );
      }
    },
    [handleSelectZoneInternal, handleDeleteSelectedZone],
  );

  /**
   * This callback is triggered when select link results become available
   * to visually add permanently selected links to the select link road volume map.
   */
  const updateFeatureStateWithSelectedLinks = useCallback(
    (segmentGroups: SegmentsGroup[], sourceId: string, layerId: string) => {
      segmentGroups.forEach((group) => {
        group.segments.forEach((link) => {
          if (map.current.getSource(sourceId)) {
            updateFeatureStateForRoads(sourceId, link.fromToId, PermanentHighlightedFeature, true, layerId);
          }
        });
      });
    },
    [updateFeatureStateForRoads],
  );

  const updateFeatureStateWithSelectedZones = useCallback(
    (zones: SelectedArea[], zoneMode: string, sourceId: string, layerId: string) => {
      zones.forEach((zone) => {
        if (map.current.getSource(sourceId)) {
          updateFeatureStateForZone(sourceId, zone, zoneMode, PermanentHighlightedFeature, true, layerId);
        }
      });
    },
    [updateFeatureStateForZone],
  );

  const updateFeatureStateWithSelectedODs = useCallback(
    (sourceId: string) => {
      zoneLayers?.forEach((layer) => {
        updateFeatureStateWithSelectedZones(
          selectedOriginsRef.current,
          ZoneSelectionMode.Origins,
          sourceId,
          layer.name,
        );
        updateFeatureStateWithSelectedZones(
          selectedDestinationsRef.current,
          ZoneSelectionMode.Destinations,
          sourceId,
          layer.name,
        );
      });
    },
    [zoneLayers, updateFeatureStateWithSelectedZones],
  );

  /**
   * Adds a map layer and features with borders of the selected zones.
   */
  const loadSelectedZones = useCallback(() => {
    const selectedZoneIds = [
      ...selectedOriginsRef.current.map((zone) => zone.id),
      ...selectedDestinationsRef.current.map((zone) => zone.id),
    ];
    if (ODMetadata.data && zoneLayers && selectedZoneIds.length > 0) {
      if (clearSelectedZonesFunction.current) {
        clearSelectedZonesFunction.current();
      }

      const { clear } = initSelectedZones(map.current, {
        metadata: ODMetadata.data,
        availableLayers: zoneLayers,
        selectedZoneIds,
      });

      clearSelectedZonesFunction.current = clear;
      updateFeatureStateWithSelectedODs(SELECTED_ZONE_SOURCE_ID);
    }
  }, [ODMetadata, zoneLayers, updateFeatureStateWithSelectedODs]);

  // Set Links mode by default
  useEffect(() => {
    dispatch(analyticsActions.setMapVisualizationMode(MapVisualizationType.ROADS));
  }, [dispatch]);

  // Fetch select link configuration
  useEffect(() => {
    if (configId && isTokenLoaded) {
      dispatch(selectLinkActions.fetchSelectLinkConfig(configId));
    }
  }, [configId, isTokenLoaded, dispatch]);

  useEffect(() => {
    if (selectLinkConfig.data) {
      const { segmentsGroups, segmentsGroupsOp, origins, destinations, minCount } = selectLinkConfig.data;
      setSelectedLinkGroups(segmentsGroups || []);
      setSelectLinkPredicateLogic(segmentsGroupsOp || SelectLinkPredicateLogic.And);
      setSelectedOrigins(origins || []);
      selectedOriginsRef.current = origins || [];
      setSelectedDestinations(destinations || []);
      selectedDestinationsRef.current = destinations || [];
      setSelectLinkOptions({ minVolume: minCount ?? DefaultMinCount });

      const maxGroupId =
        segmentsGroups && segmentsGroups.length > 0
          ? Math.max(...segmentsGroups.map(({ groupName }) => parseInt(groupName)))
          : 0;
      setMaxSegmentsGroupId(maxGroupId);
      if (maxGroupId > 0) {
        activeSegmentsGroupRef.current = maxGroupId.toString();
      }
    }
  }, [selectLinkConfig.data]);

  useEffect(() => {
    if (map.current) return; // initialize map only once

    if (selectedFocusArea && (selectedFocusArea.datasetId || !selectedFocusArea?.datasetId)) {
      const newBounds = savedBounds ? LngLatBounds.convert(savedBounds) : getBounds(selectedFocusArea.geometry);
      const padding = savedBounds ? 0 : { top: 40, bottom: 40, left: 340, right: 340 };

      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: MapBoxStyles.Default,
        bounds: newBounds,
        fitBoundsOptions: {
          padding,
        },
        pitch: 0,
        bearing: 0,
        logoPosition: "bottom-right",
        transformRequest: (url, resourceType): any => {
          if (resourceType === "Tile" && url.startsWith(process.env.REACT_APP_API_HOST as string)) {
            return {
              headers: {
                authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
              },
              url,
            };
          }
        },
      });

      // disable map rotation using right click + drag
      map.current.dragRotate.disable();

      // disable map rotation using touch rotation gesture
      map.current.touchZoomRotate.disableRotation();

      // Add full extent control to the map.
      map.current.addControl(
        getMapHomeControl(map.current, newBounds, {
          padding,
        }),
        "top-left",
      );

      // Add zoom controls to the map.
      map.current.addControl(new mapboxgl.NavigationControl({ showCompass: false }), "top-left");

      // map.current.addControl(new MapboxGeocoder(geocoderOptions), "top-left");

      map.current.on("load", () => {
        setMapLoaded(true);
      });
      map.current.on("idle", () => {
        setMapRender(false);
      });
      map.current.on("render", () => {
        setMapRender(true);
      });
      map.current.on("style.load", () => {
        if (selectedFocusArea) {
          addBoundariesLayer({ map: map.current, selectedArea: selectedFocusArea });

          setBaseStylesUpdated(true);
        }
      });
    }
  }, [selectedFocusArea, baseMapStyle, savedBounds, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(selectLinkActions.clearSelectLinkSegmentCounts());
      dispatch(selectLinkActions.clearNewSelectLinkConfig());
      dispatch(selectLinkActions.clearSelectLinkConfig());
      dispatch(selectLinkActions.setSelectLinkAnalysisMode(SelectLinkAnalysisMode.Configuration));
    };
  }, [dispatch]);

  useEffect(() => {
    return () => {
      const bounds = map.current?.getBounds().toArray();

      if (bounds) {
        dispatch(mapActions.setMapBounds(bounds));
      }
    };
  }, [dispatch]);

  //Fetch Segment IDs
  useEffect(() => {
    if (mapLoaded && selectedFocusArea && selectedFocusArea.areas) {
      getRoadSegmentIds(selectedFocusArea.areas);
    }
  }, [mapLoaded, selectedFocusArea, getRoadSegmentIds]);

  //Fetch OD Zones Ids
  useEffect(() => {
    if (
      selectedFocusArea &&
      !selectedFocusArea?.datasetId &&
      mode === MapVisualizationType.OD &&
      ODIds.state === DataState.EMPTY &&
      ODMetadata.state === DataState.AVAILABLE
    ) {
      if (timePeriod) {
        dispatch(
          analyticsActions.fetchODIds(getAvailableZoomLevels(ODMetadata.data.tileService.layers), {
            timePeriod,
            areaOfInterest: selectedFocusArea?.areas || null,
          }),
        );
      }
    }
  }, [selectedFocusArea, mode, ODIds.state, ODMetadata, timePeriod, dispatch]);

  //Initialize map for OD
  useEffect(() => {
    if (
      !selectedFocusArea?.datasetId &&
      mapLoaded &&
      baseStylesUpdated &&
      mode === MapVisualizationType.OD &&
      ODMetadata.state === DataState.AVAILABLE &&
      ODIds.state === DataState.AVAILABLE &&
      ODCounts.state === DataState.AVAILABLE
    ) {
      if (clearFunction.current) {
        clearFunction.current();
        handleSelectRoadVolume(null);
      }
      const initODPayloadBase = {
        colorScheme,
        tileService: ODMetadata.data.tileService,
        externalZonesTileService: ODMetadata.data.tileService,
        ids: ODIds.data,
        popupRef: ODPopupRef,
        showZones: showZonesRef.current,
        queryType,
        setODPopupProps,
        setSelectedZone: handleSelectZone,
        setColorScale,
        isSelectLinkMap: true,
      };

      const initODPayload: LayersData = {
        ...initODPayloadBase,
        selectedZone: null,
        counts: ODCounts.data as Counts,
        availableLayers: ODMetadata.data.tileService.layers,
        blockedZoomLevel: null,
      };

      const { clear } = initOD(map.current, initODPayload);

      addCustomGAEvent("map", "view", "select-link-OD", user, userOrganizationName);

      clearFunction.current = clear;

      updateFeatureStateWithSelectedODs(ZONE_SOURCE_ID);
    }
  }, [
    selectedFocusArea?.datasetId,
    mode,
    ODMetadata,
    ODCounts,
    ODIds,
    mapLoaded,
    queryType,
    baseStylesUpdated,
    colorScheme,
    user,
    handleSelectZone,
    handleSelectRoadVolume,
    userOrganizationName,
    updateFeatureStateWithSelectedODs,
  ]);

  //Initialize map for Roads
  useEffect(() => {
    if (!mapLoaded) return;

    switch (mode) {
      case MapVisualizationType.ROADS: {
        if (
          roadsMetadata.state === DataState.AVAILABLE &&
          roadsVolumes.state === DataState.AVAILABLE &&
          roadSegmentIds.state === DataState.AVAILABLE &&
          baseStylesUpdated &&
          selectLinkAnalysisMode === SelectLinkAnalysisMode.Configuration
        ) {
          if (clearFunction.current) {
            clearFunction.current();
          }

          // cleanup old select link results
          if (clearSelectLinkFunction.current) clearSelectLinkFunction.current();
          dispatch(selectLinkActions.clearSelectLinkSegmentCounts());

          const { clear } = initRoadSegments(map.current, {
            tileService: roadsMetadata.data.tileService,
            roadsVolumes: roadsVolumes.data,
            roadSegmentIds: roadSegmentIds.data,
            roadSegmentIdsByRoadClass,
            popupRef: roadsPopupRef,
            popupHoverRef: roadsVolumesRef,
            gates: null,
            showRoadVolumes: showRoadVolumesRef.current,
            isPedestriansMode: false,
            minVolume: 0,
            setRoadHoverProps,
            setVolumeProps,
            setSelectedRoadVolume: handleSelectRoadVolume,
            setSelectedRoadVolumeId: handleSelectRoadVolumeId,
            layerIds: MainRoadsLayerIds,
            colorIds: MainRoadsColorIds,
            isSelectLink: false,
          });

          addCustomGAEvent("map", "view", "select-link-roads", user, userOrganizationName);

          clearFunction.current = clear;
          const layerName = roadsMetadata.data.tileService.layerName;
          setRoadsLayerId(layerName);

          updateFeatureStateWithSelectedLinks(selectedLinkGroups, MainRoadsLayerIds.roadsSourceId, layerName);
          loadSelectedZones();

          if (!selectLinkMarkers.length) addAllMarkersToMap(selectedLinkGroups);
        }

        break;
      }
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [
    selectedFocusArea,
    mode,
    mapLoaded,
    roadsMetadata,
    roadsVolumes,
    showRoadVolumes,
    roadSegmentIds,
    baseStylesUpdated,
    measure,
    user,
    userOrganizationName,
    setMeasure,
    handleSelectRoadVolume,
    handleSelectRoadVolumeId,
    dispatch,
  ]);
  /* eslint-enable react-hooks/exhaustive-deps */

  const changeShowRoadVolumes = useCallback(() => {
    const isVisible = !showRoadVolumes;

    setShowRoadVolumes(isVisible);
    showRoadVolumesRef.current = isVisible;

    if (map.current.getLayer(MainRoadsLayerIds.roadsVolumesLayerId)) {
      toggleRoadLayersVisibility(isVisible);
    }
  }, [showRoadVolumes]);

  const changeShowZones = useCallback(() => {
    const isVisible = !showZones;
    setShowZones(isVisible);
    showZonesRef.current = isVisible;

    toggleZonesLayersVisibility(isVisible);
  }, [showZones, toggleZonesLayersVisibility]);

  const handleHoverSegmentFromPopup = (segmentId: string | null) => {
    if (map.current.getLayer(MainRoadsLayerIds.roadsSegmentsLayerId)) {
      if (hoveredSegmentId) {
        updateFeatureStateForRoads(MainRoadsLayerIds.roadsSourceId, hoveredSegmentId, HoverHighlightFeature, false);
      }

      if (segmentId) {
        updateFeatureStateForRoads(MainRoadsLayerIds.roadsSourceId, segmentId, HoverHighlightFeature, true);
      }
    }
    setHoveredSegmentId(segmentId);
  };

  const highlightSelectedSegment = (segmentId: string | null, featureName: string) => {
    if (map.current?.getLayer(MainRoadsLayerIds.roadsSegmentsLayerId)) {
      if (selectedRoadVolume) {
        updateFeatureStateForRoads(MainRoadsLayerIds.roadsSourceId, selectedRoadVolume.ftSegmentId, featureName);
      }

      if (segmentId) {
        updateFeatureStateForRoads(MainRoadsLayerIds.roadsSourceId, segmentId, featureName, true);
      }
    }
  };

  const handleToggleQueryType = useCallback(
    (type: QueryType) => {
      dispatch(analyticsActions.setQueryType(type));
    },
    [dispatch],
  );

  useEffect(() => {
    if (
      selectedRoadVolume &&
      roadsVolumes.state === DataState.AVAILABLE &&
      baseStylesUpdated &&
      roadsVolumes.data.segmentVolumes.get(selectedRoadVolume.ftSegmentId)
    ) {
      setIsConfigPanelOpen(true);
      highlightSelectedSegment(selectedRoadVolume.ftSegmentId, SelectHighlightFeature);
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [roadsVolumes.state, baseStylesUpdated, selectedRoadVolume, roadsVolumes.data]);
  /* eslint-enable react-hooks/exhaustive-deps */

  const removePopup = () => {
    if (roadsPopupRef.current) {
      let elem = roadsPopupRef.current as HTMLElement;
      if (!elem) return;
      let stop = false;
      while (!stop) {
        if (elem?.classList.contains("mapboxgl-popup")) stop = true;
        else if (elem.parentElement) elem = elem.parentElement;
        else return;
      }
      if (elem) elem.remove();
    }
  };

  /**
   * Triggers initialization of the select link road volumes when response comes back from the BE.
   */
  useEffect(() => {
    if (!map.current) return;
    if (areSelectLinkResultsAvailable) {
      if (areSelectLinkResultsEmpty) {
        setIsEmptyResultWarningOpen(true);
      } else {
        dispatch(selectLinkActions.setSelectLinkAnalysisMode(SelectLinkAnalysisMode.Results));

        if (roadsMetadata.state === DataState.AVAILABLE && selectLinkSegmentCounts.state === DataState.AVAILABLE) {
          if (clearSelectLinkFunction.current) clearSelectLinkFunction.current();

          removePopup();

          const { clear } = initSelectLinkRoads(map.current, {
            tileService: roadsMetadata.data.tileService,
            segmentCounts: selectLinkSegmentCounts.data,
            roadSegmentIds: roadSegmentIds.data || {},
            roadSegmentIdsByRoadClass,
            minVolume: selectLinkOptions.minVolume || 0,
            selectLinkVolumesRef,
            setSelectLinkHoverProps,
          });

          updateFeatureStateWithSelectedLinks(selectedLinkGroups, SelectLinkLayerIds.roadsSourceId, roadsLayerId);

          clearSelectLinkFunction.current = clear;

          loadSelectedZones();
        }
      }
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [
    areSelectLinkResultsAvailable,
    roadsMetadata,
    selectLinkSegmentCounts,
    roadSegmentIds,
    baseMapStyle,
    updateFeatureStateWithSelectedLinks,
  ]);
  /* eslint-enable react-hooks/exhaustive-deps */

  /**
   * This effect is responsible for toggling configuration (regular roads) and select link road volumes
   * in response to clicking on the map toggle Configuration / Results.
   */
  useEffect(() => {
    if (!mapLoaded) return;
    if (selectLinkAnalysisMode === SelectLinkAnalysisMode.Results) {
      toggleRoadLayersVisibility(false);
      toggleZonesLayersVisibility(false, true);
      toggleSelectLinkLayersVisibility(true);
    } else {
      if (showRoadVolumes) toggleRoadLayersVisibility(true);
      if (showZones) toggleZonesLayersVisibility(true, true);
      toggleSelectLinkLayersVisibility(false);
      setIsConfigPanelOpen(true);
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [mapLoaded, selectLinkAnalysisMode]);
  /* eslint-enable react-hooks/exhaustive-deps */

  /**
   * This effect is responsible for switching off corresponding layers
   * and adding permanently selected links features back to the map in response to changing base map styles.
   */
  useEffect(() => {
    if (!mapLoaded) return;
    if (baseStylesUpdated) {
      updateFeatureStateWithSelectedLinks(selectedLinkGroups, MainRoadsLayerIds.roadsSourceId, roadsLayerId);
      updateFeatureStateWithSelectedODs(ZONE_SOURCE_ID);
      if (areSelectLinkResultsAvailable) {
        updateFeatureStateWithSelectedLinks(selectedLinkGroups, SelectLinkLayerIds.roadsSourceId, roadsLayerId);
      }
      if (selectLinkAnalysisMode === SelectLinkAnalysisMode.Configuration) {
        toggleSelectLinkLayersVisibility(false);
        if (mode === MapVisualizationType.ROADS) {
          if (roadsVolumes.state === DataState.AVAILABLE) {
            if (!showRoadVolumes) {
              toggleRoadLayersVisibility(false);
            } else {
              toggleRoadLayersVisibility(true);
            }
          }
        } else if (mode === MapVisualizationType.OD) {
          if (ODCounts.state === DataState.AVAILABLE) {
            if (!showZones) {
              toggleZonesLayersVisibility(false);
            } else {
              toggleZonesLayersVisibility(true);
            }
          }
        }
      } else if (selectLinkAnalysisMode === SelectLinkAnalysisMode.Results) {
        toggleRoadLayersVisibility(false);
        toggleZonesLayersVisibility(false);
      }
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [baseStylesUpdated]);
  /* eslint-enable react-hooks/exhaustive-deps */

  useEffect(() => {
    if (selectLinkSegmentCounts.error) {
      toast.error("Failed to complete select link analysis for this configuration", {
        position: toast.POSITION.TOP_CENTER,
      });
      toast.clearWaitingQueue();
    }
  }, [selectLinkSegmentCounts.error]);

  useEffect(() => {
    if (newExport.data) {
      toast.success("New export job added", {
        position: toast.POSITION.TOP_CENTER,
      });
      dispatch(exportActions.clearNewExport());
      toast.clearWaitingQueue();
    }
  }, [newExport.data, dispatch]);

  useEffect(() => {
    if (newExport.error) {
      toast.error("Failed to add new export job", {
        position: toast.POSITION.TOP_CENTER,
      });
      dispatch(exportActions.clearNewExport());
      toast.clearWaitingQueue();
    }
  }, [newExport.error, dispatch]);

  /**
   * This callback prepares payload and sends select link request to the BE.
   */
  const getSelectLinkSegmentCounts = useCallback(
    (
      filters: FiltersType,
      selectedFocusArea: FocusAreaItem,
      segmentGroups: SegmentsGroup[],
      origins: SelectedArea[],
      destinations: SelectedArea[],
      predicateLogic: string,
      { minVolume }: SelectLinkAnalysisOptions,
    ) => {
      if (timePeriod) {
        dispatch(
          selectLinkActions.fetchSelectLinkSegmentCounts({
            timePeriod,
            level: "BlockGroup",
            compression: "gzip",
            format: "protobuf",
            areaOfInterest: selectedFocusArea?.areas || null,
            filter: buildFilters(filters),
            measure,
            precision: 0,
            selectedSegmentsPredicate: buildSegmentsPredicate(segmentGroups, predicateLogic),
            selectedOriginsPredicate: buildZonesPredicate(origins),
            selectedDestinationsPredicate: buildZonesPredicate(destinations),
            aggTotal: false,
            countsFilter: {
              min: minVolume || 0,
              max: 1e9,
            },
          }),
        );
      }
    },
    [measure, timePeriod, dispatch],
  );

  /**
   * This callback zooms onto the selected link segment when it is being clicked on the right side config pane.
   */
  const handleZoomOnSegment = useCallback(
    (lngLat: LngLatLike, zoom: number = 14) => {
      if (map.current) {
        map.current.flyTo({
          center: lngLat,
          zoom,
        });
      }
    },
    [map],
  );

  const updateMapOnDeleteSelectedLink = useCallback(
    (segment: SelectedSegmentConfig) => {
      // switch off permanent highlight feature and delete the marker for both roads and select link layers
      [MainRoadsLayerIds, SelectLinkLayerIds].forEach((source) => {
        if (map.current.getSource(source.roadsSourceId)) {
          const segmentFromToId = segment.fromToId;
          updateFeatureStateForRoads(source.roadsSourceId, segmentFromToId, SelectHighlightFeature, false);
          updateFeatureStateForRoads(source.roadsSourceId, segmentFromToId, PermanentHighlightedFeature, false);
        }
      });
      deleteMarkerForSegmentAndRemoveFromMap(selectLinkMarkers, segment, setSelectLinkMarkers);
    },
    [selectLinkMarkers, updateFeatureStateForRoads],
  );

  /**
   * This callback handles deletion of selected link segment in the right config pane:
   * - deletes the segments from the state
   * - deletes the permanently highlighted feature from the feature state (if both directional segments were removed from the list)
   * - deletes the marker from the map adn the state (if both directional segments were removed from the list)
   */
  const handleDeleteSelectedLink = useCallback(
    (segment: SelectedSegmentConfig, segmentsGroupId: string) => {
      setSelectedLinkGroups(deleteSegmentFromGroup(selectedLinkGroups, segmentsGroupId, segment));
      updateMapOnDeleteSelectedLink(segment);
    },
    [selectedLinkGroups, updateMapOnDeleteSelectedLink],
  );

  const updateSelectLinkConfiguration = useCallback(
    (
      segmentsGroups: SegmentsGroup[],
      origins: SelectedArea[],
      destinations: SelectedArea[],
      predicateLogic: SelectLinkPredicateLogic,
    ) => {
      if (selectLinkConfig.data) {
        const updateRequest = {
          analysisName: selectLinkConfig.data.analysisName,
          segmentsGroups,
          segmentsGroupsOp: predicateLogic,
          origins,
          destinations,
          minCount: selectLinkOptions.minVolume,
        } as SelectLinkConfigUpdateRequest;
        dispatch(selectLinkActions.updateSelectLinkConfig(selectLinkConfig.data.configId, updateRequest));
      }
    },
    [dispatch, selectLinkConfig.data, selectLinkOptions.minVolume],
  );

  const handleChangeSelectLinkMode = (newMode: string) => {
    dispatch(selectLinkActions.setSelectLinkAnalysisMode(newMode as SelectLinkAnalysisMode));
  };

  const handleChangePredicateLogic = (predicateLogic: SelectLinkPredicateLogic) => {
    setSelectLinkPredicateLogic(predicateLogic);
  };

  const handleChangeZoneSelectionMode = (newMode: string) => {
    zoneSelectionModeRef.current = newMode;
  };

  const handleChangeActiveSegmentsGroup = (groupId: string) => {
    activeSegmentsGroupRef.current = groupId;
  };

  const handleAddNewSegmentsGroup = (group: SegmentsGroup, id: number) => {
    const newGroups = [...selectedLinkGroups, group];
    setSelectedLinkGroups(newGroups);
    activeSegmentsGroupRef.current = group.groupName;
    setMaxSegmentsGroupId(id);
  };

  const handleDeleteSegmentsGroup = (groupId: string) => {
    const group = selectedLinkGroups.find((group) => group.groupName === groupId);
    const segments = new Set<string>();
    group?.segments.forEach((segment) => {
      updateMapOnDeleteSelectedLink(segment);
      segments.add(segment.segmentId);
    });
    setSelectLinkMarkers(selectLinkMarkers.filter(({ segmentId }) => !segments.has(segmentId)));
    setSelectedLinkGroups(selectedLinkGroups.filter((group) => group.groupName !== groupId));
  };

  const handleChangeOptions = (options: SelectLinkAnalysisOptions) => {
    setSelectLinkOptions(options);
  };

  /**
   * Handles click on the "Run Analysis" button in the right pane.
   */
  const handleRunAnalysis = (options: SelectLinkAnalysisOptions) => {
    updateSelectLinkConfiguration(
      selectedLinkGroups,
      selectedOriginsRef.current,
      selectedDestinationsRef.current,
      selectLinkPredicateLogic,
    );
    if (currentRoadFilters && selectedFocusArea) {
      getSelectLinkSegmentCounts(
        currentRoadFilters,
        selectedFocusArea,
        selectedLinkGroups,
        selectedOriginsRef.current,
        selectedDestinationsRef.current,
        selectLinkPredicateLogic,
        options,
      );

      addCustomGAEvent("select-link", "analysis", "run-analysis", user, userOrganizationName);
    }
  };

  const handleConfigureAnalysis = () => {
    handleChangeSelectLinkMode(SelectLinkAnalysisMode.Configuration);
  };

  const handleSaveSelectLinkConfiguration = (options: SelectLinkAnalysisOptions) => {
    updateSelectLinkConfiguration(
      selectedLinkGroups,
      selectedOriginsRef.current,
      selectedDestinationsRef.current,
      selectLinkPredicateLogic,
    );
  };

  const addAllMarkersToMap = (segmentGroups: SegmentsGroup[]) => {
    const markers = segmentGroups.flatMap((group) => {
      return group.segments.reduce((acc, segment) => {
        const { segmentId, lon, lat } = segment;
        const markerExists = acc.some(({ segmentId: id }) => id === segmentId);
        if (!markerExists) {
          const marker = createNewSegmentMarker(segmentId, lon, lat, group?.color);
          return [...acc, marker];
        }
        return acc;
      }, [] as SegmentMarker[]);
    });
    setSelectLinkMarkers(markers);
  };

  const createMarkerAndAddToMap = (segmentConfig: SelectedSegmentConfig, segmentGroups: SegmentsGroup[]) => {
    const activeSegmentsGroup = activeSegmentsGroupRef.current;
    if (!activeSegmentsGroup) return;
    const group = segmentGroups.find((group) => group.groupName === activeSegmentsGroup);
    const { segmentId, lon, lat } = segmentConfig;
    const segmentMarker = createNewSegmentMarker(segmentId, lon, lat, group?.color);
    selectLinkMarkers.push(segmentMarker);
    setSelectLinkMarkers(selectLinkMarkers);
  };

  const createNewSegmentMarker = (segmentId: string, lon: number, lat: number, color?: string) => {
    const marker = new Marker({ scale: 0.6, offset: [0, 0], color: color });
    marker.setLngLat([lon, lat]).addTo(map.current);
    return {
      segmentId,
      marker,
    };
  };

  /**
   * Handles clicking on the directional segment on the popup:
   * - adds directional segment to the state and the list in the right pane
   * - adds permanently highlighted feature to the feature state (in reverse segment is not already present in the list)
   * - adds a marker to the state and on the map (in reverse segment is not already present in the list)
   */
  const handleClickSegmentFromPopup = (selectedVolume: SelectedVolume, id: string) => {
    if (isSelectLinkQueryRunning) return;
    handleSelectRoadVolumeId(id);
    if (activeSegmentsGroupRef.current === "") return;
    const directionalSelectedVolume = getSelectedSegmentConfig(selectedVolume, id);

    // do this only if segment hasn't been already added to the list, otherwise ignore
    if (!segmentExistsInGroups(selectedLinkGroups, directionalSelectedVolume)) {
      const newGroups = addSegmentToGroups(
        selectedLinkGroups,
        activeSegmentsGroupRef.current,
        directionalSelectedVolume,
      );
      setSelectedLinkGroups(newGroups);

      // this two calls are needed for the popup with multiple segments
      handleSelectRoadVolume(selectedVolume);
      highlightSelectedSegment(selectedVolume?.ftSegmentId, SelectHighlightFeature);

      // add permanently highlighted feature for selected links (always displayed on the map)
      updateFeatureStateForRoads(
        MainRoadsLayerIds.roadsSourceId,
        selectedVolume?.ftSegmentId,
        PermanentHighlightedFeature,
        true,
      );

      // add permanent marker to map and state
      createMarkerAndAddToMap(directionalSelectedVolume, selectedLinkGroups);
    }

    setIsConfigPanelOpen(selectedLinkGroups.length > 0);
  };

  /**
   * Toggles visibility of all road layers on the map.
   */
  const toggleRoadLayersVisibility = (isVisible: boolean) => {
    if (map.current.getLayer(MainRoadsLayerIds.roadsVolumesLayerId)) {
      map.current.setLayoutProperty(
        MainRoadsLayerIds.roadsVolumesLayerId,
        "visibility",
        isVisible ? "visible" : "none",
      );
    }
    if (map.current.getLayer(MainRoadsLayerIds.limitedAccessRoadsVolumesLayerId)) {
      map.current.setLayoutProperty(
        MainRoadsLayerIds.limitedAccessRoadsVolumesLayerId,
        "visibility",
        isVisible ? "visible" : "none",
      );
    }
    if (map.current.getLayer(MainRoadsLayerIds.roadsHairlinesLayerId)) {
      map.current.setLayoutProperty(
        MainRoadsLayerIds.roadsHairlinesLayerId,
        "visibility",
        isVisible ? "visible" : "none",
      );
    }
    if (map.current.getLayer(MainRoadsLayerIds.roadsHighlightedVolumesLayerId)) {
      map.current.setLayoutProperty(
        MainRoadsLayerIds.roadsHighlightedVolumesLayerId,
        "visibility",
        isVisible ? "visible" : "none",
      );
    }
    if (map.current.getLayer(MainRoadsLayerIds.roadsSegmentsLayerId)) {
      map.current.setLayoutProperty(
        MainRoadsLayerIds.roadsSegmentsLayerId,
        "visibility",
        isVisible ? "visible" : "none",
      );
    }
  };

  /**
   * Toggles visibility of all select link layers on the map.
   */
  const toggleSelectLinkLayersVisibility = (isVisible: boolean) => {
    if (map.current.getLayer(SelectLinkRoads.SegmentHairlines)) {
      map.current.setLayoutProperty(SelectLinkRoads.SegmentHairlines, "visibility", isVisible ? "visible" : "none");
    }
    if (map.current.getLayer(SelectLinkRoads.SegmentsLayerId)) {
      map.current.setLayoutProperty(SelectLinkRoads.SegmentsLayerId, "visibility", isVisible ? "visible" : "none");
    }
    if (map.current.getLayer(SelectLinkRoads.SegmentsCountsLayerId)) {
      map.current.setLayoutProperty(
        SelectLinkRoads.SegmentsCountsLayerId,
        "visibility",
        isVisible ? "visible" : "none",
      );
    }
    if (map.current.getLayer(SelectLinkRoads.SegmentsCountsLayerIdLimitedAccess)) {
      map.current.setLayoutProperty(
        SelectLinkRoads.SegmentsCountsLayerIdLimitedAccess,
        "visibility",
        isVisible ? "visible" : "none",
      );
    }
    if (map.current.getLayer(SelectLinkRoads.HighlightedVolumeLayerId)) {
      map.current.setLayoutProperty(
        SelectLinkRoads.HighlightedVolumeLayerId,
        "visibility",
        isVisible ? "visible" : "none",
      );
    }
  };

  // Geocoding handlers copy pasted from the MapPage
  const handleGeocodingSearch = (searchText: string) => {
    const proximity = map.current.getCenter().toArray().join(",");

    dispatch(
      analyticsActions.fetchGeocodingSearchResults(
        searchText,
        process.env.REACT_APP_MAPBOX_ACCESS_TOKEN as string,
        proximity,
      ),
    );
  };

  const handleClearGeocodingSearch = () => {
    dispatch(analyticsActions.clearGeocodingSearchResults());
  };

  const handleRemoveSearchMarker = () => {
    searchMarker.current.remove();
  };

  const handleGeocodingFlyTo = (coordinates: [number, number], placeName: string) => {
    if (map.current) {
      map.current.flyTo({
        center: coordinates,
        zoom: 14,
      });

      searchMarker.current.remove();
      searchMarker.current.setLngLat(coordinates).addTo(map.current);
    }

    addCustomGAEvent("geocoding-search", "search", placeName, user, userOrganizationName);
  };

  return (
    <SelectLinkPageContainer>
      <ToggleButtons
        leftButtonLabel="Configuration"
        rightButtonLabel="Results"
        activeIndex={selectLinkAnalysisMode}
        onChangeIndex={handleChangeSelectLinkMode}
        leftButtonDisabled={false}
        rightButtonDisabled={!areSelectLinkResultsAvailable || areConfigChangesPending || areSelectLinkResultsEmpty}
        leftButtonIndex={SelectLinkAnalysisMode.Configuration}
        rightButtonIndex={SelectLinkAnalysisMode.Results}
        leftButtonVariant="leftsquared"
        rightButtonVariant="rightsquared"
        isMapToggle={true}
      />
      <MapControlsPanel
        map={map.current}
        mapLoaded={mapLoaded}
        filterLoading={filterLoading || isSelectLinkQueryRunning}
        selectedZone={null}
        showZones={showZones}
        showRoadVolumes={showRoadVolumes}
        handleToggleQueryType={handleToggleQueryType}
        setIsExportDialogOpen={setIsExportDialogOpen}
        setBaseStylesUpdated={setBaseStylesUpdated}
        changeShowRoadVolumes={changeShowRoadVolumes}
        changeShowZones={changeShowZones}
        leftButtonLabel="Zones"
        rightButtonLabel="Links"
        leftButtonDisabled={shouldDisableMapControls}
        rightButtonDisabled={shouldDisableMapControls}
        disableMapLayers={shouldDisableMapControls}
        exportButtonDisabled={!areSelectLinkResultsAvailable || areConfigChangesPending || areSelectLinkResultsEmpty}
        isAnalysis
        isAnalysisResults={selectLinkAnalysisMode === SelectLinkAnalysisMode.Results}
      />
      <RightSidebar isOpen={isConfigPanelOpen} animation={true}>
        {isSelectLinkQueryRunning || !selectLinkConfig.data ? (
          <RightSidebarLoaderWrapper>
            <Loader />
          </RightSidebarLoaderWrapper>
        ) : (
          <>
            {selectLinkConfig.data && (
              <SelectLinkConfigPanel
                selectLinkConfig={selectLinkConfig.data}
                filterLoading={filterLoading}
                selectedLinkGroups={selectedLinkGroups}
                selectedOrigins={selectedOrigins}
                selectedDestinations={selectedDestinations}
                predicateLogic={selectLinkPredicateLogic}
                options={selectLinkOptions}
                mode={mode}
                selectLinkAnalysisMode={selectLinkAnalysisMode}
                activeZoneSelectionMode={zoneSelectionModeRef.current}
                activeSegmentsGroup={activeSegmentsGroupRef.current}
                maxGroupId={maxSegmentsGroupId}
                onDeleteSelectedLink={handleDeleteSelectedLink}
                onDeleteSelectedZone={handleDeleteSelectedZone}
                onRunAnalysis={handleRunAnalysis}
                onChangePredicateLogic={handleChangePredicateLogic}
                onZoomOnCoordinate={handleZoomOnSegment}
                onChangeZoneSelectionMode={handleChangeZoneSelectionMode}
                onChangeActiveGroup={handleChangeActiveSegmentsGroup}
                onAddNewSegmentsGroup={handleAddNewSegmentsGroup}
                onDeleteSegmentsGroup={handleDeleteSegmentsGroup}
                onChangeOptions={handleChangeOptions}
                onSaveConfiguration={handleSaveSelectLinkConfiguration}
                onConfigureAnalysis={handleConfigureAnalysis}
              />
            )}
          </>
        )}
      </RightSidebar>
      {filterLoading && <SpinnerOverlay />}
      <Popup>
        <div ref={roadsPopupRef}>
          <VolumePopupContent
            selectedVolume={selectedRoadVolume}
            volume={volumeProps}
            title={activeSegmentsGroupRef.current ? "Select segment and direction" : "No segments group selected"}
            areSegmentsAddable={activeSegmentsGroupRef.current !== ""}
            onHover={handleHoverSegmentFromPopup}
            onClick={handleClickSegmentFromPopup}
            isPedestriansMode={false}
          />
        </div>
      </Popup>
      <Popup>
        <div ref={roadsVolumesRef}>
          <RoadsHoverPopup volume={roadHoverProps?.volume} />
        </div>
      </Popup>
      <Popup>
        <div ref={selectLinkVolumesRef}>
          <RoadsHoverPopup volume={selectLinkHoverProps?.volume} />
        </div>
      </Popup>
      <Popup>
        <div ref={ODPopupRef}>
          {ODPopupProps && (
            <ODPopup
              count={ODPopupProps.count}
              type={ODPopupProps.type}
              countByDensity={ODPopupProps.countByDensity}
              flowInfo={ODPopupProps.flowInfo}
            />
          )}
        </div>
      </Popup>
      <Popup>
        <div ref={emptyResultWarningPopupRef}>
          {isEmptyResultWarningOpen && (
            <EmptyResultWarning isOpen={isEmptyResultWarningOpen} onClose={() => setIsEmptyResultWarningOpen(false)} />
          )}
        </div>
      </Popup>
      <Mapbox ref={mapContainer} style={{ display: "block" }} />

      {mapError && <MapErrorPage />}

      {isExportDialogOpen && (
        <NewExportDialog
          style={{ minWidth: "700px" }}
          mode={mode}
          isOpen={isExportDialogOpen}
          ODZoomLevel={getLayerFromZoom(map.current?.getZoom(), zoneLayers)?.level}
          measure={measure}
          selectedArea={selectedFocusArea}
          zoningLevels={zoningLevels}
          isSelectLink={true}
          onClose={() => setIsExportDialogOpen(false)}
        />
      )}
      {!mapError && (
        <GeocodingSearch
          geocodingSearchResults={geocodingSearchResults.data}
          geocodingSearchByText={handleGeocodingSearch}
          clearGeocodingSearch={handleClearGeocodingSearch}
          removeSearchMarker={handleRemoveSearchMarker}
          flyTo={handleGeocodingFlyTo}
        />
      )}
    </SelectLinkPageContainer>
  );
};
