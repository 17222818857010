import { useAuth0 } from "@auth0/auth0-react";
import styled from "@emotion/styled";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import difference from "@turf/difference";
import union from "@turf/union";
import pinImage from "assets/png/pin.png";
import { Feature, Polygon } from "geojson";
import mapboxgl, { Map, MapLayerMouseEvent, Marker } from "mapbox-gl";
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { GeocodingSearch } from "features";

import {
  ComputeDatasetDialog,
  GEN_GATES_LAYER_ID,
  GatesEditor,
  LOCKED_GEN_GATES_LAYER_ID,
  MainPanel,
} from "features/dataset-editor";
import {
  EditorGateCandidates,
  EditorOD,
  EditorRoads,
  addBoundariesLayer,
  drawStyles,
  getAvailableZoomLevels,
  getBounds,
  initEditorGateCandidates,
  initEditorOD,
  initEditorRoads,
} from "features/map-visualization";

import { CheckboxDropdownItem, LeftSidebar, RightSidebar, SpinnerOverlay, getMapHomeControl } from "components";

import { useAppDispatch, useAppSelector, usePageTracking } from "hooks";

import { DataState } from "store/interfaces";
import { analyticsActions } from "store/sections/analytics";

import { Gate, ODTileLayer } from "types";

import { addCustomGAEvent } from "utils/addCustomGAEvent";

import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import "mapbox-gl/dist/mapbox-gl.css";

/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable import/no-webpack-loader-syntax */
(mapboxgl as any).workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

/* eslint-enable @typescript-eslint/no-var-requires */
/* eslint-enable import/no-webpack-loader-syntax */

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN as string;

const DEFAULT_SIMPLIFICATION_DISTANCE_M = 0.5;

const MapPageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const Mapbox = styled.div`
  height: 100%;
`;

interface RoadClass extends CheckboxDropdownItem {
  value: string;
}

export type RoadClassItems = {
  [key: string]: RoadClass;
};

export interface RoadClassGroup {
  items: RoadClassItems;
}

export const DatasetEditor: FC = () => {
  const dispatch = useAppDispatch();

  // General and Map
  const navigate = useNavigate();
  const { search } = useLocation();
  const { datasetId } = useParams();
  const { user } = useAuth0();

  usePageTracking();

  const isTokenLoaded = useAppSelector((state) => state.analytics.authorizationTokenLoaded);

  const [mapLoaded, setMapLoaded] = useState(false);
  const [baseStylesUpdated, setBaseStylesUpdated] = useState<boolean>(false);
  const [isGateEditorOpen, setIsGateEditorOpen] = useState(false);
  const [isValidationModalOpen, setIsValidationModalOpen] = useState(false);
  const [isPolygonSubtractMode, setPolygonSubtractMode] = useState<boolean>(false);
  const [isDrawModeActive, setDrawModeActive] = useState<boolean>(false);
  const [isPolygonSelected, setIsPolygonSelected] = useState(false);

  // Roads
  const [showRoads, setShowRoads] = useState<boolean>(true);
  const [roadClasses, setRoadClasses] = useState<RoadClassGroup>({ items: {} });
  const [isRoadsSourceInitialized, setRoadsSourceInitialized] = useState<boolean>(false);
  const [showGates, setShowGates] = useState<boolean>(true);

  // OD
  const [showZones, setShowZones] = useState<boolean>(true);
  const selectedZoning = useAppSelector((state) => state.analytics.ODDatasetConfig.data?.zoningLevel);

  // General
  const subareaState = useAppSelector((state) => state.analytics.subareaState);
  const focusAreas = useAppSelector((state) => state.analytics.focusAreasAndDatasets);
  const newODDatasetConfiguration = useAppSelector((state) => state.analytics.newODDatasetConfig);
  const ODDatasetConfiguration = useAppSelector((state) => state.analytics.ODDatasetConfig);
  const geocodingSearchResults = useAppSelector((state) => state.analytics.geocodingSearch);
  const userOrganizationName = useAppSelector((state) => state.license.user.data?.organization?.name);
  const generatedGatesDataRef = useRef<Gate[] | null>(null);
  const searchMarker = useRef(new Marker({ scale: 0.8, offset: [0, -22], color: "blue" }));
  const customZoningSelectorFolders = useAppSelector((state) => state.datasetFolders.customZoningSelectorFolders.data);

  //Map
  const baseMapStyle = useAppSelector((state) => state.map.baseMapStyle);

  useEffect(() => {
    if (ODDatasetConfiguration.data?.gates) {
      generatedGatesDataRef.current = ODDatasetConfiguration.data.gates;
    }
  }, [ODDatasetConfiguration.data?.gates]);

  const areaOfInterest = useMemo(
    () =>
      focusAreas.data?.find(
        (area) => !area.datasetId && ODDatasetConfiguration.data?.licensedAreaId.toString() === area.licensedAreaId,
      ),

    [focusAreas.data, ODDatasetConfiguration.data?.licensedAreaId],
  );

  // Roads
  const roadsMetadata = useAppSelector((state) => state.analytics.roadsMetadata);
  const roadSegmentIds = useAppSelector((state) => state.analytics.roadSegmentIds);

  // OD
  const ODMetadata = useAppSelector((state) => state.analytics.ODMetadata);
  const ODIds = useAppSelector((state) => state.analytics.ODIds);

  // Refs
  const mapContainer: any = useRef(null);
  const map = useRef<Map | null>(null);
  const draw = useRef<any>(null);
  const ODClearFunction = useRef<() => void>();
  const roadsClearFunction = useRef<() => void>();
  const gateCandidatesClearFunction = useRef<() => void>();

  const layers = useMemo(() => ODMetadata.data?.tileService.layers || [], [ODMetadata.data?.tileService.layers]);

  const roadsLayerName = useMemo(
    () => roadsMetadata.data?.tileService.layerName,
    [roadsMetadata.data?.tileService.layerName],
  );

  const currentLayer = useMemo(() => {
    if (selectedZoning !== "Custom") {
      return layers.find(({ level }) => level === selectedZoning);
    }

    let customZoningTileInfo: (ODTileLayer & { type?: string }) | null = null;

    customZoningSelectorFolders?.folders.forEach((folder) => {
      folder.customZonings.forEach((customZoning) => {
        if (customZoning.customZoningId === ODDatasetConfiguration.data?.customZoningId) {
          customZoningTileInfo = { ...customZoning.tileService, type: "CustomZoning" };
          customZoningTileInfo.url = `${process.env.REACT_APP_API_HOST}${customZoning.tileService.url}`;
          return;
        }
      });
    });

    if (customZoningTileInfo) {
      return customZoningTileInfo as ODTileLayer;
    }
  }, [layers, selectedZoning, customZoningSelectorFolders, ODDatasetConfiguration.data?.customZoningId]);

  const selectedRoadClasses = useMemo(
    () =>
      Object.values(roadClasses.items)
        .filter(({ isChecked }) => isChecked)
        .map(({ value }) => Number(value)),
    [roadClasses],
  );

  const getRoadSegmentIds = useCallback(() => {
    if (ODDatasetConfiguration.data) {
      dispatch(
        analyticsActions.fetchSegmentIds({
          timePeriod: ODDatasetConfiguration.data.timePeriod,
          areaOfInterest: ODDatasetConfiguration.data.areaIds || null,
          onlyFromTo: true,
          includeReverse: true,
          compression: "gzip",
        }),
      );
    }
  }, [ODDatasetConfiguration.data, dispatch]);

  const getSubareaState = useCallback(
    (e: MapLayerMouseEvent) => {
      const feature = e.features?.[0] as Feature<Polygon, any>;

      if (
        feature &&
        (feature.geometry.type === "Polygon" || feature.geometry.type === "MultiPolygon") &&
        ODDatasetConfiguration.state === DataState.AVAILABLE
      ) {
        let allFeatures = draw.current.getAll().features;

        if (allFeatures.length === 0) {
          dispatch(analyticsActions.setSubAreaPolygon(null));

          return;
        }

        if (isPolygonSubtractMode) {
          allFeatures = allFeatures
            .filter((f: Feature<Polygon, any>) => f.id !== feature.id)
            .map((f: Feature<Polygon, any>) => difference(f, feature as Feature<Polygon, any>))
            .filter((f: Feature<Polygon, any>) => f !== null);
        }

        if (allFeatures.length === 0) {
          dispatch(analyticsActions.setSubAreaPolygon(null));
          setDrawModeActive(false);
          setPolygonSubtractMode(false);

          return;
        }

        let combined = allFeatures[0];
        for (let i = 1; i < allFeatures.length; i++) {
          combined = union(combined, allFeatures[i]);
        }

        if (
          combined &&
          (combined.geometry.type === "Polygon" || combined.geometry.type === "MultiPolygon") &&
          selectedZoning
        ) {
          dispatch(analyticsActions.setSubAreaPolygon(combined.geometry));
          setDrawModeActive(false);
          setPolygonSubtractMode(false);
        }
      }
    },
    [selectedZoning, ODDatasetConfiguration, isPolygonSubtractMode, dispatch],
  );

  const setSegmentFeatureState = useCallback(
    (id: string, state: { [key: string]: boolean }) => {
      if (map.current && mapLoaded && map.current.getSource(EditorRoads.SourceId) && roadsLayerName) {
        map.current?.setFeatureState(
          {
            source: EditorRoads.SourceId,
            sourceLayer: roadsLayerName,
            id,
          },
          state,
        );
      }
    },
    [mapLoaded, roadsLayerName],
  );

  const onSelectionChange = () => {
    setIsPolygonSelected(Boolean(draw.current.getSelected().features.length));
  };

  const onModeChange = () => {
    setDrawModeActive(false);
    setPolygonSubtractMode(false);
  };

  const onChangeZoning = useCallback(
    (zoningLevel: string, customZoningId?: string) => {
      if (ODDatasetConfiguration.data) {
        dispatch(
          analyticsActions.updateODDatasetConfigSucceeded({
            ...ODDatasetConfiguration.data,
            zoningLevel,
            customZoningId,
          }),
        );
      }
    },
    [ODDatasetConfiguration.data, dispatch],
  );

  const toggleGateEditorPanel = () => {
    setIsGateEditorOpen(!isGateEditorOpen);
  };

  // Fetch dataset configuration
  useEffect(() => {
    if (datasetId && isTokenLoaded) {
      dispatch(analyticsActions.fetchODDatasetConfig(datasetId));
    }
  }, [datasetId, isTokenLoaded, dispatch]);

  // Replace dataset id in url when, on version conflict, a new dataset is created
  useEffect(() => {
    if (newODDatasetConfiguration) {
      navigate(`/datasets/${newODDatasetConfiguration.datasetId}/edit`);
      dispatch(analyticsActions.clearNewODDatasetConfig());
    }
  }, [newODDatasetConfiguration, search, navigate, dispatch]);

  useEffect(() => {
    if (ODDatasetConfiguration.state === DataState.AVAILABLE && mapLoaded && draw.current) {
      draw.current.deleteAll();
      if (ODDatasetConfiguration.data.subAreaGeometry) draw.current.add(ODDatasetConfiguration.data.subAreaGeometry);
    }
  }, [ODDatasetConfiguration.state, ODDatasetConfiguration.data?.subAreaGeometry, mapLoaded, dispatch]);

  useEffect(() => {
    if (ODDatasetConfiguration.state === DataState.AVAILABLE && roadsMetadata.state === DataState.AVAILABLE) {
      const datasetRoadClasses = roadsMetadata.data?.roadClasses
        .map((roadClass) => ({
          value: roadClass.id.toString(),
          label: roadClass.label,
          isChecked: ODDatasetConfiguration.data?.gateRoadClasses?.indexOf(roadClass.id) !== -1,
        }))
        .reduce((map, roadClass) => {
          return {
            ...map,
            [roadClass.value]: roadClass,
          };
        }, {});
      setRoadClasses({
        items: datasetRoadClasses,
      });
    }
  }, [
    ODDatasetConfiguration.state,
    ODDatasetConfiguration.data?.gateRoadClasses,
    roadsMetadata.state,
    roadsMetadata.data?.roadClasses,
  ]);

  useEffect(() => {
    if (ODDatasetConfiguration.state === DataState.AVAILABLE) {
      if (
        isRoadsSourceInitialized &&
        ODDatasetConfiguration.data.timePeriod &&
        ODDatasetConfiguration.data.gateRoadClasses &&
        selectedZoning
      ) {
        if (ODDatasetConfiguration.data.subAreaGeometry) {
          dispatch(
            analyticsActions.fetchSubareaState({
              polygon: ODDatasetConfiguration.data.subAreaGeometry,
              timePeriod: ODDatasetConfiguration.data.timePeriod,
              gateRoadClasses: ODDatasetConfiguration.data.gateRoadClasses,
              level: selectedZoning,
              customZoningId: ODDatasetConfiguration.data.customZoningId,
              includeSegmentGeometry: true,
            }),
          );
        } else {
          dispatch(analyticsActions.clearSubareaState());
        }
      }
    }
  }, [
    ODDatasetConfiguration.state,
    ODDatasetConfiguration.data?.subAreaGeometry,
    ODDatasetConfiguration.data?.timePeriod,
    ODDatasetConfiguration.data?.gateRoadClasses,
    ODDatasetConfiguration.data?.customZoningId,
    isRoadsSourceInitialized,
    selectedZoning,
    dispatch,
  ]);

  const changeShowZones = () => {
    if (currentLayer) {
      const isVisible = !showZones;
      setShowZones(isVisible);

      map.current?.setLayoutProperty(currentLayer.name, "visibility", isVisible ? "visible" : "none");

      map.current?.setLayoutProperty(
        `${EditorOD.ZonesBounds}-${currentLayer.name}`,
        "visibility",
        isVisible ? "visible" : "none",
      );
    }
  };

  const changeShowRoads = () => {
    const isVisible = !showRoads;
    setShowRoads(isVisible);

    map.current?.setLayoutProperty(EditorRoads.SegmentsLayerId, "visibility", isVisible ? "visible" : "none");
    map.current?.setLayoutProperty(
      EditorRoads.HighlightedSegmentsLayerId,
      "visibility",
      isVisible ? "visible" : "none",
    );
  };

  const changeShowGates = () => {
    const isVisible = !showGates;
    setShowGates(isVisible);

    if (map.current?.getLayer(GEN_GATES_LAYER_ID)) {
      map.current?.setLayoutProperty(GEN_GATES_LAYER_ID, "visibility", isVisible ? "visible" : "none");
    }
    if (map.current?.getLayer(LOCKED_GEN_GATES_LAYER_ID)) {
      map.current?.setLayoutProperty(LOCKED_GEN_GATES_LAYER_ID, "visibility", isVisible ? "visible" : "none");
    }
  };

  useEffect(() => {
    if (map.current) return; // initialize map only once

    if (areaOfInterest) {
      const bounds = getBounds(ODDatasetConfiguration.data?.subAreaGeometry || areaOfInterest.geometry);
      const padding = { top: 40, bottom: 40, left: 340, right: 40 };

      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: baseMapStyle,
        bounds: bounds,
        fitBoundsOptions: {
          padding,
        },
        pitch: 0,
        bearing: 0,
        logoPosition: "bottom-right",
        transformRequest: (url, resourceType): any => {
          if (resourceType === "Tile" && url.startsWith(process.env.REACT_APP_API_HOST as string)) {
            return {
              headers: {
                authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
              },
              url,
            };
          }
        },
      });

      // disable map rotation using right click + drag
      map.current.dragRotate.disable();

      // disable map rotation using touch rotation gesture
      map.current.touchZoomRotate.disableRotation();

      // Add full extent control to the map.
      map.current.addControl(
        getMapHomeControl(map.current, bounds, {
          padding,
        }),
        "top-left",
      );

      // Add zoom controls to the map.
      map.current.addControl(new mapboxgl.NavigationControl({ showCompass: false }), "top-left");

      // Disable polygon dragging
      const directSelectOnDrag = MapboxDraw.modes.direct_select.onDrag;
      MapboxDraw.modes.direct_select.onDrag = (state: any, e: any) => {
        if (!state.selectedCoordPaths || state.selectedCoordPaths.length <= 0) {
          return;
        }
        return directSelectOnDrag.call(MapboxDraw.modes.direct_select, state, e);
      };

      // Add the ability to drag the map when the polygon is active
      const simpleSelectStartOnActiveFeature = MapboxDraw.modes.simple_select.startOnActiveFeature;
      MapboxDraw.modes.simple_select.startOnActiveFeature = function (state: any, e: any) {
        if (!state.selectedCoordPaths || state.selectedCoordPaths.length <= 0) {
          return;
        }

        return simpleSelectStartOnActiveFeature.call(MapboxDraw.modes.simple_select, state, e);
      };

      draw.current = new MapboxDraw({
        displayControlsDefault: false,
        touchEnabled: false,
        boxSelect: false,
        controls: {
          polygon: true,
          trash: true,
        },
        styles: drawStyles,
      });
      if (draw.current) map.current.addControl(draw.current, "top-right");

      map.current.on("load", () => {
        map.current?.loadImage(pinImage, (error, image) => {
          if (error) throw error;
          if (image) map.current?.addImage("pin", image);
        });

        setMapLoaded(true);
      });

      map.current.on("style.load", () => {
        if (map.current) {
          addBoundariesLayer({
            map: map.current,
            selectedArea: {
              id: "editor",
              geometry: areaOfInterest.geometry,
            } as any,
          });

          setBaseStylesUpdated(true);
        }
      });

      const sourceCallback = () => {
        if (
          map.current &&
          map.current.getSource(EditorRoads.SourceId) &&
          map.current.isSourceLoaded(EditorRoads.SourceId)
        ) {
          setRoadsSourceInitialized(true);
        }
      };

      map.current.on("sourcedata", sourceCallback);
    }

    return () => {
      dispatch(analyticsActions.clearSubareaState());
    };
  }, [ODDatasetConfiguration.data?.subAreaGeometry, areaOfInterest, baseMapStyle, dispatch]);

  useEffect(() => {
    if (map.current) {
      map.current.on("draw.create", getSubareaState);
      map.current.on("draw.delete", getSubareaState);
      map.current.on("draw.update", getSubareaState);
      map.current.on("draw.selectionchange", onSelectionChange);
      map.current.on("draw.modechange", onModeChange);
    }
  }, [mapLoaded, getSubareaState]);

  // Set selectedZoning to the minimum granularity available on first render
  useEffect(() => {
    if (mapLoaded && !selectedZoning && ODMetadata.state === DataState.AVAILABLE) {
      onChangeZoning(ODMetadata.data.level, ODDatasetConfiguration.data?.customZoningId);
    }
  }, [mapLoaded, selectedZoning, ODMetadata, ODDatasetConfiguration.data?.customZoningId, onChangeZoning]);

  //Fetch Segment IDs
  useEffect(() => {
    if (mapLoaded && roadSegmentIds.state === DataState.EMPTY) {
      getRoadSegmentIds();
    }
  }, [mapLoaded, roadSegmentIds.state, getRoadSegmentIds]);

  //Fetch OD Zones Ids
  useEffect(() => {
    if (
      ODDatasetConfiguration.data?.areaIds &&
      ODDatasetConfiguration.data?.timePeriod &&
      ODIds.state === DataState.EMPTY &&
      ODMetadata.state === DataState.AVAILABLE
    ) {
      dispatch(
        analyticsActions.fetchODIds(getAvailableZoomLevels(ODMetadata.data.tileService.layers), {
          timePeriod: ODDatasetConfiguration.data.timePeriod,
          areaOfInterest: ODDatasetConfiguration.data.areaIds,
        }),
      );
    }
  }, [
    ODDatasetConfiguration.data?.areaIds,
    ODDatasetConfiguration.data?.timePeriod,
    ODIds.state,
    ODMetadata,
    dispatch,
  ]);

  //Initialize map for OD
  useEffect(() => {
    if (
      map.current &&
      mapLoaded &&
      currentLayer &&
      ODIds.state === DataState.AVAILABLE &&
      ODMetadata.state === DataState.AVAILABLE &&
      isRoadsSourceInitialized
    ) {
      if (ODClearFunction.current) {
        ODClearFunction.current();
      }

      const { clear } = initEditorOD(map.current, {
        ids: ODIds.data,
        layer: currentLayer,
        outerLayer: ODMetadata.data.tileService.layers[0],
      });

      ODClearFunction.current = clear;
    }
  }, [currentLayer, ODIds, ODMetadata, mapLoaded, isRoadsSourceInitialized]);

  //Set highlighted zones filter on subarea state change
  useEffect(() => {
    if (
      map.current &&
      mapLoaded &&
      currentLayer &&
      baseStylesUpdated &&
      map.current.getLayer(EditorOD.ZonesFillHighlighted)
    ) {
      map.current.setFilter(EditorOD.ZonesFillHighlighted, [
        "in",
        ["get", currentLayer.idField],
        ["literal", subareaState.data?.zoneIds || []],
      ]);
    }
  }, [currentLayer, subareaState.data?.zoneIds, mapLoaded, baseStylesUpdated]);

  //Initialize map for Roads
  useEffect(() => {
    if (
      map.current &&
      mapLoaded &&
      roadSegmentIds.state === DataState.AVAILABLE &&
      roadsMetadata.state === DataState.AVAILABLE &&
      baseStylesUpdated
    ) {
      if (roadsClearFunction.current) {
        roadsClearFunction.current();
      }

      const { clear } = initEditorRoads(map.current, {
        tileService: roadsMetadata.data.tileService,
        roadSegmentIds: Object.keys(roadSegmentIds.data || {}),
      });

      roadsClearFunction.current = clear;
    }
  }, [mapLoaded, roadSegmentIds, baseStylesUpdated, roadsMetadata, dispatch]);

  // Initialize gate candidates
  useEffect(() => {
    if (map.current && mapLoaded) {
      if (gateCandidatesClearFunction.current) {
        gateCandidatesClearFunction.current();
      }

      const { clear } = initEditorGateCandidates(map.current, {
        candidates: subareaState.data?.gateCandidates || [],
        generatedGates: generatedGatesDataRef.current || [],
      });

      gateCandidatesClearFunction.current = clear;
    }
  }, [mapLoaded, subareaState.data?.gateCandidates]);

  // Filtering out gate segments candidates that are already part of an existing gate
  useEffect(() => {
    if (
      map.current &&
      mapLoaded &&
      ODDatasetConfiguration.state === DataState.AVAILABLE &&
      map.current.getLayer(EditorGateCandidates.LayerId)
    ) {
      map.current.setFilter(EditorGateCandidates.LayerId, [
        "match",
        ["get", "fromToSegId"],
        ["in", ...(ODDatasetConfiguration.data.gates?.map((gate) => gate.segments.map((s) => s.id)).flat(1) || [])],
        false,
        true,
      ]);
    }
  }, [mapLoaded, ODDatasetConfiguration.state, ODDatasetConfiguration.data?.gates]);

  // Set filter for showing segments just for the selected road classes
  useEffect(() => {
    if (
      mapLoaded &&
      roadSegmentIds.state === DataState.AVAILABLE &&
      roadsMetadata.state === DataState.AVAILABLE &&
      map.current?.getLayer(EditorRoads.SegmentsLayerId) &&
      map.current?.getLayer(EditorRoads.HighlightedSegmentsLayerId)
    ) {
      const filter = [
        "all",
        ["in", roadsMetadata.data?.tileService.facilityTypeField, ...selectedRoadClasses],
        ["in", roadsMetadata.data?.tileService.fromToSegmentIdField, ...Object.keys(roadSegmentIds.data || {})],
      ];
      map.current.setFilter(EditorRoads.SegmentsLayerId, filter);
      map.current.setFilter(EditorRoads.HighlightedSegmentsLayerId, filter);
    }
  }, [mapLoaded, roadsMetadata, roadSegmentIds, selectedRoadClasses]);

  const onChangeRoadClasses = useCallback(
    (roadClasses: RoadClassGroup) => {
      setRoadClasses(roadClasses);

      if (ODDatasetConfiguration.data && roadClasses.items) {
        dispatch(
          analyticsActions.updateODDatasetConfigSucceeded({
            ...ODDatasetConfiguration.data,
            gateRoadClasses: Object.values(roadClasses?.items)
              .filter((item) => item.isChecked)
              .map((item) => Number(item.value)),
          }),
        );
      }
    },
    [ODDatasetConfiguration.data, dispatch],
  );

  const onAddFullEntireAreaPolygon = useCallback(() => {
    if (ODIds.data && ODDatasetConfiguration.data && selectedZoning) {
      dispatch(
        analyticsActions.fetchSubareaPolygon({
          timePeriod: ODDatasetConfiguration.data.timePeriod,
          simplification: {
            distanceM: DEFAULT_SIMPLIFICATION_DISTANCE_M,
          },
          customZoningId: selectedZoning === "Custom" ? ODDatasetConfiguration.data.customZoningId : undefined,
        }),
      );
    }
  }, [selectedZoning, ODIds.data, ODDatasetConfiguration.data, dispatch]);

  const handleOpenValidationModal = useCallback(() => {
    if (!isValidationModalOpen) {
      setIsValidationModalOpen(true);
    }
  }, [isValidationModalOpen]);

  const handleCloseValidationModal = useCallback(() => {
    if (isValidationModalOpen) {
      setIsValidationModalOpen(false);
    }
  }, [isValidationModalOpen]);

  const handleGeocodingSearch = (searchText: string) => {
    if (map.current) {
      const proximity = map.current.getCenter().toArray().join(",");

      dispatch(
        analyticsActions.fetchGeocodingSearchResults(
          searchText,
          process.env.REACT_APP_MAPBOX_ACCESS_TOKEN as string,
          proximity,
        ),
      );
    }
  };

  const handleClearGeocodingSearch = () => {
    dispatch(analyticsActions.clearGeocodingSearchResults());
  };

  const handleRemoveSearchMarker = () => {
    searchMarker.current.remove();
  };

  const handleGeocodingFlyTo = (coordinates: [number, number], placeName: string) => {
    if (map.current) {
      map.current.flyTo({
        center: coordinates,
        zoom: 14,
      });

      searchMarker.current.remove();
      searchMarker.current.setLngLat(coordinates).addTo(map.current);
    }

    addCustomGAEvent("geocoding-search", "search", placeName, user, userOrganizationName);
  };

  return (
    <MapPageContainer>
      {mapLoaded && subareaState.state === DataState.LOADING && <SpinnerOverlay />}

      <LeftSidebar>
        <MainPanel
          draw={draw.current}
          zoningOptions={layers.map((l) => l.level)}
          selectedZoning={selectedZoning}
          setSelectedZoning={onChangeZoning}
          setPolygonSubtractMode={setPolygonSubtractMode}
          showZones={showZones}
          showRoads={showRoads}
          showGates={showGates}
          changeShowZones={changeShowZones}
          changeShowRoads={changeShowRoads}
          changeShowGates={changeShowGates}
          roadClasses={roadClasses}
          setRoadClasses={onChangeRoadClasses}
          toggleGateEditorPanel={toggleGateEditorPanel}
          onAddFullEntireAreaPolygon={onAddFullEntireAreaPolygon}
          openValidationModal={handleOpenValidationModal}
          areaOfInterest={areaOfInterest}
          setDrawModeActive={setDrawModeActive}
          isDrawModeActive={isDrawModeActive}
          isPolygonSelected={isPolygonSelected}
          isPolygonSubtractMode={isPolygonSubtractMode}
        />
      </LeftSidebar>
      <Mapbox ref={mapContainer} style={{ display: "block" }} />
      <RightSidebar isOpen={isGateEditorOpen} animation={true}>
        <GatesEditor
          map={map.current}
          draw={draw.current}
          mapLoaded={mapLoaded}
          roadClasses={roadClasses}
          isRoadsSourceInitialized={isRoadsSourceInitialized}
          setSegmentFeatureState={setSegmentFeatureState}
          setIsGateEditorOpen={setIsGateEditorOpen}
        />
      </RightSidebar>
      <ComputeDatasetDialog isOpen={isValidationModalOpen} onClose={handleCloseValidationModal} />
      <GeocodingSearch
        geocodingSearchResults={geocodingSearchResults.data}
        geocodingSearchByText={handleGeocodingSearch}
        clearGeocodingSearch={handleClearGeocodingSearch}
        removeSearchMarker={handleRemoveSearchMarker}
        flyTo={handleGeocodingFlyTo}
      />
    </MapPageContainer>
  );
};
