export const gatesFileColums = [
  {
    column: "gate_id",
    type: "Integer",
    description: "Teralytics gate ID",
    notes: "",
  },
  {
    column: "gate_idseq",
    type: "Integer",
    description:
      "Renumbered gate ID, mapped into 1-32,000 range, following highest zone ID (zone_idseq) as Bentley CUBE allows maximum value for OD zone IDs of 32,000 )",
    notes: "",
  },
  {
    column: "from_node",
    type: "Integer",
    description: "Teralytics FROM node ID for the gate segment",
    notes: "",
  },
  {
    column: "to_node",
    type: "Integer",
    description: "Teralytics TO node ID for the gate segment",
    notes: "",
  },
  {
    column: "longitude",
    type: "Double",
    description: "Longitude of the gate marker",
    notes: "",
  },
  {
    column: "latitude",
    type: "Double",
    description: "Latitude of the gate marker",
    notes: "",
  },
  {
    column: "description",
    type: "Text",
    description: "Optional gate description, if defined by user in Studio",
    notes: "",
  },
];
