import { Divider as BpDivider } from "@blueprintjs/core";
import styled from "@emotion/styled";

export const Divider = styled(BpDivider)`
  &.bp4-divider {
    border-bottom: 1px solid var(--color-border);
    border-right: 1px solid var(--color-border);
  }
`;

export const DividerWithText = styled.div`
  display: flex;
  align-items: center;
  margin: 0.5rem 0 0.5rem 0;
  font-size: 1em;
  font-weight: 500;

  &:before,
  &:after {
    content: "";
    flex: 1;
    height: 1px;
    margin: 0 1em;
    background: var(--color-border);
  }
`;
