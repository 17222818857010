import styled from "@emotion/styled";
import { Alert, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { Map } from "mapbox-gl";
import React, { Dispatch, FC, SetStateAction, memo, useMemo } from "react";

import { BaseMapStyles, Filters, MapBoxStyles, MapLayers } from "features";

import { AreaName, Button, FocusAreaDropdown, LeftSidebar, ToggleButtons } from "components";

import { useAppDispatch, useAppSelector } from "hooks";

import { DataState } from "store/interfaces";
import { analyticsActions } from "store/sections/analytics";
import { globalActions } from "store/sections/global";
import { mapActions } from "store/sections/map";

import { MapVisualizationType, QueryType, SelectedArea } from "types";

interface MapControlsPanelProps {
  map: Map | null;
  mapLoaded: boolean;
  filterLoading: boolean;
  selectedZone: SelectedArea | null;
  showZones: boolean;
  showRoadVolumes: boolean;
  handleSelectZone?: (selectedZone: SelectedArea | null) => void;
  handleToggleQueryType: (type: QueryType) => void;
  setIsExportDialogOpen?: Dispatch<SetStateAction<boolean>>;
  setBaseStylesUpdated: Dispatch<SetStateAction<boolean>>;
  changeShowRoadVolumes: () => void;
  changeShowZones: () => void;
  leftButtonLabel: string;
  rightButtonLabel: string;
  leftButtonDisabled: boolean;
  rightButtonDisabled: boolean;
  exportButtonDisabled: boolean;
  disableMapLayers: boolean;
  zoningLevel?: string;
  isAnalysis?: boolean;
  isAnalysisResults?: boolean;
}

const AreaNameContainer = styled.div`
  margin: -0.8rem 0 1rem 0.5rem;
  display: flex;
`;

const FiltersContainer = styled.div<{ isDataset: boolean }>`
  overflow-y: auto;
  overflow-x: hidden;
  height: ${({ isDataset }) => (isDataset ? "calc(100% - 240px)" : "calc(100% - 225px)")};
  margin: 1rem -1rem 0 0;
  padding: 0 1rem 0 2px;
`;

const ExportButton = styled(Button)`
  width: 100%;
  margin-bottom: 1rem;
`;

export const MapControlsPanel: FC<MapControlsPanelProps> = memo(
  ({
    map,
    mapLoaded,
    filterLoading,
    selectedZone,
    showZones,
    showRoadVolumes,
    handleSelectZone,
    handleToggleQueryType,
    setIsExportDialogOpen,
    setBaseStylesUpdated,
    changeShowRoadVolumes,
    changeShowZones,
    disableMapLayers,
    leftButtonLabel,
    rightButtonLabel,
    leftButtonDisabled,
    rightButtonDisabled,
    zoningLevel,
    exportButtonDisabled,
    isAnalysis,
    isAnalysisResults,
  }) => {
    const dispatch = useAppDispatch();

    const isODExportAllowed = useAppSelector((state) => state.analytics.ODMetadata.data?.exportPermissions.allowExport);
    const isRoadsExportAllowed = useAppSelector(
      (state) => state.analytics.roadsMetadata.data?.exportPermissions.allowExport,
    );
    const colorScheme = useAppSelector((state) => state.map.colorScheme);
    const focusAreas = useAppSelector((state) => state.analytics.focusAreasAndDatasets);
    const selectedFocusArea = useAppSelector((state) => state.global.selectedFocusArea);
    const ODMetadata = useAppSelector((state) => state.analytics.ODMetadata);
    const roadsMetadata = useAppSelector((state) => state.analytics.roadsMetadata);
    const datasetMetadata = useAppSelector((state) => state.analytics.datasetMetadata);
    const timePeriod = useAppSelector((state) => state.global.timePeriod);
    const mode = useAppSelector((state) => state.analytics.mapVisualizationMode);

    const isODDisabled = useMemo(
      () => ODMetadata.error?.status === 403 || datasetMetadata.error?.status === 403,
      [ODMetadata.error?.status, datasetMetadata.error?.status],
    );

    const isRoadsDisabled = useMemo(() => roadsMetadata.error?.status === 403, [roadsMetadata.error?.status]);

    const isExportDisabled = useMemo(
      () => isODExportAllowed === false || isRoadsExportAllowed === false || exportButtonDisabled,
      [isODExportAllowed, isRoadsExportAllowed, exportButtonDisabled],
    );

    const baseMapStyle = useAppSelector((state) => state.map.baseMapStyle);

    const handleChangeVisualizationMode = (mode: string) => {
      dispatch(analyticsActions.setMapVisualizationMode(mode as MapVisualizationType));

      if (selectedZone && handleSelectZone) {
        handleSelectZone(null);
      }
    };

    const handleChangeBaseMapStyle = (style: MapBoxStyles) => {
      if (baseMapStyle !== style && map) {
        dispatch(mapActions.setBaseMapStyle(style));
        map.setStyle(style);

        setBaseStylesUpdated(false);
      }
    };

    const changeColorScheme = (scheme: string) => {
      dispatch(mapActions.setColorScheme(scheme));
    };

    const loading = useMemo(
      () =>
        focusAreas.state === DataState.LOADING ||
        ODMetadata.state === DataState.LOADING ||
        datasetMetadata.state === DataState.LOADING ||
        roadsMetadata.state === DataState.LOADING,
      [focusAreas.state, ODMetadata.state, datasetMetadata.state, roadsMetadata.state],
    );

    const handleChangeFocusArea = (focusAreaId: string) => {
      if (focusAreaId) {
        dispatch(globalActions.setSelectedFocusAreaId({ focusAreaId }));
      }
    };

    const handleChangeTimePeriod = (event: SelectChangeEvent) => {
      const bounds: any = map?.getBounds().toArray();

      if (bounds) {
        dispatch(mapActions.setMapBounds(bounds));
      }
      dispatch(globalActions.setTimePeriod(event.target.value));
    };

    return (
      <LeftSidebar>
        <FocusAreaDropdown
          sx={{ mb: 2 }}
          loading={loading}
          disabled={
            focusAreas.state === DataState.EMPTY ||
            focusAreas.state === DataState.ERROR ||
            !!isAnalysis ||
            filterLoading
          }
          options={focusAreas.data || []}
          value={selectedFocusArea}
          onChange={handleChangeFocusArea}
        />
        {selectedFocusArea?.datasetId && (
          <AreaNameContainer>
            <AreaName>{selectedFocusArea?.region}</AreaName>
          </AreaNameContainer>
        )}
        <FormControl fullWidth sx={{ mb: 2 }}>
          <InputLabel id="time-period-select">Time Period</InputLabel>
          <Select
            labelId="time-period-select"
            id="time-period-select"
            value={timePeriod || ""}
            label="Time Period"
            disabled={(selectedFocusArea?.timePeriods?.length ?? 1) <= 1 || !!isAnalysis || filterLoading}
            onChange={handleChangeTimePeriod}
          >
            {selectedFocusArea?.timePeriods?.map((timePeriod) => (
              <MenuItem key={timePeriod} value={timePeriod}>
                {timePeriod}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {setIsExportDialogOpen && (
          <ExportButton
            leadingIcon="import"
            size="sm"
            color="primaryLight"
            disabled={isExportDisabled}
            onClick={() => setIsExportDialogOpen(true)}
          >
            New Export
          </ExportButton>
        )}

        {mode && (
          <ToggleButtons
            leftButtonLabel={leftButtonLabel}
            rightButtonLabel={rightButtonLabel}
            activeIndex={mode}
            onChangeIndex={handleChangeVisualizationMode}
            leftButtonDisabled={isODDisabled || leftButtonDisabled || filterLoading}
            rightButtonDisabled={isRoadsDisabled || rightButtonDisabled || filterLoading}
            leftButtonIndex={MapVisualizationType.OD}
            rightButtonIndex={MapVisualizationType.ROADS}
          />
        )}

        <FiltersContainer isDataset={!!selectedFocusArea?.datasetId}>
          {mode === MapVisualizationType.OD && isAnalysis && (
            <Alert
              severity="info"
              sx={{
                fontSize: 10,
                fontWeight: 500,
                padding: "2px 6px",
                backgroundColor: "#eff6ff",
              }}
            >
              Attribute Filters in Zones selection are not applied to Select Link query
            </Alert>
          )}
          <Filters
            mode={mode}
            loading={filterLoading}
            disabled={loading || disableMapLayers}
            setCurrentQueryType={handleToggleQueryType}
            isAnalysis={isAnalysis}
          />
          <MapLayers
            map={map}
            mapLoaded={mapLoaded}
            mode={mode}
            showZones={showZones}
            showRoadVolumes={showRoadVolumes}
            colorScheme={colorScheme}
            disabled={disableMapLayers}
            loading={filterLoading}
            zoningLevel={zoningLevel}
            selectedZone={selectedZone}
            changeColorScheme={changeColorScheme}
            changeShowRoadVolumes={changeShowRoadVolumes}
            changeShowZones={changeShowZones}
            isAnalysis={isAnalysis}
            isAnalysisResults={isAnalysisResults}
          />
          {isAnalysis ? null : <BaseMapStyles baseMapStyle={baseMapStyle} onChangeStyle={handleChangeBaseMapStyle} />}
        </FiltersContainer>
      </LeftSidebar>
    );
  },
);
