import { Icon } from "@blueprintjs/core";
import styled from "@emotion/styled";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { LngLatLike } from "mapbox-gl";
import { FC, MouseEvent, memo, useCallback, useEffect, useMemo, useState } from "react";

import { Button, Divider, FlexContainer, IconButton, ToggleButtons } from "components";

import {
  SelectLinkAnalysisMode,
  SelectLinkAnalysisOptions,
  ZoneSelectionMode,
} from "components/pages/analytics/select-link/types";

import { useAppSelector } from "hooks";

import {
  MapVisualizationType,
  SegmentsGroup,
  SelectLinkConfig,
  SelectLinkPredicateLogic,
  SelectedArea,
  SelectedSegmentConfig,
} from "types";

import { AndOrToggle } from "./AndOrToggle";
import { ConfirmDeleteGroupDialog } from "./ConfirmDeleteGroupDialog";
import { isLastSegmentInGroup, isLastSegmentsGroup, selectColorOrGenerate } from "./utils";

interface SelectLinkConfigPanelProps {
  selectLinkConfig: SelectLinkConfig;
  filterLoading: boolean;
  selectedLinkGroups: SegmentsGroup[];
  selectedOrigins: SelectedArea[];
  selectedDestinations: SelectedArea[];
  predicateLogic: SelectLinkPredicateLogic;
  options: SelectLinkAnalysisOptions;
  mode: string | null;
  selectLinkAnalysisMode: string;
  activeZoneSelectionMode: string;
  activeSegmentsGroup: string;
  maxGroupId: number;
  onDeleteSelectedLink: (segment: SelectedSegmentConfig, segmentsGroupId: string) => void;
  onDeleteSelectedZone: (zoneToDelete: SelectedArea) => void;
  onRunAnalysis: (options: SelectLinkAnalysisOptions) => void;
  onChangePredicateLogic: (index: SelectLinkPredicateLogic) => void;
  onZoomOnCoordinate: (ll: LngLatLike) => void;
  onChangeZoneSelectionMode: (index: string) => void;
  onChangeActiveGroup: (groupId: string) => void;
  onAddNewSegmentsGroup: (group: SegmentsGroup, id: number) => void;
  onDeleteSegmentsGroup: (groupId: string) => void;
  onChangeOptions: (options: SelectLinkAnalysisOptions) => void;
  onSaveConfiguration: (options: SelectLinkAnalysisOptions) => void;
  onConfigureAnalysis: () => void;
}

const SegmentsContainer = styled.div`
  height: auto;
  overflow-y: auto;
  height: calc(100% - 180px - 1rem);
`;

const ZonesContainer = styled.div`
  height: auto;
  overflow-y: auto;
  height: calc(100% - 224px + 0.25rem);
`;

const ElementsList = styled.ul`
  padding: 0.25rem 0;
`;

const ListItem = styled.li<{ isSelected?: boolean }>`
  width: 100%;
  min-height: 32px;
  margin: 0.25rem 0;
  padding: 0.25rem 1rem;
  border: ${({ isSelected }) => (isSelected ? "1px solid var( --color-primary)" : "1px solid var(--color-gray-300)")};
  transition: border 0.3s ease-out;
  border-radius: 8px;
  box-shadow: var(--box-shadow-xs);
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

const SegmentDirectionContainer = styled.div`
  min-width: 35px;
`;

const DirectionIcon = styled(Icon)`
  margin-right: 0.25rem;
`;

const EllipsisText = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const ElementName = styled(EllipsisText)`
  font-size: 12px;
  font-weight: 500;
  max-width: 120px;
`;

const StyledIconButton = styled(IconButton)`
  border: none;
`;

const DeleteButton = styled(StyledIconButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  width: 10px;
  height: 20px;
  cursor: pointer;
  color: #000000;
  border: none;
  transition: color 350ms ease;
  box-shadow: none;

  &:hover {
    background-color: transparent;
    color: var(--color-primaryLight);
  }
`;

const TitlePanel = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const GroupHeader = styled.div`
  font-size: 14px;
  font-weight: 600;
  padding-bottom: 0.25rem;
`;

const ActionButtons = styled(FlexContainer)`
  margin: 1rem 0;
  justify-content: space-between;
`;

const ComputeButton = styled(Button)`
  width: 100px;
`;

const SelectLinkOptions = styled.div`
  display: flex;
  gap: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
`;

const TopMarginDivider = styled(Divider)`
  margin-top: 32px;
`;

const AddGroupButton = styled(Button)`
  margin: 0 auto 1rem auto;
`;

const SegmentsGroupContainer = styled.div`
  margin: auto auto 0.5rem auto;
`;

const ColoredCircle = styled.div<{ color: string }>`
  width: 16px;
  height: 16px;
  background-color: ${({ color }) => color};
  border-radius: 50%;
`;

const GroupSummaryWrapper = styled(FlexContainer)`
  gap: 8px;
`;

const GroupDeleteButtonWrapper = styled.div`
  margin-left: auto;
`;

const ListItemWrapper = styled(FlexContainer)`
  flex-direction: column;
`;

const AndOrLabel = styled.div`
  font-size: 12px;
  font-weight: 600;
`;

const StyledAccordion = styled(MuiAccordion)<{ selected: boolean }>`
  border-bottom: 0px;
  margin-bottom: 0.5rem;
  border: ${({ selected }) => (selected ? "1.5px solid var(--color-secondary)" : "1px solid var(--color-gray-300)")};
  border-radius: 8px;

  &.Mui-expanded {
    margin: 0;
    margin-bottom: 0.5rem;
  }
`;

const StyledAccordionSummary = styled(MuiAccordionSummary)`
  min-height: 32px;
  flex-direction: row-reverse;
  gap: 4px;
  padding-right: 8px;
  padding-left: 4px;

  &.Mui-expanded {
    min-height: 24px;
    padding: 8px 8px 8px 4px;
  }

  & .MuiAccordionSummary-content {
    margin: 0;
    display: inline-block;

    &.Mui-expanded {
      margin: 0;
    }

    & .MuiTypography-root {
      font-size: 14px;
      font-weight: 500;
    }
  }
`;

const StyledAccordionDetails = styled(MuiAccordionDetails)`
  padding: 0 8px;
  border-top: 1px solid var(--color-gray-300);
`;

const AndOrToggleContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const SelectLinkConfigPanel: FC<SelectLinkConfigPanelProps> = memo(
  ({
    selectedLinkGroups,
    selectedOrigins,
    selectedDestinations,
    predicateLogic,
    options,
    mode,
    selectLinkAnalysisMode,
    activeZoneSelectionMode,
    activeSegmentsGroup,
    maxGroupId,
    selectLinkConfig,
    onDeleteSelectedLink,
    onDeleteSelectedZone,
    onRunAnalysis,
    onChangePredicateLogic,
    onZoomOnCoordinate,
    onChangeZoneSelectionMode,
    onChangeActiveGroup,
    onAddNewSegmentsGroup,
    onDeleteSegmentsGroup,
    onChangeOptions,
    onSaveConfiguration,
    onConfigureAnalysis,
  }) => {
    const [selectedSegment, setSelectedSegment] = useState<SelectedSegmentConfig | null>(null);
    const [selectedZone, setSelectedZone] = useState<SelectedArea | null>(null);
    const [minVolume, setMinVolume] = useState<number>(options.minVolume);
    const [zoneSelectionMode, setZoneSelectionMode] = useState<string>(activeZoneSelectionMode);
    const [activeGroupId, setActiveGroupId] = useState<string>(activeSegmentsGroup);
    const [groupToDelete, setGroupToDelete] = useState<SegmentsGroup | null>(null);

    const isLoading = useAppSelector((state) => state.selectLink.loading);

    const selectedLinks = selectedLinkGroups.flatMap((group) => group.segments);

    const canRunAnalysis = selectedLinks.length > 0;
    const isResults = selectLinkAnalysisMode === SelectLinkAnalysisMode.Results;

    const handleMinVolumeChange = (e: any) => {
      const newMinVolume = Math.max(0, e.target.value);
      setMinVolume(newMinVolume);
      onChangeOptions({ minVolume: newMinVolume });
    };

    const handleSelectSegment = useCallback(
      (link: SelectedSegmentConfig) => {
        setSelectedSegment(selectedSegment?.segmentId === link.segmentId ? null : link);
        onZoomOnCoordinate([link.lon, link.lat]);
      },
      [selectedSegment, onZoomOnCoordinate],
    );

    const handleSegmentDeleteButtonClick = (e: MouseEvent, link: SelectedSegmentConfig, segmentsGroupId: string) => {
      e.preventDefault();
      e.stopPropagation();
      onDeleteSelectedLink(link, segmentsGroupId);
    };

    const handleChangeZoneSelectionMode = (zoneMode: string) => {
      setZoneSelectionMode(zoneMode);
      onChangeZoneSelectionMode(zoneMode);
    };

    const handleSelectZone = useCallback(
      (zone: SelectedArea) => {
        setSelectedZone(selectedZone?.id === zone.id ? null : zone);
      },
      [selectedZone],
    );

    const handleZoneDeleteButtonClick = (e: MouseEvent, zone: SelectedArea) => {
      onDeleteSelectedZone(zone);
    };

    const handleAddNewSegmentsGroup = useCallback(() => {
      const color = selectColorOrGenerate(new Set(selectedLinkGroups.map(({ color }) => color)));
      const id = maxGroupId + 1;
      const newGroup = {
        groupName: `${id}`,
        segments: [],
        color,
      };
      if (selectedLinkGroups.length === 0) setActiveGroupId(newGroup.groupName);
      onAddNewSegmentsGroup(newGroup, id);
    }, [maxGroupId, selectedLinkGroups, onAddNewSegmentsGroup]);

    const updateActiveGroupId = (groupId: string): void => {
      setActiveGroupId(groupId);
      onChangeActiveGroup(groupId);
    };

    const onExpandGroup = (groupId: string, isExpanded: boolean) => {
      updateActiveGroupId(groupId);
    };

    const deleteSegmentsGroup = (groupId: string | null) => {
      if (groupId === null) return;

      onDeleteSegmentsGroup(groupId);
      if (activeGroupId === groupId) updateActiveGroupId("");

      setGroupToDelete(null);
    };

    const handleDeleteSegmentsGroup = (e: React.SyntheticEvent, groupId: string) => {
      e.stopPropagation();

      const groupToDelete = selectedLinkGroups.find(({ groupName }) => groupName === groupId);

      if (groupToDelete?.segments.length) {
        setGroupToDelete(groupToDelete);
      } else {
        deleteSegmentsGroup(groupId);
      }
    };

    const sortedGroups = useMemo(
      () => [...selectedLinkGroups].sort((groupA, groupB) => Number(groupA.groupName) - Number(groupB.groupName)),
      [selectedLinkGroups],
    );

    useEffect(() => {
      setMinVolume(options.minVolume);
      setActiveGroupId(activeSegmentsGroup);
    }, [options.minVolume, activeSegmentsGroup]);

    // Create an empty segments group by default
    useEffect(() => {
      if (
        selectedLinkGroups.length === 0 &&
        (!selectLinkConfig.segmentsGroups || selectLinkConfig.segmentsGroups.length === 0)
      ) {
        handleAddNewSegmentsGroup();
      }
    }, [selectLinkConfig.segmentsGroups, selectedLinkGroups.length, handleAddNewSegmentsGroup]);

    return (
      <>
        {groupToDelete && (
          <ConfirmDeleteGroupDialog
            isOpen={Boolean(groupToDelete)}
            segmentsCount={groupToDelete.segments.length}
            onClose={() => setGroupToDelete(null)}
            deleteSegmentsGroup={() => deleteSegmentsGroup(groupToDelete.groupName)}
          />
        )}
        {mode === MapVisualizationType.ROADS && (
          <>
            <AddGroupButton
              color="whiteShadow"
              leadingIcon="plus"
              onClick={handleAddNewSegmentsGroup}
              disabled={isResults}
            >
              New Segments Group
            </AddGroupButton>
            <SegmentsContainer>
              {sortedGroups.map((group) => {
                const selected = activeGroupId === group.groupName;
                return (
                  <SegmentsGroupContainer key={`segments-group-${group.groupName}`}>
                    <StyledAccordion
                      selected={selected}
                      expanded={true}
                      onChange={() => onExpandGroup(group.groupName, activeGroupId !== group.groupName)}
                    >
                      <StyledAccordionSummary
                        id={group.groupName}
                        aria-controls={`group${group.groupName}-header`}
                        expandIcon={<ExpandMoreIcon sx={{ color: "transparent" }} />}
                        sx={{
                          backgroundColor: selected ? "#eff6ff" : "#fff",
                          transition: "all 0.3s ease-out",
                          borderTopLeftRadius: "2px",
                          borderTopRightRadius: "2px",
                        }}
                      >
                        <GroupSummaryWrapper>
                          <ColoredCircle color={group.color} />
                          <Typography>
                            Group {group.groupName} ({group.segments.length})
                          </Typography>
                          <GroupDeleteButtonWrapper>
                            <IconButton
                              icon="cross"
                              color="whiteShadow"
                              variant="squared"
                              size="xxs"
                              disabled={isResults}
                              onClick={(e) => handleDeleteSegmentsGroup(e, group.groupName)}
                            />
                          </GroupDeleteButtonWrapper>
                        </GroupSummaryWrapper>
                      </StyledAccordionSummary>
                      <StyledAccordionDetails>
                        {group.segments.length === 0 && (
                          <Typography fontSize={12} sx={{ padding: "4px" }}>
                            Add segments to the group by clicking them on the map and selecting a direction in the
                            popup.
                          </Typography>
                        )}
                        {group.segments.length > 0 && (
                          <ElementsList>
                            {group.segments.map((link) => (
                              <ListItemWrapper key={`group-${group.groupName}-segment-${link.segmentId}`}>
                                <ListItem
                                  key={link.segmentId}
                                  isSelected={selectedSegment?.segmentId === link.segmentId}
                                  onClick={() => handleSelectSegment(link)}
                                >
                                  <FlexContainer>
                                    <SegmentDirectionContainer>
                                      <DirectionIcon icon={link.directionIcon} />
                                    </SegmentDirectionContainer>
                                    <div>
                                      <ElementName>{link.streetName}</ElementName>
                                    </div>
                                  </FlexContainer>
                                  {!isResults && (
                                    <DeleteButton
                                      size="xs"
                                      icon="cross"
                                      onClick={(e) => handleSegmentDeleteButtonClick(e, link, group.groupName)}
                                    />
                                  )}
                                </ListItem>
                                {!isLastSegmentInGroup(group, link) &&
                                  (sortedGroups.length === 1 ? (
                                    <AndOrToggle
                                      value={predicateLogic}
                                      disabled={isResults}
                                      onChange={onChangePredicateLogic}
                                    />
                                  ) : (
                                    <AndOrLabel>
                                      {predicateLogic === SelectLinkPredicateLogic.And ? "OR" : "AND"}
                                    </AndOrLabel>
                                  ))}
                              </ListItemWrapper>
                            ))}
                          </ElementsList>
                        )}
                      </StyledAccordionDetails>
                    </StyledAccordion>
                    {!isLastSegmentsGroup(sortedGroups, group) && (
                      <AndOrToggleContainer>
                        <AndOrToggle value={predicateLogic} disabled={isResults} onChange={onChangePredicateLogic} />
                      </AndOrToggleContainer>
                    )}
                  </SegmentsGroupContainer>
                );
              })}
            </SegmentsContainer>
          </>
        )}
        {mode === MapVisualizationType.OD && (
          <>
            <TitlePanel>
              <div>
                <GroupHeader>{`Zones (${
                  zoneSelectionMode === ZoneSelectionMode.Origins ? selectedOrigins.length : selectedDestinations.length
                })`}</GroupHeader>
              </div>
            </TitlePanel>
            <ToggleButtons
              leftButtonLabel="Origins"
              rightButtonLabel="Destinations"
              activeIndex={zoneSelectionMode}
              onChangeIndex={handleChangeZoneSelectionMode}
              leftButtonDisabled={false}
              rightButtonDisabled={false}
              leftButtonIndex={ZoneSelectionMode.Origins}
              rightButtonIndex={ZoneSelectionMode.Destinations}
            />
            <ZonesContainer>
              <ElementsList>
                {(zoneSelectionMode === ZoneSelectionMode.Origins ? selectedOrigins : selectedDestinations).map(
                  (zone) => (
                    <ListItem
                      key={zone.id}
                      isSelected={selectedZone?.id === zone.id}
                      onClick={() => handleSelectZone(zone)}
                    >
                      <FlexContainer>
                        <ElementName>{zone.id}</ElementName>
                      </FlexContainer>
                      <DeleteButton
                        size="xs"
                        icon="cross"
                        onClick={(e) => handleZoneDeleteButtonClick(e, zone)}
                        disabled={isResults}
                      />
                    </ListItem>
                  ),
                )}
              </ElementsList>
            </ZonesContainer>
          </>
        )}
        <TopMarginDivider />
        <SelectLinkOptions>
          <TextField
            type="number"
            value={minVolume}
            label="Min Volume"
            size="small"
            disabled={!canRunAnalysis || isResults}
            onChange={handleMinVolumeChange}
            sx={{
              maxWidth: 110,
            }}
          />
        </SelectLinkOptions>
        <Divider />
        <ActionButtons>
          <ComputeButton
            size="sm"
            type="button"
            disabled={!canRunAnalysis}
            onClick={() => (isResults ? onConfigureAnalysis() : onRunAnalysis({ minVolume }))}
          >
            <>{isResults ? "Configure" : "Run Analysis"}</>
          </ComputeButton>
          <Button
            color="white"
            size="sm"
            type="submit"
            loading={isLoading}
            disabled={isResults}
            onClick={() => onSaveConfiguration({ minVolume })}
          >
            Save
          </Button>
        </ActionButtons>
      </>
    );
  },
);
