import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import logo from "assets/svg/logo.svg";
import React, { FC, useEffect } from "react";

import { StatePageContainer } from "components";

interface ErrorPageProps {
  errorMsg: string;
  logoutAndRedirect: (url: string) => void;
}

const LoginErrorPageContainer = styled(StatePageContainer)`
  background-color: #12335c;
`;

const ErrorContainer = styled.div`
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  height: 150px;
  display: grid;
  justify-items: center;

  & img {
    width: 180px;
  }

  & a {
    color: #ffffff;
  }
`;

export const LoginErrorPage: FC<ErrorPageProps> = ({ errorMsg, logoutAndRedirect }) => {
  useEffect(() => {
    const timeout = setTimeout(() => {
      logoutAndRedirect("https://www.teralytics.net/");
    }, 5000);

    return () => {
      clearTimeout(timeout);
    };
  }, [logoutAndRedirect]);

  return (
    <LoginErrorPageContainer>
      <ErrorContainer>
        <img src={logo} alt="logo" />
        <h4>{errorMsg}</h4>
        <p>
          You are being redirected to{" "}
          <Typography
            sx={{
              display: "inline",
              fontWeight: "inherit",
              textDecoration: "underline",
              "&:hover": { cursor: "pointer" },
            }}
            onClick={() => logoutAndRedirect("https://www.teralytics.net/")}
          >
            Teralytics
          </Typography>
          ...
        </p>
      </ErrorContainer>
    </LoginErrorPageContainer>
  );
};
