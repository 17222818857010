import { Map } from "mapbox-gl";
import { Dispatch, SetStateAction } from "react";

import {
  CHOROPLETH_LINE_COLOR,
  CHOROPLETH_LINE_OPACITY,
  CHOROPLETH_LINE_WIDTH,
  ROAD_SEGMENT_PERMANENT_HIGHLIGHT_COLOR,
  ZONE_PERMANENT_HIGHLIGHT_COLOR_DESTINATION,
  ZONE_PERMANENT_HIGHLIGHT_COLOR_ORIGIN,
  defaultLayers,
  getMaxZoom,
  getODSource,
} from "features/map-visualization";

import { RoadsHoverPopupProps } from "components";

import {
  ODMetadata,
  ODTileLayer,
  RoadSegmentIdsByRoadClass,
  RoadSegmentIdsWithFactype,
  RoadsTileService,
  RoadsVolumes,
} from "types";

import { RoadColors, RoadLayerIds, initRoadSegments } from "../map-visualization/roads";

export enum SelectLinkRoads {
  SourceId = "select-link",
  SegmentHairlines = "segment-hairlines",
  SegmentsLayerId = "segment-ids",
  SegmentsCountsLayerId = "segments-counts",
  SegmentsCountsLayerIdLimitedAccess = "segments-counts-limited-access",
  HighlightedVolumeLayerId = "highlighted-select-link-volume",
  RoadsMeasureRangeLayerId = "select-link-roads-measure-range",
}

interface SelectLinkRoadsData {
  tileService: RoadsTileService;
  segmentCounts: RoadsVolumes;
  roadSegmentIds: RoadSegmentIdsWithFactype;
  roadSegmentIdsByRoadClass: RoadSegmentIdsByRoadClass | null;
  minVolume: number;
  selectLinkVolumesRef: any;
  setSelectLinkHoverProps: Dispatch<SetStateAction<RoadsHoverPopupProps | null>>;
}

export const SelectLinkLayerIds = {
  roadsSourceId: SelectLinkRoads.SourceId,
  roadsVolumesLayerId: SelectLinkRoads.SegmentsCountsLayerId,
  limitedAccessRoadsVolumesLayerId: SelectLinkRoads.SegmentsCountsLayerIdLimitedAccess,
  roadsHairlinesLayerId: SelectLinkRoads.SegmentHairlines,
  roadsHighlightedVolumesLayerId: SelectLinkRoads.HighlightedVolumeLayerId,
  roadsSegmentsLayerId: SelectLinkRoads.SegmentsLayerId,
  roadsMeasureRangeLayerId: SelectLinkRoads.RoadsMeasureRangeLayerId,
} as RoadLayerIds;

export const SelectLinkColorIds = {
  hairlineColor: "#f99933",
  volumeColor: "#fbbf80",
  volumeHoverColor: "#fab266",
  segmentHighlightColor: "#f88c1a",
  segmentSuperHighlightColor: "#7c4000",
  permanentHighlightColor: ROAD_SEGMENT_PERMANENT_HIGHLIGHT_COLOR,
  networkVolumeOpacity: 0.6,
  networkVolumeHighlightOpacity: 0.8,
  measureRangeColor: "#ffff00",
  majorRoadVolumeColor: "#fbbf80",
  majorRoadVolumeOpacity: 0.6,
} as RoadColors;

export const initSelectLinkRoads = (
  map: Map,
  {
    tileService,
    segmentCounts,
    roadSegmentIds,
    roadSegmentIdsByRoadClass,
    minVolume,
    selectLinkVolumesRef,
    setSelectLinkHoverProps,
  }: SelectLinkRoadsData,
) => {
  return initRoadSegments(map, {
    tileService,
    roadsVolumes: segmentCounts,
    roadSegmentIds,
    roadSegmentIdsByRoadClass,
    popupHoverRef: selectLinkVolumesRef,
    gates: null,
    showRoadVolumes: true,
    isPedestriansMode: false,
    minVolume,
    setRoadHoverProps: setSelectLinkHoverProps,
    layerIds: SelectLinkLayerIds,
    colorIds: SelectLinkColorIds,
    isSelectLink: true,
  });
};

export const SELECTED_ZONE_SOURCE_ID = "selected-zones";

const SELECTED_ZONES_LAYER_NAME = "selected borders";

interface SelectedZonesLayersData {
  metadata: ODMetadata;
  availableLayers: ODTileLayer[];
  selectedZoneIds: string[];
}

export const initSelectedZones = (
  map: Map,
  { metadata, availableLayers, selectedZoneIds }: SelectedZonesLayersData,
) => {
  const existingDefaultLayers = defaultLayers.filter((layer) => !!map.getLayer(layer));

  const maxZoom = getMaxZoom(availableLayers);
  const source = getODSource(metadata.tileService, false, availableLayers, maxZoom);

  map.addSource(SELECTED_ZONE_SOURCE_ID, source);

  // const matchesSelectOrigin

  availableLayers.forEach((layer) => {
    map.addLayer(
      {
        id: `${SELECTED_ZONES_LAYER_NAME} ${layer.name}`,
        type: "line",
        source: SELECTED_ZONE_SOURCE_ID,
        "source-layer": layer.name,
        filter: ["in", layer.idField, ...selectedZoneIds],
        paint: {
          "line-color": [
            "case",
            ["boolean", ["feature-state", "permanentSelectHighlightOrigin"], false],
            ZONE_PERMANENT_HIGHLIGHT_COLOR_ORIGIN,
            ["boolean", ["feature-state", "permanentSelectHighlightDestination"], false],
            ZONE_PERMANENT_HIGHLIGHT_COLOR_DESTINATION,
            CHOROPLETH_LINE_COLOR,
          ],
          "line-opacity": [
            "case",
            ["boolean", ["feature-state", "permanentSelectHighlightOrigin"], false],
            0.9,
            ["boolean", ["feature-state", "permanentSelectHighlightDestination"], false],
            0.9,
            CHOROPLETH_LINE_OPACITY,
          ],
          "line-width": [
            "case",
            ["boolean", ["feature-state", "permanentSelectHighlightOrigin"], false],
            2,
            ["boolean", ["feature-state", "permanentSelectHighlightDestination"], false],
            2,
            CHOROPLETH_LINE_WIDTH,
          ],
        },
      },
      ...existingDefaultLayers,
    );
  });

  return {
    clear: () => {
      availableLayers.forEach((layer) => {
        if (map.getLayer(`${SELECTED_ZONES_LAYER_NAME} ${layer.name}`))
          map.removeLayer(`${SELECTED_ZONES_LAYER_NAME} ${layer.name}`);
      });

      if (map.getSource(SELECTED_ZONE_SOURCE_ID)) map.removeSource(SELECTED_ZONE_SOURCE_ID);
    },
  };
};
