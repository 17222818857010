import { ItemRenderer, Select2 as Select } from "@blueprintjs/select";
import styled from "@emotion/styled";
import { WarningAmberOutlined } from "@mui/icons-material";
import { FormControlLabel, FormGroup, Switch, Tooltip, Typography } from "@mui/material";
import { Alert } from "@mui/material";
import findIndex from "lodash/findIndex";
import { ChangeEvent, useEffect, useState } from "react";

import { Button, Dialog, Input, MenuItem, SelectInput, TimePeriodSelector } from "components";

import { useAppDispatch, useAppSelector } from "hooks";

import { DataState } from "store/interfaces";
import { analyticsActions } from "store/sections/analytics";

import { CreateDatasetPayload, CustomDataset, DatasetType } from "types";

interface Props {
  areas?: boolean;
  title: string;
  buttonLabel: string;
  description?: string;
  fieldLabel?: string;
  initialText?: string;
  errorMessage?: string | null;
  isAreaOfFocusSelectDisabled?: boolean;
  datasetToCopy?: CustomDataset;
  optionsAvailable?: boolean;
  customZoning?: { id: string; name: string } | null;
  onClose: () => void;
  onSubmit: (
    name: string,
    openEditor: boolean,
    configOptions: Omit<CreateDatasetPayload, "folderId" | "datasetName">,
  ) => void;
}

const TitleText = styled.div`
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 24px;
  text-align: center;
`;

const TextDescription = styled.div`
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 24px;
  text-align: center;
  color: var(--color-textSecondary);
`;

const Label = styled.label`
  font-size: 14px;
  margin: 10px 0;
  color: #2a2e33;
`;

const SubmitButton = styled(Button)`
  margin-top: 24px;
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  margin-top: 4px;
  margin-left: 0;

  & .MuiFormControlLabel-label {
    font-size: 14px;
  }
`;

export const FolderDatasetDialog = ({
  areas,
  title,
  description,
  fieldLabel,
  buttonLabel,
  initialText,
  errorMessage,
  isAreaOfFocusSelectDisabled,
  datasetToCopy,
  optionsAvailable,
  customZoning,
  onClose,
  onSubmit,
}: Props) => {
  const dispatch = useAppDispatch();

  const loading = useAppSelector((state) => state.datasetFolders.loading);
  const focusAreas = useAppSelector((state) => state.analytics.focusAreas);

  const [text, setText] = useState(initialText || "");
  const [activeSubareaIndex, setActiveSubareaIndex] = useState(0);
  const [timePeriod, setTimePeriod] = useState<string>("");
  const [options, setOptions] = useState({
    initializeSubarea: true,
    addDefaultGates: true,
    openEditor: true,
  });

  useEffect(() => {
    if (areas) {
      dispatch(analyticsActions.fetchFocusAreas(customZoning?.id, customZoning ? true : false));
    }
  }, [customZoning, areas, dispatch]);

  useEffect(() => {
    if (datasetToCopy && isAreaOfFocusSelectDisabled) {
      const subareaIndex = focusAreas.data?.findIndex((area) => area.id === `${datasetToCopy.licensedAreaId}`);
      if (subareaIndex !== undefined) setActiveSubareaIndex(subareaIndex);
    }
  }, [focusAreas.data, datasetToCopy, isAreaOfFocusSelectDisabled]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setText(e.target.value);
  };

  const handleChangeOptions = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;

    if (name === "initializeSubarea" && !checked) {
      setOptions({ ...options, [name]: checked, addDefaultGates: false });
      return;
    }
    setOptions({ ...options, [name]: checked });
  };

  const handleSubmit = () => {
    onSubmit(text, options.openEditor, {
      timePeriod,
      datasetType: DatasetType.OD,
      licensedAreaId: focusAreas.data?.[activeSubareaIndex]?.licensedAreaId as string,
      initializeSubarea: options.initializeSubarea,
      addDefaultGates: options.addDefaultGates,

      ...(customZoning?.id ? { customZoningId: customZoning.id } : {}),
    });
  };

  const renderSubarea: ItemRenderer<any> = (subarea, { handleClick, handleFocus, modifiers }) => {
    if (!modifiers.matchesPredicate) {
      return null;
    }

    return (
      <MenuItem
        active={modifiers.active}
        key={subarea.id}
        onClick={handleClick}
        onFocus={handleFocus}
        roleStructure="listoption"
        text={subarea.label}
        disabled={!subarea.enabled}
        icon={
          !subarea.enabled && (
            <Tooltip title={subarea.disabledReason} placement="right">
              <WarningAmberOutlined fontSize="small" color={"warning"} />
            </Tooltip>
          )
        }
      />
    );
  };

  return (
    <Dialog onClose={onClose} isOpen>
      <TitleText>{title}</TitleText>
      {description && <TextDescription>{description}</TextDescription>}
      {customZoning && (
        <>
          <Label>Custom zoning</Label>
          <Typography fontSize={14} fontWeight={600} mb={1}>
            {customZoning.name}
          </Typography>
        </>
      )}
      {fieldLabel && <Label>{fieldLabel}</Label>}
      <Input value={text} onChange={handleChange} disabled={areas && !focusAreas.data?.length} />

      {areas && (
        <>
          {/* TODO Implement AreaOfFocusSelector component */}
          <Label>Area of interest</Label>
          <Select
            disabled={Boolean(focusAreas.data?.length && focusAreas.data.length <= 1)}
            items={focusAreas?.data || []}
            itemRenderer={renderSubarea}
            activeItem={focusAreas.data?.[activeSubareaIndex]}
            onItemSelect={(area) => {
              setActiveSubareaIndex(findIndex(focusAreas?.data, { id: area.id }));
            }}
            filterable={false}
            popoverProps={{
              matchTargetWidth: true,
              minimal: true,
            }}
            fill
          >
            <SelectInput
              disabled={
                Boolean(focusAreas.data?.length && focusAreas.data.length <= 1) ||
                isAreaOfFocusSelectDisabled ||
                !focusAreas.data?.length
              }
              value={focusAreas.data?.[activeSubareaIndex]?.label || ""}
            />
          </Select>
          <TimePeriodSelector
            timePeriods={focusAreas.data?.[activeSubareaIndex]?.timePeriods || []}
            selectedTimePeriod={timePeriod}
            defaultTimePeriod={datasetToCopy?.timePeriod}
            setSelectedTimePeriod={setTimePeriod}
            disabled={false}
          />
        </>
      )}
      {!datasetToCopy && focusAreas.state === DataState.AVAILABLE && !focusAreas.data?.length && (
        <Alert severity="warning" sx={{ mt: 1 }}>
          No area of interest intersects with the uploaded zoning.
        </Alert>
      )}
      {optionsAvailable && (
        <FormGroup sx={{ marginTop: 1 }}>
          {!datasetToCopy && (
            <>
              <StyledFormControlLabel
                control={
                  <Switch
                    color="secondary"
                    size="small"
                    name="initializeSubarea"
                    checked={options.initializeSubarea}
                    onChange={handleChangeOptions}
                    disabled={!focusAreas.data?.length}
                  />
                }
                label="Initialize subarea (covering all zones)"
              />
              <StyledFormControlLabel
                control={
                  <Switch
                    color="secondary"
                    size="small"
                    name="addDefaultGates"
                    checked={options.addDefaultGates}
                    onChange={handleChangeOptions}
                    disabled={!focusAreas.data?.length || !options.initializeSubarea}
                  />
                }
                label="Add gates on major roads"
              />
            </>
          )}
          <StyledFormControlLabel
            control={
              <Switch
                color="secondary"
                size="small"
                name="openEditor"
                checked={options.openEditor}
                onChange={handleChangeOptions}
                disabled={!focusAreas.data?.length}
              />
            }
            label="Open dataset editor"
          />
        </FormGroup>
      )}
      {errorMessage && (
        <Alert severity="error" sx={{ mt: 2 }}>
          {errorMessage}
        </Alert>
      )}

      <SubmitButton
        color="primaryLight"
        loading={loading}
        disabled={!text || (areas && !focusAreas.data?.length)}
        onClick={handleSubmit}
      >
        {buttonLabel}
      </SubmitButton>
    </Dialog>
  );
};
