import { InfoOutlined } from "@mui/icons-material";
import { Grid, Typography } from "@mui/material";
import { FC } from "react";

import { Button, Dialog } from "components";

export interface EmptyResultWarningProps {
  isOpen: boolean;
  onClose: () => void;
}

export const EmptyResultWarning: FC<EmptyResultWarningProps> = ({ isOpen, onClose }) => (
  <Dialog isOpen={isOpen} onClose={onClose}>
    <Grid container alignItems={"center"} marginBottom={1}>
      <InfoOutlined color={"info"} />
      <Typography marginLeft={1}>Select link query did not return any trips.</Typography>
    </Grid>

    <Grid container alignItems={"center"} justifyContent="flex-end">
      <Button size="sm" color="primaryLight" onClick={onClose}>
        Ok
      </Button>
    </Grid>
  </Dialog>
);
