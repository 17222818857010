import React, { FC } from "react";
import styled from "@emotion/styled";

import { ReactComponent as ReactAnimatedLogo } from "./logo.svg";

export interface LoaderProps {
  size?: number;
}

const ReactAnimatedLogoStyled = styled(ReactAnimatedLogo)<LoaderProps>`
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
`;

export const Loader: FC<LoaderProps> = ({ size = 75 }) => <ReactAnimatedLogoStyled size={size} />;
