import React from "react";
import styled from "@emotion/styled";

import { Button, Dialog } from "components";

interface Props {
  title: string;
  description: string;
  onClose: () => void;
  onSubmit: () => void;
  confirmButtonText?: string;
}

const DialogBodyWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const TitleText = styled.div`
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 24px;
  text-align: center;
`;

const ConfirmDescription = styled.div`
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 24px;
  text-align: center;
  color: var(--color-textSecondary);
`;

export const ConfirmDialog = ({ title, description, onClose, onSubmit, confirmButtonText = "Confirm" }: Props) => {
  return (
    <Dialog canOutsideClickClose={false} onClose={onClose} isOpen>
      <TitleText>{title}</TitleText>
      <ConfirmDescription>{description}</ConfirmDescription>
      <DialogBodyWrapper>
        <Button color="white" onClick={onClose}>
          Cancel
        </Button>
        <Button color="primaryLight" onClick={onSubmit}>
          {confirmButtonText}
        </Button>
      </DialogBodyWrapper>
    </Dialog>
  );
};
