import styled from "@emotion/styled";
import { Box } from "@mui/material";
import { FC } from "react";

export interface HighligthPageContainerProps {
  children: React.ReactNode[];
}

const HeaderContainer = styled(Box)`
  position: relative;
  width: 100%;
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const BlurBackground = styled(Box)`
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background: radial-gradient(circle, rgba(68, 162, 245, 1) 3%, rgba(63, 70, 108, 1) 40%, rgba(15, 25, 59, 1) 90%);
  background-size: 100% 350%;
  background-position: center bottom;
`;

export const PageContent = styled.div`
  margin-top: -16rem;
  display: grid;
  grid-template-columns: auto minmax(700px, 1200px) auto;
  grid-template-rows: calc(100% - 64px);
  width: 100%;
  padding: 1rem 4rem 3rem 4rem;
  overflow-y: auto;
`;

export const HighligthPageContainer: FC<HighligthPageContainerProps> = ({ children }) => {
  return (
    <>
      <HeaderContainer>
        <BlurBackground />
      </HeaderContainer>
      <PageContent>
        <aside />
        <Box>{children}</Box>
        <aside />
      </PageContent>
    </>
  );
};
