import { SegmentMarker } from "features/select-link/types";

import {
  FocusAreaItem,
  SegmentsGroup,
  SelectLinkPredicateLogic,
  SelectedArea,
  SelectedElementsPredicate,
  SelectedSegmentConfig,
} from "types";

export const DefaultMinCount = 5;

export const deleteSegmentFromGroup = (
  groups: SegmentsGroup[],
  groupId: string,
  segment: SelectedSegmentConfig,
): SegmentsGroup[] => {
  const group = groups.find((group) => group.groupName === groupId);
  if (!group) return groups;
  const newSegments = group?.segments.filter((link) => link.segmentId !== segment.segmentId);
  const newGroup = {
    ...group,
    segments: newSegments,
  };
  const newGroups = [newGroup, ...groups.filter((group) => group.groupName !== groupId)].sort(
    ({ groupName: groupId }) => Number(groupId),
  );
  return newGroups;
};

export const addSegmentToGroups = (
  groups: SegmentsGroup[],
  groupId: string,
  segment: SelectedSegmentConfig,
): SegmentsGroup[] => {
  const group = groups.find((group) => group.groupName === groupId);
  if (!group) return groups;
  const newSegments = [...group.segments, segment];
  const newGroup = {
    ...group,
    segments: newSegments,
  };
  return [...groups.filter((group) => group.groupName !== groupId), newGroup];
};

export const existsReverseSegment = (groups: SegmentsGroup[], segment: SelectedSegmentConfig): boolean => {
  return groups.some((group) => {
    return group.segments.some((link) => link.fromToId === segment.fromToId && link.segmentId !== segment.segmentId);
  });
};

/**
 * Builds select link request predicate for the segments.
 */
export const buildSegmentsPredicate = (
  selectedLinkGroups: SegmentsGroup[],
  predicateLogic: string,
): SelectedElementsPredicate => {
  const nonEmptyGroups = selectedLinkGroups.filter(({ segments }) => segments.length > 0);
  const isBetweenGroupsLogic = nonEmptyGroups.length > 1; // within a signle group operator shouldn't be reversed
  const isAndLogic = predicateLogic === SelectLinkPredicateLogic.And;
  const clauses = nonEmptyGroups.map((group) => {
    const parts = group.segments.map((link) => {
      return {
        isNot: false,
        elementId: link.segmentId,
      };
    });
    return {
      isAnd: isBetweenGroupsLogic ? !isAndLogic : isAndLogic,
      isNot: false,
      parts,
    };
  });
  return {
    isAnd: isBetweenGroupsLogic ? isAndLogic : !isAndLogic,
    clauses,
  };
};

export const buildZonesPredicate = (selectedZones: SelectedArea[]): SelectedElementsPredicate | null => {
  if (selectedZones.length === 0) return null;
  const clauses = selectedZones.map(({ id: zoneId }) => ({
    isAnd: true,
    isNot: false,
    parts: [
      {
        isNot: false,
        elementId: zoneId,
      },
    ],
  }));
  return {
    isAnd: false,
    clauses,
  };
};

export const segmentExistsInGroups = (groups: SegmentsGroup[], segment: SelectedSegmentConfig): boolean => {
  return groups.some((group) => group.segments.some((link) => link.segmentId === segment.segmentId));
};

/**
 * Finds the marker to delete by its coordinates and removes it from the state and from the map, if it exists.
 */
export const deleteMarkerForSegmentAndRemoveFromMap = (
  markers: SegmentMarker[],
  segment: SelectedSegmentConfig,
  setMarkers: (markers: SegmentMarker[]) => void,
): void => {
  const newMarkers = markers.filter(({ segmentId, marker }) => {
    if (segmentId === segment.segmentId) marker.remove();

    return segmentId !== segment.segmentId;
  });
  setMarkers(newMarkers);
};

export const updateFeatureState = (
  map: mapboxgl.Map,
  sourceId: string,
  layer: string,
  elementId: string | undefined,
  stateName: string,
  status?: boolean,
) => {
  if (status !== undefined) {
    map.setFeatureState(
      {
        source: sourceId,
        sourceLayer: layer,
        id: elementId,
      },
      {
        [stateName]: status,
      },
    );
  } else {
    map.removeFeatureState(
      {
        source: sourceId,
        sourceLayer: layer,
        id: elementId,
      },
      stateName,
    );
  }
};

export const getFocusArea = (focusAreas: FocusAreaItem[], licensedAreaId: number): FocusAreaItem | undefined => {
  return focusAreas.find(({ id }) => id === licensedAreaId.toString());
};
