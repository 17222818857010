import scaleCluster from "d3-scale-cluster";

import { getSqMiDensity, sequentialSchemes } from "features/map-visualization";

import { RoadVmtMetadata, RoadVmtZoneCounts, ZoneIds } from "types";

export const getAvailableLayers = (roadVmtMetadata: RoadVmtMetadata) => {
  const { tileService, detailLevel: roadVmtLevel } = roadVmtMetadata;
  const { layers } = tileService;
  return layers.filter(({ level }) => level === roadVmtLevel);
};

export const getColorScale = (
  layerZoomLevel: string,
  zoneIds: ZoneIds,
  zoneCounts: RoadVmtZoneCounts,
  colorScheme: string,
  byArea: boolean = true,
) => {
  const layerIDs = zoneIds[layerZoomLevel];
  const density = Object.entries(layerIDs).map(([zoneId, stats]) => {
    const count = zoneCounts?.zones?.[zoneId] ?? 0;
    return byArea ? getSqMiDensity(count, stats.sqKm) : Math.floor(count / (stats?.pop ?? 1.0));
  });
  const currentColorScheme = sequentialSchemes[colorScheme];
  return scaleCluster().domain(density).range(currentColorScheme);
};
