import React, { FC, DetailedHTMLProps, ImgHTMLAttributes } from "react";
import styled from "@emotion/styled";
import { Icon } from "@blueprintjs/core";

interface Props extends DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {
  width?: number;
  height?: number;
}

const RoundImage = styled.img`
  border-radius: 50%;
`;

const StyledIcon = styled(Icon)`
  border-radius: 50%;
  background-color: var(--color-primaryLight);

  &.bp4-icon > svg:not([fill]) {
    fill: var(--color-secondaryLight);
  }
`;

export const Avatar: FC<Props> = ({ src, width = 40, ...props }) => (
  <>{src ? <RoundImage src={src} width={width} {...props} /> : <StyledIcon icon="user" size={width} />}</>
);
