import styled from "@emotion/styled";
import { ChangeEvent, FC } from "react";

import { Badge, BadgeGroup, CheckboxDropdown } from "components";

import { QueryType } from "types";

import { FiltersType, areAllItemsUnChecked } from "./Filters";

export interface ODFiltersPanelProps {
  filters: FiltersType | null;
  queryType: QueryType;
  loading: boolean;
  disabled?: boolean;
  handleChangeQueryType: (type: QueryType) => void;
  handleChangeFilter: (event: ChangeEvent<HTMLInputElement>) => void;
  handleChangeAllFilters: (isChecked: boolean) => (groupName: string) => void;
}

const FilterHeader = styled.h5`
  margin-bottom: 8px;
  font-size: 12px;
  font-weight: 600;
`;

export const ODFiltersPanel: FC<ODFiltersPanelProps> = ({
  filters,
  queryType,
  loading,
  disabled,
  handleChangeQueryType,
  handleChangeFilter,
  handleChangeAllFilters,
}) => {
  return (
    <>
      <FilterHeader>Trip Direction</FilterHeader>
      <BadgeGroup>
        <Badge
          onClick={() => handleChangeQueryType(QueryType.INCOMING)}
          color={queryType === QueryType.INCOMING ? "primary" : "gray"}
          interactive
        >
          Incoming
        </Badge>
        <Badge
          onClick={() => handleChangeQueryType(QueryType.OUTGOING)}
          color={queryType === QueryType.OUTGOING ? "primary" : "gray"}
          interactive
        >
          Outgoing
        </Badge>
      </BadgeGroup>
      {filters &&
        Object.entries(filters).map(([groupKey, group], i) => (
          <CheckboxDropdown
            key={i}
            isGroupChecked={group.isChecked}
            groupName={groupKey}
            groupLabel={group.label}
            groupIcon={group.icon}
            items={group.items}
            placement="top"
            disabled={loading || disabled}
            error={areAllItemsUnChecked(group.items)}
            onChange={handleChangeFilter}
            selectAll={handleChangeAllFilters(true)}
            clearAll={handleChangeAllFilters(false)}
          />
        ))}
    </>
  );
};
