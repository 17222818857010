import { Icon, IconName } from "@blueprintjs/core";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import React, { ButtonHTMLAttributes, DetailedHTMLProps, forwardRef } from "react";

import { Spinner } from "../spinner/Spinner";

export type ButtonColor = "primary" | "secondary" | "white" | "primaryLight" | "whiteBlue" | "whiteShadow";

export type ButtonSize = "xxs" | "xs" | "sm" | "md" | "lg" | "xl";

export type ButtonVariant = "leftsquared" | "rightsquared" | "squared" | "rounded";

export interface BaseButtonProps {
  color?: ButtonColor;
  size?: ButtonSize;
  variant?: ButtonVariant;
  withBorder?: boolean;
}

interface ButtonCommonProps
  extends BaseButtonProps,
    Omit<DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>, "color"> {
  fullWidth?: boolean;
  loading?: boolean;
}

type ButtonConditionalProps =
  | {
      leadingIcon?: IconName;
      trailingIcon?: never;
    }
  | { leadingIcon?: never; trailingIcon?: IconName };

export type ButtonProps = ButtonCommonProps & ButtonConditionalProps;

const colorStyles = {
  primary: css`
    border-color: transparent;
    color: var(--color-textContrast);
    background-color: var(--color-primary);

    /* &:hover:not([disabled]) {
      background-color: var(--color-primary);
    } */
  `,
  secondary: css`
    border-color: var(--color-secondary);
    color: var(--color-text);
    background-color: var(--color-secondaryLight);

    /* &:hover:not([disabled]) {
      border-width: 2px;
    } */

    & .bp4-icon > svg:not([fill]) {
      fill: var(--color-primaryLight);
    }
  `,
  white: css`
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    border-color: var(--color-gray-300);
    color: var(--color-gray-700);
    background-color: var(--color-textContrast);

    &:hover:not([disabled]) {
      background-color: var(--color-gray-200);
      border-color: var(--color-gray-500);
    }

    & .bp4-icon > svg:not([fill]) {
      fill: var(--color-gray-500);
    }
  `,
  primaryLight: css`
    border-color: transparent;
    color: var(--color-gray-50);
    background-color: var(--color-primaryLight);

    &:hover:not([disabled]) {
      opacity: 0.9;
    }
  `,
  whiteBlue: css`
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
    border-color: transparent;
    color: var(--color-primaryLight);
    background-color: var(--color-textContrast);

    &:hover:not([disabled]) {
      background-color: var(--color-gray-100);
    }
  `,
  whiteShadow: css`
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
    border-color: transparent;
    color: var(--color-gray-600);
    background-color: var(--color-textContrast);

    &:hover:not([disabled]) {
      background-color: var(--color-gray-100);
    }

    & .bp4-icon > svg:not([fill]) {
      fill: var(--color-gray-900);
    }
  `,
};

const variantStyles = {
  leftsquared: css`
    border-radius: 6px 0px 0px 6px;
  `,
  rightsquared: css`
    border-radius: 0px 6px 6px 0px;
  `,
  squared: css`
    border-radius: 6px;
  `,
  rounded: css`
    border-radius: 9999px;
  `,
};

const sizeStyles = {
  xxs: css`
    font-size: 10px;
    height: 20px;
    padding: 0 4px;
  `,
  xs: css`
    font-size: 12px;
    height: 30px;
    padding: 0 6px;
  `,
  sm: css`
    font-size: 14px;
    height: 34px;
    padding: 0 7px;
  `,
  md: css`
    font-size: 14px;
    height: 38px;
    padding: 0 12px;
  `,
  lg: css`
    font-size: 16px;
    height: 42px;
    padding: 0 11px;
  `,
  xl: css`
    font-size: 16px;
    height: 50px;
    padding: 0 19px;
  `,
};

export const BaseButton = styled.button<BaseButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-weight: 500;
  box-shadow: var(--box-shadow-sm);
  border-width: ${({ withBorder = true }) => (withBorder ? "1px" : "0")};
  border-style: solid;
  white-space: nowrap;

  &:disabled {
    cursor: not-allowed;
    color: var(--color-gray-400);
    background-color: var(--color-gray-200);
  }

  ${({ color = "primary" }) => colorStyles[color]};
  ${({ variant = "squared" }) => variantStyles[variant]}
`;

const StyledButton = styled(BaseButton)<ButtonCommonProps>`
  & svg {
    width: 1em;
    margin: 0 0.375em;
  }

  & .button-text {
    margin: 0 0.375em;
  }

  ${({ fullWidth = false }) => fullWidth && `width: 100%;`}
  ${({ size = "md" }) => sizeStyles[size]};
`;

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ children, leadingIcon, trailingIcon, disabled, loading, ...props }, ref) => (
    <StyledButton ref={ref} disabled={loading ? true : disabled} {...props}>
      {loading ? (
        <Spinner />
      ) : (
        <>
          {leadingIcon && <Icon icon={leadingIcon} />}
          <span className="button-text">{children}</span>
          {trailingIcon && <Icon icon={trailingIcon} />}
        </>
      )}
    </StyledButton>
  ),
);
