import { Icon } from "@blueprintjs/core";
import { Tooltip2 as Tooltip } from "@blueprintjs/popover2";

const FacilityTypes = () => (
  <table width={"250px"}>
    <tr>
      <th align="left">Code</th>
      <th align="left">Description</th>
    </tr>
    <tr>
      <td>0</td>
      <td>Gate connector</td>
    </tr>
    <tr>
      <td>1</td>
      <td>Limited access highway</td>
    </tr>
    <tr>
      <td>2</td>
      <td>Principal arterial</td>
    </tr>
    <tr>
      <td>3</td>
      <td>Major arterial</td>
    </tr>
    <tr>
      <td>4</td>
      <td>Minor arterial</td>
    </tr>
    <tr>
      <td>5</td>
      <td>Major collector</td>
    </tr>
    <tr>
      <td>6</td>
      <td>Minor collector</td>
    </tr>
    <tr>
      <td>7</td>
      <td>Local street</td>
    </tr>
    <tr>
      <td>8</td>
      <td>Service road</td>
    </tr>
    <tr>
      <td>9</td>
      <td>Rural road</td>
    </tr>
    <tr>
      <td>11</td>
      <td>Freeway ramp</td>
    </tr>
    <tr>
      <td>12</td>
      <td>Principal ramp</td>
    </tr>
    <tr>
      <td>13</td>
      <td>Major ramp</td>
    </tr>
    <tr>
      <td>14</td>
      <td>Minor ramp</td>
    </tr>
    <tr>
      <td>15</td>
      <td>Local ramp</td>
    </tr>
    <tr>
      <td>21</td>
      <td>Busway</td>
    </tr>
    <tr>
      <td>31</td>
      <td>Pedestrian</td>
    </tr>
    <tr>
      <td>32</td>
      <td>Non-motorized</td>
    </tr>
    <tr>
      <td>81</td>
      <td>Ferry</td>
    </tr>
    <tr>
      <td>97</td>
      <td>Abandoned</td>
    </tr>
    <tr>
      <td>98</td>
      <td>Construction</td>
    </tr>
  </table>
);

const AreaTypes = () => (
  <table width={"250px"}>
    <tr>
      <th align="left">Code</th>
      <th align="left">Description</th>
    </tr>
    <tr>
      <td>1</td>
      <td>Dense Urban</td>
    </tr>
    <tr>
      <td>2</td>
      <td>Urban</td>
    </tr>
    <tr>
      <td>3</td>
      <td>Suburban Commercial</td>
    </tr>
    <tr>
      <td>4</td>
      <td>Suburban Residential</td>
    </tr>
    <tr>
      <td>5</td>
      <td>Rural</td>
    </tr>
  </table>
);

const FHWAFunctionalClasses = () => (
  <table width={"250px"}>
    <tr>
      <th align="left">Code</th>
      <th align="left">Description</th>
    </tr>
    <tr>
      <td>1</td>
      <td>Interstate</td>
    </tr>
    <tr>
      <td>2</td>
      <td>Principal arterial - other freeways and expressways </td>
    </tr>
    <tr>
      <td>3</td>
      <td>Principal arterial - other</td>
    </tr>
    <tr>
      <td>4</td>
      <td>Minor arterial</td>
    </tr>
    <tr>
      <td>5</td>
      <td>Major collector</td>
    </tr>
    <tr>
      <td>6</td>
      <td>Minor collector</td>
    </tr>
    <tr>
      <td>7</td>
      <td>Local street</td>
    </tr>
  </table>
);

export const roadShapeFileColumns = [
  {
    column: "factype",
    type: "Integer",
    description: (
      <div>
        Facility type for the road segment. Value for gate connectors: 0{" "}
        <Tooltip content={<FacilityTypes />}>
          <Icon icon="help" style={{ marginLeft: "10px" }} />
        </Tooltip>
      </div>
    ),
    notes: "",
  },
  {
    column: "st_name",
    type: "Text",
    description: "Road name. NULL for gate connectors",
    notes: "",
  },
  {
    column: "to_node",
    type: "Integer64",
    description:
      "Original TO node ID (nationwide unique). For gate connectors: 0 if the gate is at the TO end of the line",
    notes: "",
  },
  {
    column: "from_node",
    type: "Integer64",
    description:
      "Original FROM node ID (nationwide unique). For gate connectors: 0 if the gate is at the FROM end of the line",
    notes: "",
  },
  {
    column: "segment_id",
    type: "Text",
    description:
      "Textual segment ID (e.g. 131567937_2_NW_18) NULL for gate connectors, nationwide unique for other segments",
    notes: "",
  },
  {
    column: "A",
    type: "Integer",
    description: "Renumbered FROM node ID, mapped into 32,001-999,999 range",
    notes: "",
  },
  {
    column: "B",
    type: "Integer",
    description: "Renumbered TO node ID, mapped into 32,001-999,999 range",
    notes: "",
  },
  {
    column: "distance",
    type: "Integer",
    description: "Length of the segment (miles), rounded to 3 or 4 decimal places. Value for gate connector: 0",
    notes: "",
  },
  {
    column: "highway",
    type: "Text",
    description: (
      <div>
        OpenStreetMap highway road type attributes which are the basis for defining facility types. Values are
        documented on the OpenStreetMap OSM wiki page (
        <a href="https://wiki.openstreetmap.org/wiki/Key:highway" target="_blank" rel="noreferrer">
          https://wiki.openstreetmap.org/wiki/Key:highway
        </a>
        ) Value for gate connectors: "highway"
      </div>
    ),
    notes: "",
  },
  {
    column: "atype",
    type: "Integer",
    description: (
      <div>
        Area type attribute based on block group socioeconomic data (1,2,3,4,5). Value for gate connectors: 0{" "}
        <Tooltip content={<AreaTypes />}>
          <Icon icon="help" style={{ marginLeft: "10px" }} />
        </Tooltip>
      </div>
    ),
    notes: '"All Access" only',
  },
  {
    column: "spd_limit",
    type: "Integer",
    description: "Estimated speed limit based on OpenStreetMap MAXSPEED classification, -1 for unknown",
    notes: '"All Access" only',
  },
  {
    column: "oneway",
    type: "Integer",
    description: "Flag for one-way roadways (0 = no, 1 = yes)",
    notes: '"All Access" only',
  },
  {
    column: "ramp",
    type: "Integer",
    description: "Flag for roadways on bridge structures (0 = no, 1 = yes)",
    notes: '"All Access" only',
  },
  {
    column: "bridge",
    type: "Integer",
    description: "Flag for roadways on bridge structures (0 = no, 1 = yes)",
    notes: '"All Access" only',
  },
  {
    column: "tunnel",
    type: "Integer",
    description: "Flag for roadways in tunnels (0 = no, 1 = yes)",
    notes: '"All Access" only',
  },
  {
    column: "divider",
    type: "Integer",
    description: "Flag for physical divider in the median of a roadway (0 = no, 1 = yes)",
    notes: '"All Access" only',
  },
  {
    column: "lanes",
    type: "Integer",
    description: "Number of lanes",
    notes: '"All Access" only',
  },
  {
    column: "cap_lane",
    type: "Integer",
    description: "Estimated per-lane capacity",
    notes: '"All Access" only',
  },
  {
    column: "urbanrural",
    type: "Integer",
    description:
      "Urban/rural values are set using census 2020 urban area boundaries based on population density, without consideration of employment. (0 = rural, 1= urban)",
    notes: '"All Access" only',
  },
  {
    column: "fhwa_class",
    type: "Integer",
    description: (
      <div>
        FHWA functional class <sup>2</sup>
        <Tooltip content={<FHWAFunctionalClasses />}>
          <Icon icon="help" style={{ marginLeft: "10px" }} />
        </Tooltip>
      </div>
    ),
    notes: '"All Access" only',
  },
  {
    column: "cnt_aadt",
    type: "Long",
    description: "Total daily traffic of the segment",
    notes: "",
  },
  {
    column: "VOLDT1, VOLDT2, VOLDT3, VOLDT4",
    type: "Integer",
    description: "Volume breakdowns grouped by day type DT1: Mon-Thu, DT2: Fri, DT3: Sat, DT4: Sun",
    notes: "",
  },
  {
    column: "VOLH00-VOLH23",
    type: "Integer",
    description: "Volume breakdowns grouped by hour",
    notes: "",
  },
  {
    column: "VOLHW, VOLWH, VOLHO, VOLWO, VOLOW, VOLNHB",
    type: "Integer",
    description: "Volume breakdowns grouped by trip purpose H: Home, W: Work, O:Other, NHB: Non home based",
    notes: "",
  },
  {
    column: "VOLHBW, VOLHBO, VOLNHB",
    type: "Integer",
    description: "Volume breakdowns grouped by main purpose",
    notes: "Essentials and essentials+",
  },
];
