import scaleCluster from "d3-scale-cluster";
import { AnySourceData, Map, MapLayerMouseEvent, Popup } from "mapbox-gl";
import { Dispatch, SetStateAction } from "react";

import { ODPopupProps } from "components";

import {
  Counts,
  DatasetGate,
  ODMetadata,
  ODTileLayer,
  ODTileService,
  QueryType,
  RoadsTileService,
  SelectedArea,
  ZoneIds,
} from "types";

import { GATES_LAYER_ID, addGatesEvents, addGatesLayer, clearGatesEvents, removeGatesLayer } from "./gates";
import {
  CHOROPLETH_FILL_COLOR,
  CHOROPLETH_FILL_OPACITY,
  CHOROPLETH_FILL_OPACITY_HOVER,
  CHOROPLETH_FILL_OPACITY_SELECTED,
  CHOROPLETH_LINE_COLOR,
  CHOROPLETH_LINE_OPACITY,
  COUNTY_BORDER_LINE_COLOR,
  COUNTY_BORDER_LINE_OPACITY,
  COUNTY_BORDER_LINE_WIDTH,
  ROAD_SEGMENT_HIGHLIGHT_COLOR,
  ROAD_SEGMENT_SUPER_HIGHLIGHT_COLOR,
  SELECT_LINK_FILL_OPACITY,
  SELECT_LINK_FILL_OPACITY_HOVER,
  ZONE_HOVER_HIGHLIGHT_COLOR,
  ZONE_PERMANENT_HIGHLIGHT_COLOR_DESTINATION,
  ZONE_PERMANENT_HIGHLIGHT_COLOR_ORIGIN,
  ZONE_PERMANENT_HIGHLIGHT_FILL_OPACITY,
  sequentialSchemes,
} from "./layerColors";
import { ROADS_SEGMENTS_LAYER_ID, ROADS_SOURCE_ID } from "./roads";
import {
  closePopup,
  getGatesSegmentsId,
  getLayerFromZoom,
  getMaxZoom,
  getODLayerNames,
  getODPromoteIds,
  getPopupQueryType,
  getSqMiDensity,
  updateFeatureStatesByCounts,
} from "./utils";

interface BaseLayersData {
  colorScheme: string;
  ids: ZoneIds;
  counts: Counts;
  gates?: DatasetGate[];
  popupRef: any;
  showZones: boolean;
  availableLayers: ODTileLayer[];
  selectedZone: SelectedArea | null;
  queryType: QueryType;
  blockedZoomLevel: number | null;
  setSelectedZone: (selectedZone: SelectedArea | null) => void;
  tileService: ODTileService;
  externalZonesTileService: ODTileService;
  isSelectLinkMap?: boolean;
  setODPopupProps: Dispatch<SetStateAction<ODPopupProps | null>>;
  setColorScale: Dispatch<SetStateAction<any>>;
}

interface ODLayersData extends BaseLayersData {
  isDataset?: false;
  roadsTileService?: undefined;
}

interface DatasetLayersData extends BaseLayersData {
  isDataset: true;
  roadsTileService: RoadsTileService;
}

export interface ZoneSourceInput {
  selectedZone: SelectedArea | null;
  tileService: ODTileService;
  availableLayers: ODTileLayer[];
  maxZoom: number;
  metadata?: ODMetadata;
}

export type LayersData = ODLayersData | DatasetLayersData;

interface MapActionState {
  id: string | number;
  source: string;
}

export const ZONE_SOURCE_ID = "zones";
export const OUT_ZONES_SOURCE_ID = "out-zones";
export const OUT_ZONES_LAYER_LINE = "out-zones-layer-line";
export const OUT_ZONES_LAYER_FILL = "out-zones-layer-fill";
export const ZONES_LAYER_FILL_HIGHLIGHTED = "out-zones-layer-fill-highlighted";
export const ZONE_BORDERS_LAYER_NAME = "zone-borders";
export const RANGE_MEASURE_LAYER_NAME = "range-measure";
export const CHOROPLETH_LINE_WIDTH = 0.6;

export const defaultLayers = ["admin_country", "road-label"];

export const getODSource = (
  tileService: ODTileService,
  isZoningLevelBlocked: boolean,
  availableLayers: ODTileLayer[],
  maxZoom: number,
) => {
  return {
    type: "vector",
    tiles: isZoningLevelBlocked
      ? [availableLayers[0].url + "/{z}/{x}/{y}.pbf"]
      : [tileService.url + "/{z}/{x}/{y}.pbf"],
    promoteId: getODPromoteIds(tileService.layers),
    minzoom: tileService.minZoom,
    maxzoom: maxZoom,
  } as AnySourceData;
};

export const initOD = (
  map: Map,
  {
    isDataset,
    colorScheme,
    ids,
    counts,
    gates,
    popupRef,
    showZones,
    availableLayers,
    selectedZone,
    queryType,
    blockedZoomLevel,
    tileService,
    externalZonesTileService,
    roadsTileService,
    isSelectLinkMap,
    setSelectedZone,
    setODPopupProps,
    setColorScale,
  }: LayersData,
) => {
  let hoveredState: MapActionState | null = null;
  let clickedState: MapActionState | null = selectedZone?.id
    ? {
        id: selectedZone.id,
        source: getLayerFromZoom(selectedZone.zoom, availableLayers)?.name as string,
      }
    : null;
  let popup: Popup = new Popup({
    closeButton: false,
    closeOnClick: false,
    offset: 15,
  });
  let previousSourceLayerId: string | undefined = undefined;

  const layerNames = getODLayerNames(availableLayers);

  const gatesIds = gates?.reduce((ids: { [key: string]: boolean }, gate) => {
    ids[gate.identifier] = true;
    return ids;
  }, {});
  const gatesSegmentsIds = getGatesSegmentsId(gates);

  const existingDefaultLayers = defaultLayers.filter((layer) => !!map.getLayer(layer));

  const maxZoom = getMaxZoom(availableLayers);

  const isZoningLevelBlocked = blockedZoomLevel !== null;

  const source = getODSource(tileService, isZoningLevelBlocked, availableLayers, maxZoom);

  map.addSource(ZONE_SOURCE_ID, source);

  map.addSource(OUT_ZONES_SOURCE_ID, {
    type: "vector",
    tiles: [externalZonesTileService.layers[0].url + "/{z}/{x}/{y}.pbf"],
    promoteId: getODPromoteIds(externalZonesTileService.layers),
    minzoom: externalZonesTileService.layers[0].minzoom,
    maxzoom: externalZonesTileService.layers[0].maxzoom,
  });

  if (gates && isDataset) {
    map.addSource(ROADS_SOURCE_ID, {
      type: "vector",
      tiles: [roadsTileService.url + "/{z}/{x}/{y}.pbf"],
      promoteId: {
        [roadsTileService.layerName]: roadsTileService.fromToSegmentIdField,
      },
      minzoom: roadsTileService.minZoom,
      maxzoom: roadsTileService.maxZoom,
    });
  }

  map.addLayer(
    {
      id: OUT_ZONES_LAYER_LINE,
      type: "line",
      source: OUT_ZONES_SOURCE_ID,
      "source-layer": externalZonesTileService.layers[0].name,
      paint: {
        "line-color": COUNTY_BORDER_LINE_COLOR,
        "line-opacity": [
          "case",
          ["has", "country_name"],
          0, // transparent when country name is defined => Mexico or Canada
          COUNTY_BORDER_LINE_OPACITY, // some opacity when country name is not defined => US
        ],
        "line-width": COUNTY_BORDER_LINE_WIDTH,
      },
    },
    ...existingDefaultLayers,
  );

  map.addLayer(
    {
      id: OUT_ZONES_LAYER_FILL,
      type: "fill",
      source: OUT_ZONES_SOURCE_ID,
      "source-layer": externalZonesTileService.layers[0].name,
      paint: {
        "fill-color": "yellow",
        "fill-opacity": 0.2,
        "fill-outline-color": "black", // outline width can unfortunately not be set; black will be light gray at 0.2 opacity
      },
      filter: ["in", externalZonesTileService.layers[0].idField, ""],
    },
    ...existingDefaultLayers,
  );

  availableLayers.forEach((layer) => {
    map.addLayer(
      {
        id: layer.name,
        type: "fill",
        "source-layer": layer.name,
        source: ZONE_SOURCE_ID,
        layout: {
          visibility: showZones ? "visible" : "none",
        },
        paint: {
          "fill-color": [
            "case",
            ["boolean", ["feature-state", "permanentSelectHighlightOrigin"], false],
            ZONE_PERMANENT_HIGHLIGHT_COLOR_ORIGIN,
            ["boolean", ["feature-state", "permanentSelectHighlightDestination"], false],
            ZONE_PERMANENT_HIGHLIGHT_COLOR_DESTINATION,
            ["==", ["feature-state", "color"], null],
            CHOROPLETH_FILL_COLOR,
            ["feature-state", "color"],
          ],
          "fill-opacity": [
            "case",
            ["boolean", ["feature-state", "permanentSelectHighlightOrigin"], false],
            ZONE_PERMANENT_HIGHLIGHT_FILL_OPACITY,
            ["boolean", ["feature-state", "permanentSelectHighlightDestination"], false],
            ZONE_PERMANENT_HIGHLIGHT_FILL_OPACITY,
            ["==", ["feature-state", "color"], null],
            0,
            ["boolean", ["feature-state", "hover"], false],
            isSelectLinkMap ? SELECT_LINK_FILL_OPACITY_HOVER : CHOROPLETH_FILL_OPACITY_HOVER,
            ["boolean", ["feature-state", "click"], false],
            CHOROPLETH_FILL_OPACITY_SELECTED,
            isSelectLinkMap ? SELECT_LINK_FILL_OPACITY : CHOROPLETH_FILL_OPACITY,
          ],
        },
      },
      ...existingDefaultLayers,
    );

    map.addLayer(
      {
        id: `${ZONE_BORDERS_LAYER_NAME} ${layer.name}`,
        type: "line",
        source: ZONE_SOURCE_ID,
        "source-layer": layer.name,
        paint: {
          "line-color": [
            "case",
            ["boolean", ["feature-state", "permanentSelectHighlightOrigin"], false],
            ZONE_PERMANENT_HIGHLIGHT_COLOR_ORIGIN,
            ["boolean", ["feature-state", "permanentSelectHighlightDestination"], false],
            ZONE_PERMANENT_HIGHLIGHT_COLOR_DESTINATION,
            ["boolean", ["feature-state", "hover"], false],
            ZONE_HOVER_HIGHLIGHT_COLOR,
            CHOROPLETH_LINE_COLOR,
          ],
          "line-opacity": CHOROPLETH_LINE_OPACITY,
          "line-width": ["case", ["boolean", ["feature-state", "hover"], false], 2, CHOROPLETH_LINE_WIDTH],
        },
        filter: ["in", layer.idField, ...Object.keys(ids[layer.level])],
      },
      ...existingDefaultLayers,
    );

    map.addLayer(
      {
        id: `${RANGE_MEASURE_LAYER_NAME}-${layer.name}`,
        type: "fill",
        "source-layer": layer.name,
        source: ZONE_SOURCE_ID,
        layout: {
          visibility: showZones ? "visible" : "none",
        },
        filter: ["in", layer.idField, ""],
        paint: {
          "fill-color": "#ffff00",
          "fill-opacity": 0.3,
        },
      },
      ...existingDefaultLayers,
    );
  });

  if (gates && isDataset) {
    map.addLayer(
      {
        id: ROADS_SEGMENTS_LAYER_ID,
        type: "line",
        source: ROADS_SOURCE_ID,
        "source-layer": roadsTileService.layerName,
        filter: ["in", roadsTileService.fromToSegmentIdField, ...Object.keys(gatesSegmentsIds)],
        paint: {
          "line-color": [
            "case",
            ["boolean", ["feature-state", "hoverHighlight"], false],
            ROAD_SEGMENT_SUPER_HIGHLIGHT_COLOR,
            ["boolean", ["feature-state", "selectHighlight"], false],
            ROAD_SEGMENT_SUPER_HIGHLIGHT_COLOR,
            ROAD_SEGMENT_HIGHLIGHT_COLOR,
          ],
          "line-width": [
            "interpolate",
            ["exponential", 1.6],
            ["zoom"],
            6,
            ["case", ["boolean", ["feature-state", "hover"], false], 2, 0.5],
            20,
            ["case", ["boolean", ["feature-state", "hover"], false], 35, 30],
          ],
          "line-offset": 0,
          "line-opacity": ["case", ["boolean", ["feature-state", "hover"], false], 1, 0],
        },
      },
      ...existingDefaultLayers,
    );
  }

  if (gates) {
    addGatesLayer(map, gates);
    addGatesEvents();
  }

  const getColorScale = () => {
    const zoom = isZoningLevelBlocked ? maxZoom : map.getZoom();
    const layerZoomLevel = getLayerFromZoom(zoom, availableLayers)?.level as string;

    const layerIDs = ids[layerZoomLevel];
    const sqmi = Object.entries(layerIDs).map(([zoneId, stats]) =>
      getSqMiDensity(counts?.zones?.[zoneId] ?? 0, stats.sqKm),
    );
    const currentColorScheme = sequentialSchemes[colorScheme];
    const scale = scaleCluster().domain(sqmi).range(currentColorScheme);
    return scale;
  };

  const handleUpdateODCounts = (event: any) => {
    if (
      event.sourceId === ZONE_SOURCE_ID &&
      map.getSource(ZONE_SOURCE_ID) &&
      map.isSourceLoaded(ZONE_SOURCE_ID) &&
      counts
    ) {
      const zoom = isZoningLevelBlocked ? maxZoom : map.getZoom();
      const sourceLayerId = getLayerFromZoom(zoom, availableLayers)?.name as string;
      const layerZoomLevel = getLayerFromZoom(zoom, availableLayers)?.level as string;

      const scale = getColorScale();
      setColorScale({ scale });
      updateFeatureStatesByCounts({
        map,
        counts,
        ids,
        scale,
        sourceLayerId,
        zoneSourceId: ZONE_SOURCE_ID,
        layerZoomLevel,
      });
    }
  };

  const handleZoom = () => {
    if (counts) {
      const zoom = isZoningLevelBlocked ? maxZoom : map.getZoom();
      const sourceLayerId = getLayerFromZoom(zoom, availableLayers)?.name as string;

      if (previousSourceLayerId !== sourceLayerId) {
        const scale = getColorScale();
        previousSourceLayerId = sourceLayerId;
        setColorScale({ scale });
      }
    }
  };

  const handleMouseClick = (e: MapLayerMouseEvent) => {
    const zoom = isZoningLevelBlocked ? blockedZoomLevel : selectedZone ? selectedZone.zoom : e.target.getZoom();
    const sourceLayerId = getLayerFromZoom(zoom, availableLayers)?.name as string;
    const layerZoneId = getLayerFromZoom(zoom, availableLayers)?.idField as string;
    const layerZoomLevel = getLayerFromZoom(zoom, availableLayers)?.level as string;

    const layerIDs = ids[layerZoomLevel];
    const mergedIds = { ...layerIDs, ...gatesIds };

    if (e.features?.length) {
      const feature = e.features[0];
      const featureId = feature.properties?.[layerZoneId] || feature.id;
      const zone = mergedIds[featureId];

      if (Boolean(zone) && isSelectLinkMap) {
        setSelectedZone({
          id: featureId,
          itemType: feature.source === GATES_LAYER_ID ? "gate" : "zone",
          level: layerZoomLevel,
          zoom,
        });
      } else {
        if (Boolean(zone) && featureId !== clickedState?.id) {
          if (clickedState) {
            map.setFeatureState(
              {
                source: ZONE_SOURCE_ID,
                sourceLayer: clickedState.source,
                id: clickedState.id,
              },
              { click: false },
            );
          }

          if (featureId) {
            clickedState = { id: featureId, source: sourceLayerId };
            map.setFeatureState(
              {
                source: ZONE_SOURCE_ID,
                sourceLayer: sourceLayerId,
                id: featureId,
              },
              { click: true },
            );

            setSelectedZone({
              id: featureId,
              itemType: feature.source === GATES_LAYER_ID ? "gate" : "zone",
              level: layerZoomLevel,
              zoom,
            });
          }
        } else {
          clickedState = null;
          setSelectedZone(null);
        }
      }
    }

    closePopup(popup, setODPopupProps);
  };

  const handleMousemove = (e: MapLayerMouseEvent) => {
    const zoom = e.target.getZoom();
    const sourceLayerId = getLayerFromZoom(zoom, availableLayers)?.name as string;
    const layerZoneId = getLayerFromZoom(zoom, availableLayers)?.idField as string;

    const layerZoomLevel = getLayerFromZoom(zoom, availableLayers)?.level as string;

    const layerIDs = ids[layerZoomLevel];
    const mergedIds = { ...layerIDs, ...gatesIds };

    if (e.features?.length) {
      const feature = e.features[0];
      const featureId = feature.properties?.[layerZoneId] || feature.id;
      const count =
        feature.source === GATES_LAYER_ID ? counts?.gates?.[featureId] || 0 : counts?.zones?.[featureId] || 0;
      const zone = mergedIds[featureId];

      if (Boolean(zone)) {
        if (hoveredState && feature.source !== GATES_LAYER_ID) {
          map.setFeatureState(
            {
              source: ZONE_SOURCE_ID,
              sourceLayer: hoveredState.source,
              id: hoveredState.id,
            },
            { hover: false },
          );
          if (isSelectLinkMap) map.getCanvas().style.cursor = "crosshair";
        }

        if (feature.source !== GATES_LAYER_ID) {
          hoveredState = { id: featureId, source: sourceLayerId };
          map.setFeatureState(
            {
              source: ZONE_SOURCE_ID,
              sourceLayer: sourceLayerId,
              id: featureId,
            },
            { hover: true },
          );
        }

        setODPopupProps({
          count,
          countByDensity: getSqMiDensity(count, feature.properties?.area_sqkm),
          type: getPopupQueryType(selectedZone?.id, featureId, queryType),
          gateId: feature.properties?.identifier,
        });
        popup.setLngLat(e.lngLat).setDOMContent(popupRef.current).addTo(map);
      } else {
        closePopup(popup, setODPopupProps);
        if (isSelectLinkMap) map.getCanvas().style.cursor = "grab";
      }
    }
  };

  const handleMouseleave = () => {
    if (hoveredState) {
      map.setFeatureState(
        {
          source: ZONE_SOURCE_ID,
          sourceLayer: hoveredState.source,
          id: hoveredState.id,
        },
        { hover: false },
      );
    }
    closePopup(popup, setODPopupProps);

    hoveredState = null;
  };

  map.on("mousemove", [GATES_LAYER_ID, ...layerNames], handleMousemove);
  map.on("mouseleave", [GATES_LAYER_ID, ...layerNames], handleMouseleave);
  map.on("click", [GATES_LAYER_ID, ...layerNames], handleMouseClick);

  map.on("sourcedata", handleUpdateODCounts);
  map.on("zoom", handleZoom);

  return {
    clear: () => {
      layerNames.forEach((layerId) => {
        if (map.getLayer(`${ZONE_BORDERS_LAYER_NAME} ${layerId}`))
          map.removeLayer(`${ZONE_BORDERS_LAYER_NAME} ${layerId}`);

        if (map.getLayer(`${RANGE_MEASURE_LAYER_NAME}-${layerId}`))
          map.removeLayer(`${RANGE_MEASURE_LAYER_NAME}-${layerId}`);

        if (map.getLayer(layerId)) map.removeLayer(layerId);
      });
      if (map.getLayer(ROADS_SEGMENTS_LAYER_ID)) map.removeLayer(ROADS_SEGMENTS_LAYER_ID);

      if (map.getLayer(OUT_ZONES_LAYER_LINE)) map.removeLayer(OUT_ZONES_LAYER_LINE);

      if (map.getLayer(OUT_ZONES_LAYER_FILL)) map.removeLayer(OUT_ZONES_LAYER_FILL);

      if (map.getSource(ROADS_SOURCE_ID)) map.removeSource(ROADS_SOURCE_ID);

      if (map.getSource(ZONE_SOURCE_ID)) map.removeSource(ZONE_SOURCE_ID);

      if (map.getSource(OUT_ZONES_SOURCE_ID)) map.removeSource(OUT_ZONES_SOURCE_ID);

      removeGatesLayer();

      map.off("sourcedata", handleUpdateODCounts);
      map.off("zoom", handleZoom);
      map.off("mousemove", [GATES_LAYER_ID, ...layerNames], handleMousemove);
      map.off("mouseleave", [GATES_LAYER_ID, ...layerNames], handleMouseleave);
      map.off("click", [GATES_LAYER_ID, ...layerNames], handleMouseClick);
      clearGatesEvents();

      closePopup(popup, setODPopupProps);
    },
  };
};
