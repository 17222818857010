import styled from "@emotion/styled";
import AutoAwesomeOutlined from "@mui/icons-material/AutoAwesomeOutlined";
import FolderOpenOutlinedIcon from "@mui/icons-material/FolderOpenOutlined";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import PublicOutlinedIcon from "@mui/icons-material/PublicOutlined";
import { SxProps } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import React from "react";

import { FocusAreaItem } from "types";

interface Props {
  value: FocusAreaItem | null;
  loading: boolean;
  disabled: boolean;
  options: FocusAreaItem[];
  noLabel?: boolean;
  sx?: SxProps;
  onChange: (value: any) => void;
}

const GroupHeader = styled("div")(({ theme }) => ({
  position: "sticky",
  display: "flex",
  padding: "5px 10px 0px 27px",
  color: "var(--color-textSecondary)",
  textOverflow: "ellipsis",
  overflow: "hidden",
  whiteSpace: "nowrap",
  fontSize: "var(--font-size-sm)",
  fontWeight: "bold",
}));

const RegionGroupHeader = styled("div")(({ theme }) => ({
  position: "sticky",
  padding: "0 10px",
  backgroundColor: "white",
  fontSize: "var(--font-size-sm)",
  fontWeight: "bold",
}));

const GroupItems = styled("ul")({
  padding: 0,
  margin: "0 10px",
  fontSize: "var(--font-size-sm)",
  fontWeight: "bold",
});

export const FocusAreaDropdown = ({ loading, disabled, options, value, onChange, noLabel, ...props }: Props) => {
  const REGION_FLAG = "REGION_FLAG";

  return (
    <Autocomplete
      loading={loading}
      disabled={disabled}
      options={options}
      componentsProps={{
        popper: {
          placement: "bottom-start",
          style: {
            width: "412px", // 412px so that is aligned with "open button in the dashboard"
          },
        },
      }}
      groupBy={(option: FocusAreaItem) => (option.folderName ? option.folderName : `${REGION_FLAG}${option.region}`)}
      getOptionLabel={(option: FocusAreaItem) => option.label}
      isOptionEqualToValue={(option, value) => option?.id === value?.id}
      renderOption={(props, option) => (
        <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
          {option.datasetId ? (
            <InsertDriveFileOutlinedIcon
              fontSize="small"
              style={{ marginLeft: "22px", marginRight: "5px", color: "#374151" }}
            />
          ) : option.isDemo ? (
            <AutoAwesomeOutlined fontSize="small" style={{ marginRight: "5px", color: "#60a5fa" }} />
          ) : (
            <PublicOutlinedIcon fontSize="small" style={{ marginRight: "5px", color: "#60a5fa" }} />
          )}
          {option.label}
        </Box>
      )}
      //
      renderInput={(params) => (
        <TextField {...params} size="small" label={noLabel === true ? undefined : "Area of interest"} />
      )}
      renderGroup={(params) => {
        const isRegion = params.group.startsWith(REGION_FLAG);

        return (
          <ul key={params.key}>
            {isRegion ? (
              <>
                {Boolean(params.key) && (
                  <Divider variant="middle" style={{ marginTop: "5px", marginBottom: "5px" }} light />
                )}
                <RegionGroupHeader>{params.children}</RegionGroupHeader>
              </>
            ) : (
              <GroupHeader>
                <FolderOpenOutlinedIcon fontSize="small" style={{ marginRight: "4px" }} />
                {params.group}
              </GroupHeader>
            )}

            {!isRegion && <GroupItems>{params.children}</GroupItems>}
          </ul>
        );
      }}
      value={value || null}
      onChange={(event, value) => onChange(value?.id)}
      {...props}
    />
  );
};
