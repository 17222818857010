import styled from "@emotion/styled";

export const Table = styled.table`
  margin-top: 1rem;
  width: 100%;

  & thead {
    background-color: var(--color-secondaryLight);
    color: var(--color-textSecondary);

    & th {
      font-size: 12px;
      font-weight: 500;
      padding: 0.5rem 1rem;
    }
    & th:first-of-type {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }
    & th:last-of-type {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }

  & td {
    padding: 0.5rem 1rem;
  }
`;
