import styled from "@emotion/styled";
import React, { ChangeEvent, Dispatch, FC, ReactNode, SetStateAction, SyntheticEvent, useState } from "react";

import { Button, Dialog, DialogProps, Input } from "components";

enum Steps {
  Error = "error",
  ChooseName = "choose-name",
}

export interface SaveErrorDialogProps extends DialogProps {
  error: any;
  handleDiscardChanges: () => void;
  handleOverwrite: () => void;
  handleCreateNewDataset: (name: string) => void;
  onClose: (event: SyntheticEvent<HTMLElement, Event>) => void;
}

interface DialogContentProps {
  title?: string | ReactNode;
  body?: string | ReactNode;
  actions?: ReactNode;
}

const DialogTitle = styled.h2`
  font-size: 18px;
  font-weight: 600;
`;

const DialogBody = styled.div`
  margin: 2rem 0 1.5rem 0;
  font-size: 16px;
`;

const DialogActions = styled.div`
  display: flex;
  justify-content: flex-end;

  & button {
    min-width: 100px;
    &:not(:last-of-type) {
      margin-right: 1rem;
    }
  }
`;

const DatasetNameInput = styled(Input)`
  width: 400px;
  margin-top: 1rem;
`;

const ErrorMessage = styled.div`
  color: var(--color-red-800);
  font-size: 14px;
  margin-top: 8px;
`;

const DialogContent: FC<DialogContentProps> = ({ title, body, actions }) => (
  <>
    <DialogTitle>{title}</DialogTitle>
    <DialogBody>{body}</DialogBody>
    <DialogActions>{actions}</DialogActions>
  </>
);

const getDialogContent = (
  error: any,
  setStep: Dispatch<SetStateAction<Steps>>,
  onClose: (event: SyntheticEvent<HTMLElement, Event>) => void,
  handleDiscardChanges: () => void,
  handleOverwrite: () => void,
) => {
  switch (true) {
    case error?.status === 404:
      return (
        <DialogContent
          title="This dataset has been deleted"
          body="Do you want to save a new copy?"
          actions={
            <>
              <Button color="white" onClick={onClose}>
                No
              </Button>
              <Button color="primaryLight" onClick={() => setStep(Steps.ChooseName)}>
                Yes
              </Button>
            </>
          }
        />
      );
    case error?.status === 409:
      return (
        <DialogContent
          title="This dataset has been changed elsewhere in the meantime"
          body="What do you want to do?"
          actions={
            <>
              <Button color="white" onClick={onClose}>
                Cancel
              </Button>
              <Button color="primaryLight" onClick={handleDiscardChanges}>
                Discard your changes
              </Button>
              <Button color="primaryLight" onClick={handleOverwrite}>
                Overwrite other changes
              </Button>
              <Button color="primaryLight" onClick={() => setStep(Steps.ChooseName)}>
                Keep both
              </Button>
            </>
          }
        />
      );
    case error?.status === 422 && error?.body?.reason === "read-only":
      return (
        <DialogContent
          title={error?.body?.what}
          body="Do you want to save a new copy?"
          actions={
            <>
              <Button color="white" onClick={onClose}>
                No
              </Button>
              <Button color="primaryLight" onClick={() => setStep(Steps.ChooseName)}>
                Yes
              </Button>
            </>
          }
        />
      );
    default:
      return (
        <DialogContent
          title="Error"
          body={error?.body?.what || error?.body?.msg || "Something went wrong. Please retry later."}
          actions={
            <Button color="primaryLight" onClick={onClose}>
              Close
            </Button>
          }
        />
      );
  }
};

export const SaveErrorDialog: FC<SaveErrorDialogProps> = ({
  error,
  onClose,
  handleDiscardChanges,
  handleOverwrite,
  handleCreateNewDataset,
  ...props
}) => {
  const [step, setStep] = useState<Steps>(Steps.Error);
  const [name, setName] = useState("");

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const handleSave = () => {
    handleCreateNewDataset(name);
  };

  const datasetNameErrorMsg = (error?.status === 422 && error?.body?.reason !== "read-only" && error?.body?.what) || "";

  return (
    <Dialog {...props} onClose={onClose}>
      {step === Steps.Error && getDialogContent(error, setStep, onClose, handleDiscardChanges, handleOverwrite)}
      {step === Steps.ChooseName && (
        <DialogContent
          body={
            <>
              Choose a name for the new dataset
              <DatasetNameInput value={name} onChange={handleChange} />
              {datasetNameErrorMsg && <ErrorMessage>{datasetNameErrorMsg}</ErrorMessage>}
            </>
          }
          actions={
            <>
              <Button color="white" onClick={onClose}>
                Cancel
              </Button>
              <Button color="primaryLight" onClick={handleSave}>
                Save
              </Button>
            </>
          }
        />
      )}
    </Dialog>
  );
};
