import styled from "@emotion/styled";
import dataQualityButtonImg from "assets/png/data-quality.png";
import faqButtonImg from "assets/png/faq.png";
import productButtonImg from "assets/png/product.png";
import tutorialButtonImg from "assets/png/video.png";
import React, { FC, useMemo } from "react";
import { Link } from "react-router-dom";

import { usePageTracking } from "hooks";

import { DOCS_PRODUCT_ROUTES, DOCS_ROUTES } from "types";

import { PageHeader } from "./PageHeader";

interface NavItem {
  label: string;
  image: string;
  url: string;
}

const HomeContainer = styled.div`
  margin-top: -180px;
  display: flex;
  justify-content: center;
  width: 100%;
`;

const Header = styled.h2`
  font-size: 24px;
  color: #ffffff;
  margin-bottom: 1rem;
`;

const NavContainer = styled.div<{ items: number }>`
  width: 100%;
  max-width: 1000px;
  z-index: 2;
  display: flex;

  @media only screen and (min-height: 900px) {
    max-width: 1200px;
  }
`;

const NavButton = styled(Link)`
  margin: 0.5rem;
  position: relative;

  & img {
    border-radius: 8px;
  }
`;

const Label = styled.h3`
  color: #ffffff;
  font-weight: 600;
  position: absolute;
  bottom: 2rem;
  left: 2rem;
`;

export const Home: FC = () => {
  const navItems: NavItem[] = useMemo(
    () => [
      {
        label: "Video tutorials",
        image: tutorialButtonImg,
        url: DOCS_ROUTES.Tutorials,
      },
      {
        label: "Reference",
        image: productButtonImg,
        url: `${DOCS_ROUTES.Product}/${DOCS_PRODUCT_ROUTES.License}`,
      },
      {
        label: "Data Quality",
        image: dataQualityButtonImg,
        url: DOCS_ROUTES.DataQuality,
      },
      {
        label: "FAQ's",
        image: faqButtonImg,
        url: DOCS_ROUTES.Faq,
      },
    ],
    [],
  );

  usePageTracking();

  return (
    <>
      <PageHeader />
      <HomeContainer>
        <div>
          <Header>Documentation</Header>
          <NavContainer items={navItems.length}>
            {navItems.map(({ label, url, image }) => (
              <NavButton key={url} to={url}>
                <img src={image} alt={image} width={"100%"} height={"100%"} />
                <Label>{label}</Label>
              </NavButton>
            ))}
          </NavContainer>
        </div>
      </HomeContainer>
    </>
  );
};
