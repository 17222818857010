import { Map } from "mapbox-gl";

import { RoadsTileService } from "types";

const defaultLayers = ["admin_country", "road-label"];

export enum EditorRoads {
  SourceId = "roads",
  SegmentsLayerId = "road-segments",
  HighlightedSegmentsLayerId = "road-segments-highlighted",
}

export enum EditorRoadsHighlightStatus {
  HoverAdd = "hoverAdd",
  HoverDelete = "hoverDelete",
  GateHover = "gateHover",
  AllGateSegments = "allGateSegments",
  SelectedGateSegments = "selectedGateSegments",
  SelectedSegment = "selectedSegment",
}

interface EditorRoadsData {
  tileService: RoadsTileService;
  roadSegmentIds: string[];
}

export const initEditorRoads = (map: Map, { tileService, roadSegmentIds }: EditorRoadsData) => {
  const existingDefaultLayers = defaultLayers.filter((layer) => !!map.getLayer(layer));

  map.addSource(EditorRoads.SourceId, {
    type: "vector",
    tiles: [tileService.url + "/{z}/{x}/{y}.pbf"],
    promoteId: {
      [tileService.layerName]: tileService.fromToSegmentIdField,
    },
    minzoom: tileService.minZoom,
    maxzoom: tileService.maxZoom,
  });

  map.addLayer(
    {
      id: EditorRoads.SegmentsLayerId,
      type: "line",
      source: EditorRoads.SourceId,
      "source-layer": tileService.layerName,
      filter: ["in", tileService.fromToSegmentIdField, ...roadSegmentIds],
      paint: {
        "line-color": "#FDA4AF",
        "line-width": ["interpolate", ["exponential", 1.6], ["zoom"], 6, 1, 10, 2, 13, 3, 16, 5, 20, 16],
        "line-offset": 0,
        "line-opacity": 1,
      },
      layout: {
        "line-cap": "round",
      },
    },
    ...existingDefaultLayers,
  );

  map.addLayer({
    id: EditorRoads.HighlightedSegmentsLayerId,
    type: "line",
    source: EditorRoads.SourceId,
    "source-layer": tileService.layerName,
    filter: ["in", tileService.fromToSegmentIdField, ...roadSegmentIds],
    paint: {
      "line-color": [
        "case",
        ["boolean", ["feature-state", EditorRoadsHighlightStatus.HoverAdd], false],
        "green",
        ["boolean", ["feature-state", EditorRoadsHighlightStatus.HoverDelete], false],
        "red",
        ["boolean", ["feature-state", EditorRoadsHighlightStatus.GateHover], false],
        "#1e40af",
        ["boolean", ["feature-state", EditorRoadsHighlightStatus.SelectedSegment], false],
        "red",
        ["boolean", ["feature-state", EditorRoadsHighlightStatus.SelectedGateSegments], false],
        "purple",
        ["boolean", ["feature-state", EditorRoadsHighlightStatus.AllGateSegments], false],
        "#139eec",
        "#FEF3C7",
      ],
      "line-width": ["interpolate", ["exponential", 1.6], ["zoom"], 6, 2, 10, 6, 13, 8, 16, 9, 20, 18],
      "line-blur": 1,
      "line-opacity": [
        "case",
        ["boolean", ["feature-state", EditorRoadsHighlightStatus.HoverAdd], false],
        0.8,
        ["boolean", ["feature-state", EditorRoadsHighlightStatus.HoverDelete], false],
        0.8,
        ["boolean", ["feature-state", EditorRoadsHighlightStatus.GateHover], false],
        0.8,
        ["boolean", ["feature-state", EditorRoadsHighlightStatus.SelectedSegment], false],
        0.8,
        ["boolean", ["feature-state", EditorRoadsHighlightStatus.SelectedGateSegments], false],
        0.8,
        ["boolean", ["feature-state", EditorRoadsHighlightStatus.AllGateSegments], false],
        0.8,
        0,
      ],
    },
    layout: {
      "line-cap": "round",
    },
  });

  return {
    clear: () => {
      if (map.getLayer(EditorRoads.SegmentsLayerId)) {
        map.removeLayer(EditorRoads.SegmentsLayerId);
      }

      if (map.getLayer(EditorRoads.HighlightedSegmentsLayerId)) {
        map.removeLayer(EditorRoads.HighlightedSegmentsLayerId);
      }
      if (map.getSource(EditorRoads.SourceId)) {
        map.removeSource(EditorRoads.SourceId);
      }
    },
  };
};
