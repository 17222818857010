import { IconName } from "@blueprintjs/core";
import styled from "@emotion/styled";
import React, { ChangeEvent, FC, useState } from "react";

import { Checkbox, PopoverForm, SelectInput } from "components";

export interface CheckboxDropdownItem {
  label: string;
  isChecked: boolean;
}

export type CheckboxDropdownItems = {
  [key: string]: CheckboxDropdownItem;
};

export interface CheckboxDropdownProps {
  isGroupChecked: boolean;
  groupName: string;
  groupLabel: string;
  groupIcon: IconName;
  items: CheckboxDropdownItems;
  error: boolean;
  disabled?: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  selectAll: (groupName: string) => void;
  clearAll: (groupName: string) => void;
  placement?: "bottom" | "top";
}

const ListContainer = styled.div`
  margin: 8px 0;
`;

const GroupHeader = styled.p`
  margin-bottom: 0.25rem;
  font-size: 12px;
  font-weight: 600;
`;

export const CheckboxDropdown: FC<CheckboxDropdownProps> = ({
  isGroupChecked,
  groupName,
  groupLabel,
  groupIcon,
  items,
  error,
  placement,
  disabled,
  onChange,
  selectAll,
  clearAll,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const generateInputValue = (items: CheckboxDropdownItems) => {
    let str = "";
    let count = 0;
    Object.values(items).forEach((item, i) => {
      if (item.isChecked) {
        if (!str) {
          str += item.label;
        } else {
          count++;
        }
      }
    });
    return (str += count > 0 ? ` + ${count} more` : "");
  };

  const handleInteraction = (state: boolean) => {
    setIsOpen(state);
  };

  const formContent = Object.entries(items).map(([itemKey, item], i) => (
    <Checkbox
      key={i}
      name={`${groupName}-${itemKey}`}
      label={item.label}
      checked={item.isChecked}
      onChange={onChange}
    />
  ));

  return (
    <ListContainer>
      <GroupHeader>{groupLabel}</GroupHeader>
      <PopoverForm
        title={groupLabel}
        content={formContent}
        isOpen={isOpen}
        handleInteraction={handleInteraction}
        placement={placement || "bottom"}
        primaryAction={<div onClick={() => selectAll(groupName)}>Select all</div>}
        secondaryAction={<div onClick={() => clearAll(groupName)}>Clear all</div>}
        matchTargetWidth
        displayCloseIcon
      >
        <SelectInput
          color={isGroupChecked ? "gray" : "secondary"}
          error={error}
          disabled={disabled}
          //helperText={"Select a filter"}
          icon={groupIcon}
          value={isGroupChecked ? "All" : generateInputValue(items)}
          placement={placement || "bottom"}
          isOpen={isOpen}
        />
      </PopoverForm>
    </ListContainer>
  );
};
