import React, { FC, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import styled from "@emotion/styled";
import { Icon } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { Formik, Field, Form } from "formik";

import { useAppDispatch, useAppSelector } from "hooks";
import { Dialog, FlexContainer, Input, Button, TextArea } from "components";
import { licenseActions } from "store/sections/license";
import { Image } from "types";
import { DataState } from "store/interfaces";

const IconContainer = styled(FlexContainer)`
  cursor: pointer;
  margin-right: 1rem;
`;

const Title = styled.h2`
  margin-bottom: 0.5rem;
`;

const SubTitle = styled.h4`
  margin-bottom: 1.5rem;
  color: var(--color-textSecondary);
  font-weight: 400;
  max-width: 600px;
`;

const FieldContainer = styled.div`
  position: relative;
  padding-bottom: 1rem;
  margin-bottom: 0.5rem;
`;

const Label = styled.label`
  display: inline-block;
  font-weight: 500;
  margin-bottom: 0.25rem;
`;

const ErrorMsg = styled.div`
  position: absolute;
  bottom: -2px;
  color: var(--color-red-rose);
  font-size: 12px;
  font-weight: 500;
  margin-left: 0.5rem;
`;

const StyledTextArea = styled(TextArea)`
  width: 100%;
  min-height: 200px;
`;

const Actions = styled(FlexContainer)`
  justify-content: flex-end;
  margin-top: 1.5rem;

  & button {
    margin-left: 1rem;
  }
`;

interface FormData {
  subject: string;
  message: string;
  image: Image | null;
}

export const HelpForm: FC = () => {
  const dispatch = useAppDispatch();
  const { pathname, search } = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const feedback = useAppSelector((state) => state.license.feedback);

  const validate = (values: FormData) => {
    const errors: any = {};

    if (!values.subject) {
      errors.subject = "Required";
    } else if (!values.message) {
      errors.message = "Required";
    }
    return errors;
  };

  const handleChangeFileInput = (
    event: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void,
  ) => {
    const fileReader = new FileReader();
    const image = event?.target?.files?.[0];
    if (image) {
      fileReader.onload = () => {
        if (fileReader.readyState === 2) {
          const res = fileReader.result as string;
          setFieldValue("image", {
            content: res.replace(`data:${image.type};base64,`, ""),
            contentType: image.type,
            fileName: image.name,
          });
        }
      };
      fileReader.readAsDataURL(image);
    }
  };

  const handleSubmit = (values: FormData) => {
    const { subject, message, image } = values;

    dispatch(
      licenseActions.sendFeedback({
        subject,
        body: message,
        url: pathname + search,
        images: image ? [image] : undefined,
      }),
    );
  };

  useEffect(() => {
    if (feedback.data) setIsOpen(false);

    return () => {
      dispatch(licenseActions.clearFeedback());
    };
  }, [feedback.data, dispatch]);

  return (
    <>
      <IconContainer>
        <Icon icon={IconNames.COMMENT} color="white" size={18} onClick={() => setIsOpen(true)} />
      </IconContainer>
      <Dialog isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <Title>Customer Feedback</Title>
        <SubTitle>
          We value your input! Let us know how we can improve Studio by sending us a message with your suggestions,
          feature requests, or bug reports.
        </SubTitle>
        <Formik<FormData>
          initialValues={{ subject: "", message: "", image: null }}
          onSubmit={handleSubmit}
          validate={validate}
        >
          {({ errors, touched, setFieldValue }) => (
            <Form>
              <FieldContainer>
                <Label htmlFor="subject">Subject:*</Label>
                <Field as={Input} id="subject" name="subject" type="subject" />
                {errors.subject && touched.subject ? <ErrorMsg>{errors.subject}</ErrorMsg> : null}
              </FieldContainer>
              <FieldContainer>
                <Label htmlFor="message">Message:*</Label>
                <Field as={StyledTextArea} id="message" name="message" />
                {errors.message && touched.message ? <ErrorMsg>{errors.message}</ErrorMsg> : null}
              </FieldContainer>
              <FieldContainer>
                <Label htmlFor="image">Upload image:</Label>
                <Field name="image">
                  {({ field, form: { errors }, meta }: any) => (
                    <div>
                      <input
                        id="image"
                        type="file"
                        accept="image/png, image/gif, image/jpeg"
                        onChange={(event) => handleChangeFileInput(event, setFieldValue)}
                      />
                      {meta.touched && meta.error && <ErrorMsg>{errors.message}</ErrorMsg>}
                    </div>
                  )}
                </Field>
                {errors.image && touched.image ? <ErrorMsg>{errors.image}</ErrorMsg> : null}
              </FieldContainer>
              <Actions>
                <Button
                  type="button"
                  color="whiteBlue"
                  onClick={() => setIsOpen(false)}
                  disabled={feedback.state === DataState.LOADING}
                >
                  Cancel
                </Button>
                <Button type="submit" color="primaryLight" loading={feedback.state === DataState.LOADING}>
                  Submit
                </Button>
              </Actions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </>
  );
};
