import { Map } from "mapbox-gl";

import { DatasetGate, Segment } from "types";

import { ROADS_SOURCE_ID } from "./roads";

export const GATES_LAYER_ID = "gates";

let map: Map | null = null;
let gates: DatasetGate[] | null = null;
let featureId: string | null = null;
let hoveredSegments: Segment[] | null = null;

const updateSegmentsStateWithHover = (map: Map, segments: Segment[], status: boolean) => {
  segments.forEach((segment) => {
    map.setFeatureState(
      {
        source: ROADS_SOURCE_ID,
        sourceLayer: ROADS_SOURCE_ID,
        id: segment.id,
      },
      { hover: status },
    );
  });
};

export const addGatesLayer = (mapInstance: Map, datasetGates: DatasetGate[]) => {
  map = mapInstance;
  gates = datasetGates;

  map.addSource(GATES_LAYER_ID, {
    type: "geojson",
    promoteId: "identifier",
    data: {
      type: "FeatureCollection",
      features: gates.map((gate) => ({
        type: "Feature",
        properties: {
          identifier: gate.identifier,
        },
        geometry: {
          type: "Point",
          coordinates: [gate.lon, gate.lat],
        },
      })),
    },
  });

  map.addLayer({
    id: GATES_LAYER_ID,
    type: "circle",
    source: GATES_LAYER_ID,
    paint: {
      "circle-color": ["case", ["boolean", ["feature-state", "hover"], false], "#1E40AF", "#139EEC"],
      "circle-radius": 8,
      "circle-stroke-width": 2,
      "circle-stroke-color": "#ffffff",
    },
  });

  map.addLayer({
    id: `highlight-range-${GATES_LAYER_ID}`,
    type: "circle",
    source: GATES_LAYER_ID,
    filter: ["in", "identifier", ""],
    paint: {
      "circle-opacity": 0,
      "circle-radius": 8,
      "circle-stroke-width": 3,
      "circle-stroke-opacity": 0.8,
      "circle-stroke-color": "#ffff00",
    },
  });
};

export const removeGatesLayer = () => {
  if (map) {
    if (map.getLayer(GATES_LAYER_ID)) map.removeLayer(GATES_LAYER_ID);
    if (map.getLayer(`highlight-range-${GATES_LAYER_ID}`)) map.removeLayer(`highlight-range-${GATES_LAYER_ID}`);
    if (map.getSource(GATES_LAYER_ID)) map.removeSource(GATES_LAYER_ID);
  }
};

const handleMouseMove = (e: any) => {
  if (map) {
    if (featureId) {
      map.setFeatureState(
        {
          source: GATES_LAYER_ID,
          id: featureId,
        },
        { hover: false },
      );
    }

    const id = e.features[0].id;
    if (id && gates) {
      map.getCanvas().style.cursor = "pointer";
      map.setFeatureState(
        {
          source: GATES_LAYER_ID,
          id,
        },
        { hover: true },
      );

      const gate = gates.find((gate: DatasetGate) => gate.identifier === id);
      const segments = gate?.segments || [];
      hoveredSegments = segments;
      updateSegmentsStateWithHover(map, segments, true);
      featureId = id;
    }
  }
};

const handleMouseLeave = () => {
  if (map && featureId) {
    map.getCanvas().style.cursor = "";
    map.setFeatureState(
      {
        source: GATES_LAYER_ID,
        id: featureId,
      },
      { hover: false },
    );
    featureId = null;
  }

  if (map && hoveredSegments) {
    updateSegmentsStateWithHover(map, hoveredSegments, false);
  }
};

export const addGatesEvents = () => {
  if (map) {
    map.on("mousemove", GATES_LAYER_ID, handleMouseMove);
    map.on("mouseleave", GATES_LAYER_ID, handleMouseLeave);
  }
};

export const clearGatesEvents = () => {
  if (map) {
    map.off("mousemove", GATES_LAYER_ID, handleMouseMove);
    map.off("mouseleave", GATES_LAYER_ID, handleMouseLeave);
  }
};
