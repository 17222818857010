import { UniqueIdentifier } from "@dnd-kit/core";
import { Api } from "api";
import { Geometry } from "geojson";
import { produce, setAutoFreeze, setUseStrictShallowCopy } from "immer";
import { NavigateFunction } from "react-router-dom";
import { Reducer } from "redux";
import { all, call, getContext, put, select, takeLatest } from "redux-saga/effects";

import {
  AddGateArguments,
  AddGateResponse,
  Counts,
  CountsByZoneId,
  DatasetCountsArguments,
  DatasetCountsByZoneIdArguments,
  DatasetGate,
  DatasetMetadata,
  DatasetZoneDetailsArguments,
  FiltersType,
  FocusAreaItem,
  Gate,
  GateCoordinates,
  GateCoordinatesArguments,
  GateSegment,
  GateSegmentProps,
  GenerateGatesArguments,
  GeocodingSearchResults,
  MapVisualizationMode,
  MapVisualizationType,
  MeasureRange,
  MeasureRangeRequest,
  MeasureType,
  NewODDatasetConfigPayload,
  ODCountsArguments,
  ODCountsByZoneIdArguments,
  ODDatasetConfig,
  ODDatasetValidation,
  ODMeasureRange,
  ODMetadata,
  ODMetadataArguments,
  QueryType,
  RoadClassCategory,
  RoadSegmentDetails,
  RoadSegmentIdsArguments,
  RoadSegmentIdsByRoadClass,
  RoadSegmentIdsWithFactype,
  RoadSegmentsDetailsArguments,
  RoadsMetadata,
  RoadsMetadataArguments,
  RoadsVolumes,
  RoadsVolumesArguments,
  SelectedArea,
  SelectedVolume,
  SubareaPolygonArguments,
  SubareaState,
  SubareaStateArguments,
  UpdateODDatasetConfigPayload,
  ZoneDetails,
  ZoneDetailsArguments,
  ZoneIds,
  ZoneIdsArguments,
} from "types";

import { reportAboutErrorState } from "utils/reports";

import { Action, ActionsUnion, createAction } from "../actionHelpers";
import { DataState, LoadingErrorData, ResponseError } from "../interfaces";
import { extendODMetadata, extendRoadsMetadata, isSameGate } from "../utils";
import { AnalyticsActionType } from "./actionTypes";
import { DatasetFoldersActionType, GlobalActionType } from "./actionTypes";

export interface AnalyticsState {
  authorizationTokenLoaded: boolean;
  datasetCounts: LoadingErrorData<Counts>;
  datasetIds: LoadingErrorData<ZoneIds>;
  datasetCountsByZoneId: LoadingErrorData<CountsByZoneId>;
  datasetMetadata: LoadingErrorData<DatasetMetadata>;
  datasetGates: LoadingErrorData<DatasetGate[]>;
  gateDetails: LoadingErrorData<ZoneDetails>;
  zoneDetails: LoadingErrorData<ZoneDetails>;
  ODCounts: LoadingErrorData<Counts>;
  ODIds: LoadingErrorData<ZoneIds>;
  ODCountsByZoneId: LoadingErrorData<CountsByZoneId>;
  ODMetadata: LoadingErrorData<ODMetadata>;
  subareaState: LoadingErrorData<SubareaState>;
  newGate: LoadingErrorData<Gate>;
  roadsMetadata: LoadingErrorData<RoadsMetadata>;
  roadsVolumes: LoadingErrorData<RoadsVolumes>;
  roadSegmentIds: LoadingErrorData<RoadSegmentIdsWithFactype>;
  reverseRoadSegmentIds: RoadSegmentIdsWithFactype;
  roadSegmentIdsByRoadClass: RoadSegmentIdsByRoadClass | null;
  roadSegmentsDetails: LoadingErrorData<Record<string, RoadSegmentDetails>>;
  geocodingSearch: LoadingErrorData<GeocodingSearchResults>;
  loadingGeneratedGates: boolean;
  loadingGateSegments: boolean;
  loadingSubareaPolygon: boolean;
  newODDatasetConfig: ODDatasetConfig | null;
  savedODDatasetConfig: ODDatasetConfig | null;
  ODDatasetConfig: LoadingErrorData<ODDatasetConfig>;
  ODDatasetConfigValidation: LoadingErrorData<ODDatasetValidation>;
  measure: MeasureType;
  queryType: QueryType;
  ODFilters: FiltersType | null;
  roadFilters: FiltersType | null;
  datasetFilters: FiltersType | null;
  selectedZone: SelectedArea | null;
  selectedRoadVolume: SelectedVolume | null;
  selectedRoadVolumeId: string | null;
  focusAreasAndDatasets: LoadingErrorData<FocusAreaItem[]>;
  focusAreas: LoadingErrorData<FocusAreaItem[]>;
  ODMeasureRange: LoadingErrorData<ODMeasureRange>;
  datasetMeasureRange: LoadingErrorData<ODMeasureRange>;
  roadsMeasureRange: LoadingErrorData<MeasureRange>;
  ODRange: { [key: string]: [number, number] } | null;
  datasetRange: { [key: string]: [number, number] } | null;
  roadsRange: [number, number] | null;
  ODMeasureRangeByZone: MeasureRange | null;
  ODRangeByZone: [number, number] | null;
  mapVisualizationMode: MapVisualizationMode | null;
}

export type AnalyticsAction = ActionsUnion<typeof analyticsActions>;

export const analyticsActions = {
  setAutorizationTokenLoaded: (loaded: boolean) =>
    createAction(AnalyticsActionType.SET_AUTHORIZATION_TOKEN_LOADED, loaded),

  clearDatasetMetadata: () => createAction(AnalyticsActionType.CLEAR_DATASET_METADATA),

  // Dataset Counts Actions
  fetchDatasetCounts: (datasetId: string, levels: string[], config: DatasetCountsArguments) =>
    createAction(AnalyticsActionType.FETCH_DATASET_COUNTS, {
      datasetId,
      levels,
      config,
    }),
  fetchDatasetCountsSucceeded: (datasetCounts: Counts) =>
    createAction(AnalyticsActionType.FETCH_DATASET_COUNTS_SUCCEEDED, {
      datasetCounts,
    }),
  fetchDatasetCountsFailed: (error: ResponseError) =>
    createAction(AnalyticsActionType.FETCH_DATASET_COUNTS_FAILED, error),

  // Dataset Ids Actions
  fetchDatasetIds: (datasetId: string, levels: string[], config: ZoneIdsArguments) =>
    createAction(AnalyticsActionType.FETCH_DATASET_IDS, {
      datasetId,
      levels,
      config,
    }),
  fetchDatasetIdsSucceeded: (datasetIds: ZoneIds) =>
    createAction(AnalyticsActionType.FETCH_DATASET_IDS_SUCCEEDED, {
      datasetIds,
    }),
  fetchDatasetIdsFailed: (error: ResponseError) => createAction(AnalyticsActionType.FETCH_DATASET_IDS_FAILED, error),

  fetchDatasetCountsByZoneId: (datasetId: string, config: DatasetCountsByZoneIdArguments) =>
    createAction(AnalyticsActionType.FETCH_DATASET_COUNTS_BY_ZONE_ID, {
      datasetId,
      config,
    }),
  fetchDatasetCountsByZoneIdSucceeded: (datasetCounts: CountsByZoneId) =>
    createAction(AnalyticsActionType.FETCH_DATASET_COUNTS_BY_ZONE_ID_SUCCEEDED, {
      datasetCounts,
    }),
  fetchDatasetCountsByZoneIdFailed: (error: ResponseError) =>
    createAction(AnalyticsActionType.FETCH_DATASET_COUNTS_BY_ZONE_ID_FAILED, error),

  // Dataset Metadata Actions
  fetchDatasetMetadata: (datasetId: string) => createAction(AnalyticsActionType.FETCH_DATASET_METADATA, datasetId),
  fetchDatasetMetadataSucceeded: (datasetMetadata: DatasetMetadata) =>
    createAction(AnalyticsActionType.FETCH_DATASET_METADATA_SUCCEEDED, {
      datasetMetadata,
    }),
  fetchDatasetMetadataFailed: (error: ResponseError) =>
    createAction(AnalyticsActionType.FETCH_DATASET_METADATA_FAILED, error),

  // Dataset Gates Actions
  fetchDatasetGates: (datasetId: string) => createAction(AnalyticsActionType.FETCH_DATASET_GATES, datasetId),
  fetchDatasetGatesSucceeded: (datasetGates: DatasetGate[]) =>
    createAction(AnalyticsActionType.FETCH_DATASET_GATES_SUCCEEDED, {
      datasetGates,
    }),
  fetchDatasetGatesFailed: (error: ResponseError) =>
    createAction(AnalyticsActionType.FETCH_DATASET_GATES_FAILED, error),

  // Gate Details Actions
  fetchGateDetails: (datasetId: string, config: any) =>
    createAction(AnalyticsActionType.FETCH_GATE_DETAILS, { datasetId, config }),
  fetchGateDetailsSucceeded: (gateDetails: any) =>
    createAction(AnalyticsActionType.FETCH_GATE_DETAILS_SUCCEEDED, {
      gateDetails,
    }),
  fetchGateDetailsFailed: (error: ResponseError) => createAction(AnalyticsActionType.FETCH_GATE_DETAILS_FAILED, error),

  // Zone Details Actions
  fetchZoneDetails: (config: ZoneDetailsArguments) => createAction(AnalyticsActionType.FETCH_ZONE_DETAILS, config),
  fetchZoneDetailsSucceeded: (zoneDetails: ZoneDetails) =>
    createAction(AnalyticsActionType.FETCH_ZONE_DETAILS_SUCCEEDED, {
      zoneDetails,
    }),
  fetchZoneDetailsFailed: (error: ResponseError) => createAction(AnalyticsActionType.FETCH_ZONE_DETAILS_FAILED, error),

  // Zone Counts Actions
  fetchODCounts: (levels: string[], config: ODCountsArguments) =>
    createAction(AnalyticsActionType.FETCH_ZONE_COUNTS, { levels, config }),
  fetchODCountsSucceeded: (ODCounts: Counts) =>
    createAction(AnalyticsActionType.FETCH_ZONE_COUNTS_SUCCEEDED, {
      ODCounts,
    }),
  fetchODCountsFailed: (error: ResponseError) => createAction(AnalyticsActionType.FETCH_ZONE_COUNTS_FAILED, error),

  // Zone Ids Actions
  fetchODIds: (levels: string[], config: ZoneIdsArguments) =>
    createAction(AnalyticsActionType.FETCH_ZONE_IDS, { levels, config }),
  fetchODIdsSucceeded: (zoneIds: ZoneIds) =>
    createAction(AnalyticsActionType.FETCH_ZONE_IDS_SUCCEEDED, {
      zoneIds,
    }),
  fetchODIdsFailed: (error: ResponseError) => createAction(AnalyticsActionType.FETCH_ZONE_IDS_FAILED, error),

  fetchODCountsByZoneId: (config: ODCountsByZoneIdArguments) =>
    createAction(AnalyticsActionType.FETCH_ZONE_COUNTS_BY_ZONE_ID, config),
  fetchODCountsByZoneIdSucceeded: (ODCounts: CountsByZoneId) =>
    createAction(AnalyticsActionType.FETCH_ZONE_COUNTS_BY_ZONE_ID_SUCCEEDED, {
      ODCounts,
    }),
  fetchODCountsByZoneIdFailed: (error: ResponseError) =>
    createAction(AnalyticsActionType.FETCH_ZONE_COUNTS_BY_ZONE_ID_FAILED, error),

  // OD Metadata Actions
  fetchODMetadata: (config: ODMetadataArguments) => createAction(AnalyticsActionType.FETCH_OD_METADATA, config),
  fetchODMetadataSucceeded: (ODMetadata: ODMetadata) =>
    createAction(AnalyticsActionType.FETCH_OD_METADATA_SUCCEEDED, {
      ODMetadata,
    }),
  fetchODMetadataFailed: (error: ResponseError) => createAction(AnalyticsActionType.FETCH_OD_METADATA_FAILED, error),

  // Dataset Editor Subarea Actions
  fetchSubareaState: (config: SubareaStateArguments) => createAction(AnalyticsActionType.FETCH_SUBAREA_STATE, config),
  fetchSubareaStateSucceeded: (subareaState: SubareaState) =>
    createAction(AnalyticsActionType.FETCH_SUBAREA_STATE_SUCCEEDED, {
      subareaState,
    }),
  fetchSubareaStateFailed: (error: ResponseError) =>
    createAction(AnalyticsActionType.FETCH_SUBAREA_STATE_FAILED, error),
  clearSubareaState: () => createAction(AnalyticsActionType.CLEAR_SUBAREA_STATE),

  // Dataset Editor Subarea Polygon
  fetchSubareaPolygon: (config: SubareaPolygonArguments) =>
    createAction(AnalyticsActionType.FETCH_SUBAREA_POLYGON, config),
  fetchSubareaPolygonSucceeded: () => createAction(AnalyticsActionType.FETCH_SUBAREA_POLYGON_SUCCEEDED),
  fetchSubareaPolygonFailed: (error: ResponseError) =>
    createAction(AnalyticsActionType.FETCH_SUBAREA_POLYGON_FAILED, error),
  setSubAreaPolygon: (polygon: Geometry | null) => createAction(AnalyticsActionType.SET_SUBAREA_POLYGON, polygon),

  // Dataset Editor Generate Gates
  fetchGeneratedGates: (config: GenerateGatesArguments) =>
    createAction(AnalyticsActionType.FETCH_GENERATED_GATES, config),
  fetchGenerateGatesSucceeded: (gates: Gate[]) =>
    createAction(AnalyticsActionType.FETCH_GENERATED_GATES_SUCCEEDED, {
      gates,
    }),
  fetchGenerateGatesFailed: (error: ResponseError) =>
    createAction(AnalyticsActionType.FETCH_GENERATED_GATES_FAILED, error),

  // Dataset Editor Add Gate
  fetchAddedGate: (config: AddGateArguments) => createAction(AnalyticsActionType.FETCH_ADDED_GATE, config),
  fetchAddedGateSucceeded: (addGateResponse: AddGateResponse) =>
    createAction(AnalyticsActionType.FETCH_ADDED_GATE_SUCCEEDED, addGateResponse),
  fetchAddedGateFailed: (error: ResponseError) => createAction(AnalyticsActionType.FETCH_ADDED_GATE_FAILED, error),
  toggleLockGate: (gateId: string) => createAction(AnalyticsActionType.TOGGLE_LOCK_GATE, gateId),
  clearNewGate: () => createAction(AnalyticsActionType.CLEAR_NEW_GATE),
  // Dataset Editor Delete Gate
  deleteGate: (id: string) => createAction(AnalyticsActionType.DELETE_GATE, id),
  deleteAllGates: () => createAction(AnalyticsActionType.DELETE_ALL_GATES),

  // Dataset Editor Add Segments
  addGateSegments: (gateId: string, newSegments: GateSegmentProps[], config: GateCoordinatesArguments) =>
    createAction(AnalyticsActionType.ADD_GATE_SEGMENTS, {
      gateId,
      newSegments,
      config,
    }),
  addGateSegmentsSucceeded: (gateId: string, gateCoordinates: GateCoordinates, newSegments: GateSegment[]) =>
    createAction(AnalyticsActionType.ADD_GATE_SEGMENTS_SUCCEEDED, {
      gateId,
      gateCoordinates,
      newSegments,
    }),
  addGateSegmentsFailed: (error: ResponseError) => createAction(AnalyticsActionType.ADD_GATE_SEGMENTS_FAILED, error),

  // Dataset Editor Delete Segments
  deleteGateSegments: (gateId: string, segmentsToDeleteIds: string[], config: GateCoordinatesArguments) =>
    createAction(AnalyticsActionType.DELETE_GATE_SEGMENTS, {
      gateId,
      segmentsToDeleteIds,
      config,
    }),
  deleteGateSegmentsSucceeded: (gateId: string, gateCoordinates: GateCoordinates, segmentsToDeleteIds: string[]) =>
    createAction(AnalyticsActionType.DELETE_GATE_SEGMENTS_SUCCEEDED, {
      gateId,
      gateCoordinates,
      segmentsToDeleteIds,
    }),
  deleteGateSegmentsFailed: (error: ResponseError) =>
    createAction(AnalyticsActionType.DELETE_GATE_SEGMENTS_FAILED, error),

  // Dataset Editor Update Description
  updateGateDescription: (gateId: string, description: string) =>
    createAction(AnalyticsActionType.UPDATE_GATE_DESCRIPTION, {
      gateId,
      description,
    }),
  // Dataset Editor Config Actions
  fetchODDatasetConfig: (datasetId: UniqueIdentifier) =>
    createAction(AnalyticsActionType.FETCH_OD_DATASET_CONFIG, datasetId),
  fetchODDatasetConfigSucceeded: (config: ODDatasetConfig) =>
    createAction(AnalyticsActionType.FETCH_OD_DATASET_CONFIG_SUCCEEDED, {
      config,
    }),
  fetchODDatasetConfigFailed: (error: ResponseError) =>
    createAction(AnalyticsActionType.FETCH_OD_DATASET_CONFIG_FAILED, error),

  updateODDatasetConfig: (datasetId: string, updatedConfig: UpdateODDatasetConfigPayload) =>
    createAction(AnalyticsActionType.UPDATE_OD_DATASET_CONFIG, {
      datasetId,
      updatedConfig,
    }),
  updateODDatasetConfigSucceeded: (config: ODDatasetConfig) =>
    createAction(AnalyticsActionType.UPDATE_OD_DATASET_CONFIG_SUCCEEDED, {
      config,
    }),
  updateDDatasetConfigFailed: (error: ResponseError) =>
    createAction(AnalyticsActionType.UPDATE_OD_DATASET_CONFIG_FAILED, error),

  createODDatasetConfig: (newConfig: NewODDatasetConfigPayload) =>
    createAction(AnalyticsActionType.CREATE_OD_DATASET_CONFIG, newConfig),
  createODDatasetConfigSucceeded: (config: ODDatasetConfig) =>
    createAction(AnalyticsActionType.CREATE_OD_DATASET_CONFIG_SUCCEEDED, {
      config,
    }),
  createODDatasetConfigFailed: (error: ResponseError) =>
    createAction(AnalyticsActionType.CREATE_OD_DATASET_CONFIG_FAILED, error),
  clearNewODDatasetConfig: () => createAction(AnalyticsActionType.CLEAR_NEW_OD_DATASET_CONFIG),

  // Roads Metadata Actions
  fetchRoadsMetadata: (config: RoadsMetadataArguments) =>
    createAction(AnalyticsActionType.FETCH_ROADS_METADATA, config),
  fetchRoadsMetadataSucceeded: (roadsMetadata: RoadsMetadata) =>
    createAction(AnalyticsActionType.FETCH_ROADS_METADATA_SUCCEEDED, {
      roadsMetadata,
    }),
  fetchRoadsMetadataFailed: (error: ResponseError) =>
    createAction(AnalyticsActionType.FETCH_ROADS_METADATA_FAILED, error),

  // Roads Volumes Actions
  fetchRoadsVolumes: (config: RoadsVolumesArguments) => createAction(AnalyticsActionType.FETCH_ROADS_VOLUMES, config),
  fetchRoadsVolumesSucceeded: (roadsVolumes: RoadsVolumes) =>
    createAction(AnalyticsActionType.FETCH_ROADS_VOLUMES_SUCCEEDED, {
      roadsVolumes,
    }),
  fetchRoadsVolumesFailed: (error: ResponseError) =>
    createAction(AnalyticsActionType.FETCH_ROADS_VOLUMES_FAILED, error),

  // Segment Ids Actions
  fetchSegmentIds: (config: RoadSegmentIdsArguments) =>
    createAction(AnalyticsActionType.FETCH_ROAD_SEGMENT_IDS, config),
  fetchSegmentIdsSucceeded: (roadSegmentIds: RoadSegmentIdsWithFactype) =>
    createAction(AnalyticsActionType.FETCH_ROAD_SEGMENT_IDS_SUCCEEDED, {
      roadSegmentIds,
    }),
  fetchSegmentIdsFailed: (error: ResponseError) =>
    createAction(AnalyticsActionType.FETCH_ROAD_SEGMENT_IDS_FAILED, error),

  // Segment Details Actions
  fetchSegmentsDetails: (config: RoadSegmentsDetailsArguments) =>
    createAction(AnalyticsActionType.FETCH_ROAD_SEGMENTS_DETAILS, config),
  fetchSegmentsDetailsSucceeded: (roadSegmentsDetails: Record<string, RoadSegmentDetails>) =>
    createAction(AnalyticsActionType.FETCH_ROAD_SEGMENTS_DETAILS_SUCCEEDED, {
      roadSegmentsDetails,
    }),
  fetchSegmentsDetailsFailed: (error: ResponseError) =>
    createAction(AnalyticsActionType.FETCH_ROAD_SEGMENTS_DETAILS_FAILED, error),

  setMeasure: (measure: MeasureType) => createAction(AnalyticsActionType.SET_MEASURE, measure),

  setQueryType: (type: QueryType) => createAction(AnalyticsActionType.SET_QUERY_TYPE, type),

  updateCurrentODFilters: (filters: FiltersType | null) =>
    createAction(AnalyticsActionType.UPDATE_CURRENT_OD_FILTERS, filters),

  updateCurrentRoadFilters: (filters: FiltersType | null) =>
    createAction(AnalyticsActionType.UPDATE_CURRENT_ROAD_FILTERS, filters),

  updateCurrentDatasetFilters: (filters: FiltersType | null) =>
    createAction(AnalyticsActionType.UPDATE_CURRENT_DATASET_FILTERS, filters),

  clearFilters: () => createAction(AnalyticsActionType.CLEAR_FILTERS),

  // Validate the persisted configuration
  validateODDatasetConfig: (datasetConfigId: string) =>
    createAction(AnalyticsActionType.VALIDATE_OD_DATASET_CONFIG, {
      datasetConfigId,
    }),
  validateODDatasetConfigSucceeded: (validation: ODDatasetValidation) =>
    createAction(AnalyticsActionType.VALIDATE_OD_DATASET_CONFIG_SUCCEEDED, {
      validation,
    }),
  validateODDatasetConfigFailed: (error: ResponseError) =>
    createAction(AnalyticsActionType.VALIDATE_OD_DATASET_CONFIG_FAILED, error),

  // Compute OD Dataset
  computeODDataset: (datasetConfigId: string, notifyByEmail: boolean, navigate: NavigateFunction) =>
    createAction(AnalyticsActionType.COMPUTE_OD_DATASET, {
      datasetConfigId,
      notifyByEmail,
      navigate,
    }),
  computeODDatasetSucceeded: (datasetId: string) =>
    createAction(AnalyticsActionType.COMPUTE_OD_DATASET_SUCCEEDED, {
      datasetId,
    }),
  computeODDatasetFailed: (error: ResponseError) => createAction(AnalyticsActionType.COMPUTE_OD_DATASET_FAILED, error),

  // Cancel OD Dataset Computation
  cancelODDatasetComputation: (datasetConfigId: string) =>
    createAction(AnalyticsActionType.CANCEL_OD_DATASET_COMPUTATION, {
      datasetConfigId,
    }),
  cancelODDatasetComputationSucceeded: (datasetConfigId: string) =>
    createAction(AnalyticsActionType.CANCEL_OD_DATASET_COMPUTATION_SUCCEEDED, {
      datasetConfigId,
    }),
  cancelODDatasetComputationFailed: (error: ResponseError) =>
    createAction(AnalyticsActionType.CANCEL_OD_DATASET_COMPUTATION_FAILED, error),
  fetchGeocodingSearchResults: (query: string, token: string, proximity: string) =>
    createAction(AnalyticsActionType.FETCH_GEOCODING_SEARCH_RESULTS, {
      query,
      token,
      proximity,
    }),
  fetchGeocodingSearchResultsSucceeded: (searchResults: GeocodingSearchResults) =>
    createAction(AnalyticsActionType.FETCH_GEOCODING_SEARCH_RESULTS_SUCCEEDED, searchResults),
  fetchGeocodingSearchResultsFailed: (error: ResponseError) =>
    createAction(AnalyticsActionType.FETCH_GEOCODING_SEARCH_RESULTS_FAILED, error),
  clearGeocodingSearchResults: () => createAction(AnalyticsActionType.CLEAR_GEOCODING_SEARCH_RESULTS),
  setSelectedZone: (selectedZone: SelectedArea | null) =>
    createAction(AnalyticsActionType.SET_SELECTED_ZONE, selectedZone),
  setSelectedRoadVolume: (selectedRoadVolume: SelectedVolume | null) =>
    createAction(AnalyticsActionType.SET_SELECTED_ROAD_VOLUME, selectedRoadVolume),
  setSelectedRoadVolumeId: (selectedRoadVolumeId: string | null) =>
    createAction(AnalyticsActionType.SET_SELECTED_ROAD_VOLUME_ID, selectedRoadVolumeId),
  fetchFocusAreasAndDatasets: () => createAction(AnalyticsActionType.FETCH_FOCUS_AREAS_AND_DATASETS),
  fetchFocusAreasAndDatasetsSucceeded: (focusAreasAndDatasets: FocusAreaItem[]) =>
    createAction(AnalyticsActionType.FETCH_FOCUS_AREAS_AND_DATASETS_SUCCEEDED, {
      focusAreasAndDatasets,
    }),
  fetchFocusAreasAndDatasetsFailed: (error: ResponseError) =>
    createAction(AnalyticsActionType.FETCH_FOCUS_AREAS_AND_DATASETS_FAILED, error),
  fetchFocusAreas: (customZoningId?: string | null, forDatasetCreation?: boolean) =>
    createAction(AnalyticsActionType.FETCH_FOCUS_AREAS, { customZoningId, forDatasetCreation }),
  fetchFocusAreasSucceeded: (focusAreas: FocusAreaItem[]) =>
    createAction(AnalyticsActionType.FETCH_FOCUS_AREAS_SUCCEEDED, {
      focusAreas,
    }),
  fetchFocusAreasFailed: (error: ResponseError) => createAction(AnalyticsActionType.FETCH_FOCUS_AREAS_FAILED, error),

  fetchODMeasureRange: (levels: string[], config: MeasureRangeRequest) =>
    createAction(AnalyticsActionType.FETCH_OD_MEASURE_RANGE, { levels, config }),
  fetchODMeasureRangeSucceeded: (measureRange: ODMeasureRange) =>
    createAction(AnalyticsActionType.FETCH_OD_MEASURE_RANGE_SUCCEEDED, measureRange),
  fetchODMeasureRangeFailed: (error: ResponseError) =>
    createAction(AnalyticsActionType.FETCH_OD_MEASURE_RANGE_FAILED, error),

  fetchDatasetMeasureRange: (datasetId: string, levels: string[], config: MeasureRangeRequest) =>
    createAction(AnalyticsActionType.FETCH_DATASET_MEASURE_RANGE, { datasetId, levels, config }),
  fetchDatasetMeasureRangeSucceeded: (measureRange: ODMeasureRange) =>
    createAction(AnalyticsActionType.FETCH_DATASET_MEASURE_RANGE_SUCCEEDED, measureRange),
  fetchDatasetMeasureRangeFailed: (error: ResponseError) =>
    createAction(AnalyticsActionType.FETCH_DATASET_MEASURE_RANGE_FAILED, error),

  fetchRoadsMeasureRange: (config: MeasureRangeRequest) =>
    createAction(AnalyticsActionType.FETCH_ROADS_MEASURE_RANGE, { config }),
  fetchRoadsMeasureRangeSucceeded: (measureRange: MeasureRange) =>
    createAction(AnalyticsActionType.FETCH_ROADS_MEASURE_RANGE_SUCCEEDED, measureRange),
  fetchRoadsMeasureRangeFailed: (error: ResponseError) =>
    createAction(AnalyticsActionType.FETCH_ROADS_MEASURE_RANGE_FAILED, error),

  setODRange: (zoningLevelRange: { [key: string]: [number, number] } | null) =>
    createAction(AnalyticsActionType.SET_OD_RANGE, zoningLevelRange),
  setDatasetRange: (zoningLevelRange: { [key: string]: [number, number] } | null) =>
    createAction(AnalyticsActionType.SET_DATASET_RANGE, zoningLevelRange),
  setRoadsRange: (range: [number, number] | null) => createAction(AnalyticsActionType.SET_ROADS_RANGE, range),
  setODMeasureRangeByZone: (measureRange: MeasureRange) =>
    createAction(AnalyticsActionType.SET_OD_MEASURE_RANGE_BY_ZONE, measureRange),
  setODRangeByZone: (range: [number, number] | null) => createAction(AnalyticsActionType.SET_OD_RANGE_BY_ZONE, range),
  setMapVisualizationMode: (mode: MapVisualizationMode) =>
    createAction(AnalyticsActionType.SET_MAP_VISUALIZATION_MODE, mode),
};

const initialState: AnalyticsState = {
  authorizationTokenLoaded: false,
  datasetCounts: {
    state: DataState.EMPTY,
    data: null,
    error: null,
  },
  datasetIds: {
    state: DataState.EMPTY,
    data: null,
    error: null,
  },
  datasetCountsByZoneId: {
    state: DataState.EMPTY,
    data: null,
    error: null,
  },
  datasetMetadata: {
    state: DataState.EMPTY,
    data: null,
    error: null,
  },
  datasetGates: {
    state: DataState.EMPTY,
    data: null,
    error: null,
  },
  gateDetails: {
    state: DataState.EMPTY,
    data: null,
    error: null,
  },
  zoneDetails: {
    state: DataState.EMPTY,
    data: null,
    error: null,
  },
  ODCounts: {
    state: DataState.EMPTY,
    data: null,
    error: null,
  },
  ODIds: {
    state: DataState.EMPTY,
    data: null,
    error: null,
  },
  ODCountsByZoneId: {
    state: DataState.EMPTY,
    data: null,
    error: null,
  },
  ODMetadata: {
    state: DataState.EMPTY,
    data: null,
    error: null,
  },
  subareaState: {
    state: DataState.EMPTY,
    data: null,
    error: null,
  },
  newGate: {
    state: DataState.EMPTY,
    data: null,
    error: null,
  },
  roadsMetadata: {
    state: DataState.EMPTY,
    data: null,
    error: null,
  },
  roadsVolumes: {
    state: DataState.EMPTY,
    data: null,
    error: null,
  },
  roadSegmentIds: {
    state: DataState.EMPTY,
    data: null,
    error: null,
  },
  reverseRoadSegmentIds: {},
  roadSegmentIdsByRoadClass: null,
  roadSegmentsDetails: {
    state: DataState.EMPTY,
    data: null,
    error: null,
  },
  ODDatasetConfig: {
    state: DataState.EMPTY,
    data: null,
    error: null,
  },
  ODDatasetConfigValidation: {
    state: DataState.EMPTY,
    data: null,
    error: null,
  },
  geocodingSearch: {
    state: DataState.EMPTY,
    data: null,
    error: null,
  },
  focusAreasAndDatasets: {
    state: DataState.EMPTY,
    data: null,
    error: null,
  },
  focusAreas: {
    state: DataState.EMPTY,
    data: null,
    error: null,
  },
  ODMeasureRange: {
    state: DataState.EMPTY,
    data: null,
    error: null,
  },
  datasetMeasureRange: {
    state: DataState.EMPTY,
    data: null,
    error: null,
  },
  roadsMeasureRange: {
    state: DataState.EMPTY,
    data: null,
    error: null,
  },
  savedODDatasetConfig: null,
  newODDatasetConfig: null,
  loadingGateSegments: false,
  loadingGeneratedGates: false,
  loadingSubareaPolygon: false,
  measure: MeasureType.AADT,
  queryType: QueryType.INCOMING,
  ODFilters: null,
  roadFilters: null,
  datasetFilters: null,
  selectedZone: null,
  selectedRoadVolume: null,
  selectedRoadVolumeId: null,
  ODRange: null,
  datasetRange: null,
  roadsRange: null,
  ODMeasureRangeByZone: null,
  ODRangeByZone: null,
  mapVisualizationMode: null,
};

const reducer: Reducer<AnalyticsState, AnalyticsAction> = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case AnalyticsActionType.SET_AUTHORIZATION_TOKEN_LOADED: {
        draft.authorizationTokenLoaded = action.payload;
        return;
      }
      case AnalyticsActionType.COMPUTE_OD_DATASET:
      case AnalyticsActionType.COMPUTE_OD_DATASET_SUCCEEDED:
      case AnalyticsActionType.COMPUTE_OD_DATASET_FAILED:
      case AnalyticsActionType.CANCEL_OD_DATASET_COMPUTATION:
      case AnalyticsActionType.CANCEL_OD_DATASET_COMPUTATION_SUCCEEDED:
      case AnalyticsActionType.CANCEL_OD_DATASET_COMPUTATION_FAILED:
      case AnalyticsActionType.CLEAR_DATASET_METADATA: {
        draft.datasetMetadata = initialState.datasetMetadata;
        draft.datasetCounts = initialState.datasetCounts;
        draft.datasetIds = initialState.datasetIds;
        draft.datasetCountsByZoneId = initialState.datasetCountsByZoneId;
        draft.datasetGates = initialState.datasetGates;
        draft.gateDetails = initialState.gateDetails;
        draft.zoneDetails = initialState.zoneDetails;
        draft.ODMetadata = initialState.ODMetadata;
        draft.ODCounts = initialState.ODCounts;
        draft.ODIds = initialState.ODIds;
        draft.ODCountsByZoneId = initialState.ODCountsByZoneId;
        draft.subareaState = initialState.subareaState;
        draft.newGate = initialState.newGate;
        draft.roadsMetadata = initialState.roadsMetadata;
        draft.roadsVolumes = initialState.roadsVolumes;
        draft.roadSegmentIds = initialState.roadSegmentIds;
        draft.reverseRoadSegmentIds = initialState.reverseRoadSegmentIds;
        draft.roadSegmentIdsByRoadClass = initialState.roadSegmentIdsByRoadClass;
        draft.roadSegmentsDetails = initialState.roadSegmentsDetails;
        draft.ODDatasetConfig = initialState.ODDatasetConfig;
        draft.ODDatasetConfigValidation = initialState.ODDatasetConfigValidation;
        draft.savedODDatasetConfig = initialState.savedODDatasetConfig;
        draft.newODDatasetConfig = initialState.newODDatasetConfig;
        draft.selectedZone = initialState.selectedZone;
        draft.ODMeasureRange = initialState.ODMeasureRange;
        draft.ODRange = initialState.ODRange;
        draft.datasetMeasureRange = initialState.datasetMeasureRange;
        draft.datasetRange = initialState.datasetRange;
        draft.roadsMeasureRange = initialState.roadsMeasureRange;
        draft.roadsRange = initialState.roadsRange;
        draft.ODMeasureRangeByZone = initialState.ODMeasureRangeByZone;
        draft.ODRangeByZone = initialState.ODRangeByZone;
        draft.mapVisualizationMode = initialState.mapVisualizationMode;
        return;
      }
      case AnalyticsActionType.CLEAR_FILTERS: {
        draft.measure = initialState.measure;
        draft.queryType = initialState.queryType;
        draft.ODFilters = initialState.ODFilters;
        draft.roadFilters = initialState.roadFilters;
        draft.datasetFilters = initialState.datasetFilters;
        return;
      }
      case AnalyticsActionType.FETCH_DATASET_IDS: {
        draft.datasetIds = {
          state: DataState.LOADING,
          data: null,
          error: null,
        };
        return;
      }
      case AnalyticsActionType.FETCH_DATASET_IDS_SUCCEEDED: {
        draft.datasetIds = {
          state: DataState.AVAILABLE,
          data: action.payload.datasetIds,
          error: null,
        };
        return;
      }
      case AnalyticsActionType.FETCH_DATASET_IDS_FAILED: {
        draft.datasetIds = {
          state: DataState.ERROR,
          error: action.payload,
          data: null,
        };
        return;
      }
      case AnalyticsActionType.FETCH_DATASET_COUNTS: {
        draft.datasetMeasureRange = initialState.datasetMeasureRange;
        draft.datasetCounts = {
          state: DataState.LOADING,
          data: null,
          error: null,
        };
        return;
      }
      case AnalyticsActionType.FETCH_DATASET_COUNTS_SUCCEEDED: {
        draft.datasetCounts = {
          state: DataState.AVAILABLE,
          data: action.payload.datasetCounts,
          error: null,
        };
        return;
      }
      case AnalyticsActionType.FETCH_DATASET_COUNTS_FAILED: {
        draft.datasetCounts = {
          state: DataState.ERROR,
          error: action.payload,
          data: null,
        };
        return;
      }
      case AnalyticsActionType.FETCH_DATASET_COUNTS_BY_ZONE_ID: {
        draft.ODMeasureRangeByZone = initialState.ODMeasureRangeByZone;
        draft.datasetCountsByZoneId = {
          state: DataState.LOADING,
          data: null,
          error: null,
        };
        return;
      }
      case AnalyticsActionType.FETCH_DATASET_COUNTS_BY_ZONE_ID_SUCCEEDED: {
        draft.datasetCountsByZoneId = {
          state: DataState.AVAILABLE,
          data: action.payload.datasetCounts,
          error: null,
        };

        return;
      }
      case AnalyticsActionType.FETCH_DATASET_COUNTS_BY_ZONE_ID_FAILED: {
        draft.datasetCountsByZoneId = {
          state: DataState.ERROR,
          error: action.payload,
          data: null,
        };
        return;
      }
      case AnalyticsActionType.FETCH_DATASET_METADATA: {
        draft.datasetMetadata = {
          state: DataState.LOADING,
          data: null,
          error: null,
        };
        return;
      }
      case AnalyticsActionType.FETCH_DATASET_METADATA_SUCCEEDED: {
        draft.datasetMetadata = {
          state: DataState.AVAILABLE,
          data: action.payload.datasetMetadata,
          error: null,
        };
        return;
      }
      case AnalyticsActionType.FETCH_DATASET_METADATA_FAILED: {
        if (action.payload.status === 403) draft.mapVisualizationMode = MapVisualizationType.ROADS;

        draft.datasetMetadata = {
          state: DataState.ERROR,
          error: action.payload,
          data: null,
        };
        return;
      }
      case AnalyticsActionType.FETCH_DATASET_GATES: {
        draft.datasetGates = {
          state: DataState.LOADING,
          data: null,
          error: null,
        };
        return;
      }
      case AnalyticsActionType.FETCH_DATASET_GATES_SUCCEEDED: {
        draft.datasetGates = {
          state: DataState.AVAILABLE,
          data: action.payload.datasetGates,
          error: null,
        };
        return;
      }
      case AnalyticsActionType.FETCH_DATASET_GATES_FAILED: {
        draft.datasetGates = {
          state: DataState.ERROR,
          error: action.payload,
          data: null,
        };
        return;
      }
      case AnalyticsActionType.FETCH_GATE_DETAILS: {
        draft.zoneDetails = {
          state: DataState.LOADING,
          data: null,
          error: null,
        };
        return;
      }
      case AnalyticsActionType.FETCH_GATE_DETAILS_SUCCEEDED: {
        draft.zoneDetails = {
          state: DataState.AVAILABLE,
          data: action.payload.gateDetails,
          error: null,
        };
        return;
      }
      case AnalyticsActionType.FETCH_GATE_DETAILS_FAILED: {
        draft.zoneDetails = {
          state: DataState.ERROR,
          error: action.payload,
          data: null,
        };
        return;
      }
      case AnalyticsActionType.FETCH_ZONE_DETAILS: {
        draft.zoneDetails = {
          state: DataState.LOADING,
          data: null,
          error: null,
        };
        return;
      }
      case AnalyticsActionType.FETCH_ZONE_DETAILS_SUCCEEDED: {
        draft.zoneDetails = {
          state: DataState.AVAILABLE,
          data: action.payload.zoneDetails,
          error: null,
        };
        return;
      }
      case AnalyticsActionType.FETCH_ZONE_DETAILS_FAILED: {
        draft.zoneDetails = {
          state: DataState.ERROR,
          error: action.payload,
          data: null,
        };
        return;
      }
      case AnalyticsActionType.FETCH_ZONE_IDS: {
        draft.ODIds = {
          state: DataState.LOADING,
          data: null,
          error: null,
        };
        return;
      }
      case AnalyticsActionType.FETCH_ZONE_IDS_SUCCEEDED: {
        draft.ODIds = {
          state: DataState.AVAILABLE,
          data: action.payload.zoneIds,
          error: null,
        };
        return;
      }
      case AnalyticsActionType.FETCH_ZONE_IDS_FAILED: {
        draft.ODIds = {
          state: DataState.ERROR,
          error: action.payload,
          data: null,
        };
        return;
      }
      case AnalyticsActionType.FETCH_ZONE_COUNTS: {
        draft.ODMeasureRange = initialState.ODMeasureRange;
        draft.ODCounts = {
          state: DataState.LOADING,
          data: null,
          error: null,
        };
        return;
      }
      case AnalyticsActionType.FETCH_ZONE_COUNTS_SUCCEEDED: {
        draft.ODCounts = {
          state: DataState.AVAILABLE,
          data: action.payload.ODCounts,
          error: null,
        };
        return;
      }
      case AnalyticsActionType.FETCH_ZONE_COUNTS_FAILED: {
        draft.ODCounts = {
          state: DataState.ERROR,
          error: action.payload,
          data: null,
        };
        return;
      }
      case AnalyticsActionType.FETCH_ZONE_COUNTS_BY_ZONE_ID: {
        draft.ODMeasureRangeByZone = initialState.ODMeasureRangeByZone;
        draft.ODCountsByZoneId = {
          state: DataState.LOADING,
          data: null,
          error: null,
        };
        return;
      }
      case AnalyticsActionType.FETCH_ZONE_COUNTS_BY_ZONE_ID_SUCCEEDED: {
        draft.ODCountsByZoneId = {
          state: DataState.AVAILABLE,
          data: action.payload.ODCounts,
          error: null,
        };

        return;
      }
      case AnalyticsActionType.FETCH_ZONE_COUNTS_BY_ZONE_ID_FAILED: {
        draft.ODCountsByZoneId = {
          state: DataState.ERROR,
          error: action.payload,
          data: null,
        };
        return;
      }
      case AnalyticsActionType.FETCH_OD_METADATA: {
        draft.ODMetadata = {
          state: DataState.LOADING,
          data: null,
          error: null,
        };
        return;
      }
      case AnalyticsActionType.FETCH_OD_METADATA_SUCCEEDED: {
        draft.ODMetadata = {
          state: DataState.AVAILABLE,
          data: action.payload.ODMetadata,
          error: null,
        };
        return;
      }
      case AnalyticsActionType.FETCH_OD_METADATA_FAILED: {
        if (action.payload.status === 403) draft.mapVisualizationMode = MapVisualizationType.ROADS;

        draft.ODMetadata = {
          state: DataState.ERROR,
          error: action.payload,
          data: null,
        };
        return;
      }
      case AnalyticsActionType.FETCH_SUBAREA_STATE: {
        draft.subareaState = {
          state: DataState.LOADING,
          data: null,
          error: null,
        };

        return;
      }
      case AnalyticsActionType.FETCH_SUBAREA_STATE_SUCCEEDED: {
        draft.subareaState = {
          state: DataState.AVAILABLE,
          data: action.payload.subareaState,
          error: null,
        };
        return;
      }
      case AnalyticsActionType.FETCH_SUBAREA_STATE_FAILED: {
        draft.subareaState = {
          state: DataState.ERROR,
          error: action.payload,
          data: null,
        };
        return;
      }
      case AnalyticsActionType.CLEAR_SUBAREA_STATE: {
        draft.subareaState = {
          state: DataState.EMPTY,
          data: null,
          error: null,
        };
        return;
      }
      case AnalyticsActionType.FETCH_SUBAREA_POLYGON: {
        draft.loadingSubareaPolygon = true;
        return;
      }
      case AnalyticsActionType.FETCH_SUBAREA_POLYGON_SUCCEEDED: {
        draft.loadingSubareaPolygon = false;

        return;
      }
      case AnalyticsActionType.FETCH_SUBAREA_POLYGON_FAILED: {
        draft.loadingSubareaPolygon = false;
        return;
      }
      case AnalyticsActionType.SET_SUBAREA_POLYGON: {
        if (draft.ODDatasetConfig.data) draft.ODDatasetConfig.data.subAreaGeometry = action.payload;
        return;
      }
      case AnalyticsActionType.FETCH_GENERATED_GATES: {
        draft.loadingGeneratedGates = true;
        return;
      }
      case AnalyticsActionType.FETCH_GENERATED_GATES_SUCCEEDED: {
        const gates = action.payload.gates;

        const newGeneratedGates = state.ODDatasetConfig.data?.gates
          ? [...state.ODDatasetConfig.data.gates.filter((gate) => gate.pinned), ...gates]
          : gates;

        draft.ODDatasetConfig = {
          state: DataState.AVAILABLE,
          data: {
            ...state.ODDatasetConfig.data,
            gates: newGeneratedGates.sort((a, b) => +a.identifier - +b.identifier),
          } as ODDatasetConfig,
          error: null,
        };

        draft.loadingGeneratedGates = false;

        return;
      }
      case AnalyticsActionType.FETCH_GENERATED_GATES_FAILED: {
        draft.loadingGeneratedGates = false;
        return;
      }
      case AnalyticsActionType.FETCH_ADDED_GATE: {
        draft.newGate = {
          state: DataState.LOADING,
          data: null,
          error: null,
        };

        return;
      }
      case AnalyticsActionType.FETCH_ADDED_GATE_SUCCEEDED: {
        if (!action.payload.addedGate) return;

        const allPinnedGates = state.ODDatasetConfig.data?.gates?.filter((gate) => gate.pinned);

        //Checking if the gate already exists by comparing the segments, and if the already existing gate is pinned.
        const isNewGatePinned = allPinnedGates?.find((gate) => isSameGate(action.payload.addedGate, gate));

        //If there is already a gate containig the same segments and the gate is pinned we don't substitute it with the new gate
        if (isNewGatePinned) return;

        const newGate = { ...action.payload.addedGate, pinned: true };

        const newGates = [...action.payload.updatedGates, ...action.payload.unchangedGates, newGate];

        draft.ODDatasetConfig = {
          state: DataState.AVAILABLE,
          data: {
            ...state.ODDatasetConfig.data,
            gates: newGates.sort((a, b) => +a.identifier - +b.identifier),
          } as ODDatasetConfig,
          error: null,
        };

        draft.newGate = {
          state: DataState.AVAILABLE,
          data: newGate,
          error: null,
        };

        return;
      }
      case AnalyticsActionType.FETCH_ADDED_GATE_FAILED: {
        draft.newGate = {
          state: DataState.ERROR,
          data: null,
          error: action.payload,
        };
        return;
      }
      case AnalyticsActionType.TOGGLE_LOCK_GATE: {
        const newGates = draft.ODDatasetConfig.data?.gates?.map((gate: Gate) =>
          gate.identifier === action.payload ? { ...gate, pinned: !gate.pinned } : gate,
        );
        draft.ODDatasetConfig = {
          state: DataState.AVAILABLE,
          error: null,
          data: {
            ...state.ODDatasetConfig.data,
            gates: newGates as Gate[],
          } as ODDatasetConfig,
        };
        return;
      }
      case AnalyticsActionType.CLEAR_NEW_GATE: {
        draft.newGate = {
          state: DataState.EMPTY,
          data: null,
          error: null,
        };
        return;
      }
      case AnalyticsActionType.DELETE_GATE: {
        const filteredGates = draft.ODDatasetConfig.data?.gates?.filter(
          (gate: Gate) => gate.identifier !== action.payload,
        );
        draft.ODDatasetConfig = filteredGates
          ? {
              state: DataState.AVAILABLE,
              error: null,
              data: {
                ...state.ODDatasetConfig.data,
                gates: filteredGates,
              } as ODDatasetConfig,
            }
          : {
              state: DataState.AVAILABLE,
              error: null,
              data: {
                ...state.ODDatasetConfig.data,
                gates: null,
              } as ODDatasetConfig,
            };
        return;
      }
      case AnalyticsActionType.DELETE_ALL_GATES: {
        draft.ODDatasetConfig = {
          state: DataState.AVAILABLE,
          error: null,
          data: {
            ...state.ODDatasetConfig.data,
            gates: [],
          } as ODDatasetConfig,
        };
        return;
      }
      case AnalyticsActionType.ADD_GATE_SEGMENTS:
      case AnalyticsActionType.DELETE_GATE_SEGMENTS: {
        draft.loadingGateSegments = true;
        return;
      }
      case AnalyticsActionType.ADD_GATE_SEGMENTS_SUCCEEDED: {
        const { gateId, gateCoordinates } = action.payload;
        const newSegments = action.payload.newSegments.map((segment) => ({
          ...segment,
          from: gateCoordinates.segmentNodes?.[segment.id].from,
          to: gateCoordinates.segmentNodes?.[segment.id].to,
        }));
        const newGeneratedGates = draft.ODDatasetConfig.data?.gates?.map((gate) =>
          gate.identifier === gateId
            ? {
                ...gate,
                lat: gateCoordinates.lat,
                lon: gateCoordinates.lon,
                segments: [...gate.segments, ...newSegments],
              }
            : gate,
        ) as Gate[];

        draft.ODDatasetConfig = {
          state: DataState.AVAILABLE,
          error: null,
          data: {
            ...state.ODDatasetConfig.data,
            gates: newGeneratedGates,
          } as ODDatasetConfig,
        };

        draft.loadingGateSegments = false;

        return;
      }
      case AnalyticsActionType.DELETE_GATE_SEGMENTS_SUCCEEDED: {
        const filteredGatesSegments = draft.ODDatasetConfig.data?.gates?.map((gate) =>
          gate.identifier === action.payload.gateId
            ? {
                ...gate,
                lat: action.payload.gateCoordinates.lat,
                lon: action.payload.gateCoordinates.lon,
                segments: gate.segments.filter((segment) => !action.payload.segmentsToDeleteIds.includes(segment.id)),
              }
            : gate,
        ) as Gate[];

        draft.ODDatasetConfig = filteredGatesSegments
          ? {
              state: DataState.AVAILABLE,
              error: null,
              data: {
                ...state.ODDatasetConfig.data,
                gates: filteredGatesSegments,
              } as ODDatasetConfig,
            }
          : {
              state: DataState.AVAILABLE,
              error: null,
              data: {
                ...state.ODDatasetConfig.data,
                gates: null,
              } as ODDatasetConfig,
            };

        draft.loadingGateSegments = false;
        return;
      }
      case AnalyticsActionType.ADD_GATE_SEGMENTS_FAILED:
      case AnalyticsActionType.DELETE_GATE_SEGMENTS_FAILED: {
        draft.loadingGateSegments = false;
        return;
      }
      case AnalyticsActionType.UPDATE_GATE_DESCRIPTION: {
        const newGeneratedGates = draft.ODDatasetConfig.data?.gates?.map((gate) =>
          gate.identifier === action.payload.gateId
            ? {
                ...gate,
                description: action.payload.description,
              }
            : gate,
        ) as Gate[];

        if (draft.ODDatasetConfig.state === DataState.AVAILABLE && newGeneratedGates) {
          draft.ODDatasetConfig.data = {
            ...state.ODDatasetConfig.data,
            gates: newGeneratedGates,
          } as ODDatasetConfig;
        }

        return;
      }
      case AnalyticsActionType.FETCH_OD_DATASET_CONFIG: {
        draft.ODDatasetConfig.state = DataState.LOADING;
        draft.ODDatasetConfig.error = null;
        return;
      }
      case AnalyticsActionType.CREATE_OD_DATASET_CONFIG:
      case AnalyticsActionType.UPDATE_OD_DATASET_CONFIG: {
        draft.ODDatasetConfig.state = DataState.LOADING;
        return;
      }
      case AnalyticsActionType.CREATE_OD_DATASET_CONFIG_SUCCEEDED: {
        if (
          !state.savedODDatasetConfig ||
          state.savedODDatasetConfig.datasetId !== action.payload.config.datasetId ||
          state.savedODDatasetConfig.updatedAt !== action.payload.config.updatedAt
        ) {
          draft.savedODDatasetConfig = action.payload.config;
        }
        draft.newODDatasetConfig = action.payload.config;
        draft.ODDatasetConfig = {
          state: DataState.AVAILABLE,
          data: action.payload.config,
          error: null,
        };
        return;
      }
      case AnalyticsActionType.FETCH_OD_DATASET_CONFIG_SUCCEEDED:
      case AnalyticsActionType.UPDATE_OD_DATASET_CONFIG_SUCCEEDED: {
        if (
          !state.savedODDatasetConfig ||
          state.savedODDatasetConfig.datasetId !== action.payload.config.datasetId ||
          state.savedODDatasetConfig.updatedAt !== action.payload.config.updatedAt
        ) {
          draft.savedODDatasetConfig = action.payload.config;
        }
        draft.ODDatasetConfig = {
          state: DataState.AVAILABLE,
          data: action.payload.config,
          error: null,
        };

        return;
      }
      case AnalyticsActionType.FETCH_OD_DATASET_CONFIG_FAILED: {
        return;
      }
      case AnalyticsActionType.UPDATE_OD_DATASET_CONFIG_FAILED:
      case AnalyticsActionType.CREATE_OD_DATASET_CONFIG_FAILED: {
        draft.ODDatasetConfig.state = DataState.ERROR;
        draft.ODDatasetConfig.error = action.payload;
        return;
      }
      case AnalyticsActionType.CLEAR_NEW_OD_DATASET_CONFIG: {
        draft.newODDatasetConfig = null;
        return;
      }

      case AnalyticsActionType.FETCH_ROADS_METADATA: {
        draft.roadsMetadata = {
          state: DataState.LOADING,
          data: null,
          error: null,
        };
        return;
      }
      case AnalyticsActionType.FETCH_ROADS_METADATA_SUCCEEDED: {
        draft.roadsMetadata = {
          state: DataState.AVAILABLE,
          data: action.payload.roadsMetadata,
          error: null,
        };
        return;
      }
      case AnalyticsActionType.FETCH_ROADS_METADATA_FAILED: {
        if (action.payload.status === 403) draft.mapVisualizationMode = MapVisualizationType.OD;

        draft.roadsMetadata = {
          state: DataState.ERROR,
          error: action.payload,
          data: null,
        };
        return;
      }
      case AnalyticsActionType.FETCH_ROADS_VOLUMES: {
        draft.roadsMeasureRange = initialState.roadsMeasureRange;
        draft.roadsVolumes = {
          state: DataState.LOADING,
          data: null,
          error: null,
        };
        return;
      }
      case AnalyticsActionType.FETCH_ROADS_VOLUMES_SUCCEEDED: {
        setAutoFreeze(false);
        setUseStrictShallowCopy(false);
        draft.roadsVolumes = {
          state: DataState.AVAILABLE,
          data: action.payload.roadsVolumes,
          error: null,
        };

        return;
      }
      case AnalyticsActionType.FETCH_ROADS_VOLUMES_FAILED: {
        draft.roadsVolumes = {
          state: DataState.ERROR,
          error: action.payload,
          data: null,
        };
        return;
      }
      case AnalyticsActionType.FETCH_ROAD_SEGMENT_IDS: {
        draft.roadSegmentIds = {
          state: DataState.LOADING,
          data: null,
          error: null,
        };
        return;
      }
      case AnalyticsActionType.FETCH_ROAD_SEGMENT_IDS_SUCCEEDED: {
        setAutoFreeze(false);
        setUseStrictShallowCopy(false);

        draft.roadSegmentIds = {
          state: DataState.AVAILABLE,
          data: action.payload.roadSegmentIds,
          error: null,
        };

        const reverseRoadSegmentIds: RoadSegmentIdsWithFactype = {};

        Object.entries(action.payload.roadSegmentIds || {}).forEach(([key, value]) => {
          if (value.reverseSegmentId) {
            reverseRoadSegmentIds[value.reverseSegmentId] = { factype: value.factype, reverseSegmentId: key };
          }
        });

        draft.reverseRoadSegmentIds = reverseRoadSegmentIds;

        const getRoadCategoryFromFactype = (factype: number) => {
          switch (factype) {
            case 1:
              return RoadClassCategory.LIMITED_ACCESS;
            default:
              return RoadClassCategory.OTHER;
          }
        };

        const roadSegmentIdsByRoadClass = Object.entries(action.payload.roadSegmentIds || {}).reduce(
          (obj: RoadSegmentIdsByRoadClass, [key, value]) => {
            obj[getRoadCategoryFromFactype(value.factype)].push(key);
            return obj;
          },
          {
            limitedAccess: [],
            other: [],
          },
        );

        draft.roadSegmentIdsByRoadClass = roadSegmentIdsByRoadClass;

        return;
      }
      case AnalyticsActionType.FETCH_ROAD_SEGMENT_IDS_FAILED: {
        draft.roadSegmentIds = {
          state: DataState.ERROR,
          error: action.payload,
          data: null,
        };
        return;
      }
      case AnalyticsActionType.FETCH_ROAD_SEGMENTS_DETAILS: {
        draft.roadSegmentsDetails = {
          state: DataState.LOADING,
          data: null,
          error: null,
        };
        return;
      }
      case AnalyticsActionType.FETCH_ROAD_SEGMENTS_DETAILS_SUCCEEDED: {
        draft.roadSegmentsDetails = {
          state: DataState.AVAILABLE,
          data: action.payload.roadSegmentsDetails,
          error: null,
        };
        return;
      }
      case AnalyticsActionType.FETCH_ROAD_SEGMENTS_DETAILS_FAILED: {
        draft.roadSegmentsDetails = {
          state: DataState.ERROR,
          error: action.payload,
          data: null,
        };
        return;
      }
      case AnalyticsActionType.SET_MEASURE: {
        // draft.ODCounts = initialState.ODCounts;
        // draft.datasetCounts = initialState.datasetCounts;
        draft.roadsVolumes = initialState.roadsVolumes;

        // draft.ODFilters = initialState.ODFilters;
        // draft.datasetFilters = initialState.datasetFilters;
        draft.roadFilters = initialState.roadFilters;
        draft.measure = action.payload;
        return;
      }
      case AnalyticsActionType.SET_QUERY_TYPE: {
        draft.queryType = action.payload;
        return;
      }
      case AnalyticsActionType.UPDATE_CURRENT_ROAD_FILTERS: {
        draft.roadFilters = action.payload;
        draft.roadsRange = null;
        return;
      }
      case AnalyticsActionType.UPDATE_CURRENT_OD_FILTERS: {
        draft.ODFilters = action.payload;
        draft.ODRange = null;
        draft.ODRangeByZone = null;
        return;
      }
      case AnalyticsActionType.UPDATE_CURRENT_DATASET_FILTERS: {
        draft.datasetFilters = action.payload;
        draft.datasetRange = null;
        draft.ODRangeByZone = null;
        return;
      }

      case AnalyticsActionType.VALIDATE_OD_DATASET_CONFIG: {
        draft.ODDatasetConfigValidation = {
          state: DataState.LOADING,
          data: null,
          error: null,
        };
        return;
      }
      case AnalyticsActionType.VALIDATE_OD_DATASET_CONFIG_SUCCEEDED: {
        draft.ODDatasetConfigValidation = {
          state: DataState.AVAILABLE,
          data: action.payload.validation,
          error: null,
        };
        return;
      }
      case AnalyticsActionType.VALIDATE_OD_DATASET_CONFIG_FAILED: {
        draft.ODDatasetConfigValidation = {
          state: DataState.ERROR,
          error: action.payload,
          data: null,
        };
        return;
      }
      case AnalyticsActionType.FETCH_GEOCODING_SEARCH_RESULTS: {
        draft.geocodingSearch = {
          state: DataState.LOADING,
          data: state.geocodingSearch?.data,
          error: null,
        };
        return;
      }
      case AnalyticsActionType.FETCH_GEOCODING_SEARCH_RESULTS_SUCCEEDED: {
        draft.geocodingSearch = {
          state: DataState.AVAILABLE,
          data: action.payload,
          error: null,
        };
        return;
      }
      case AnalyticsActionType.FETCH_GEOCODING_SEARCH_RESULTS_FAILED: {
        draft.geocodingSearch = {
          state: DataState.ERROR,
          error: action.payload,
          data: null,
        };
        return;
      }
      case AnalyticsActionType.CLEAR_GEOCODING_SEARCH_RESULTS: {
        draft.geocodingSearch = {
          state: DataState.EMPTY,
          data: null,
          error: null,
        };
        return;
      }

      case AnalyticsActionType.SET_SELECTED_ZONE: {
        draft.selectedZone = action.payload;
        draft.ODRangeByZone = null;
        return;
      }
      case AnalyticsActionType.SET_SELECTED_ROAD_VOLUME: {
        draft.selectedRoadVolume = action.payload;
        return;
      }
      case AnalyticsActionType.SET_SELECTED_ROAD_VOLUME_ID: {
        draft.selectedRoadVolumeId = action.payload;
        return;
      }
      case AnalyticsActionType.FETCH_FOCUS_AREAS_AND_DATASETS: {
        draft.focusAreasAndDatasets = {
          state: DataState.LOADING,
          data: null,
          error: null,
        };

        return;
      }
      case AnalyticsActionType.FETCH_FOCUS_AREAS_AND_DATASETS_SUCCEEDED: {
        draft.focusAreasAndDatasets = {
          state: DataState.AVAILABLE,
          data: action.payload.focusAreasAndDatasets,
          error: null,
        };

        return;
      }
      case AnalyticsActionType.FETCH_FOCUS_AREAS_AND_DATASETS_FAILED: {
        draft.focusAreasAndDatasets = {
          state: DataState.ERROR,
          error: action.payload,
          data: null,
        };

        return;
      }
      case AnalyticsActionType.FETCH_FOCUS_AREAS: {
        draft.focusAreas = {
          state: DataState.LOADING,
          data: null,
          error: null,
        };

        return;
      }
      case AnalyticsActionType.FETCH_FOCUS_AREAS_SUCCEEDED: {
        draft.focusAreas = {
          state: DataState.AVAILABLE,
          data: action.payload.focusAreas,
          error: null,
        };

        return;
      }
      case AnalyticsActionType.FETCH_FOCUS_AREAS_FAILED: {
        draft.focusAreas = {
          state: DataState.ERROR,
          error: action.payload,
          data: null,
        };

        return;
      }
      case AnalyticsActionType.FETCH_OD_MEASURE_RANGE: {
        draft.ODMeasureRange = {
          state: DataState.LOADING,
          data: null,
          error: null,
        };

        return;
      }
      case AnalyticsActionType.FETCH_OD_MEASURE_RANGE_SUCCEEDED: {
        draft.ODMeasureRange = {
          state: DataState.AVAILABLE,
          data: action.payload,
          error: null,
        };

        return;
      }
      case AnalyticsActionType.FETCH_OD_MEASURE_RANGE_FAILED: {
        draft.ODMeasureRange = {
          state: DataState.ERROR,
          error: action.payload,
          data: null,
        };

        return;
      }
      case AnalyticsActionType.FETCH_DATASET_MEASURE_RANGE: {
        draft.datasetMeasureRange = {
          state: DataState.LOADING,
          data: null,
          error: null,
        };

        return;
      }
      case AnalyticsActionType.FETCH_DATASET_MEASURE_RANGE_SUCCEEDED: {
        draft.datasetMeasureRange = {
          state: DataState.AVAILABLE,
          data: action.payload,
          error: null,
        };

        return;
      }
      case AnalyticsActionType.FETCH_DATASET_MEASURE_RANGE_FAILED: {
        draft.datasetMeasureRange = {
          state: DataState.ERROR,
          error: action.payload,
          data: null,
        };

        return;
      }
      case AnalyticsActionType.FETCH_ROADS_MEASURE_RANGE: {
        draft.roadsMeasureRange = {
          state: DataState.LOADING,
          data: null,
          error: null,
        };

        return;
      }
      case AnalyticsActionType.FETCH_ROADS_MEASURE_RANGE_SUCCEEDED: {
        draft.roadsMeasureRange = {
          state: DataState.AVAILABLE,
          data: action.payload,
          error: null,
        };

        return;
      }
      case AnalyticsActionType.FETCH_ROADS_MEASURE_RANGE_FAILED: {
        draft.roadsMeasureRange = {
          state: DataState.ERROR,
          error: action.payload,
          data: null,
        };

        return;
      }
      case AnalyticsActionType.SET_OD_RANGE: {
        const newODRange = action.payload;
        draft.ODRange = newODRange
          ? {
              ...state.ODRange,
              ...newODRange,
            }
          : newODRange;
        return;
      }
      case AnalyticsActionType.SET_DATASET_RANGE: {
        const newDatasetRange = action.payload;
        draft.datasetRange = newDatasetRange
          ? {
              ...state.datasetRange,
              ...newDatasetRange,
            }
          : newDatasetRange;
        return;
      }
      case AnalyticsActionType.SET_ROADS_RANGE: {
        draft.roadsRange = action.payload;
        return;
      }
      case AnalyticsActionType.SET_OD_MEASURE_RANGE_BY_ZONE: {
        draft.ODMeasureRangeByZone = action.payload;
        return;
      }
      case AnalyticsActionType.SET_OD_RANGE_BY_ZONE: {
        draft.ODRangeByZone = action.payload;
        return;
      }
      case AnalyticsActionType.SET_MAP_VISUALIZATION_MODE: {
        draft.mapVisualizationMode = action.payload;
        return;
      }
      default:
        return state;
    }
  });

export default reducer;

function* fetchDatasetCounts(
  action: Action<string, { datasetId: string; levels: string[]; config: DatasetCountsArguments }>,
): Generator {
  try {
    const api = yield getContext("api");
    const {
      analyticsApi: { getDatasetCounts },
    } = api as Api;
    const datasetCounts: any = yield call(
      getDatasetCounts,
      action.payload.datasetId,
      action.payload.levels,
      action.payload.config,
    );

    yield put({
      type: AnalyticsActionType.FETCH_DATASET_MEASURE_RANGE_SUCCEEDED,
      payload: datasetCounts.availableRange,
    });
    yield put({
      type: AnalyticsActionType.FETCH_DATASET_COUNTS_SUCCEEDED,
      payload: { datasetCounts },
    });
  } catch (e) {
    reportAboutErrorState(e, AnalyticsActionType.FETCH_DATASET_COUNTS_FAILED);

    yield put({
      type: AnalyticsActionType.FETCH_DATASET_COUNTS_FAILED,
      payload: e,
    });
  }
}

function* fetchDatasetIds(
  action: Action<string, { datasetId: string; levels: string[]; config: ZoneIdsArguments }>,
): Generator {
  try {
    const api = yield getContext("api");
    const {
      analyticsApi: { getDatasetIds },
    } = api as Api;
    const datasetIds = yield call(
      getDatasetIds,
      action.payload.datasetId,
      action.payload.levels,
      action.payload.config,
    );
    yield put({
      type: AnalyticsActionType.FETCH_DATASET_IDS_SUCCEEDED,
      payload: { datasetIds },
    });
  } catch (e) {
    reportAboutErrorState(e, AnalyticsActionType.FETCH_DATASET_IDS_FAILED);

    yield put({
      type: AnalyticsActionType.FETCH_DATASET_IDS_FAILED,
      payload: e,
    });
  }
}

function* fetchDatasetCountsByZoneId(
  action: Action<string, { datasetId: string; config: DatasetCountsByZoneIdArguments }>,
): Generator {
  try {
    const api = yield getContext("api");
    const {
      analyticsApi: { getDatasetCountsByZoneId },
    } = api as Api;
    const datasetCounts: any = yield call(getDatasetCountsByZoneId, action.payload.datasetId, action.payload.config);
    const min = datasetCounts.counts.availableRange[action.payload.config.selectedId].min;
    const max = datasetCounts.counts.availableRange[action.payload.config.selectedId].max;

    yield put({
      type: AnalyticsActionType.SET_OD_MEASURE_RANGE_BY_ZONE,
      payload: { min, max },
    });

    yield put({
      type: AnalyticsActionType.FETCH_DATASET_COUNTS_BY_ZONE_ID_SUCCEEDED,
      payload: { datasetCounts },
    });
  } catch (e) {
    reportAboutErrorState(e, AnalyticsActionType.FETCH_DATASET_COUNTS_BY_ZONE_ID_FAILED);

    yield put({
      type: AnalyticsActionType.FETCH_DATASET_COUNTS_BY_ZONE_ID_FAILED,
      payload: e,
    });
  }
}

function* fetchDatasetMetadata(action: Action<string, string>): Generator {
  try {
    const api = yield getContext("api");
    const {
      analyticsApi: { getDatasetMetadata },
      tileServiceApi: { getTileServiceMetadata },
    } = api as Api;
    const datasetMetadata: any = yield call(getDatasetMetadata, action.payload);

    let datasetExtendedTileServiceMetadata;

    if (!datasetMetadata.tileService.minZoom && !datasetMetadata.tileService.maxZoom) {
      const datasetTileServiceMetadata: any = yield call(getTileServiceMetadata, datasetMetadata.tileService.url);
      datasetExtendedTileServiceMetadata = extendODMetadata(datasetMetadata.tileService, datasetTileServiceMetadata);
    } else {
      datasetExtendedTileServiceMetadata = extendODMetadata(datasetMetadata.tileService);
    }

    const roadsTileServiceMetadata: any = yield call(getTileServiceMetadata, datasetMetadata.roadsTileService.url);

    const roadsExtendedTileServiceMetadata = extendRoadsMetadata(
      datasetMetadata.roadsTileService,
      roadsTileServiceMetadata,
    );
    yield put({
      type: AnalyticsActionType.FETCH_DATASET_METADATA_SUCCEEDED,
      payload: {
        datasetMetadata: {
          ...datasetMetadata,
          tileService: datasetExtendedTileServiceMetadata,
          roadsTileService: roadsExtendedTileServiceMetadata,
        },
      },
    });
  } catch (error) {
    reportAboutErrorState(error, AnalyticsActionType.FETCH_DATASET_METADATA_FAILED);

    yield put({
      type: AnalyticsActionType.FETCH_DATASET_METADATA_FAILED,
      payload: error,
    });
  }
}

function* fetchDatasetGates(action: Action<string, string>): Generator {
  try {
    const api = yield getContext("api");
    const {
      analyticsApi: { getDatasetGates },
    } = api as Api;
    const datasetGates = yield call(getDatasetGates, action.payload);
    yield put({
      type: AnalyticsActionType.FETCH_DATASET_GATES_SUCCEEDED,
      payload: { datasetGates },
    });
  } catch (e: any) {
    reportAboutErrorState(e, AnalyticsActionType.FETCH_DATASET_GATES_FAILED);

    yield put({
      type: AnalyticsActionType.FETCH_DATASET_GATES_FAILED,
      payload: {
        message: e.message,
      },
    });
  }
}

function* fetchGateDetails(
  action: Action<string, { datasetId: string; config: DatasetZoneDetailsArguments }>,
): Generator {
  try {
    const api = yield getContext("api");
    const {
      analyticsApi: { getGateDetails },
    } = api as Api;
    const gateDetails = yield call(getGateDetails, action.payload.datasetId, action.payload.config);
    yield put({
      type: AnalyticsActionType.FETCH_GATE_DETAILS_SUCCEEDED,
      payload: { gateDetails },
    });
  } catch (e) {
    reportAboutErrorState(e, AnalyticsActionType.FETCH_GATE_DETAILS_FAILED);

    yield put({
      type: AnalyticsActionType.FETCH_GATE_DETAILS_FAILED,
      payload: e,
    });
  }
}

function* fetchZoneDetails(action: Action<string, ZoneDetailsArguments>): Generator {
  try {
    const api = yield getContext("api");
    const {
      analyticsApi: { getZoneDetails },
    } = api as Api;
    const zoneDetails = yield call(getZoneDetails, action.payload);
    yield put({
      type: AnalyticsActionType.FETCH_ZONE_DETAILS_SUCCEEDED,
      payload: { zoneDetails },
    });
  } catch (e) {
    reportAboutErrorState(e, AnalyticsActionType.FETCH_ZONE_DETAILS_FAILED);

    yield put({
      type: AnalyticsActionType.FETCH_ZONE_DETAILS_FAILED,
      payload: e,
    });
  }
}

function* fetchODCounts(action: Action<string, { levels: string[]; config: ODCountsArguments }>): Generator {
  try {
    const api = yield getContext("api");
    const {
      analyticsApi: { getODCounts },
    } = api as Api;
    const ODCounts: any = yield call(getODCounts, action.payload.levels, action.payload.config);

    yield put({
      type: AnalyticsActionType.FETCH_OD_MEASURE_RANGE_SUCCEEDED,
      payload: ODCounts.availableRange,
    });
    yield put({
      type: AnalyticsActionType.FETCH_ZONE_COUNTS_SUCCEEDED,
      payload: { ODCounts },
    });
  } catch (e) {
    reportAboutErrorState(e, AnalyticsActionType.FETCH_ZONE_COUNTS_FAILED);

    yield put({
      type: AnalyticsActionType.FETCH_ZONE_COUNTS_FAILED,
      payload: e,
    });
  }
}

function* fetchODIds(action: Action<string, { levels: string[]; config: ODCountsArguments }>): Generator {
  try {
    const api = yield getContext("api");
    const {
      analyticsApi: { getODIds },
    } = api as Api;
    const zoneIds = yield call(getODIds, action.payload.levels, action.payload.config);
    yield put({
      type: AnalyticsActionType.FETCH_ZONE_IDS_SUCCEEDED,
      payload: { zoneIds },
    });
  } catch (e) {
    reportAboutErrorState(e, AnalyticsActionType.FETCH_ZONE_IDS_FAILED);

    yield put({
      type: AnalyticsActionType.FETCH_ZONE_IDS_FAILED,
      payload: e,
    });
  }
}

function* fetchODCountsByZoneId(action: Action<string, ODCountsByZoneIdArguments>): Generator {
  try {
    const api = yield getContext("api");
    const {
      analyticsApi: { getODCountsByZoneId },
    } = api as Api;
    const ODCounts: any = yield call(getODCountsByZoneId, action.payload);
    const min = ODCounts.counts.availableRange[ODCounts.selectedZoneId].min;
    const max = ODCounts.counts.availableRange[ODCounts.selectedZoneId].max;

    yield put({
      type: AnalyticsActionType.SET_OD_MEASURE_RANGE_BY_ZONE,
      payload: { min, max },
    });

    yield put({
      type: AnalyticsActionType.FETCH_ZONE_COUNTS_BY_ZONE_ID_SUCCEEDED,
      payload: { ODCounts },
    });
  } catch (e) {
    reportAboutErrorState(e, AnalyticsActionType.FETCH_ZONE_COUNTS_BY_ZONE_ID_FAILED);

    yield put({
      type: AnalyticsActionType.FETCH_ZONE_COUNTS_BY_ZONE_ID_FAILED,
      payload: e,
    });
  }
}

function* fetchODMetadata(action: Action<string, ODMetadataArguments>): Generator {
  try {
    const api = yield getContext("api");
    const {
      analyticsApi: { getODMetadata },
      tileServiceApi: { getTileServiceMetadata },
    } = api as Api;
    const ODMetadata: any = yield call(getODMetadata, action.payload);
    const tileServiceMetadata: any = yield call(getTileServiceMetadata, ODMetadata.tileService.url);
    const extendedTileServiceMetadata = extendODMetadata(ODMetadata.tileService, tileServiceMetadata);

    yield put({
      type: AnalyticsActionType.FETCH_OD_METADATA_SUCCEEDED,
      payload: {
        ODMetadata: { ...ODMetadata, tileService: extendedTileServiceMetadata },
      },
    });
  } catch (error) {
    reportAboutErrorState(error, AnalyticsActionType.FETCH_OD_METADATA_FAILED);

    yield put({
      type: AnalyticsActionType.FETCH_OD_METADATA_FAILED,
      payload: error,
    });
  }
}

function* fetchSubareaState(action: Action<string, SubareaStateArguments>): Generator {
  try {
    const api = yield getContext("api");
    const {
      analyticsApi: { getSubareaState },
    } = api as Api;
    const subareaState: any = yield call(getSubareaState, action.payload);

    yield put({
      type: AnalyticsActionType.FETCH_SUBAREA_STATE_SUCCEEDED,
      payload: {
        subareaState,
      },
    });
  } catch (e) {
    reportAboutErrorState(e, AnalyticsActionType.FETCH_SUBAREA_STATE_FAILED);

    yield put({
      type: AnalyticsActionType.FETCH_SUBAREA_STATE_FAILED,
      payload: e,
    });
  }
}

function* fetchSubareaPolygon(action: Action<string, SubareaPolygonArguments>): Generator {
  try {
    const api = yield getContext("api");
    const {
      analyticsApi: { getSubareaPolygon },
    } = api as Api;
    const subareaPolygon: any = yield call(getSubareaPolygon, action.payload);
    const ODDatasetConfigData: any = yield select((state) => state.analytics.ODDatasetConfig.data);

    yield put({
      type: AnalyticsActionType.FETCH_SUBAREA_POLYGON_SUCCEEDED,
    });

    yield put({
      type: AnalyticsActionType.FETCH_OD_DATASET_CONFIG_SUCCEEDED,
      payload: {
        config: {
          ...ODDatasetConfigData,
          subAreaGeometry: subareaPolygon.polygon,
        },
      },
    });
  } catch (e) {
    reportAboutErrorState(e, AnalyticsActionType.FETCH_SUBAREA_POLYGON_FAILED);

    yield put({
      type: AnalyticsActionType.FETCH_SUBAREA_POLYGON_FAILED,
      payload: e,
    });
  }
}

function* fetchGeneratedGates(action: Action<string, GenerateGatesArguments>): Generator {
  try {
    const api = yield getContext("api");
    const {
      analyticsApi: { getGeneratedGates },
    } = api as Api;
    const gates: any = yield call(getGeneratedGates, action.payload);

    yield put({
      type: AnalyticsActionType.FETCH_GENERATED_GATES_SUCCEEDED,
      payload: {
        gates,
      },
    });
  } catch (e) {
    reportAboutErrorState(e, AnalyticsActionType.FETCH_GENERATED_GATES_FAILED);

    yield put({
      type: AnalyticsActionType.FETCH_GENERATED_GATES_FAILED,
      payload: e,
    });
  }
}

function* fetchAddedGate(action: Action<string, AddGateArguments>): Generator {
  try {
    const api = yield getContext("api");
    const {
      analyticsApi: { getAddGate },
    } = api as Api;
    const addGateResonse: any = yield call(getAddGate, action.payload);

    yield put({
      type: AnalyticsActionType.FETCH_ADDED_GATE_SUCCEEDED,
      payload: {
        ...addGateResonse,
      },
    });
  } catch (e) {
    reportAboutErrorState(e, AnalyticsActionType.FETCH_ADDED_GATE_FAILED);

    yield put({
      type: AnalyticsActionType.FETCH_ADDED_GATE_FAILED,
      payload: e,
    });
  }
}

function* addGateSegments(
  action: Action<
    string,
    {
      gateId: string;
      newSegments: GateSegment[];
      config: GateCoordinatesArguments;
    }
  >,
): Generator {
  try {
    const api = yield getContext("api");
    const {
      analyticsApi: { getGateCoordinates },
    } = api as Api;
    const gateCoordinates: any = yield call(getGateCoordinates, action.payload.config);

    yield put({
      type: AnalyticsActionType.ADD_GATE_SEGMENTS_SUCCEEDED,
      payload: {
        gateId: action.payload.gateId,
        newSegments: action.payload.newSegments,
        gateCoordinates,
      },
    });
  } catch (e) {
    reportAboutErrorState(e, AnalyticsActionType.ADD_GATE_SEGMENTS_FAILED);

    yield put({
      type: AnalyticsActionType.ADD_GATE_SEGMENTS_FAILED,
      payload: e,
    });
  }
}

function* deleteGateSegments(
  action: Action<
    string,
    {
      gateId: string;
      segmentsToDeleteIds: GateSegment[];
      config: GateCoordinatesArguments;
    }
  >,
): Generator {
  try {
    const api = yield getContext("api");
    const {
      analyticsApi: { getGateCoordinates },
    } = api as Api;
    const gateCoordinates: any = yield call(getGateCoordinates, action.payload.config);

    yield put({
      type: AnalyticsActionType.DELETE_GATE_SEGMENTS_SUCCEEDED,
      payload: {
        gateId: action.payload.gateId,
        segmentsToDeleteIds: action.payload.segmentsToDeleteIds,
        gateCoordinates,
      },
    });
  } catch (e) {
    reportAboutErrorState(e, AnalyticsActionType.DELETE_GATE_SEGMENTS_FAILED);

    yield put({
      type: AnalyticsActionType.DELETE_GATE_SEGMENTS_FAILED,
      payload: e,
    });
  }
}

function* fetchRoadsMetadata(action: Action<string, RoadsMetadataArguments>): Generator {
  try {
    const api = yield getContext("api");
    const {
      analyticsApi: { getRoadsMetadata },
      tileServiceApi: { getTileServiceMetadata },
    } = api as Api;
    const roadsMetadata: any = yield call(getRoadsMetadata, action.payload);
    const tileServiceMetadata: any = yield call(getTileServiceMetadata, roadsMetadata.tileService.url);
    const extendedTileServiceMetadata = extendRoadsMetadata(roadsMetadata.tileService, tileServiceMetadata);

    yield put({
      type: AnalyticsActionType.FETCH_ROADS_METADATA_SUCCEEDED,
      payload: {
        roadsMetadata: {
          ...roadsMetadata,
          tileService: extendedTileServiceMetadata,
        },
      },
    });
  } catch (error) {
    reportAboutErrorState(error, AnalyticsActionType.FETCH_ROADS_METADATA_FAILED);

    yield put({
      type: AnalyticsActionType.FETCH_ROADS_METADATA_FAILED,
      payload: error,
    });
  }
}

function* fetchRoadsVolumes(action: Action<string, RoadsVolumesArguments>): Generator {
  try {
    const api = yield getContext("api");
    const {
      analyticsApi: { getRoadsVolumes },
    } = api as Api;
    const roadsVolumes: any = yield call(getRoadsVolumes, action.payload);

    yield put({
      type: AnalyticsActionType.FETCH_ROADS_MEASURE_RANGE_SUCCEEDED,
      payload: { min: roadsVolumes.minVolume, max: roadsVolumes.maxVolume },
    });

    yield put({
      type: AnalyticsActionType.FETCH_ROADS_VOLUMES_SUCCEEDED,
      payload: { roadsVolumes },
    });
  } catch (e: any) {
    reportAboutErrorState(e, AnalyticsActionType.FETCH_ROADS_VOLUMES_FAILED);

    yield put({
      type: AnalyticsActionType.FETCH_ROADS_VOLUMES_FAILED,
      payload: {
        message: e.message,
      },
    });
  }
}

function* fetchRoadSegmentIds(action: Action<string, RoadSegmentIdsArguments>): Generator {
  try {
    const api = yield getContext("api");
    const {
      analyticsApi: { getRoadSegmentIds },
    } = api as Api;
    const roadSegmentIds = yield call(getRoadSegmentIds, action.payload);
    yield put({
      type: AnalyticsActionType.FETCH_ROAD_SEGMENT_IDS_SUCCEEDED,
      payload: { roadSegmentIds },
    });
  } catch (e: any) {
    reportAboutErrorState(e, AnalyticsActionType.FETCH_ROAD_SEGMENT_IDS_FAILED);

    yield put({
      type: AnalyticsActionType.FETCH_ROAD_SEGMENT_IDS_FAILED,
      payload: {
        message: e.message,
      },
    });
  }
}

function* fetchRoadSegmentsDetails(action: Action<string, RoadSegmentsDetailsArguments>): Generator {
  try {
    const api = yield getContext("api");
    const {
      analyticsApi: { getSegmentsDetails },
    } = api as Api;
    const roadSegmentsDetailsList: any = yield call(getSegmentsDetails, action.payload);
    const roadSegmentsDetails: Record<string, RoadSegmentDetails> = {};

    roadSegmentsDetailsList.forEach((segment: any) => {
      roadSegmentsDetails[segment?.segment_id] = segment;
    });

    yield put({
      type: AnalyticsActionType.FETCH_ROAD_SEGMENTS_DETAILS_SUCCEEDED,
      payload: { roadSegmentsDetails: roadSegmentsDetails },
    });
  } catch (e: any) {
    reportAboutErrorState(e, AnalyticsActionType.FETCH_ROAD_SEGMENTS_DETAILS_FAILED);

    yield put({
      type: AnalyticsActionType.FETCH_ROAD_SEGMENTS_DETAILS_FAILED,
      payload: {
        message: e.message,
      },
    });
  }
}

function* fetchODDatasetConfig(action: Action<string, string>): Generator {
  try {
    const api = yield getContext("api");
    const {
      analyticsApi: { getODDatasetConfig },
    } = api as Api;
    const config: any = yield call(getODDatasetConfig, action.payload);
    const selectedFocusAreaId: any = yield select((state) => state.global.selectedFocusAreaId);

    if (!selectedFocusAreaId) {
      yield put({
        type: GlobalActionType.SET_SELECTED_FOCUS_AREA_ID,
        payload: { focusAreaId: config.licensedAreaId.toString(), reset: false },
      });
    }

    yield put({
      type: AnalyticsActionType.FETCH_OD_DATASET_CONFIG_SUCCEEDED,
      payload: {
        config,
      },
    });
  } catch (e) {
    reportAboutErrorState(e, AnalyticsActionType.FETCH_OD_DATASET_CONFIG_FAILED);

    yield put({
      type: AnalyticsActionType.FETCH_OD_DATASET_CONFIG_FAILED,
      payload: e,
    });
  }
}

function* updateODDatasetConfig(
  action: Action<string, { datasetId: string; updatedConfig: UpdateODDatasetConfigPayload }>,
): Generator {
  try {
    const api = yield getContext("api");
    const {
      analyticsApi: { updateODDatasetConfig },
    } = api as Api;
    let config: any = yield call(updateODDatasetConfig, action.payload.datasetId, action.payload.updatedConfig);
    const previousConfig: any = yield select((state) => state.analytics.ODDatasetConfig.data);

    if (!Object.keys(config.permissions).length) {
      config = { ...config, permissions: previousConfig.permissions };
    }
    yield put({
      type: AnalyticsActionType.UPDATE_OD_DATASET_CONFIG_SUCCEEDED,
      payload: {
        config,
      },
    });
  } catch (e) {
    reportAboutErrorState(e, AnalyticsActionType.UPDATE_OD_DATASET_CONFIG_FAILED);

    yield put({
      type: AnalyticsActionType.UPDATE_OD_DATASET_CONFIG_FAILED,
      payload: e,
    });
  }
}

function* createODDatasetConfig(action: Action<string, NewODDatasetConfigPayload>): Generator {
  try {
    const api = yield getContext("api");
    const {
      analyticsApi: { createODDatasetConfig },
    } = api as Api;
    const config: any = yield call(createODDatasetConfig, action.payload);

    yield put({
      type: AnalyticsActionType.CREATE_OD_DATASET_CONFIG_SUCCEEDED,
      payload: {
        config,
      },
    });
  } catch (e) {
    reportAboutErrorState(e, AnalyticsActionType.CREATE_OD_DATASET_CONFIG_FAILED);

    yield put({
      type: AnalyticsActionType.CREATE_OD_DATASET_CONFIG_FAILED,
      payload: e,
    });
  }
}

function* validateODDatasetConfig(action: Action<string, { datasetConfigId: string }>): Generator {
  try {
    const api = yield getContext("api");
    const {
      analyticsApi: { validateODDatasetConfig },
    } = api as Api;
    const validation: any = yield call(validateODDatasetConfig, action.payload.datasetConfigId);

    yield put({
      type: AnalyticsActionType.VALIDATE_OD_DATASET_CONFIG_SUCCEEDED,
      payload: {
        validation,
      },
    });
  } catch (e) {
    reportAboutErrorState(e, AnalyticsActionType.VALIDATE_OD_DATASET_CONFIG_FAILED);

    yield put({
      type: AnalyticsActionType.VALIDATE_OD_DATASET_CONFIG_FAILED,
      payload: e,
    });
  }
}

function* computeODDataset(
  action: Action<
    string,
    {
      datasetConfigId: string;
      notifyByEmail: boolean;
      navigate: NavigateFunction;
    }
  >,
): Generator {
  try {
    const api = yield getContext("api");
    const { datasetConfigId, notifyByEmail, navigate } = action.payload;
    const {
      analyticsApi: { computeODDataset },
    } = api as Api;
    const computation: any = yield call(computeODDataset, datasetConfigId, notifyByEmail);

    yield put({
      type: AnalyticsActionType.COMPUTE_OD_DATASET_SUCCEEDED,
      payload: {
        computation,
      },
    });

    navigate(`/datasets`);
  } catch (e) {
    reportAboutErrorState(e, AnalyticsActionType.COMPUTE_OD_DATASET_FAILED);

    yield put({
      type: AnalyticsActionType.COMPUTE_OD_DATASET_FAILED,
      payload: e,
    });
  }
}

function* cancelODDatasetComputation(action: Action<string, { datasetConfigId: string }>): Generator {
  try {
    const api = yield getContext("api");

    const {
      analyticsApi: { cancelODDatasetComputation },
    } = api as Api;
    const datasetConfig: any = yield call(cancelODDatasetComputation, action.payload.datasetConfigId);

    yield put({
      type: AnalyticsActionType.CANCEL_OD_DATASET_COMPUTATION_SUCCEEDED,
      payload: {
        datasetConfig,
      },
    });

    yield put({
      type: DatasetFoldersActionType.FETCH_FOLDERS_STRUCTURE,
      payload: {},
    });
  } catch (e) {
    reportAboutErrorState(e, AnalyticsActionType.CANCEL_OD_DATASET_COMPUTATION_FAILED);

    yield put({
      type: AnalyticsActionType.CANCEL_OD_DATASET_COMPUTATION_FAILED,
      payload: e,
    });
  }
}

function* fetchGeocodingSearchResults(
  action: Action<string, { query: string; token: string; proximity: string }>,
): Generator {
  try {
    const { query, token, proximity } = action.payload;
    const api = yield getContext("api");
    const {
      analyticsApi: { getGeocoding },
    } = api as Api;
    const results: any = yield call(getGeocoding, {
      searchText: query,
      token,
      proximity,
    });

    yield put({
      type: AnalyticsActionType.FETCH_GEOCODING_SEARCH_RESULTS_SUCCEEDED,
      payload: results,
    });
  } catch (e) {
    reportAboutErrorState(e, AnalyticsActionType.FETCH_GEOCODING_SEARCH_RESULTS_FAILED);

    yield put({
      type: AnalyticsActionType.FETCH_GEOCODING_SEARCH_RESULTS_FAILED,
      payload: e,
    });
  }
}

export function* fetchFocusAreasAndDatasets(): Generator {
  try {
    const api = yield getContext("api");
    const {
      analyticsApi: { getFocusAreasAndDatasets },
    } = api as Api;
    const focusAreasAndDatasets: any = yield call(getFocusAreasAndDatasets);

    yield put({
      type: AnalyticsActionType.FETCH_FOCUS_AREAS_AND_DATASETS_SUCCEEDED,
      payload: { focusAreasAndDatasets },
    });
  } catch (e) {
    reportAboutErrorState(e, AnalyticsActionType.FETCH_FOCUS_AREAS_AND_DATASETS_FAILED);

    yield put({
      type: AnalyticsActionType.FETCH_FOCUS_AREAS_AND_DATASETS_FAILED,
      payload: e,
    });
  }
}

function* fetchFocusAreas(
  action: Action<string, { customZoningId?: string | null; forDatasetCreation?: boolean }>,
): Generator {
  try {
    const api = yield getContext("api");
    const {
      analyticsApi: { getFocusAreas },
    } = api as Api;
    const { customZoningId, forDatasetCreation } = action.payload;
    const focusAreas: any = yield call(getFocusAreas, customZoningId, forDatasetCreation);

    yield put({
      type: AnalyticsActionType.FETCH_FOCUS_AREAS_SUCCEEDED,
      payload: { focusAreas },
    });
  } catch (e) {
    reportAboutErrorState(e, AnalyticsActionType.FETCH_FOCUS_AREAS_FAILED);

    yield put({
      type: AnalyticsActionType.FETCH_FOCUS_AREAS_FAILED,
      payload: e,
    });
  }
}

function* fetchODMeasureRange(action: Action<string, { levels: string[]; config: MeasureRangeRequest }>): Generator {
  try {
    const { levels, config } = action.payload;

    const api = yield getContext("api");
    const {
      analyticsApi: { getODMeasureRange },
    } = api as Api;
    const measureRange = yield call(getODMeasureRange, levels, config);
    yield put({
      type: AnalyticsActionType.FETCH_OD_MEASURE_RANGE_SUCCEEDED,
      payload: measureRange,
    });
  } catch (e: any) {
    reportAboutErrorState(e, AnalyticsActionType.FETCH_OD_MEASURE_RANGE_FAILED);

    yield put({
      type: AnalyticsActionType.FETCH_OD_MEASURE_RANGE_FAILED,
      error: {
        message: e.message,
      },
    });
  }
}

function* fetchDatasetMeasureRange(
  action: Action<string, { datasetId: string; levels: string[]; config: MeasureRangeRequest }>,
): Generator {
  try {
    const { datasetId, levels, config } = action.payload;

    const api = yield getContext("api");
    const {
      analyticsApi: { getDatasetMeasureRange },
    } = api as Api;
    const measureRange = yield call(getDatasetMeasureRange, datasetId, levels, config);
    yield put({
      type: AnalyticsActionType.FETCH_DATASET_MEASURE_RANGE_SUCCEEDED,
      payload: measureRange,
    });
  } catch (e: any) {
    reportAboutErrorState(e, AnalyticsActionType.FETCH_DATASET_MEASURE_RANGE_FAILED);

    yield put({
      type: AnalyticsActionType.FETCH_DATASET_MEASURE_RANGE_FAILED,
      error: {
        message: e.message,
      },
    });
  }
}

function* fetchRoadsMeasureRange(action: Action<string, { config: MeasureRangeRequest }>): Generator {
  try {
    const api = yield getContext("api");
    const {
      analyticsApi: { getRoadsMeasureRange },
    } = api as Api;
    const measureRange = yield call(getRoadsMeasureRange, action.payload.config);
    yield put({
      type: AnalyticsActionType.FETCH_ROADS_MEASURE_RANGE_SUCCEEDED,
      payload: measureRange,
    });
  } catch (e: any) {
    reportAboutErrorState(e, AnalyticsActionType.FETCH_ROADS_MEASURE_RANGE_FAILED);

    yield put({
      type: AnalyticsActionType.FETCH_ROADS_MEASURE_RANGE_FAILED,
      error: {
        message: e.message,
      },
    });
  }
}

export function* analyticsSaga() {
  yield all([
    takeLatest(AnalyticsActionType.FETCH_DATASET_COUNTS, fetchDatasetCounts),
    takeLatest(AnalyticsActionType.FETCH_DATASET_IDS, fetchDatasetIds),
    takeLatest(AnalyticsActionType.FETCH_DATASET_COUNTS_BY_ZONE_ID, fetchDatasetCountsByZoneId),
    takeLatest(AnalyticsActionType.FETCH_DATASET_METADATA, fetchDatasetMetadata),
    takeLatest(AnalyticsActionType.FETCH_DATASET_GATES, fetchDatasetGates),
    takeLatest(AnalyticsActionType.FETCH_GATE_DETAILS, fetchGateDetails),
    takeLatest(AnalyticsActionType.FETCH_ZONE_DETAILS, fetchZoneDetails),
    takeLatest(AnalyticsActionType.FETCH_ZONE_COUNTS, fetchODCounts),
    takeLatest(AnalyticsActionType.FETCH_ZONE_IDS, fetchODIds),
    takeLatest(AnalyticsActionType.FETCH_ZONE_COUNTS_BY_ZONE_ID, fetchODCountsByZoneId),
    takeLatest(AnalyticsActionType.FETCH_OD_METADATA, fetchODMetadata),
    takeLatest(AnalyticsActionType.FETCH_SUBAREA_STATE, fetchSubareaState),
    takeLatest(AnalyticsActionType.FETCH_SUBAREA_POLYGON, fetchSubareaPolygon),
    takeLatest(AnalyticsActionType.FETCH_GENERATED_GATES, fetchGeneratedGates),
    takeLatest(AnalyticsActionType.FETCH_ADDED_GATE, fetchAddedGate),
    takeLatest(AnalyticsActionType.ADD_GATE_SEGMENTS, addGateSegments),
    takeLatest(AnalyticsActionType.DELETE_GATE_SEGMENTS, deleteGateSegments),
    takeLatest(AnalyticsActionType.FETCH_ROADS_METADATA, fetchRoadsMetadata),
    takeLatest(AnalyticsActionType.FETCH_ROADS_VOLUMES, fetchRoadsVolumes),
    takeLatest(AnalyticsActionType.FETCH_ROAD_SEGMENT_IDS, fetchRoadSegmentIds),
    takeLatest(AnalyticsActionType.FETCH_ROAD_SEGMENTS_DETAILS, fetchRoadSegmentsDetails),
    takeLatest(AnalyticsActionType.FETCH_OD_DATASET_CONFIG, fetchODDatasetConfig),
    takeLatest(AnalyticsActionType.CREATE_OD_DATASET_CONFIG, createODDatasetConfig),
    takeLatest(AnalyticsActionType.UPDATE_OD_DATASET_CONFIG, updateODDatasetConfig),
    takeLatest(AnalyticsActionType.VALIDATE_OD_DATASET_CONFIG, validateODDatasetConfig),
    takeLatest(AnalyticsActionType.COMPUTE_OD_DATASET, computeODDataset),
    takeLatest(AnalyticsActionType.CANCEL_OD_DATASET_COMPUTATION, cancelODDatasetComputation),
    takeLatest(AnalyticsActionType.FETCH_GEOCODING_SEARCH_RESULTS, fetchGeocodingSearchResults),
    takeLatest(AnalyticsActionType.FETCH_FOCUS_AREAS_AND_DATASETS, fetchFocusAreasAndDatasets),
    takeLatest(AnalyticsActionType.FETCH_FOCUS_AREAS, fetchFocusAreas),
    takeLatest(AnalyticsActionType.FETCH_OD_MEASURE_RANGE, fetchODMeasureRange),
    takeLatest(AnalyticsActionType.FETCH_DATASET_MEASURE_RANGE, fetchDatasetMeasureRange),
    takeLatest(AnalyticsActionType.FETCH_ROADS_MEASURE_RANGE, fetchRoadsMeasureRange),
  ]);
}
