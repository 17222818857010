import { useEffect } from "react";
import { useLocation, useMatch, useSearchParams } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "hooks";

import { DataState } from "store/interfaces";
import { analyticsActions } from "store/sections/analytics";
import { globalActions } from "store/sections/global";

import { MapVisualizationType, ROUTES } from "types";

export const useMapSearchParams = () => {
  const dispatch = useAppDispatch();

  const { pathname } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const datasetEditorRouteMatch = useMatch(ROUTES.DatasetEditor);
  const selectLinkRouteMatch = useMatch(ROUTES.SelectLinkEditor);

  const selectedFocusAreaId = useAppSelector((state) => state.global.selectedFocusAreaId);
  const timePeriod = useAppSelector((state) => state.global.timePeriod);
  const focusArea = useAppSelector((state) => state.analytics.focusAreasAndDatasets);
  const mode = useAppSelector((state) => state.analytics.mapVisualizationMode);

  useEffect(() => {
    if (focusArea.state === DataState.AVAILABLE) {
      const focusAreaId = searchParams.get("id");
      const firstFocusAreaId = focusArea.data[0].id;
      const searchParamTimePeriod = searchParams.get("timePeriod");
      const searchParamMode = searchParams.get("mode") as MapVisualizationType;

      const setSelectedFocusAreaId = () => {
        if (!selectedFocusAreaId) {
          dispatch(
            globalActions.setSelectedFocusAreaId({
              focusAreaId: focusAreaId || firstFocusAreaId,
              ...(searchParamTimePeriod && !timePeriod ? { timePeriod: searchParamTimePeriod } : {}),
            }),
          );
        }
      };

      const setMode = () => {
        if (mode === null) {
          dispatch(analyticsActions.setMapVisualizationMode(searchParamMode ?? MapVisualizationType.OD));
        }
      };

      const setFocusAreaIdParam = () => {
        if (selectedFocusAreaId && focusAreaId !== selectedFocusAreaId) {
          searchParams.set("id", selectedFocusAreaId);
        }
      };

      const setTimePeriodParam = () => {
        if (timePeriod && searchParamTimePeriod !== timePeriod) {
          searchParams.set("timePeriod", timePeriod);
        }
      };

      const setModeParam = () => {
        if (mode !== null && searchParamMode !== mode) {
          searchParams.set("mode", mode);
        }
      };

      if (datasetEditorRouteMatch || selectLinkRouteMatch) {
        setSearchParams(undefined);
        return;
      }

      setSelectedFocusAreaId();
      setMode();

      if (pathname === ROUTES.Map) {
        setFocusAreaIdParam();
        setTimePeriodParam();
        setModeParam();
        setSearchParams(searchParams);
        return;
      }

      if (pathname === ROUTES.CorridorDiscovery) {
        setFocusAreaIdParam();
        setTimePeriodParam();
        setSearchParams(searchParams);
        return;
      }
      
      if (pathname === ROUTES.RoadVmt) {
        setFocusAreaIdParam();
        setTimePeriodParam();
        setSearchParams(searchParams);
        return;
      }

      if (searchParams.get("id") !== null) {
        setSearchParams(undefined);
      }
    }
  }, [
    selectedFocusAreaId,
    timePeriod,
    mode,
    searchParams,
    pathname,
    focusArea,
    datasetEditorRouteMatch,
    selectLinkRouteMatch,
    setSearchParams,
    dispatch,
  ]);
};
