import { IconName } from "@blueprintjs/core";
import styled from "@emotion/styled";
import { isEqual } from "lodash";
import React, { ChangeEvent, FC, useCallback, useDeferredValue, useEffect, useMemo, useState } from "react";

import { getAvailableZoomLevels } from "features";

import { Button, CheckboxDropdownItem, DividerWithText, FlexContainer } from "components";

import { useAppDispatch, useAppSelector } from "hooks";

import { DataState } from "store/interfaces";
import { analyticsActions } from "store/sections/analytics";

import {
  DatasetMetadata,
  FilterArguments,
  FocusAreaItem,
  MapVisualizationMode,
  MapVisualizationType,
  Measure,
  MeasureType,
  ODMetadata,
  QueryType,
} from "types";

import { ODFiltersPanel } from "./ODFiltersPanel";
import { RoadsFiltersPanel } from "./RoadsFiltersPanel";

interface Filter extends CheckboxDropdownItem {
  value: string;
}

type FilterItems = {
  [key: string]: Filter;
};

interface FilterGroup extends CheckboxDropdownItem {
  items: FilterItems;
  icon: IconName;
}

export type FiltersType = {
  [key: string]: FilterGroup;
};

export interface FiltersProps {
  mode: MapVisualizationMode | null;
  loading: boolean;
  disabled?: boolean;
  isAnalysis?: boolean;
  setCurrentQueryType: (type: QueryType) => void;
}

const FormWrapper = styled.div`
  display: grid;
  grid-template-rows: auto 46px;
`;

const FiltersControls = styled(FlexContainer)`
  margin-top: 0.5rem;
  justify-content: space-between;
`;

const getFiltersByMeasure = (measure: Measure): FiltersType => {
  const newFilters: FiltersType = {};
  if (measure) {
    for (let i = 0, { length } = measure.dimensions; i < length; i++) {
      const dimension = measure.dimensions[i];
      if (dimension.enabled) {
        newFilters[dimension.columnName] = {
          isChecked: true,
          label: dimension.label,
          icon: getIconByDimension(dimension.columnName),
          items: Object.entries(dimension.categories || {}).reduce((newItems: FilterItems, [itemKey, itemValue]) => {
            const item = itemValue as any;
            newItems[itemKey] = {
              ...item,
              label: item.label || item.value,
              isChecked: true,
            };
            return newItems;
          }, {}),
        };
      }
    }
  }

  return newFilters;
};

export const getIconByDimension = (dimension: string) => {
  switch (dimension) {
    case "day_type":
      return "calendar";
    case "hour":
      return "time";
    default:
      return "filter";
  }
};

export const buildFilters = (filters: FiltersType | null): FilterArguments => {
  const filter: Record<string, string[]> = {};

  if (filters) {
    for (let [filterKey, filterValues] of Object.entries(filters)) {
      if (!filterValues.isChecked) {
        filter[filterKey] = Object.entries(filterValues.items)
          .filter(([, itemValue]) => itemValue.isChecked)
          .map(([, itemValue]) => itemValue.value);
      }
    }
  }

  return filter;
};

export const areAllItemsUnChecked = (items: FilterItems) => Object.values(items).every((item) => !item.isChecked);

const getCurrentMeasure = (measures: Measure[] | undefined, measure: MeasureType) =>
  measures?.find((m) => m.columnName === measure);

export const Filters: FC<FiltersProps> = ({ mode, loading, disabled, isAnalysis, setCurrentQueryType }) => {
  const dispatch = useAppDispatch();

  const measure = useAppSelector((state) => state.analytics.measure);
  const currentQueryType = useAppSelector((state) => state.analytics.queryType);

  const [filters, setFilters] = useState<FiltersType | null>(null);
  const deferredFilters = useDeferredValue(filters);

  const [queryType, setQueryType] = useState<QueryType>(currentQueryType);

  const selectLinkConfigData = useAppSelector((state) => state.selectLink.selectLinkConfig.data);

  //Selected area
  const timePeriod = useAppSelector((state) => state.global.timePeriod);
  const selectedFocusAreaId = useAppSelector((state) => state.global.selectedFocusAreaId);
  const selectedFocusArea = useAppSelector((state) => state.global.selectedFocusArea);

  //OD
  const ODCounts = useAppSelector((state) => state.analytics.ODCounts);
  const ODMetadata = useAppSelector((state) => state.analytics.ODMetadata);
  const currentODFilters = useAppSelector((state) => state.analytics.ODFilters);
  const currentODMeasure = useMemo(
    () => getCurrentMeasure(ODMetadata.data?.measures, MeasureType.AADT), //Temporary fix. Need to implement separate measure for each mode
    [ODMetadata.data?.measures],
  );
  const isOD = useMemo(
    () => mode === MapVisualizationType.OD && selectedFocusArea && !selectedFocusArea?.datasetId,
    [mode, selectedFocusArea],
  );

  //Roads
  const roadVolumes = useAppSelector((state) => state.analytics.roadsVolumes);
  const roadMetadata = useAppSelector((state) => state.analytics.roadsMetadata);
  const roadMeasures = useMemo(
    () =>
      isAnalysis
        ? // For now select link only support "aadt", other measures available for road should be ignored
          roadMetadata.data?.measures.filter((measure) => measure.columnName === "aadt")
        : roadMetadata.data?.measures.filter(
            (measure) => !(timePeriod === "2019" && measure.columnName === MeasureType.TRUCKS),
          ),
    [roadMetadata.data?.measures, timePeriod, isAnalysis],
  );
  const currentRoadFilters = useAppSelector((state) => state.analytics.roadFilters);
  const currentRoadMeasure = useMemo(() => getCurrentMeasure(roadMeasures, measure), [roadMeasures, measure]);
  const isRoads = useMemo(() => mode === MapVisualizationType.ROADS, [mode]);

  //Dataset
  const datasetCounts = useAppSelector((state) => state.analytics.datasetCounts);
  const datasetMetadata = useAppSelector((state) => state.analytics.datasetMetadata);
  const currentDatasetFilters = useAppSelector((state) => state.analytics.datasetFilters);
  const currentDatasetMeasure = useMemo(
    () => getCurrentMeasure(datasetMetadata.data?.measures, MeasureType.AADT), //Temporary fix. Need to implement separate measure for each mode
    [datasetMetadata.data?.measures],
  );
  const isDataset = useMemo(
    () => mode === MapVisualizationType.OD && selectedFocusArea?.datasetId,
    [mode, selectedFocusArea?.datasetId],
  );

  const isRevertFiltersOn = useMemo(
    () =>
      filters &&
      ((isOD && (!isEqual(filters, currentODFilters) || queryType !== currentQueryType)) ||
        (isDataset && (!isEqual(filters, currentDatasetFilters) || queryType !== currentQueryType)) ||
        (isRoads && !isEqual(filters, currentRoadFilters))),
    [
      currentDatasetFilters,
      currentODFilters,
      currentRoadFilters,
      filters,
      isOD,
      isRoads,
      isDataset,
      queryType,
      currentQueryType,
    ],
  );

  const areInvalidFilters = useMemo(() => {
    if (filters) {
      return Object.values(filters).some((filter) => areAllItemsUnChecked(filter.items));
    }

    return false;
  }, [filters]);

  const getODCounts = useCallback(
    (filters: FiltersType, selectedFocusArea: FocusAreaItem, metadata: ODMetadata, timePeriod: string) => {
      dispatch(
        analyticsActions.fetchODCounts(getAvailableZoomLevels(metadata.tileService.layers), {
          timePeriod,
          // measure: measure === MeasureType.PEDESTRIANS ? MeasureType.AADT : measure,
          measure: currentODMeasure?.columnName || MeasureType.AADT, //Temporary fix. Need to implement separate measure for each mode
          queryType,
          filter: buildFilters(filters),
          areaOfInterest: selectedFocusArea?.areas || null,
        }),
      );
    },
    [queryType, currentODMeasure?.columnName, dispatch],
  );

  const getDatasetCounts = useCallback(
    (filters: FiltersType, selectedFocusArea: FocusAreaItem, metadata: DatasetMetadata) => {
      dispatch(
        analyticsActions.fetchDatasetCounts(selectedFocusArea.id, getAvailableZoomLevels(metadata.tileService.layers), {
          queryType,
          areaOfInterest: selectedFocusArea?.areas,
          compression: "gzip",
          // measure: measure === MeasureType.PEDESTRIANS ? MeasureType.AADT : measure,
          measure: currentDatasetMeasure?.columnName || MeasureType.AADT, //Temporary fix. Need to implement separate measure for each mode
          filter: buildFilters(filters),
        }),
      );
    },
    [currentDatasetMeasure?.columnName, queryType, dispatch],
  );

  const getRoadsVolumes = useCallback(
    (filters: FiltersType, selectedFocusArea: FocusAreaItem, timePeriod: string) => {
      dispatch(
        analyticsActions.fetchRoadsVolumes({
          timePeriod,
          compression: "gzip",
          areaOfInterest: selectedFocusArea?.areas || null,
          filter: buildFilters(filters),
          datasetId: selectedFocusArea.datasetId ?? undefined,
          measure,
        }),
      );
    },
    [measure, dispatch],
  );

  const handleChangeFilter = (event: ChangeEvent<HTMLInputElement>) => {
    if (filters) {
      const namesArr = event.target.name.split("-");
      const groupName = namesArr[0];
      const itemName = namesArr[1];

      setFilters({
        ...filters,
        [groupName]: {
          ...filters[groupName],
          isChecked: Object.entries(filters[groupName].items).every(([itemKey, item]) => {
            return itemKey === itemName ? !filters[groupName].items[itemName].isChecked : item.isChecked;
          }),
          items: {
            ...filters[groupName].items,
            [itemName]: {
              ...filters[groupName].items[itemName],
              isChecked: !filters[groupName].items[itemName].isChecked,
            },
          },
        },
      });
    }
  };

  const handleChangeAllFilters = (isChecked: boolean) => (groupName: string) => {
    if (filters) {
      setFilters({
        ...filters,
        [groupName]: {
          ...filters[groupName],
          isChecked: isChecked,
          items: Object.entries(filters[groupName].items).reduce((newItems, [itemKey, itemValue]) => {
            newItems[itemKey] = {
              ...itemValue,
              isChecked: isChecked,
            };
            return newItems;
          }, {} as FilterItems),
        },
      });
    }
  };

  const handleSubmit = () => {
    if (filters && selectedFocusArea) {
      if (isOD && ODMetadata.state === DataState.AVAILABLE && timePeriod) {
        setCurrentQueryType(queryType);
        getODCounts(filters, selectedFocusArea, ODMetadata.data, timePeriod);
        dispatch(analyticsActions.updateCurrentODFilters(filters));
      }

      if (isDataset && datasetMetadata.state === DataState.AVAILABLE) {
        setCurrentQueryType(queryType);
        getDatasetCounts(filters, selectedFocusArea, datasetMetadata.data);
        dispatch(analyticsActions.updateCurrentDatasetFilters(filters));
      }

      if (isRoads && timePeriod) {
        getRoadsVolumes(filters, selectedFocusArea, timePeriod);
        dispatch(analyticsActions.updateCurrentRoadFilters(filters));
      }
    }
  };

  const handleChangeQueryType = (type: QueryType) => {
    setQueryType(type);
  };

  const handleChangeMeasure = (newMeasure: MeasureType) => {
    dispatch(analyticsActions.setMeasure(newMeasure));
  };

  const handleRevertFilters = () => {
    setQueryType(currentQueryType);
    if (isOD) setFilters(currentODFilters);
    if (isDataset) setFilters(currentDatasetFilters);
    if (isRoads) setFilters(currentRoadFilters);
  };

  //Get OD filters from measure and set redux state
  useEffect(() => {
    if (!currentODFilters && currentODMeasure && !selectedFocusArea?.datasetId) {
      const newODFilters: FiltersType = getFiltersByMeasure(currentODMeasure);

      dispatch(analyticsActions.updateCurrentODFilters(newODFilters));
    }
  }, [selectedFocusArea?.datasetId, currentODFilters, currentODMeasure, dispatch]);

  //Get dataset filters from measure and set redux state
  useEffect(() => {
    if (!currentDatasetFilters && currentDatasetMeasure && selectedFocusArea?.datasetId) {
      const newDatasetFilters: FiltersType = getFiltersByMeasure(currentDatasetMeasure);

      dispatch(analyticsActions.updateCurrentDatasetFilters(newDatasetFilters));
    }
  }, [selectedFocusArea?.datasetId, currentDatasetFilters, currentDatasetMeasure, dispatch]);

  //Get roads filters from measure and set redux state
  useEffect(() => {
    if (!currentRoadFilters && currentRoadMeasure) {
      const newRoadFilters: FiltersType = getFiltersByMeasure(currentRoadMeasure);

      dispatch(analyticsActions.updateCurrentRoadFilters(newRoadFilters));
    }
  }, [currentRoadFilters, currentRoadMeasure, dispatch]);

  useEffect(() => {
    if (roadMeasures && !currentRoadMeasure) {
      dispatch(analyticsActions.setMeasure(roadMeasures[0].columnName as MeasureType));
      setFilters(getFiltersByMeasure(roadMeasures[0]));
    }
  }, [currentRoadMeasure, roadMeasures, dispatch]);

  //Clear component filters on mode change
  useEffect(() => {
    if (selectedFocusAreaId) {
      setFilters(null);
    }
  }, [mode, selectedFocusAreaId, timePeriod, measure]);

  //Reset filters to last applied state on Select Link Analysis run
  useEffect(() => {
    if (isAnalysis) {
      setFilters(null);
    }
  }, [isAnalysis, selectLinkConfigData]);

  //Set filters for OD on first render
  useEffect(() => {
    if (
      mode === MapVisualizationType.OD &&
      ODMetadata.state === DataState.AVAILABLE &&
      ODCounts.state !== DataState.LOADING &&
      currentODFilters &&
      !filters &&
      selectedFocusArea &&
      !selectedFocusArea.datasetId
    ) {
      setFilters(currentODFilters);
      setQueryType(currentQueryType);

      if (ODCounts.state === DataState.EMPTY && timePeriod) {
        getODCounts(currentODFilters, selectedFocusArea, ODMetadata.data, timePeriod);
      }
    }
  }, [
    mode,
    filters,
    currentODFilters,
    selectedFocusArea,
    ODMetadata,
    ODCounts.state,
    timePeriod,
    currentQueryType,
    getODCounts,
  ]);

  //Set filters for dataset on first render
  useEffect(() => {
    if (
      datasetMetadata.state === DataState.AVAILABLE &&
      mode === MapVisualizationType.OD &&
      datasetCounts.state !== DataState.LOADING &&
      currentDatasetFilters &&
      !filters &&
      selectedFocusArea?.datasetId
    ) {
      setFilters(currentDatasetFilters);
      setQueryType(currentQueryType);

      if (datasetCounts.state === DataState.EMPTY) {
        getDatasetCounts(currentDatasetFilters, selectedFocusArea, datasetMetadata.data);
      }
    }
  }, [
    mode,
    filters,
    currentDatasetFilters,
    selectedFocusArea,
    datasetMetadata,
    datasetCounts.state,
    currentQueryType,
    getDatasetCounts,
  ]);

  //Set filters for roads on first render
  useEffect(() => {
    if (
      mode === MapVisualizationType.ROADS &&
      roadVolumes.state !== DataState.LOADING &&
      currentRoadFilters &&
      !filters &&
      selectedFocusArea
    ) {
      setFilters(currentRoadFilters);

      if (roadVolumes.state === DataState.EMPTY && timePeriod) {
        getRoadsVolumes(currentRoadFilters, selectedFocusArea, timePeriod);
      }
    }
  }, [mode, filters, currentRoadFilters, selectedFocusArea, roadVolumes.state, timePeriod, getRoadsVolumes]);

  return (
    <FormWrapper>
      <div>
        <DividerWithText>Filters</DividerWithText>

        {(isOD || isDataset) && (
          <ODFiltersPanel
            filters={deferredFilters}
            queryType={queryType}
            loading={loading}
            disabled={disabled}
            handleChangeFilter={handleChangeFilter}
            handleChangeAllFilters={handleChangeAllFilters}
            handleChangeQueryType={handleChangeQueryType}
          />
        )}
        {isRoads && (
          <RoadsFiltersPanel
            filters={deferredFilters}
            roadMeasures={roadMeasures}
            currentRoadMeasure={currentRoadMeasure}
            measure={measure}
            loading={loading}
            disabled={disabled}
            isAnalysis={isAnalysis}
            setMeasure={handleChangeMeasure}
            handleChangeFilter={handleChangeFilter}
            handleChangeAllFilters={handleChangeAllFilters}
          />
        )}
      </div>

      <FiltersControls>
        {isRevertFiltersOn ? (
          <Button color="white" size="sm" onClick={handleRevertFilters}>
            Revert
          </Button>
        ) : (
          <div />
        )}

        <Button
          size="sm"
          type="submit"
          disabled={areInvalidFilters || (currentQueryType === queryType && !isRevertFiltersOn) || disabled}
          onClick={handleSubmit}
        >
          Apply
        </Button>
      </FiltersControls>
    </FormWrapper>
  );
};
