import styled from "@emotion/styled";
import * as Sentry from "@sentry/react";
import React, { FC, Fragment } from "react";
import { NavLink, Route, Routes } from "react-router-dom";

import { Divider } from "components";

import { DOCS_PRODUCT_ROUTES } from "types";

import { CorridorDiscovery } from "./CorridorDiscovery";
import { DatasetDefinition } from "./DatasetDefinition";
import { ExportContent } from "./ExportContent";
import { LicenseTier } from "./LicenseTier";
import { PageContent } from "./PageContent";
import { PageHeader } from "./PageHeader";
import { SelectLink } from "./SelectLink";

const Container = styled(PageContent)`
  display: grid;
  grid-template-rows: auto auto;
`;

const NavMenu = styled.div`
  display: flex;
  padding: 1rem;
  justify-content: center;
`;

const NavItem = styled(NavLink)`
  font-size: 18px;
  font-weight: 600;
  color: var(--color-textSecondary);

  &:hover {
    text-decoration: none;
    color: var(--color-text);
  }

  &.active {
    color: var(--color-text);
    text-decoration: underline;
    text-underline-offset: 8px;
    text-decoration-thickness: 3px;
    text-decoration-color: #11193b;
  }

  margin: 0 2.5rem;
`;

const RoutesContainer = styled.div`
  width: 100%;
  padding: 2rem;
  max-width: 1400px;
  margin: 0 auto;
`;

const navItems = [
  {
    label: "License Tiers",
    url: DOCS_PRODUCT_ROUTES.License,
  },
  {
    label: "Export Content",
    url: DOCS_PRODUCT_ROUTES.Export,
  },
  {
    label: "Dataset Definition",
    url: DOCS_PRODUCT_ROUTES.DatasetDefinition,
  },
  {
    label: "Corridor Discovery",
    url: DOCS_PRODUCT_ROUTES.CorridorDiscovery,
  },
  {
    label: "Select Link",
    url: DOCS_PRODUCT_ROUTES.SelectLink,
  },
];

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

export const Product: FC = () => {
  return (
    <>
      <PageHeader title="Teralytics Studio: Product reference" />
      <Container>
        <NavMenu>
          {navItems.map(({ label, url }, i) => (
            <Fragment key={i}>
              <NavItem to={url}>{label}</NavItem>
              {i !== navItems.length - 1 && <Divider />}
            </Fragment>
          ))}
        </NavMenu>
        <Divider />
        <RoutesContainer>
          <SentryRoutes>
            <Route path={DOCS_PRODUCT_ROUTES.License} element={<LicenseTier />} />
            <Route path={DOCS_PRODUCT_ROUTES.Export} element={<ExportContent />} />
            <Route path={DOCS_PRODUCT_ROUTES.DatasetDefinition} element={<DatasetDefinition />} />
            <Route path={DOCS_PRODUCT_ROUTES.CorridorDiscovery} element={<CorridorDiscovery />} />
            <Route path={DOCS_PRODUCT_ROUTES.SelectLink} element={<SelectLink />} />
          </SentryRoutes>
        </RoutesContainer>
      </Container>
    </>
  );
};
