import React, { FC } from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { Tag, TagProps } from "@blueprintjs/core";

type BadgeColor = "primary" | "secondary" | "gray" | "red" | "yellow" | "blue" | "green" | "purple";

interface BadgeBaseProps {
  color?: BadgeColor;
}

export type BadgeProps = BadgeBaseProps & TagProps;

const colorStyles = {
  primary: css`
    background-color: var(--color-primary);
    color: var(--color-textContrast);

    & .bp4-icon > svg:not([fill]) {
      fill: var(--color-textContrast);
    }
    &.bp4-tag:hover {
      background-color: var(--color-primary);
    }
  `,
  secondary: css`
    background-color: var(--color-secondaryLight);
    color: var(--color-text);
    border: 1px solid var(--color-secondary);

    & .bp4-icon > svg:not([fill]) {
      fill: var(--color-secondary);
    }
    &.bp4-tag:hover {
      background-color: var(--color-secondaryLight);
    }
  `,
  gray: css`
    background-color: #ffffff;
    color: var(--color-gray-800);
    border: 1px solid var(--color-gray-300);

    & .bp4-icon > svg:not([fill]) {
      fill: var(--color-primary);
    }
    &.bp4-tag:hover {
      background-color: var(--color-gray-100);
    }
  `,
  red: css`
    background-color: var(--color-red-100);
    color: var(--color-red-800);

    & .bp4-icon > svg:not([fill]) {
      fill: var(--color-red-400);
    }
    &.bp4-tag:hover {
      background-color: var(--color-red-100);
    }
  `,
  yellow: css`
    background-color: var(--color-yellow-100);
    color: var(--color-yellow-800);

    & .bp4-icon > svg:not([fill]) {
      fill: var(--color-yellow-400);
    }
    &.bp4-tag:hover {
      background-color: var(--color-yellow-100);
    }
  `,
  blue: css`
    background-color: var(--color-blue-100);
    color: var(--color-blue-800);

    & .bp4-icon > svg:not([fill]) {
      fill: var(--color-blue-400);
    }
    &.bp4-tag:hover {
      background-color: var(--color-blue-100);
    }
  `,
  green: css`
    background-color: var(--color-green-100);
    color: var(--color-green-800);

    & .bp4-icon > svg:not([fill]) {
      fill: var(--color-green-400);
    }

    &.bp4-tag:hover {
      background-color: var(--color-green-100);
    }
  `,
  purple: css`
    background-color: var(--color-purple-100);
    color: var(--color-purple-800);

    & .bp4-icon > svg:not([fill]) {
      fill: var(--color-purple-400);
    }
    &.bp4-tag:hover {
      background-color: var(--color-purple-100);
    }
  `,
};

const StyledBadge = styled(Tag)<BadgeBaseProps>`
  display: inline-flex;
  align-items: center;
  font-weight: 500;
  border-radius: 9999px;

  ${({ color = "primary" }) => colorStyles[color]}
`;

export const Badge: FC<BadgeProps> = ({ children, ...props }) => {
  return <StyledBadge {...props}>{children}</StyledBadge>;
};
