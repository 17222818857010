import { useAuth0 } from "@auth0/auth0-react";
import styled from "@emotion/styled";
import esriLogo from "assets/png/ESRI_logo.png";
import mapboxgl, { LngLatBounds, Marker } from "mapbox-gl";
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { toast } from "react-toastify";

import {
  GeocodingSearch,
  LayersData,
  MainRoadsColorIds,
  MainRoadsLayerIds,
  OUT_ZONES_LAYER_FILL,
  ROADS_SEGMENTS_LAYER_ID,
  ROADS_SOURCE_ID,
  ROADS_VOLUMES_LAYER_ID,
  ZONE_DETAILS_ARROWS_HOVER_LAYER,
  addBoundariesLayer,
  buildFilters,
  getAvailableZoomLevels,
  getBounds,
  getLayerFromZoom,
  getQueryType,
  initOD,
  initRoadSegments,
  updateTopFlows,
} from "features";

import { NewExportDialog } from "features/export";
import { AnalyticsPanel } from "features/map-visualization/AnalyticsPanel";
import { MapControlsPanel } from "features/map-visualization/MapControlsPanel";

import {
  MapErrorPage,
  ODLegend,
  ODPopup,
  ODPopupProps,
  Popup,
  RoadsHoverPopup,
  RoadsHoverPopupProps,
  SpinnerOverlay,
  VolumePopupContent,
  getMapHomeControl,
} from "components";

import { useAppDispatch, useAppSelector, usePageTracking, usePrevious } from "hooks";

import { DataState } from "store/interfaces";
import { analyticsActions } from "store/sections/analytics";
import { exportActions } from "store/sections/export";
import { mapActions } from "store/sections/map";

import {
  Counts,
  FlowsSettings,
  HoveredFlow,
  MapVisualizationType,
  MeasureType,
  ODTileLayer,
  QueryType,
  RoadSegmentDetailsRequestBreakdown,
  SelectedArea,
  SelectedVolume,
  Volume,
} from "types";

import { addCustomGAEvent } from "utils/addCustomGAEvent";
import { reportAboutErrorState } from "utils/reports";

import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import "mapbox-gl/dist/mapbox-gl.css";

/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable import/no-webpack-loader-syntax */
(mapboxgl as any).workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

/* eslint-enable @typescript-eslint/no-var-requires */
/* eslint-enable import/no-webpack-loader-syntax */

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN as string;

const EsriLogo = styled.img`
  position: absolute;
  bottom: 0px;
  right: 105px;
`;

const MapPageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const Mapbox = styled.div`
  height: 100%;
`;

const initialFlowsSettings = {
  n: 10,
  excludeExternal: false,
  excludeGates: false,
  excludeZones: false,
  excludeSameStartEnd: false,
};

const roadLayers = [
  MainRoadsLayerIds.roadsHairlinesLayerId,
  MainRoadsLayerIds.roadsHighlightedVolumesLayerId,
  MainRoadsLayerIds.roadsSegmentsLayerId,
  MainRoadsLayerIds.roadsVolumesLayerId,
  MainRoadsLayerIds.limitedAccessRoadsVolumesLayerId,
];

export const MapPage: FC = () => {
  const dispatch = useAppDispatch();

  const { user } = useAuth0();

  // General and Map
  const [mapLoaded, setMapLoaded] = useState(false);
  const [, setMapRender] = useState(false);
  const [baseStylesUpdated, setBaseStylesUpdated] = useState<boolean>(false);
  const [isExportDialogOpen, setIsExportDialogOpen] = useState(false);
  const searchMarker = useRef(new Marker({ scale: 0.8, offset: [0, -22], color: "blue" }));

  // Roads and volumes
  const [showRoadVolumes, setShowRoadVolumes] = useState<boolean>(true);
  const [volumeProps, setVolumeProps] = useState<Volume[]>([]);
  const [hoveredSegmentId, setHoveredSegmentId] = useState<string | null>(null);
  const [roadHoverProps, setRoadHoverProps] = useState<RoadsHoverPopupProps | null>(null);
  const [layerName, setLayerName] = useState<string>("");

  // OD
  const [ODPopupProps, setODPopupProps] = useState<ODPopupProps | null>(null);
  const [showZones, setShowZones] = useState<boolean>(true);
  const [colorScale, setColorScale] = useState<any | null>(null);
  const [blockedZoomLevel, setBlockedZoomLevel] = useState<number | null>(null);

  // Zone details and Flows
  const [topFlowsPopupProps, setTopFlowsPopupProps] = useState<ODPopupProps | null>(null);
  const [hoveredFlow, setHoveredFlow] = useState<HoveredFlow | null>(null);
  const [flowsSettings, setFlowsSettings] = useState<FlowsSettings>(initialFlowsSettings);

  usePageTracking();

  // General
  const focusAreas = useAppSelector((state) => state.analytics.focusAreasAndDatasets);
  const selectedFocusAreaId = useAppSelector((state) => state.global.selectedFocusAreaId);
  const previousSelectedFocusAreaId = usePrevious(selectedFocusAreaId);
  const selectedFocusArea = useAppSelector((state) => state.global.selectedFocusArea);
  const userOrganizationName = useAppSelector((state) => state.license.user.data?.organization?.name);
  const geocodingSearchResults = useAppSelector((state) => state.analytics.geocodingSearch);
  const timePeriod = useAppSelector((state) => state.global.timePeriod);
  const previousTimePeriod = usePrevious(timePeriod);
  const measure = useAppSelector((state) => state.analytics.measure);
  const mode = useAppSelector((state) => state.analytics.mapVisualizationMode);

  //Map
  const baseMapStyle = useAppSelector((state) => state.map.baseMapStyle);
  const colorScheme = useAppSelector((state) => state.map.colorScheme);
  const savedBounds = useAppSelector((state) => state.map.mapBounds);

  //Export
  const newExport = useAppSelector((state) => state.export.newExport);

  //Datasets
  const datasetMetadata = useAppSelector((state) => state.analytics.datasetMetadata);
  const datasetCounts = useAppSelector((state) => state.analytics.datasetCounts);
  const datasetCountsByZoneId = useAppSelector((state) => state.analytics.datasetCountsByZoneId);
  const datasetGates = useAppSelector((state) => state.analytics.datasetGates);
  const datasetIds = useAppSelector((state) => state.analytics.datasetIds);
  const currentDatasetFilters = useAppSelector((state) => state.analytics.datasetFilters);
  const datasetRange = useAppSelector((state) => state.analytics.datasetRange);

  // Roads and volumes
  const roadsMetadata = useAppSelector((state) => state.analytics.roadsMetadata);
  const roadsVolumes = useAppSelector((state) => state.analytics.roadsVolumes);
  const roadSegmentIds = useAppSelector((state) => state.analytics.roadSegmentIds);
  const roadSegmentIdsByRoadClass = useAppSelector((state) => state.analytics.roadSegmentIdsByRoadClass);
  const currentRoadFilters = useAppSelector((state) => state.analytics.roadFilters);
  const selectedRoadVolume = useAppSelector((state) => state.analytics.selectedRoadVolume);
  const selectedRoadVolumeId = useAppSelector((state) => state.analytics.selectedRoadVolumeId);
  const roadsRange = useAppSelector((state) => state.analytics.roadsRange);

  // OD
  const ODMetadata = useAppSelector((state) => state.analytics.ODMetadata);
  const ODCounts = useAppSelector((state) => state.analytics.ODCounts);
  const ODIds = useAppSelector((state) => state.analytics.ODIds);
  const ODCountsByZoneId = useAppSelector((state) => state.analytics.ODCountsByZoneId);
  const queryType = useAppSelector((state) => state.analytics.queryType);
  const currentODFilters = useAppSelector((state) => state.analytics.ODFilters);
  const selectedZone = useAppSelector((state) => state.analytics.selectedZone);
  const ODRange = useAppSelector((state) => state.analytics.ODRange);

  // Zone details and Flows
  const zoneDetails = useAppSelector((state) => state.analytics.zoneDetails);

  // Refs
  const mapContainer: any = useRef(null);
  const map: any = useRef(null);
  const roadsPopupRef = useRef<HTMLDivElement>(null);
  const roadsVolumesRef = useRef<HTMLDivElement>(null);
  const ODPopupRef = useRef<HTMLDivElement>(null);
  const topFlowPopupRef = useRef<HTMLDivElement>(null);
  const clearFunction = useRef<() => void>();
  const topFlowsClearFunction = useRef<() => void>();
  const showRoadVolumesRef = useRef<boolean>(true);
  const showZonesRef = useRef<boolean>(true);
  const blockedZoomLevelRef = useRef<number | null>(null);

  const previousSelectedArea = usePrevious(selectedFocusArea);

  const isPedestriansMode = measure === MeasureType.PEDESTRIANS;

  const layers = useMemo(
    () =>
      selectedFocusArea?.datasetId ? datasetMetadata.data?.tileService.layers : ODMetadata.data?.tileService.layers,
    [selectedFocusArea?.datasetId, datasetMetadata.data?.tileService.layers, ODMetadata.data?.tileService.layers],
  );

  const zoningLevel = getLayerFromZoom(
    selectedZone ? selectedZone.zoom : blockedZoomLevel ? blockedZoomLevel : map.current?.getZoom(),
    layers,
  )?.level;

  const datasetZoningLevels = useMemo(() => {
    if (datasetMetadata.data?.zoningLevels) {
      return selectedFocusArea?.zoningLevel === "Custom" && ODMetadata.data?.zoningLevels?.[0]
        ? [...datasetMetadata.data.zoningLevels, ODMetadata.data?.zoningLevels?.[0]]
        : datasetMetadata.data.zoningLevels;
    }
  }, [ODMetadata.data?.zoningLevels, datasetMetadata.data?.zoningLevels, selectedFocusArea?.zoningLevel]);

  const zoningLevels = useMemo(
    () => (selectedFocusArea?.datasetId ? datasetZoningLevels : ODMetadata.data?.zoningLevels),
    [selectedFocusArea?.datasetId, datasetZoningLevels, ODMetadata.data?.zoningLevels],
  );

  const datasetError = useMemo(
    () =>
      datasetMetadata.state === DataState.ERROR ||
      datasetCounts.state === DataState.ERROR ||
      datasetIds.state === DataState.ERROR ||
      datasetCountsByZoneId.state === DataState.ERROR ||
      datasetGates.state === DataState.ERROR,
    [datasetMetadata.state, datasetCounts.state, datasetIds.state, datasetCountsByZoneId.state, datasetGates.state],
  );

  const ODError = useMemo(
    () =>
      (ODMetadata.state === DataState.ERROR && ODMetadata.error.status !== 403) ||
      ODIds.state === DataState.ERROR ||
      ODCounts.state === DataState.ERROR ||
      ODCountsByZoneId.state === DataState.ERROR,
    [ODMetadata.state, ODMetadata.error, ODIds.state, ODCounts.state, ODCountsByZoneId.state],
  );

  const roadsError = useMemo(
    () =>
      (roadsMetadata.state === DataState.ERROR && roadsMetadata.error.status !== 403) ||
      roadSegmentIds.state === DataState.ERROR ||
      roadsVolumes.state === DataState.ERROR,
    [roadsMetadata.state, roadsMetadata.error, roadSegmentIds.state, roadsVolumes.state],
  );

  const topFlowsError = useMemo(() => zoneDetails.state === DataState.ERROR, [zoneDetails.state]);

  const mapError = useMemo(
    () => focusAreas.state === DataState.ERROR || datasetError || ODError || roadsError || topFlowsError,
    [focusAreas.state, datasetError, ODError, roadsError, topFlowsError],
  );

  const handleSelectZone = useCallback(
    (selectedZone: SelectedArea | null) => {
      dispatch(analyticsActions.setSelectedZone(selectedZone));
    },
    [dispatch],
  );

  const handleSelectRoadVolume = useCallback(
    (selectedRoadVolume: SelectedVolume | null) => {
      dispatch(analyticsActions.setSelectedRoadVolume(selectedRoadVolume));
    },
    [dispatch],
  );

  const handleSelectRoadVolumeId = useCallback(
    (selectedRoadVolumeId: string | null) => {
      dispatch(analyticsActions.setSelectedRoadVolumeId(selectedRoadVolumeId));
    },
    [dispatch],
  );

  useEffect(() => {
    if (ODRange || datasetRange || selectedZone) {
      if (blockedZoomLevel) return;
      const zoom = map.current?.getZoom();
      setBlockedZoomLevel(zoom);
      blockedZoomLevelRef.current = zoom;
    } else {
      setBlockedZoomLevel(null);
      blockedZoomLevelRef.current = null;
    }
  }, [ODRange, datasetRange, selectedZone, blockedZoomLevel]);

  useEffect(() => {
    if (roadsRange) {
      handleSelectRoadVolume(null);
      map.current?.fire("closeAllRoadsPopups");
    }
  }, [roadsRange, handleSelectRoadVolume]);

  //Resetting the map if selected area of focus changes
  useEffect(() => {
    if (
      (previousSelectedFocusAreaId && selectedFocusAreaId && previousSelectedFocusAreaId !== selectedFocusAreaId) ||
      (previousTimePeriod && timePeriod && previousTimePeriod !== timePeriod)
    ) {
      map.current = null;
    }
  }, [selectedFocusAreaId, previousSelectedFocusAreaId, timePeriod, previousTimePeriod]);

  useEffect(() => {
    if (map.current) return; // initialize map only once

    if (
      selectedFocusArea &&
      ((selectedFocusArea.datasetId && datasetGates.state === DataState.AVAILABLE) || !selectedFocusArea?.datasetId)
    ) {
      const newBounds = savedBounds ? LngLatBounds.convert(savedBounds) : getBounds(selectedFocusArea.geometry);
      const padding = { top: 40, bottom: 40, left: 340, right: 40 };

      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: baseMapStyle,
        bounds: newBounds,
        fitBoundsOptions: {
          padding: savedBounds ? 0 : padding,
        },
        pitch: 0,
        bearing: 0,
        logoPosition: "bottom-right",
        transformRequest: (url, resourceType): any => {
          if (resourceType === "Tile" && url.startsWith(process.env.REACT_APP_API_HOST as string)) {
            return {
              headers: {
                authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
              },
              url,
            };
          }
        },
      });

      // disable map rotation using right click + drag
      map.current.dragRotate.disable();

      // disable map rotation using touch rotation gesture
      map.current.touchZoomRotate.disableRotation();

      // Add full extent control to the map.
      map.current.addControl(
        getMapHomeControl(map.current, getBounds(selectedFocusArea.geometry), {
          padding,
        }),
        "top-left",
      );

      // Add zoom controls to the map.
      map.current.addControl(new mapboxgl.NavigationControl({ showCompass: false }), "top-left");

      // map.current.addControl(new MapboxGeocoder(geocoderOptions), "top-left");

      map.current.on("load", () => {
        setMapLoaded(true);
      });
      map.current.on("idle", () => {
        setMapRender(false);
      });
      map.current.on("render", () => {
        setMapRender(true);
      });
      map.current.on("style.load", () => {
        if (selectedFocusArea) {
          addBoundariesLayer({ map: map.current, selectedArea: selectedFocusArea });

          setBaseStylesUpdated(true);
        }
      });
    }
  }, [selectedFocusArea, baseMapStyle, datasetGates, savedBounds, timePeriod, dispatch]);

  useEffect(() => {
    return () => {
      const bounds = map.current?.getBounds().toArray();

      if (bounds) {
        dispatch(mapActions.setMapBounds(bounds));
      }
    };
  }, [dispatch]);

  //Fetch Segment IDs
  useEffect(() => {
    if (mapLoaded && mode === MapVisualizationType.ROADS && roadSegmentIds.state === DataState.EMPTY && timePeriod) {
      dispatch(
        analyticsActions.fetchSegmentIds({
          timePeriod,
          areaOfInterest: selectedFocusArea?.areas || null,
          onlyFromTo: true,
          includeReverse: true,
          compression: "gzip",
          datasetId: selectedFocusArea?.datasetId,
        }),
      );
    }
  }, [mapLoaded, mode, roadSegmentIds.state, timePeriod, selectedFocusArea, dispatch]);

  //Fetch OD Zones Ids
  useEffect(() => {
    if (
      selectedFocusArea &&
      !selectedFocusArea?.datasetId &&
      mode === MapVisualizationType.OD &&
      ODIds.state === DataState.EMPTY &&
      ODMetadata.state === DataState.AVAILABLE &&
      timePeriod
    ) {
      dispatch(
        analyticsActions.fetchODIds(getAvailableZoomLevels(ODMetadata.data.tileService.layers), {
          timePeriod,
          areaOfInterest: selectedFocusArea?.areas || null,
        }),
      );
    }
  }, [selectedFocusArea, mode, ODIds.state, ODMetadata, previousSelectedArea, timePeriod, dispatch]);

  //Fetch Dataset Metadata
  useEffect(() => {
    if (selectedFocusArea?.datasetId && datasetMetadata.state === DataState.EMPTY) {
      dispatch(analyticsActions.fetchDatasetMetadata(selectedFocusArea.id));
    }
  }, [selectedFocusArea, mode, datasetMetadata.state, dispatch]);

  //Fetch Dataset Zones Ids
  useEffect(() => {
    if (
      selectedFocusArea?.datasetId &&
      mode === MapVisualizationType.OD &&
      (datasetIds.state === DataState.EMPTY || previousSelectedArea?.id !== selectedFocusArea.id) &&
      datasetMetadata.state === DataState.AVAILABLE
    ) {
      dispatch(
        analyticsActions.fetchDatasetIds(
          selectedFocusArea.id,
          getAvailableZoomLevels(datasetMetadata.data.tileService.layers),
          {
            areaOfInterest: selectedFocusArea?.areas || null,
          },
        ),
      );
    }
  }, [selectedFocusArea, mode, datasetIds.state, datasetMetadata, previousSelectedArea, dispatch]);

  //Fetch Dataset Gates
  useEffect(() => {
    if (selectedFocusArea?.datasetId && datasetGates.state === DataState.EMPTY) {
      dispatch(analyticsActions.fetchDatasetGates(selectedFocusArea.id));
    }
  }, [selectedFocusArea, datasetGates.state, dispatch]);

  //Initialize map for Dataset
  useEffect(() => {
    if (
      selectedFocusArea?.datasetId &&
      mapLoaded &&
      baseStylesUpdated &&
      mode === MapVisualizationType.OD &&
      datasetMetadata.state === DataState.AVAILABLE &&
      ODMetadata.state === DataState.AVAILABLE &&
      datasetIds.state === DataState.AVAILABLE &&
      datasetGates.state === DataState.AVAILABLE &&
      ((!selectedZone &&
        datasetCounts.state === DataState.AVAILABLE &&
        datasetCountsByZoneId.state !== DataState.LOADING) ||
        (selectedZone &&
          datasetCountsByZoneId.state === DataState.AVAILABLE &&
          selectedZone.id === datasetCountsByZoneId.data.selectedZoneId))
    ) {
      if (clearFunction.current) {
        clearFunction.current();
      }

      const initDatasetPayloadBase = {
        isDataset: true as true,
        colorScheme,
        tileService: datasetMetadata.data.tileService,
        externalZonesTileService: ODMetadata.data.tileService,
        roadsTileService: datasetMetadata.data.roadsTileService,
        ids: datasetIds.data,
        gates: datasetGates.data,
        popupRef: ODPopupRef,
        showZones: showZonesRef.current,
        queryType,
        setSelectedZone: handleSelectZone,
        setODPopupProps,
        setColorScale,
      };

      const initDatasetPayload: LayersData =
        selectedZone && datasetCountsByZoneId.state === DataState.AVAILABLE
          ? {
              ...initDatasetPayloadBase,
              selectedZone,
              blockedZoomLevel,
              counts: datasetCountsByZoneId.data.counts,
              availableLayers: [
                getLayerFromZoom(selectedZone.zoom, datasetMetadata.data.tileService.layers),
              ] as ODTileLayer[],
            }
          : {
              ...initDatasetPayloadBase,
              selectedZone: null,
              blockedZoomLevel,
              counts: datasetCounts.data as Counts,
              availableLayers: blockedZoomLevel
                ? ([getLayerFromZoom(blockedZoomLevel, datasetMetadata.data.tileService.layers)] as ODTileLayer[])
                : datasetMetadata.data.tileService.layers,
            };

      const { clear } = initOD(map.current, initDatasetPayload);

      addCustomGAEvent("map", "view", "dataset", user, userOrganizationName);

      clearFunction.current = clear;
    }
  }, [
    selectedFocusArea?.datasetId,
    mode,
    mapLoaded,
    selectedZone,
    queryType,
    datasetCountsByZoneId,
    baseStylesUpdated,
    datasetMetadata,
    datasetCounts,
    datasetIds,
    datasetGates,
    colorScheme,
    user,
    userOrganizationName,
    blockedZoomLevel,
    ODMetadata,
    handleSelectZone,
  ]);

  //Initialize map for OD
  useEffect(() => {
    if (
      !selectedFocusArea?.datasetId &&
      mapLoaded &&
      baseStylesUpdated &&
      mode === MapVisualizationType.OD &&
      ODMetadata.state === DataState.AVAILABLE &&
      ODIds.state === DataState.AVAILABLE &&
      ((!selectedZone && ODCounts.state === DataState.AVAILABLE && ODCountsByZoneId.state !== DataState.LOADING) ||
        (selectedZone &&
          ODCountsByZoneId.state === DataState.AVAILABLE &&
          selectedZone.id === ODCountsByZoneId.data.selectedZoneId))
    ) {
      if (clearFunction.current) {
        clearFunction.current();
      }

      const initODPayloadBase = {
        colorScheme,
        tileService: ODMetadata.data.tileService,
        externalZonesTileService: ODMetadata.data.tileService,
        ids: ODIds.data,
        popupRef: ODPopupRef,
        showZones: showZonesRef.current,
        queryType,
        setODPopupProps,
        setSelectedZone: handleSelectZone,
        setColorScale,
      };

      const initODPayload: LayersData =
        selectedZone && ODCountsByZoneId.state === DataState.AVAILABLE
          ? {
              ...initODPayloadBase,
              selectedZone,
              blockedZoomLevel,
              counts: ODCountsByZoneId.data.counts,
              availableLayers: [
                getLayerFromZoom(selectedZone.zoom, ODMetadata.data.tileService.layers),
              ] as ODTileLayer[],
            }
          : {
              ...initODPayloadBase,
              selectedZone: null,
              blockedZoomLevel,
              counts: ODCounts.data as Counts,
              availableLayers: blockedZoomLevel
                ? ([getLayerFromZoom(blockedZoomLevel, ODMetadata.data.tileService.layers)] as ODTileLayer[])
                : ODMetadata.data.tileService.layers,
            };

      const { clear } = initOD(map.current, initODPayload);

      addCustomGAEvent("map", "view", "OD", user, userOrganizationName);

      clearFunction.current = clear;
    }
  }, [
    selectedFocusArea?.datasetId,
    mode,
    ODMetadata,
    ODCounts,
    ODIds,
    mapLoaded,
    queryType,
    baseStylesUpdated,
    selectedZone,
    ODCountsByZoneId,
    colorScheme,
    user,
    userOrganizationName,
    blockedZoomLevel,
    handleSelectZone,
    handleSelectRoadVolume,
  ]);

  //Initialize map for Roads
  useEffect(() => {
    if (!mapLoaded) return;

    switch (mode) {
      case MapVisualizationType.ROADS: {
        if (
          roadsMetadata.state === DataState.AVAILABLE &&
          roadsVolumes.state === DataState.AVAILABLE &&
          roadSegmentIds.state === DataState.AVAILABLE &&
          (selectedFocusArea?.datasetId ? datasetGates.state === DataState.AVAILABLE : true) &&
          baseStylesUpdated
        ) {
          if (clearFunction.current) {
            clearFunction.current();
          }

          const { clear } = initRoadSegments(map.current, {
            tileService: roadsMetadata.data.tileService,
            roadsVolumes: roadsVolumes.data,
            roadSegmentIds: roadSegmentIds.data,
            roadSegmentIdsByRoadClass,
            popupRef: roadsPopupRef,
            popupHoverRef: roadsVolumesRef,
            gates: selectedFocusArea?.datasetId ? datasetGates.data : null,
            showRoadVolumes: showRoadVolumesRef.current,
            isPedestriansMode,
            minVolume: 0,
            setRoadHoverProps,
            setVolumeProps,
            setSelectedRoadVolume: handleSelectRoadVolume,
            setSelectedRoadVolumeId: handleSelectRoadVolumeId,
            layerIds: MainRoadsLayerIds,
            colorIds: MainRoadsColorIds,
            isSelectLink: false,
          });

          addCustomGAEvent("map", "view", "roads", user, userOrganizationName);

          clearFunction.current = clear;
          setLayerName(roadsMetadata.data.tileService.layerName);
        }

        break;
      }
      case MapVisualizationType.OD: {
        if (isPedestriansMode) {
          dispatch(analyticsActions.setMeasure(MeasureType.AADT));
        }

        break;
      }
    }
  }, [
    selectedFocusArea,
    mode,
    mapLoaded,
    roadsMetadata,
    roadsVolumes,
    datasetGates,
    roadSegmentIds,
    roadSegmentIdsByRoadClass,
    baseStylesUpdated,
    measure,
    isPedestriansMode,
    user,
    userOrganizationName,
    handleSelectRoadVolume,
    handleSelectRoadVolumeId,
    dispatch,
  ]);

  useEffect(() => {
    if (
      !showRoadVolumes &&
      roadsVolumes.state === DataState.AVAILABLE &&
      map.current.getLayer(ROADS_VOLUMES_LAYER_ID) &&
      baseStylesUpdated
    ) {
      changeRoadVolumesAndHairlineVisibility(false);
    } else if (selectedRoadVolume && roadsVolumes.state === DataState.AVAILABLE && baseStylesUpdated) {
      getSegmentsDetails(selectedRoadVolume);
      highlightSelectedSegment(selectedRoadVolume);
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [roadsVolumes.state, showRoadVolumes, baseStylesUpdated, selectedRoadVolume, roadsVolumes.data]);
  /* eslint-enable react-hooks/exhaustive-deps */

  useEffect(() => {
    const isStandardDataset = selectedFocusArea?.datasetId && selectedFocusArea.zoningLevel !== "Custom";
    if (
      mapLoaded &&
      map.current?.getLayer(OUT_ZONES_LAYER_FILL) &&
      (isStandardDataset ? datasetMetadata.state : ODMetadata.state) === DataState.AVAILABLE
    ) {
      if (hoveredFlow && hoveredFlow.external) {
        map.current.setFilter(OUT_ZONES_LAYER_FILL, [
          "in",
          isStandardDataset
            ? datasetMetadata.data?.tileService.layers[0].idField
            : ODMetadata.data?.tileService.layers[0].idField,
          hoveredFlow.id,
        ]);
      } else {
        map.current.setFilter(OUT_ZONES_LAYER_FILL, [
          "in",
          isStandardDataset
            ? datasetMetadata.data?.tileService.layers[0].idField
            : ODMetadata.data?.tileService.layers[0].idField,
          "",
        ]);
      }
    }
  }, [hoveredFlow, mapLoaded, selectedFocusArea, ODMetadata, datasetMetadata]);

  useEffect(() => {
    if (
      baseStylesUpdated &&
      zoneDetails.state === DataState.AVAILABLE &&
      zoneDetails.data.topFlows[queryType] &&
      (selectedFocusArea?.datasetId
        ? datasetMetadata.state === DataState.AVAILABLE
        : ODMetadata.state === DataState.AVAILABLE)
    ) {
      if (topFlowsClearFunction.current) {
        topFlowsClearFunction.current();
      }

      if (selectedZone?.id === zoneDetails.data.zoneId) {
        const { clear } = updateTopFlows(map.current, {
          queryType,
          zoneDetails: zoneDetails.data,
          topFlowPopupRef,
          zoningLevels,
          setHoveredFlow,
          setTopFlowsPopupProps,
          selectedZoneId: selectedZone.id,
          selectedZoneType: selectedZone.itemType,
        });

        topFlowsClearFunction.current = clear;
      }
    }
  }, [
    selectedZone,
    baseStylesUpdated,
    selectedFocusArea?.datasetId,
    zoneDetails,
    ODMetadata.state,
    datasetMetadata.state,
    queryType,
    zoningLevels,
  ]);

  useEffect(() => {
    if (mapLoaded && map.current?.getLayer(ZONE_DETAILS_ARROWS_HOVER_LAYER)) {
      if (hoveredFlow) {
        map.current?.setFilter(ZONE_DETAILS_ARROWS_HOVER_LAYER, [
          "all",
          ["==", ["get", "id"], hoveredFlow.id],
          ["==", ["get", "external"], hoveredFlow.external],
          ["==", ["get", "isGate"], hoveredFlow.isGate],
        ]);
      } else {
        map.current?.setFilter(ZONE_DETAILS_ARROWS_HOVER_LAYER, ["==", "id", ""]);
      }
    }
  }, [mapLoaded, hoveredFlow]);

  useEffect(() => {
    if (selectedZone) {
      const areaOfInterest = selectedFocusArea?.areas || null;
      const requestMeasure = measure === MeasureType.PEDESTRIANS ? MeasureType.AADT : measure;

      if (!selectedFocusArea?.datasetId && ODMetadata.state === DataState.AVAILABLE && timePeriod) {
        const level =
          ODMetadata.data &&
          getLayerFromZoom(
            blockedZoomLevelRef.current ? blockedZoomLevelRef.current : selectedZone.zoom,
            ODMetadata.data.tileService.layers,
          )?.level;
        const dimensions =
          ODMetadata.data?.measures[0].dimensions.filter((d) => d.enabled).map((d) => d.columnName) || [];
        const breakdowns = dimensions.map((d) => ({
          dimensions: [d],
          includeUnfiltered: false,
        }));
        const filter = buildFilters(currentODFilters);
        if (level) {
          dispatch(
            analyticsActions.fetchZoneDetails({
              zoneId: selectedZone.id,
              timePeriod,
              measure: ODMetadata.data?.measures[0].columnName || "",
              level,
              summaries: {
                [queryType]: {
                  filteredTotal: true,
                  unfilteredTotal: false,
                  breakdowns,
                },
              },
              topFlows: {
                [queryType]: flowsSettings,
              },
              filter,
            }),
          );

          dispatch(
            analyticsActions.fetchODCountsByZoneId({
              selectedZoneId: selectedZone.id,
              timePeriod,
              level,
              measure: requestMeasure,
              queryType,
              filter,
              areaOfInterest,
            }),
          );
        }
      }
      if (selectedFocusArea?.datasetId && datasetMetadata.state === DataState.AVAILABLE) {
        const isGate = selectedZone.itemType === "gate";
        const dimensions =
          datasetMetadata.data?.measures[0].dimensions.filter((d) => d.enabled).map((d) => d.columnName) || [];
        const breakdowns = dimensions.map((d) => ({
          dimensions: [d],
          includeUnfiltered: false,
        }));
        const level = getLayerFromZoom(
          blockedZoomLevelRef.current ? blockedZoomLevelRef.current : selectedZone.zoom,
          datasetMetadata.data.tileService.layers,
        )?.level;
        const filter = buildFilters(currentDatasetFilters);

        dispatch(
          analyticsActions.fetchGateDetails(selectedFocusArea.id, {
            id: selectedZone.id,
            isGate,
            measure: datasetMetadata.data?.measures[0].columnName || "",
            level,
            summaries: {
              [queryType]: {
                filteredTotal: true,
                unfilteredTotal: false,
                breakdowns,
              },
            },
            topFlows: {
              [queryType]: flowsSettings,
            },
            filter,
          }),
        );

        dispatch(
          analyticsActions.fetchDatasetCountsByZoneId(selectedFocusArea.id, {
            selectedId: selectedZone.id,
            isGate,
            level,
            queryType,
            areaOfInterest,
            compression: "gzip",
            measure: requestMeasure,
            filter,
          }),
        );
      }
    }
  }, [
    selectedFocusArea,
    selectedZone,
    ODMetadata,
    measure,
    queryType,
    datasetMetadata,
    datasetGates.data,
    currentODFilters,
    currentDatasetFilters,
    flowsSettings,
    timePeriod,
    dispatch,
  ]);

  useEffect(() => {
    if (newExport.data) {
      toast.success("New export job added", {
        position: toast.POSITION.TOP_CENTER,
      });
      dispatch(exportActions.clearNewExport());
      toast.clearWaitingQueue();
    }
  }, [newExport.data, dispatch]);

  useEffect(() => {
    if (newExport.error) {
      toast.error("Failed to add new export job", {
        position: toast.POSITION.TOP_CENTER,
      });
      dispatch(exportActions.clearNewExport());
      toast.clearWaitingQueue();
    }
  }, [newExport.error, dispatch]);

  // Clear selected zone or road volume when switching between modes
  useEffect(() => {
    if (mode === MapVisualizationType.ROADS) {
      handleSelectZone(null);
    } else if (mode === MapVisualizationType.OD) {
      handleSelectRoadVolume(null);
    }
  }, [mode, handleSelectZone, handleSelectRoadVolume]);

  // Catching errors
  useEffect(() => {
    if (mapError) {
      reportAboutErrorState(
        {
          extraData: `Error statutes: focus areas: ${
            focusAreas.state === DataState.ERROR
          }, dataset: ${datasetError}, OD: ${ODError}, roads: ${roadsError}, top flows: ${topFlowsError}`,
        },
        "The map failed to show the data",
      );
    }
  }, [mapError]); // eslint-disable-line react-hooks/exhaustive-deps

  const changeRoadVolumesAndHairlineVisibility = (isVisible: boolean) => {
    roadLayers.forEach((layerId) => {
      if (map.current.getLayer(layerId)) {
        map.current.setLayoutProperty(layerId, "visibility", isVisible ? "visible" : "none");
      }
    });
  };

  const changeShowRoadVolumes = useCallback(() => {
    const isVisible = !showRoadVolumes;

    changeRoadVolumesAndHairlineVisibility(isVisible);
    setShowRoadVolumes(isVisible);
    showRoadVolumesRef.current = isVisible;
  }, [showRoadVolumes]);

  const updateFeatureStateForRoads = useCallback(
    (segmentId: string | null, stateName: string, status?: boolean) => {
      if (status !== undefined) {
        map.current.setFeatureState(
          {
            source: ROADS_SOURCE_ID,
            sourceLayer: layerName,
            id: segmentId,
          },
          {
            [stateName]: status,
          },
        );
      } else {
        map.current.removeFeatureState(
          {
            source: ROADS_SOURCE_ID,
            sourceLayer: layerName,
            id: segmentId,
          },
          stateName,
        );
      }
    },
    [layerName],
  );

  const changeShowZones = useCallback(() => {
    const isVisible = !showZones;
    setShowZones(isVisible);
    showZonesRef.current = isVisible;

    layers?.forEach((layer) => {
      map.current.setLayoutProperty(layer.name, "visibility", isVisible ? "visible" : "none");
    });
  }, [layers, showZones]);

  const handleHoverSegmentFromPopup = (segmentId: string | null) => {
    if (map.current.getLayer(ROADS_SEGMENTS_LAYER_ID)) {
      if (hoveredSegmentId) {
        updateFeatureStateForRoads(hoveredSegmentId, "hoverHighlight", false);
      }

      if (segmentId) {
        updateFeatureStateForRoads(segmentId, "hoverHighlight", true);
      }
    }
    setHoveredSegmentId(segmentId);
  };

  const handleClickSegmentFromPopup = (selectedVolume: SelectedVolume, id: string) => {
    handleSelectRoadVolumeId(id);

    if (JSON.stringify(selectedRoadVolume) !== JSON.stringify(selectedVolume)) {
      handleSelectRoadVolume(selectedVolume);
      highlightSelectedSegment(selectedVolume);
    }
  };

  const highlightSelectedSegment = (selectedVolume: SelectedVolume | null) => {
    if (map.current?.getLayer(ROADS_SEGMENTS_LAYER_ID)) {
      if (selectedRoadVolume) {
        updateFeatureStateForRoads(selectedRoadVolume.ftSegmentId, "selectHighlight");
      }

      if (selectedVolume) {
        updateFeatureStateForRoads(selectedVolume.ftSegmentId, "selectHighlight", true);
      }
    }
  };

  const getSegmentsDetails = useCallback(
    (selectedVolume: SelectedVolume) => {
      if (timePeriod) {
        const segmentIds = [selectedVolume.ftSegmentId];
        const dimensions =
          roadsMetadata.data?.measures[measure === MeasureType.AADT ? 0 : 1].dimensions
            .filter((d) => d.enabled)
            .map((d) => d.columnName) || [];
        const breakdowns: RoadSegmentDetailsRequestBreakdown[] = dimensions.map((d) => ({
          dimensions: [d],
          includeUnfiltered: false,
        }));

        if (selectedVolume?.tfSegmentId) {
          segmentIds.push(selectedVolume.tfSegmentId);
        }

        dispatch(
          analyticsActions.fetchSegmentsDetails({
            timePeriod,
            summary: {
              breakdowns,
              filteredTotal: true,
              unfilteredTotal: false,
            },
            measure,
            segmentIds,
            filter: buildFilters(currentRoadFilters),
            datasetId: selectedFocusArea?.datasetId ?? undefined,
          }),
        );
      }
    },
    [currentRoadFilters, measure, roadsMetadata.data?.measures, selectedFocusArea?.datasetId, timePeriod, dispatch],
  );

  const handleCloseRightSidebar = useCallback(() => {
    if (selectedRoadVolume) {
      handleSelectRoadVolume(null);

      if (map.current.getLayer(ROADS_SEGMENTS_LAYER_ID)) {
        updateFeatureStateForRoads(selectedRoadVolume.ftSegmentId, "selectHighlight");
      }

      handleSelectRoadVolumeId(null);
    } else if (selectedZone) {
      handleSelectZone(null);
    }
  }, [
    selectedRoadVolume,
    selectedZone,
    handleSelectRoadVolume,
    handleSelectRoadVolumeId,
    handleSelectZone,
    updateFeatureStateForRoads,
  ]);

  const handleChangeRoadVolumeId = useCallback(
    (id: string) => {
      if (id !== selectedRoadVolumeId) {
        handleSelectRoadVolumeId(id);
      }
    },
    [selectedRoadVolumeId, handleSelectRoadVolumeId],
  );

  const handleGeocodingSearch = (searchText: string) => {
    const proximity = map.current.getCenter().toArray().join(",");

    dispatch(
      analyticsActions.fetchGeocodingSearchResults(
        searchText,
        process.env.REACT_APP_MAPBOX_ACCESS_TOKEN as string,
        proximity,
      ),
    );
  };

  const handleClearGeocodingSearch = () => {
    dispatch(analyticsActions.clearGeocodingSearchResults());
  };

  const handleRemoveSearchMarker = () => {
    searchMarker.current.remove();
  };

  const handleGeocodingFlyTo = (coordinates: [number, number], placeName: string) => {
    if (map.current) {
      map.current.flyTo({
        center: coordinates,
        zoom: 14,
      });

      searchMarker.current.remove();
      searchMarker.current.setLngLat(coordinates).addTo(map.current);
    }

    addCustomGAEvent("geocoding-search", "search", placeName, user, userOrganizationName);
  };

  const handleToggleQueryType = useCallback(
    (type: QueryType) => {
      dispatch(analyticsActions.setQueryType(type));
    },
    [dispatch],
  );

  const filterLoading = useMemo(() => {
    return (
      datasetCounts.state === DataState.LOADING ||
      datasetGates.state === DataState.LOADING ||
      datasetMetadata.state === DataState.LOADING ||
      roadSegmentIds.state === DataState.LOADING ||
      roadsMetadata.state === DataState.LOADING ||
      roadsVolumes.state === DataState.LOADING ||
      ODCounts.state === DataState.LOADING ||
      ODMetadata.state === DataState.LOADING ||
      ODCountsByZoneId.state === DataState.LOADING ||
      datasetCountsByZoneId.state === DataState.LOADING
    );
  }, [
    datasetGates.state,
    datasetMetadata.state,
    roadSegmentIds.state,
    roadsMetadata.state,
    roadsVolumes.state,
    ODCounts.state,
    ODMetadata.state,
    datasetCounts.state,
    ODCountsByZoneId.state,
    datasetCountsByZoneId.state,
  ]);

  return (
    <MapPageContainer>
      <MapControlsPanel
        map={map.current}
        mapLoaded={mapLoaded}
        filterLoading={filterLoading}
        selectedZone={selectedZone}
        showZones={showZones}
        showRoadVolumes={showRoadVolumes}
        handleSelectZone={handleSelectZone}
        handleToggleQueryType={handleToggleQueryType}
        setIsExportDialogOpen={setIsExportDialogOpen}
        setBaseStylesUpdated={setBaseStylesUpdated}
        changeShowRoadVolumes={changeShowRoadVolumes}
        changeShowZones={changeShowZones}
        leftButtonLabel="OD Matrix"
        rightButtonLabel="Roads"
        leftButtonDisabled={false}
        rightButtonDisabled={false}
        disableMapLayers={false}
        zoningLevel={zoningLevel}
        exportButtonDisabled={false}
      />
      <AnalyticsPanel
        filterLoading={filterLoading}
        isPedestriansMode={isPedestriansMode}
        selectedRoadVolume={selectedRoadVolume}
        selectedRoadVolumeId={selectedRoadVolumeId}
        selectedZone={selectedZone}
        queryType={queryType}
        hoveredFlow={hoveredFlow}
        flowsSettings={flowsSettings}
        zoningLevels={zoningLevels}
        setHoveredFlow={setHoveredFlow}
        setFlowsSettings={setFlowsSettings}
        handleChangeRoadVolumeId={handleChangeRoadVolumeId}
        handleCloseRightSidebar={handleCloseRightSidebar}
      />
      {mode === MapVisualizationType.OD && mapLoaded && colorScale && (
        <ODLegend
          colorSchemeName={colorScheme}
          scale={colorScale.scale}
          queryType={selectedZone ? getQueryType(queryType) : queryType}
          zoningLevel={zoningLevel}
          zoningLevels={zoningLevels}
          subtitle="Counts per square mile"
        />
      )}
      {filterLoading && <SpinnerOverlay />}
      <Popup>
        <div ref={roadsPopupRef}>
          <VolumePopupContent
            selectedVolume={selectedRoadVolume}
            volume={volumeProps}
            onHover={handleHoverSegmentFromPopup}
            onClick={handleClickSegmentFromPopup}
            isPedestriansMode={isPedestriansMode}
          />
        </div>
      </Popup>
      <Popup>
        <div ref={roadsVolumesRef}>
          <RoadsHoverPopup
            volume={roadHoverProps?.volume}
            gateId={roadHoverProps?.gateId}
            isPedestriansMode={isPedestriansMode}
          />
        </div>
      </Popup>

      <Popup>
        <div ref={ODPopupRef}>
          {ODPopupProps && !topFlowsPopupProps && (
            <ODPopup
              count={ODPopupProps.count}
              type={ODPopupProps.type}
              countByDensity={ODPopupProps.countByDensity}
              gateId={ODPopupProps.gateId}
              flowInfo={ODPopupProps.flowInfo}
            />
          )}
        </div>
      </Popup>
      <Popup>
        <div ref={topFlowPopupRef}>
          {topFlowsPopupProps && (
            <ODPopup
              count={topFlowsPopupProps.count}
              type={topFlowsPopupProps.type}
              countByDensity={topFlowsPopupProps.countByDensity}
              gateId={topFlowsPopupProps.gateId}
              flowInfo={topFlowsPopupProps.flowInfo}
            />
          )}
        </div>
      </Popup>
      <Mapbox ref={mapContainer} style={{ display: !mapError ? "block" : "none" }} />

      {mapError && <MapErrorPage />}

      {isExportDialogOpen && (
        <NewExportDialog
          style={{ minWidth: "700px" }}
          mode={mode}
          isOpen={isExportDialogOpen}
          ODZoomLevel={getLayerFromZoom(map.current?.getZoom(), layers)?.level}
          measure={measure}
          selectedArea={selectedFocusArea}
          zoningLevels={zoningLevels}
          isSelectLink={false}
          onClose={() => setIsExportDialogOpen(false)}
        />
      )}
      {typeof baseMapStyle === "object" && <EsriLogo src={esriLogo} alt="Esri logo" width={88} />}
      {!mapError && (
        <GeocodingSearch
          geocodingSearchResults={geocodingSearchResults.data}
          geocodingSearchByText={handleGeocodingSearch}
          clearGeocodingSearch={handleClearGeocodingSearch}
          removeSearchMarker={handleRemoveSearchMarker}
          flyTo={handleGeocodingFlyTo}
        />
      )}
    </MapPageContainer>
  );
};
