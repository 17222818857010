import { IconName } from "@blueprintjs/core";
import groupBy from "lodash/groupBy";
import mapValues from "lodash/mapValues";
import orderBy from "lodash/orderBy";
import throttle from "lodash/throttle";
import uniqBy from "lodash/uniqBy";
import { LngLat, MapLayerMouseEvent, MapboxGeoJSONFeature, Map as MapboxMap, PointLike, Popup } from "mapbox-gl";
import { Dispatch, SetStateAction } from "react";

import { RoadsHoverPopupProps } from "components";

import {
  DatasetGate,
  RoadClassCategory,
  RoadSegmentIdsByRoadClass,
  RoadSegmentIdsWithFactype,
  RoadsTileService,
  RoadsVolumes,
  SelectedVolume,
  Volume,
} from "types";

import { GATES_LAYER_ID, addGatesEvents, addGatesLayer, clearGatesEvents, removeGatesLayer } from "./gates";
import {
  MAJOR_NETWORK_VOLUME_OPACITY,
  MAJOR_ROAD_VOLUME_COLOR,
  ROAD_HAIRLINE_COLOR,
  ROAD_NETWORK_VOLUME_HIGHLIGHT_OPACITY,
  ROAD_NETWORK_VOLUME_OPACITY,
  ROAD_SEGMENT_HIGHLIGHT_COLOR,
  ROAD_SEGMENT_PERMANENT_HIGHLIGHT_COLOR,
  ROAD_SEGMENT_SUPER_HIGHLIGHT_COLOR,
  ROAD_VOLUME_COLOR,
  ROAD_VOLUME_HOVER_COLOR,
} from "./layerColors";
import { getGatesSegmentsId, getSelectedVolumeObject, getUniqueFeatures } from "./utils";

export const ROADS_SOURCE_ID = "roads";
export const ROADS_VOLUMES_LAYER_ID = "road_volumes";
export const LIMITED_ACCESS_ROADS_VOLUMES_LAYER_ID = "limited_access_roads_volumes";
export const ROADS_HAIRLINES_LAYER_ID = "road_hairlines";
export const ROADS_HIGHLIGHTED_VOLUMES_LAYER_ID = "road_volumes_highlighted";
export const ROADS_SEGMENTS_LAYER_ID = "road_segments";
export const HIGHLIGHTED_SEGMENTS_LAYER_ID = "highlighted_segments";

const defaultLayers = ["admin_country", "road-label"];

const RADIUS_FOR_SEGMENT_HIGHLIGHT_IN_PIXELS = 2;

export const leftSideDirectionsIcons: IconName[] = [
  "arrow-down",
  "arrow-bottom-right",
  "arrow-bottom-left",
  "arrow-left",
];

export interface RoadLayerIds {
  roadsSourceId: string;
  roadsVolumesLayerId: string;
  limitedAccessRoadsVolumesLayerId: string;
  roadsHairlinesLayerId: string;
  roadsHighlightedVolumesLayerId: string;
  roadsSegmentsLayerId: string;
  highlightedSegmentsLayerId: string;
  roadsMeasureRangeLayerId: string;
}

export interface RoadColors {
  hairlineColor: string;
  volumeColor: string;
  volumeHoverColor: string;
  segmentHighlightColor: string;
  segmentSuperHighlightColor: string;
  permanentHighlightColor: string;
  networkVolumeOpacity: number;
  networkVolumeHighlightOpacity: number;
  measureRangeColor: string;
  majorRoadVolumeColor: string;
  majorRoadVolumeOpacity: number;
}

interface RoadsLayersData {
  tileService: RoadsTileService;
  layerIds: RoadLayerIds;
  colorIds: RoadColors;
  roadsVolumes: RoadsVolumes;
  roadSegmentIds: RoadSegmentIdsWithFactype;
  roadSegmentIdsByRoadClass: RoadSegmentIdsByRoadClass | null;
  popupRef?: any;
  popupHoverRef: any;
  gates: DatasetGate[] | null;
  showRoadVolumes: boolean;
  isPedestriansMode: boolean;
  minVolume: number;
  setRoadHoverProps: Dispatch<SetStateAction<RoadsHoverPopupProps | null>>;
  setVolumeProps?: Dispatch<SetStateAction<Volume[]>>;
  setSelectedRoadVolume?: (selectedRoadVolume: SelectedVolume | null) => void;
  setSelectedRoadVolumeId?: (selectedRoadVolumeId: string) => void;
  isSelectLink: boolean;
}

export const MainRoadsLayerIds = {
  roadsSourceId: ROADS_SOURCE_ID,
  roadsVolumesLayerId: ROADS_VOLUMES_LAYER_ID,
  limitedAccessRoadsVolumesLayerId: LIMITED_ACCESS_ROADS_VOLUMES_LAYER_ID,
  roadsHairlinesLayerId: ROADS_HAIRLINES_LAYER_ID,
  roadsHighlightedVolumesLayerId: ROADS_HIGHLIGHTED_VOLUMES_LAYER_ID,
  roadsSegmentsLayerId: ROADS_SEGMENTS_LAYER_ID,
  highlightedSegmentsLayerId: HIGHLIGHTED_SEGMENTS_LAYER_ID,
} as RoadLayerIds;

export const MainRoadsColorIds = {
  hairlineColor: ROAD_HAIRLINE_COLOR,
  volumeColor: ROAD_VOLUME_COLOR,
  volumeHoverColor: ROAD_VOLUME_HOVER_COLOR,
  segmentHighlightColor: ROAD_SEGMENT_HIGHLIGHT_COLOR,
  segmentSuperHighlightColor: ROAD_SEGMENT_SUPER_HIGHLIGHT_COLOR,
  permanentHighlightColor: ROAD_SEGMENT_PERMANENT_HIGHLIGHT_COLOR,
  networkVolumeOpacity: ROAD_NETWORK_VOLUME_OPACITY,
  networkVolumeHighlightOpacity: ROAD_NETWORK_VOLUME_HIGHLIGHT_OPACITY,
  majorRoadVolumeColor: MAJOR_ROAD_VOLUME_COLOR,
  majorRoadVolumeOpacity: MAJOR_NETWORK_VOLUME_OPACITY,
} as RoadColors;

export const roadVolumeLayers = [
  { id: MainRoadsLayerIds.roadsVolumesLayerId, color: "#13C5EC" },
  {
    id: MainRoadsLayerIds.limitedAccessRoadsVolumesLayerId,
    color: "#f57f17",
  },
];

type MapInitFunction = (map: MapboxMap, data: RoadsLayersData) => { clear: () => void };

export type HighlightedSegmentsData = {
  ids: string[];
  tileService: RoadsTileService;
};

export type EditorSegmentsData = {
  roadSegmentIds: string[];
  tileService: RoadsTileService;
  showRoads: boolean;
};

const getUniqSegments = (
  segments: MapboxGeoJSONFeature[],
  tileService: RoadsTileService,
  volumes: Map<string, number>,
) => {
  return uniqBy(segments, "id").map((s) => ({
    ...s.properties,
    fromToId: s.properties?.[tileService.fromToSegmentIdField],
    toFromId: s.properties?.[tileService.toFromSegmentIdField],
    volumeFT: volumes.get(s.properties?.[tileService.fromToSegmentIdField]) || 0,
    volumeTF: volumes.get(s.properties?.[tileService.toFromSegmentIdField]) || 0,
  }));
};

const getVolumesPropsFromSegments = (
  segments: MapboxGeoJSONFeature[],
  tileService: RoadsTileService,
  volumes: Map<string, number>,
  map: MapboxMap,
  lngLat: number[],
  layerIds: RoadLayerIds,
) => {
  const uniqSegments = getUniqSegments(segments, tileService, volumes);
  const volumesPropsOrderedByStreetName = orderBy(uniqSegments, ["st_name"], ["asc"]);

  const visibleFeatureIds = volumesPropsOrderedByStreetName.map(
    (s) =>
      (
        s as {
          fromToId: any;
          toFromId: any;
          volumeFT: number;
          volumeTF: number;
        }
      ).fromToId,
  );
  const visibleFeatures =
    visibleFeatureIds.length > 0
      ? map.queryRenderedFeatures(undefined, {
          layers: [layerIds.roadsVolumesLayerId],
          filter: ["in", tileService.fromToSegmentIdField, ...visibleFeatureIds],
        })
      : [];

  const featureWeights: Record<string, number> = {};
  if (visibleFeatures.length > 0) {
    visibleFeatures.forEach((f: any) => {
      const { geometry } = f;
      const latitudes = [...geometry.coordinates].map((c: any) => c[1]);

      featureWeights[f.id] = Math.max(0, ...latitudes);
    });
  }

  const groupedVolumesProps: any = groupBy(volumesPropsOrderedByStreetName, "st_name");

  const result = Object.values(
    mapValues(groupedVolumesProps, (group) => {
      return [...group]
        .map((el: any) => ({
          ...el,
          weight: featureWeights[el.fromToId],
          lngLat,
        }))
        .sort((a: any, b: any) => {
          return b.weight - a.weight;
        });
    }),
  ).flat();

  return result.length > 0 ? result : volumesPropsOrderedByStreetName;
};

const getMetersPerPixelAtLatitude = (map: MapboxMap) => {
  const { lat } = map.getCenter();
  const zoom = map.getZoom();

  return (40075016.686 * Math.abs(Math.cos((lat * Math.PI) / 180))) / Math.pow(2, zoom + 8);
};

const updateFeatureStatesByVolumes = (
  map: MapboxMap,
  volumes: Map<string, number>,
  featureStateSet: Set<string>,
  layerName: string,
  maxVolume: number,
  toFromSegmentIdField: string,
  isZoomChanged: boolean,
  isPedestriansMode: boolean,
  layerIds: RoadLayerIds,
  isSelectLink: boolean,
) => {
  const visibleFeatures = getUniqueFeatures(
    map.queryRenderedFeatures(undefined, {
      layers: [layerIds.roadsHairlinesLayerId],
    }),
  ).filter((f) => {
    if (isZoomChanged) {
      return true;
    }

    const states = map.getFeatureState({
      source: layerIds.roadsSourceId,
      sourceLayer: layerName,
      id: f.id,
    });

    if (typeof states?.totalVolume === "number") {
      return false;
    }

    return true;
  });

  if (visibleFeatures.length === 0) {
    return;
  }

  const metersPerPixelAtLatitude = getMetersPerPixelAtLatitude(map);
  const MIN_WIDTH_IN_PIXELS = 1;
  const MAX_WIDTH_IN_PIXELS = isPedestriansMode ? 80 : 1000;
  const features: any = {};
  const getWidthInPixelsByVolume = (volume: number) =>
    ((volume / (maxVolume * 2)) * (MAX_WIDTH_IN_PIXELS - MIN_WIDTH_IN_PIXELS)) / metersPerPixelAtLatitude;

  visibleFeatures.forEach((feature: any) => {
    const segmentId = feature.id;
    const toFromSegmentId = feature.properties[toFromSegmentIdField];

    featureStateSet.add(segmentId);
    const fromToSegmentWidth =
      (volumes.get(segmentId) || 0) > 0 ? getWidthInPixelsByVolume(volumes.get(segmentId) || 0) : 0;
    const toFromSegmentWidth =
      (volumes.get(toFromSegmentId) || 0) > 0 ? getWidthInPixelsByVolume(volumes.get(toFromSegmentId) || 0) : 0;

    const sumVolumeWidth = fromToSegmentWidth + toFromSegmentWidth;
    const signFactor = fromToSegmentWidth - toFromSegmentWidth >= 0 ? 1 : -1;

    const scalingFactor = 2;
    features[segmentId] = {
      volumeOffset: isPedestriansMode
        ? 0
        : signFactor * scalingFactor * (sumVolumeWidth / 2 - Math.min(fromToSegmentWidth, toFromSegmentWidth)),
      volumeWeight: sumVolumeWidth > 0 ? sumVolumeWidth * scalingFactor : MIN_WIDTH_IN_PIXELS,
      totalVolume: (volumes.get(segmentId) || 0) + (volumes.get(toFromSegmentId) ?? 0),
    };
  });

  Object.entries(features).forEach(([id, state]) => {
    const { volumeOffset, volumeWeight, totalVolume } = state as any;
    map.setFeatureState(
      {
        source: layerIds.roadsSourceId,
        sourceLayer: layerName,
        id,
      },
      {
        volumeOffset,
        volumeWeight,
        totalVolume,
      },
    );
  });
};

export const initRoadSegments: MapInitFunction = (
  map,
  {
    tileService,
    layerIds,
    colorIds,
    roadsVolumes,
    roadSegmentIds,
    roadSegmentIdsByRoadClass,
    popupRef,
    popupHoverRef,
    gates,
    showRoadVolumes,
    isPedestriansMode,
    minVolume,
    setSelectedRoadVolume,
    setVolumeProps,
    setRoadHoverProps,
    setSelectedRoadVolumeId,
    isSelectLink,
  },
) => {
  const FEATURE_STATE_LIMIT = 80000;
  const featureStateSet = new Set<string>();

  let selectedRoadVolume: SelectedVolume | null = null;
  let hoveredSegments: null | MapboxGeoJSONFeature[] = null;
  let selectedSegments: null | MapboxGeoJSONFeature[] = null;
  let popup: Popup | null = null;
  let popupHover: Popup | null = null;
  let zoom = map.getZoom();

  const volumes = roadsVolumes.segmentVolumes;
  const maxVolume = roadsVolumes.maxVolume;
  const gatesSegmentsIds = getGatesSegmentsId(gates);

  const mainSegmentIds = Object.keys(roadSegmentIds);

  const visibleSegmentsIds = mainSegmentIds.filter((segmentId) => {
    const reverseSegmentId = roadSegmentIds[segmentId].reverseSegmentId;
    const segmentVolume = volumes.get(segmentId) || 0;
    const reverseSegmentVolume = reverseSegmentId ? volumes.get(reverseSegmentId) || 0 : 0;
    return minVolume === 0
      ? segmentVolume + reverseSegmentVolume > minVolume
      : segmentVolume >= minVolume || reverseSegmentVolume >= minVolume;
  });

  const addRoadLayers = () => {
    const existingDefaultLayers = defaultLayers.filter((layer) => !!map.getLayer(layer));

    map.addSource(layerIds.roadsSourceId, {
      type: "vector",
      tiles: [tileService.url + "/{z}/{x}/{y}.pbf"],
      promoteId: {
        [tileService.layerName]: tileService.fromToSegmentIdField,
      },
      minzoom: tileService.minZoom,
      maxzoom: tileService.maxZoom,
    });

    map.addLayer(
      {
        id: layerIds.roadsHairlinesLayerId,
        type: "line",
        source: layerIds.roadsSourceId,
        "source-layer": tileService.layerName,
        filter: ["in", tileService.fromToSegmentIdField, ...visibleSegmentsIds],
        paint: {
          "line-color": colorIds.hairlineColor,
          "line-width": 1,
        },
        layout: {
          visibility: showRoadVolumes ? "visible" : "none",
        },
      },
      ...existingDefaultLayers,
    );

    map.addLayer(
      {
        id: layerIds.limitedAccessRoadsVolumesLayerId,
        type: "line",
        source: layerIds.roadsSourceId,
        "source-layer": tileService.layerName,
        filter: [
          "in",
          tileService.fromToSegmentIdField,
          ...(roadSegmentIdsByRoadClass?.[RoadClassCategory.LIMITED_ACCESS] || []),
        ],
        paint: {
          "line-color": colorIds.majorRoadVolumeColor,
          "line-width": ["number", ["feature-state", "volumeWeight"], 0],
          "line-offset": ["number", ["feature-state", "volumeOffset"], 0],
          "line-opacity": colorIds.majorRoadVolumeOpacity,
        },
        layout: {
          visibility: showRoadVolumes ? "visible" : "none",
        },
      },
      ...existingDefaultLayers,
    );

    map.addLayer(
      {
        id: layerIds.roadsVolumesLayerId,
        type: "line",
        source: layerIds.roadsSourceId,
        "source-layer": tileService.layerName,
        filter: [
          "in",
          tileService.fromToSegmentIdField,
          ...(roadSegmentIdsByRoadClass?.[RoadClassCategory.OTHER] || []),
        ],
        paint: {
          "line-color": colorIds.volumeColor,
          "line-width": ["number", ["feature-state", "volumeWeight"], 0],
          "line-offset": ["number", ["feature-state", "volumeOffset"], 0],
          "line-opacity": colorIds.networkVolumeOpacity,
        },
        layout: {
          visibility: showRoadVolumes ? "visible" : "none",
        },
      },
      ...existingDefaultLayers,
    );

    map.addLayer(
      {
        id: layerIds.roadsHighlightedVolumesLayerId,
        type: "line",
        source: layerIds.roadsSourceId,
        "source-layer": tileService.layerName,
        filter: ["in", tileService.fromToSegmentIdField, ...visibleSegmentsIds],
        paint: {
          "line-color": colorIds.volumeColor,
          "line-width": [
            "case",
            ["boolean", ["feature-state", "hover"], false],
            ["number", ["feature-state", "volumeWeight"], 0],
            0,
          ],
          "line-offset": ["number", ["feature-state", "volumeOffset"], 0],
          "line-opacity": colorIds.networkVolumeHighlightOpacity,
        },
      },
      ...existingDefaultLayers,
    );

    map.addLayer(
      {
        id: layerIds.roadsSegmentsLayerId,
        type: "line",
        source: layerIds.roadsSourceId,
        "source-layer": tileService.layerName,
        filter: ["in", tileService.fromToSegmentIdField, ...visibleSegmentsIds, ...Object.keys(gatesSegmentsIds)],
        paint: {
          "line-color": [
            "case",
            ["boolean", ["feature-state", "permanentSelectHighlight"], false],
            colorIds.permanentHighlightColor,
            ["boolean", ["feature-state", "hoverHighlight"], false],
            colorIds.volumeHoverColor,
            ["boolean", ["feature-state", "selectHighlight"], false],
            colorIds.segmentSuperHighlightColor,
            colorIds.segmentHighlightColor,
          ],
          "line-width": [
            "interpolate",
            ["exponential", 1.6],
            ["zoom"],
            6,
            ["case", ["boolean", ["feature-state", "hover"], false], 2, 0.5],
            20,
            ["case", ["boolean", ["feature-state", "hover"], false], 35, 30],
          ],
          "line-offset": 0,
          "line-opacity": [
            "case",
            ["boolean", ["feature-state", "hover"], false],
            1,
            ["boolean", ["feature-state", "permanentSelectHighlight"], false],
            1,
            0,
          ],
        },
      },
      ...existingDefaultLayers,
    );
  };

  if (map.isStyleLoaded()) {
    addRoadLayers();
    if (gates) {
      addGatesLayer(map, gates);
      addGatesEvents();
    }
  }

  const handleStyleData = () => {
    if (!map.getSource(layerIds.roadsSourceId)) {
      addRoadLayers();
    }
  };

  const updateSegmentsStateWithHover = (e: MapLayerMouseEvent, segments: any[], status: boolean) => {
    if (segments?.[0].source !== "gates") {
      segments.forEach((segment) => {
        map.setFeatureState(
          {
            source: layerIds.roadsSourceId,
            sourceLayer: tileService.layerName,
            id: segment.id,
          },
          { hover: status },
        );
      });

      if (status) {
        setRoadHoverProps({
          volume: getVolumesPropsFromSegments(segments, tileService, volumes, map, e.lngLat.toArray(), layerIds).find(
            (s) => s[tileService.fromToSegmentIdField] === segments[0].id,
          ),
        });
      }
    }
  };

  const updateSegmentsStateWithSelected = (segments: any[]) => {
    segments.forEach((segment) => {
      map.removeFeatureState(
        {
          source: layerIds.roadsSourceId,
          sourceLayer: tileService.layerName,
          id: segment.id,
        },
        "selectHighlight",
      );
    });
  };

  const handleClick = (e: MapLayerMouseEvent) => {
    if (popup) {
      popup.remove();
    }

    if (selectedSegments && selectedSegments.length > 0) {
      updateSegmentsStateWithHover(e, selectedSegments, false);
    }

    if (popupRef) {
      selectedSegments = hoveredSegments;

      if (selectedSegments && selectedSegments.length > 0) {
        updateSegmentsStateWithHover(e, selectedSegments, true);
      }

      if (selectedSegments && selectedSegments.length > 0 && selectedSegments[0].layer.id !== GATES_LAYER_ID) {
        const volumesProps = getVolumesPropsFromSegments(
          selectedSegments,
          tileService,
          volumes,
          map,
          e.lngLat.toArray(),
          layerIds,
        );
        const selectedVolume = volumesProps.find(
          (s) => s[tileService.fromToSegmentIdField] === selectedSegments?.[0].id,
        );
        const volumeObj = getSelectedVolumeObject(selectedVolume);

        if (setSelectedRoadVolume && setSelectedRoadVolumeId) {
          setSelectedRoadVolumeId(volumeObj.ftSegmentId); // select the "from to" direction by clicking on the segment
          selectedRoadVolume = volumeObj;
          setSelectedRoadVolume(volumeObj);
        }
        if (setVolumeProps) setVolumeProps(volumesProps);

        popup = new Popup({
          closeOnClick: false,
        })
          .setLngLat(e.lngLat)
          .setDOMContent(popupRef.current as Node)
          .setOffset([0, -25])
          .addTo(map);

        popup.on("close", function () {
          if (selectedSegments && selectedSegments.length > 0) {
            updateSegmentsStateWithHover(e, selectedSegments, false);
            updateSegmentsStateWithSelected(selectedSegments);
          }
          selectedRoadVolume = null;
          if (setSelectedRoadVolume) setSelectedRoadVolume(null);
        });

        if (popupHover) {
          popupHover.remove();
        }
      }
    }
  };

  const handleMouseMoveOnVolumesLayer = () => {
    map.getCanvas().style.cursor = "default";
  };

  const handleMouseLeaveVolumesLayer = () => {
    map.getCanvas().style.cursor = "";
  };

  const handleMousemove = (e: MapLayerMouseEvent) => {
    if (selectedRoadVolume && !selectedSegments) {
      selectedSegments = map.queryRenderedFeatures(undefined, {
        layers: [layerIds.roadsVolumesLayerId],
        filter: ["in", tileService.fromToSegmentIdField, selectedRoadVolume.ftSegmentId],
      });
    }

    if (e.features && e.features.length > 0) {
      const feature = e.features[0];

      const featureId = feature?.id as string;
      if (hoveredSegments !== null) {
        map.getCanvas().style.cursor = "";
        hoveredSegments.forEach((hoveredSegment) => {
          if (
            selectedSegments &&
            selectedSegments.length > 0 &&
            selectedSegments.find((s) => s.id === hoveredSegment.id)
          )
            return;

          map.setFeatureState(
            {
              source: layerIds.roadsSourceId,
              sourceLayer: tileService.layerName,
              id: hoveredSegment.id,
            },
            { hover: false },
          );
        });

        if (popupHover) {
          popupHover.remove();
        }
      }
      if (
        featureId &&
        feature.layer.id !== GATES_LAYER_ID &&
        mainSegmentIds.includes(featureId) &&
        featureId !== selectedSegments?.[0].id
      ) {
        // Set `bbox` as 25px reactangle area around clicked point.
        const bbox = [
          [e.point.x - RADIUS_FOR_SEGMENT_HIGHLIGHT_IN_PIXELS, e.point.y - RADIUS_FOR_SEGMENT_HIGHLIGHT_IN_PIXELS],
          [e.point.x + RADIUS_FOR_SEGMENT_HIGHLIGHT_IN_PIXELS, e.point.y + RADIUS_FOR_SEGMENT_HIGHLIGHT_IN_PIXELS],
        ] as [PointLike, PointLike];
        // Find features intersecting the bounding box.
        const selectedFeatures = map.queryRenderedFeatures(bbox, {
          layers: [layerIds.roadsSegmentsLayerId],
        });
        if (selectedFeatures.length > 0) {
          hoveredSegments = selectedFeatures;
          updateSegmentsStateWithHover(e, [hoveredSegments[0]], true);
        }

        popupHover = new Popup({
          closeButton: false,
          closeOnClick: false,
          offset: 15,
        })
          .setLngLat(e.lngLat)
          .setDOMContent(popupHoverRef.current as Node)
          .addTo(map);
      }

      if (featureId && feature.layer.id === GATES_LAYER_ID && !gatesSegmentsIds[featureId]) {
        setRoadHoverProps({ gateId: featureId });
        hoveredSegments = [feature];

        popupHover = new Popup({
          closeButton: false,
          closeOnClick: false,
          offset: 15,
        })
          .setLngLat(e.lngLat)
          .setDOMContent(popupHoverRef.current as Node)
          .addTo(map);
      }
      map.getCanvas().style.cursor = "default";
    }
  };

  const handleMouseleave = () => {
    if (hoveredSegments !== null) {
      map.getCanvas().style.cursor = "";
      hoveredSegments.forEach((hoveredSegment) => {
        if (selectedSegments && selectedSegments.length > 0) {
          const isSelected = selectedSegments.some((selectedSegment) => selectedSegment.id === hoveredSegment.id);
          if (!isSelected) {
            map.setFeatureState(
              {
                source: layerIds.roadsSourceId,
                sourceLayer: tileService.layerName,
                id: hoveredSegment.id,
              },
              { hover: false },
            );
          }
        } else {
          map.setFeatureState(
            {
              source: layerIds.roadsSourceId,
              sourceLayer: tileService.layerName,
              id: hoveredSegment.id,
            },
            { hover: false },
          );
        }
      });
    }

    if (popupHover && popupHover.isOpen()) {
      popupHover.remove();
    }
    hoveredSegments = null;
  };

  const updateVolumes = () => {
    const zoomDiff = Math.abs(map.getZoom() - zoom);
    const isZoomChanged = zoomDiff >= 0.25;

    if (featureStateSet.size > FEATURE_STATE_LIMIT || isZoomChanged) {
      featureStateSet.forEach((featureStateId) => {
        removeFeatureState(featureStateId, "volumeOffset");
        removeFeatureState(featureStateId, "volumeWeight");
        removeFeatureState(featureStateId, "totalVolume");
      });

      featureStateSet.clear();
    }

    if (map.getLayer(layerIds.roadsVolumesLayerId) && volumes.size > 0) {
      updateFeatureStatesByVolumes(
        map,
        volumes,
        featureStateSet,
        tileService.layerName,
        maxVolume,
        tileService.toFromSegmentIdField,
        isZoomChanged,
        isPedestriansMode,
        layerIds,
        isSelectLink,
      );
    }

    if (isZoomChanged) {
      zoom = map.getZoom();
    }

    if (selectedRoadVolume && !popup && popupRef && (volumes.get(selectedRoadVolume.ftSegmentId) as number) > 0) {
      popup = new Popup({
        closeOnClick: false,
      })
        .setLngLat(LngLat.convert(selectedRoadVolume.lngLat as any))
        .setDOMContent(popupRef.current as Node)
        .setOffset([0, -25])
        .addTo(map);

      if (setVolumeProps) {
        setVolumeProps(
          getVolumesPropsFromSegments(
            [{ properties: selectedRoadVolume.feature } as any],
            tileService,
            volumes,
            map,
            selectedRoadVolume.lngLat,
            layerIds,
          ),
        );
      }

      popup.on("close", function () {
        if (selectedRoadVolume) {
          map.setFeatureState(
            {
              source: layerIds.roadsSourceId,
              sourceLayer: tileService.layerName,
              id: selectedRoadVolume.ftSegmentId,
            },
            { selectHighlight: false, hover: false },
          );
        }

        selectedRoadVolume = null;
        if (setSelectedRoadVolume) setSelectedRoadVolume(null);
      });

      map.setFeatureState(
        {
          source: layerIds.roadsSourceId,
          sourceLayer: tileService.layerName,
          id: selectedRoadVolume.ftSegmentId,
        },
        { selectHighlight: true, hover: true },
      );

      if (popupHover) {
        popupHover.remove();
      }
    }
  };

  const handleMoveUpdateVolumes = throttle((e: any) => {
    updateVolumes();
  }, 1500);

  const handleChangeSourceUpdateVolumes = throttle((e: any) => {
    if (e.isSourceLoaded && e.sourceCacheId) {
      updateVolumes();
    }
  }, 1500);

  const removeFeatureState = (id: string, featureName: string) => {
    map.removeFeatureState(
      {
        source: layerIds.roadsSourceId,
        sourceLayer: tileService.layerName,
        id,
      },
      featureName,
    );
  };

  const handleRemoveAllPopups = () => {
    popup?.remove();
    popupHover?.remove();
  };

  map.on("styledata", handleStyleData);
  map.on("move", handleMoveUpdateVolumes);
  map.on("sourcedata", handleChangeSourceUpdateVolumes);
  map.on("click", handleClick);
  map.on("mousemove", [GATES_LAYER_ID, layerIds.roadsSegmentsLayerId], handleMousemove);
  map.on("mouseleave", [GATES_LAYER_ID, layerIds.roadsSegmentsLayerId], handleMouseleave);
  map.on(
    "mousemove",
    [layerIds.roadsVolumesLayerId, layerIds.limitedAccessRoadsVolumesLayerId],
    handleMouseMoveOnVolumesLayer,
  );
  map.on(
    "mouseleave",
    [layerIds.roadsVolumesLayerId, layerIds.limitedAccessRoadsVolumesLayerId],
    handleMouseLeaveVolumesLayer,
  );
  map.on("closeAllRoadsPopups", handleRemoveAllPopups);

  return {
    clear: () => {
      featureStateSet.clear();

      if (popup) {
        popup.remove();
      }
      if (map.getLayer(layerIds.roadsMeasureRangeLayerId)) {
        map.removeLayer(layerIds.roadsMeasureRangeLayerId);
      }
      if (map.getLayer(layerIds.roadsSegmentsLayerId)) {
        map.removeLayer(layerIds.roadsSegmentsLayerId);
      }
      if (map.getLayer(layerIds.roadsVolumesLayerId)) {
        map.removeLayer(layerIds.roadsVolumesLayerId);
      }
      if (map.getLayer(layerIds.limitedAccessRoadsVolumesLayerId)) {
        map.removeLayer(layerIds.limitedAccessRoadsVolumesLayerId);
      }
      if (map.getLayer(layerIds.roadsHighlightedVolumesLayerId)) {
        map.removeLayer(layerIds.roadsHighlightedVolumesLayerId);
      }
      if (map.getLayer(layerIds.roadsHairlinesLayerId)) {
        map.removeLayer(layerIds.roadsHairlinesLayerId);
      }
      if (map.getSource(layerIds.roadsSourceId)) {
        map.removeSource(layerIds.roadsSourceId);
      }
      removeGatesLayer();

      map.off("styledata", handleStyleData);
      map.off("move", handleMoveUpdateVolumes);
      map.off("sourcedata", handleChangeSourceUpdateVolumes);
      map.off("click", handleClick);
      map.off("mousemove", [GATES_LAYER_ID, layerIds.roadsSegmentsLayerId], handleMousemove);
      map.off("mouseleave", [GATES_LAYER_ID, layerIds.roadsSegmentsLayerId], handleMouseleave);
      map.off("closeAllRoadsPopups", handleRemoveAllPopups);

      clearGatesEvents();
    },
  };
};
