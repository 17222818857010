import styled from "@emotion/styled";
import { Switch as BpSwitch } from "@blueprintjs/core";

export const Switch = styled(BpSwitch)`
  &.bp4-control {
    & input:checked ~ .bp4-control-indicator {
      background-color: var(--color-primary) !important;
    }
  }

  &.bp4-control.bp4-switch .bp4-control-indicator {
    min-width: 2em;
    &::before {
      width: calc(1em + 4px);
      height: calc(1em + 4px);
      margin: -2px;
      box-shadow: var(--box-shadow-sm);
      border: 1px solid var(--color-gray-200);
    }
  }
`;
