import styled from "@emotion/styled";
import { Icon as BpIcon } from "@blueprintjs/core";

export const DocsIcon = styled(BpIcon)`
  grid-area: docs-icon;
  margin-bottom: 0;
  padding: 12px;
  background-color: rgba(19, 158, 236, 0.2);
  border-radius: 8px;

  & > svg:not([fill]) {
    color: #139eec;
  }

  @media only screen and (min-width: 768px) {
    margin-bottom: 1rem;
  }
`;
