import styled from "@emotion/styled";
import { Tabs as BpTabs } from "@blueprintjs/core";

export const Tabs = styled(BpTabs)`
  margin: 1rem 0;
  & .bp4-tab-list {
    border-bottom: 1px solid var(--color-border);
    justify-content: center;
  }
  & .bp4-tab-indicator-wrapper {
    & .bp4-tab-indicator {
      background-color: var(--color-primary);
    }
  }
  & .bp4-tab {
    color: var(--color-gray-400);
  }

  & .bp4-tab[aria-selected="true"],
  .bp4-tab:not([aria-disabled="true"]):hover {
    color: var(--color-primary);
  }
`;
