import styled from "@emotion/styled";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

import { SelectLinkPredicateLogic } from "types";

interface Props {
  value: string;
  disabled: boolean;
  onChange: (index: SelectLinkPredicateLogic) => void;
}

const StyledToggleButtonGroup = styled(ToggleButtonGroup)`
  & .MuiButtonBase-root {
    font-size: 12px;
    font-weight: 600;
    padding: 0px 3px;
  }
`;

export const AndOrToggle = ({ value, disabled, onChange }: Props) => {
  const handleChange = (event: React.MouseEvent<HTMLElement>, newLogicIndex: SelectLinkPredicateLogic) => {
    if (newLogicIndex) onChange(newLogicIndex);
  };

  return (
    <StyledToggleButtonGroup
      color="info"
      size="small"
      value={value}
      exclusive
      disabled={disabled}
      onChange={handleChange}
    >
      <ToggleButton value="0">AND</ToggleButton>
      <ToggleButton value="1">OR</ToggleButton>
    </StyledToggleButtonGroup>
  );
};
