import React, { FC } from "react";
import styled from "@emotion/styled";

import logo from "assets/svg/logo.svg";

const StyledFooter = styled.footer`
  padding: 2rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #051222;
  color: #94aece;

  & img {
    margin-bottom: 1rem;
    width: 120px;
  }

  & p {
    margin: 0.5rem 0;
  }
`;

export const Footer: FC = () => (
  <StyledFooter>
    <img src={logo} alt="logo" />
    <p>
      For more information, contact us at: <a href="mailto:info@teralytics.net">info@teralytics.net</a>
    </p>
    <p>© {new Date().getFullYear()} Teralytics Inc. All rights reserved.</p>
  </StyledFooter>
);
