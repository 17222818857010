import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import Box from "@mui/system/Box";
import React, { useState } from "react";

import { themeColors } from "theme/themeConstants";

import { AttributeStatistics, ZoningAttribute } from "types";

import { AttributesTable } from "./AttributesTable";

interface Props {
  disabled: boolean;
  idField: string;
  nameField: string;
  attributesStatistics: {
    [key: string]: AttributeStatistics;
  };
  attributes: ZoningAttribute[];
  selectedAttributes: string[];
  toggleAttribute: (attributeName: string) => void;
  toggleAllAttributes: () => void;
}

const StyledMuiAccordionSummary = styled(MuiAccordionSummary)`
  min-height: 30px;
  margin: 0;
  padding: 0;
  background-color: ${themeColors.gray100};

  &.accordion-summary-header {
    background-color: white;
  }

  &.Mui-expanded {
    min-height: 30px;
  }

  & .MuiAccordionSummary-content {
    margin: 0;
    padding: 0;

    & .MuiTypography-root {
      font-size: 14px;
      color: ${themeColors.textSecondary};
    }

    & .MuiCheckbox-root {
      padding: 0;
    }

    &.Mui-expanded {
      & .MuiTypography-root {
        color: ${themeColors.text};
        font-weight: 500;
      }
    }
  }
`;

const StyledMuiAccordionDetails = styled(MuiAccordionDetails)`
  background-color: ${themeColors.gray100};
  padding: 0 8px;
`;

const RowContainer = styled(Box)<{ disabled: boolean }>`
  display: grid;
  width: 100%;
  grid-template-columns: ${({ disabled }) => (disabled ? "" : "24px")} 1fr 100px;
  column-gap: 10px;
  align-items: center;
  padding: 0 10px;
`;

export const AttributesTab = ({
  disabled,
  idField,
  nameField,
  attributes,
  attributesStatistics,
  selectedAttributes,
  toggleAttribute,
  toggleAllAttributes,
}: Props) => {
  const [activeAttribute, setActiveAttribute] = useState<string | undefined>(undefined);

  return (
    <>
      <StyledMuiAccordionSummary sx={{ mb: "10px" }} className="accordion-summary-header">
        <RowContainer disabled={disabled}>
          {!disabled && (
            <Checkbox checked={selectedAttributes.length === attributes.length} onClick={toggleAllAttributes} />
          )}
          <Typography sx={{ fontWeight: 500, color: `${themeColors.text} !important` }}>Name</Typography>
          <Typography sx={{ fontWeight: 500, color: `${themeColors.text} !important` }}>Type</Typography>
        </RowContainer>
      </StyledMuiAccordionSummary>
      {attributes.map((attribute) => (
        <MuiAccordion
          key={attribute.name}
          expanded={activeAttribute === attribute.name}
          onChange={() => setActiveAttribute(attribute.name === activeAttribute ? undefined : attribute.name)}
        >
          <StyledMuiAccordionSummary>
            <RowContainer disabled={disabled}>
              {!disabled && (
                <Checkbox
                  checked={
                    selectedAttributes.includes(attribute.name) ||
                    attribute.name === idField ||
                    attribute.name === nameField
                  }
                  disabled={attribute.name === idField || attribute.name === nameField}
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleAttribute(attribute.name);
                  }}
                />
              )}
              <Typography>{attribute.name}</Typography>
              <Typography>{attribute.attributeType}</Typography>
            </RowContainer>
          </StyledMuiAccordionSummary>
          <StyledMuiAccordionDetails>
            <AttributesTable attributes={attributesStatistics[attribute.name]} minWidth={"100%"} />
          </StyledMuiAccordionDetails>
        </MuiAccordion>
      ))}
    </>
  );
};
