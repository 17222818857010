import styled from "@emotion/styled";
import { Checkbox as BpCheckbox } from "@blueprintjs/core";

export const Checkbox = styled(BpCheckbox)`
  &.bp4-control.bp4-checkbox .bp4-control-indicator {
    border-radius: 4px;
  }

  &.bp4-control .bp4-control-indicator {
    box-shadow: inset 0 0 0 1px var(--color-gray-300);
  }

  &.bp4-control input:checked ~ .bp4-control-indicator {
    background-color: var(--color-primary);
  }
`;
