export enum AnalyticsActionType {
  SET_AUTHORIZATION_TOKEN_LOADED = "analytics/SET_AUTHORIZATION_TOKEN_LOADED",

  CLEAR_DATASET_METADATA = "analytics/CLEAR_DATASET_METADATA",

  FETCH_DATASETS = "analytics/FETCH_DATASETS",
  FETCH_DATASETS_SUCCEEDED = "analytics/FETCH_DATASETS_SUCCEEDED",
  FETCH_DATASETS_FAILED = "analytics/FETCH_DATASETS_FAILED",

  FETCH_DATASET_COUNTS = "analytics/FETCH_DATASET_COUNTS",
  FETCH_DATASET_COUNTS_SUCCEEDED = "analytics/FETCH_DATASET_COUNTS_SUCCEEDED",
  FETCH_DATASET_COUNTS_FAILED = "analytics/FETCH_DATASET_COUNTS_FAILED",

  FETCH_DATASET_IDS = "analytics/FETCH_DATASET_IDS",
  FETCH_DATASET_IDS_SUCCEEDED = "analytics/FETCH_DATASET_IDS_SUCCEEDED",
  FETCH_DATASET_IDS_FAILED = "analytics/FETCH_DATASET_IDS_FAILED",

  FETCH_DATASET_COUNTS_BY_ZONE_ID = "analytics/FETCH_DATASET_COUNTS_BY_ZONE_ID",
  FETCH_DATASET_COUNTS_BY_ZONE_ID_SUCCEEDED = "analytics/FETCH_DATASET_COUNTS_BY_ZONE_ID_SUCCEEDED",
  FETCH_DATASET_COUNTS_BY_ZONE_ID_FAILED = "analytics/FETCH_DATASET_COUNTS_BY_ZONE_ID_FAILED",

  FETCH_DATASET_METADATA = "analytics/FETCH_DATASET_METADATA",
  FETCH_DATASET_METADATA_SUCCEEDED = "analytics/FETCH_DATASET_METADATA_SUCCEEDED",
  FETCH_DATASET_METADATA_FAILED = "analytics/FETCH_DATASET_METADATA_FAILED",

  FETCH_DATASET_GATES = "analytics/FETCH_DATASET_GATES",
  FETCH_DATASET_GATES_SUCCEEDED = "analytics/FETCH_DATASET_GATES_SUCCEEDED",
  FETCH_DATASET_GATES_FAILED = "analytics/FETCH_DATASET_GATES_FAILED",

  FETCH_GATE_DETAILS = "analytics/FETCH_GATE_DETAILS",
  FETCH_GATE_DETAILS_SUCCEEDED = "analytics/FETCH_GATE_DETAILS_SUCCEEDED",
  FETCH_GATE_DETAILS_FAILED = "analytics/FETCH_GATE_DETAILS_FAILED",

  FETCH_ZONE_DETAILS = "analytics/FETCH_ZONE_DETAILS",
  FETCH_ZONE_DETAILS_SUCCEEDED = "analytics/FETCH_ZONE_DETAILS_SUCCEEDED",
  FETCH_ZONE_DETAILS_FAILED = "analytics/FETCH_ZONE_DETAILS_FAILED",

  FETCH_ZONE_COUNTS = "analytics/FETCH_ZONE_COUNTS",
  FETCH_ZONE_COUNTS_SUCCEEDED = "analytics/FETCH_ZONE_COUNTS_SUCCEEDED",
  FETCH_ZONE_COUNTS_FAILED = "analytics/FETCH_ZONE_COUNTS_FAILED",

  FETCH_ZONE_IDS = "analytics/FETCH_ZONE_IDS",
  FETCH_ZONE_IDS_SUCCEEDED = "analytics/FETCH_ZONE_IDS_SUCCEEDED",
  FETCH_ZONE_IDS_FAILED = "analytics/FETCH_ZONE_IDS_FAILED",

  FETCH_ZONE_COUNTS_BY_ZONE_ID = "analytics/FETCH_ZONE_COUNTS_BY_ZONE_ID",
  FETCH_ZONE_COUNTS_BY_ZONE_ID_SUCCEEDED = "analytics/FETCH_ZONE_COUNTS_BY_ZONE_ID_SUCCEEDED",
  FETCH_ZONE_COUNTS_BY_ZONE_ID_FAILED = "analytics/FETCH_ZONE_COUNTS_BY_ZONE_ID_FAILED",

  FETCH_OD_METADATA = "analytics/FETCH_OD_METADATA",
  FETCH_OD_METADATA_SUCCEEDED = "analytics/FETCH_OD_METADATA_SUCCEEDED",
  FETCH_OD_METADATA_FAILED = "analytics/FETCH_OD_METADATA_FAILED",

  FETCH_SUBAREA_STATE = "analytics/FETCH_SUBAREA_STATE",
  FETCH_SUBAREA_STATE_SUCCEEDED = "analytics/FETCH_SUBAREA_STATE_SUCCEEDED",
  FETCH_SUBAREA_STATE_FAILED = "analytics/FETCH_SUBAREA_STATE_FAILED",
  CLEAR_SUBAREA_STATE = "analytics/CLEAR_SUBAREA_STATE",

  FETCH_SUBAREA_POLYGON = "analytics/FETCH_SUBAREA_POLYGON",
  FETCH_SUBAREA_POLYGON_SUCCEEDED = "analytics/FETCH_SUBAREA_POLYGON_SUCCEEDED",
  FETCH_SUBAREA_POLYGON_FAILED = "analytics/FETCH_SUBAREA_POLYGON_FAILED",
  SET_SUBAREA_POLYGON = "analytics/SET_SUBAREA_POLYGON",

  FETCH_GENERATED_GATES = "analytics/FETCH_GENERATED_GATES",
  FETCH_GENERATED_GATES_SUCCEEDED = "analytics/FETCH_GENERATED_GATES_SUCCEEDED",
  FETCH_GENERATED_GATES_FAILED = "analytics/FETCH_GENERATED_GATES_FAILED",

  FETCH_ADDED_GATE = "analytics/FETCH_ADDED_GATE",
  FETCH_ADDED_GATE_SUCCEEDED = "analytics/FETCH_ADDED_GATE_SUCCEEDED",
  FETCH_ADDED_GATE_FAILED = "analytics/FETCH_ADDED_GATE_FAILED",
  TOGGLE_LOCK_GATE = "analytics/TOGGLE_LOCK_GATE",
  DELETE_GATE = "analytics/DELETE_GATE",
  CLEAR_NEW_GATE = "analytics/CLEAR_NEW_GATE",
  DELETE_ALL_GATES = "analytics/DELETE_ALL_GATES",

  ADD_GATE_SEGMENTS = "analytics/ADD_GATE_SEGMENTS",
  ADD_GATE_SEGMENTS_SUCCEEDED = "analytics/ADD_GATE_SEGMENTS_SUCCEEDED",
  ADD_GATE_SEGMENTS_FAILED = "analytics/ADD_GATE_SEGMENTS_FAILED",

  DELETE_GATE_SEGMENTS = "analytics/DELETE_GATE_SEGMENT",
  DELETE_GATE_SEGMENTS_SUCCEEDED = "analytics/DELETE_GATE_SEGMENT_SUCCEEDED",
  DELETE_GATE_SEGMENTS_FAILED = "analytics/DELETE_GATE_SEGMENT_FAILED",

  UPDATE_GATE_DESCRIPTION = "analytics/UPDATE_GATE_DESCRIPTION",

  FETCH_OD_DATASET_CONFIG = "analytics/FETCH_OD_DATASET_CONFIG",
  FETCH_OD_DATASET_CONFIG_SUCCEEDED = "analytics/FETCH_OD_DATASET_CONFIG_SUCCEEDED",
  FETCH_OD_DATASET_CONFIG_FAILED = "analytics/FETCH_OD_DATASET_CONFIG_FAILED",

  CREATE_OD_DATASET_CONFIG = "analytics/CREATE_OD_DATASET_CONFIG",
  CREATE_OD_DATASET_CONFIG_SUCCEEDED = "analytics/CREATE_OD_DATASET_CONFIG_SUCCEEDED",
  CREATE_OD_DATASET_CONFIG_FAILED = "analytics/CREATE_OD_DATASET_CONFIG_FAILED",
  CLEAR_NEW_OD_DATASET_CONFIG = "analytics/CLEAR_NEW_OD_DATASET_CONFIG",

  UPDATE_OD_DATASET_CONFIG = "analytics/UPDATE_OD_DATASET_CONFIG",
  UPDATE_OD_DATASET_CONFIG_SUCCEEDED = "analytics/UPDATE_OD_DATASET_CONFIG_SUCCEEDED",
  UPDATE_OD_DATASET_CONFIG_FAILED = "analytics/UPDATE_OD_DATASET_CONFIG_FAILED",

  FETCH_ROADS_METADATA = "analytics/FETCH_ROADS_METADATA",
  FETCH_ROADS_METADATA_SUCCEEDED = "analytics/FETCH_ROADS_METADATA_SUCCEEDED",
  FETCH_ROADS_METADATA_FAILED = "analytics/FETCH_ROADS_METADATA_FAILED",

  FETCH_ROADS_VOLUMES = "analytics/FETCH_ROADS_VOLUMES",
  FETCH_ROADS_VOLUMES_SUCCEEDED = "analytics/FETCH_ROADS_VOLUMES_SUCCEEDED",
  FETCH_ROADS_VOLUMES_FAILED = "analytics/FETCH_ROADS_VOLUMES_FAILED",

  FETCH_ROAD_SEGMENT_IDS = "analytics/FETCH_ROAD_SEGMENT_IDS",
  FETCH_ROAD_SEGMENT_IDS_SUCCEEDED = "analytics/FETCH_ROAD_SEGMENT_IDS_SUCCEEDED",
  FETCH_ROAD_SEGMENT_IDS_FAILED = "analytics/FETCH_ROAD_SEGMENT_IDS_FAILED",

  FETCH_ROAD_SEGMENTS_DETAILS = "analytics/FETCH_ROAD_SEGMENTS_DETAILS",
  FETCH_ROAD_SEGMENTS_DETAILS_SUCCEEDED = "analytics/FETCH_ROAD_SEGMENTS_DETAILS_SUCCEEDED",
  FETCH_ROAD_SEGMENTS_DETAILS_FAILED = "analytics/FETCH_ROAD_SEGMENTS_DETAILS_FAILED",

  SET_MEASURE = "analytics/SET_MEASURE",

  SET_QUERY_TYPE = "analytics/SET_QUERY_TYPE",

  UPDATE_CURRENT_OD_FILTERS = "analytics/UPDATE_CURRENT_OD_FILTERS",
  UPDATE_CURRENT_ROAD_FILTERS = "analytics/UPDATE_CURRENT_ROAD_FILTERS",
  UPDATE_CURRENT_DATASET_FILTERS = "analytics/UPDATE_CURRENT_DATASET_FILTERS",
  CLEAR_FILTERS = "analytics/CLEAR_FILTERS",

  VALIDATE_OD_DATASET_CONFIG = "analytics/VALIDATE_OD_DATASET_CONFIG",
  VALIDATE_OD_DATASET_CONFIG_SUCCEEDED = "analytics/VALIDATE_OD_DATASET_CONFIG_SUCCEEDED",
  VALIDATE_OD_DATASET_CONFIG_FAILED = "analytics/VALIDATE_OD_DATASET_CONFIG_FAILED",

  COMPUTE_OD_DATASET = "analytics/COMPUTE_OD_DATASET",
  COMPUTE_OD_DATASET_SUCCEEDED = "analytics/COMPUTE_OD_DATASET_SUCCEEDED",
  COMPUTE_OD_DATASET_FAILED = "analytics/COMPUTE_OD_DATASET_FAILED",

  CANCEL_OD_DATASET_COMPUTATION = "analytics/CANCEL_OD_DATASET_COMPUTATION",
  CANCEL_OD_DATASET_COMPUTATION_SUCCEEDED = "analytics/CANCEL_OD_DATASET_COMPUTATION_SUCCEEDED",
  CANCEL_OD_DATASET_COMPUTATION_FAILED = "analytics/CANCEL_OD_DATASET_COMPUTATION_FAILED",

  FETCH_GEOCODING_SEARCH_RESULTS = "analytics/FETCH_GEOCODING_SEARCH_RESULTS",
  FETCH_GEOCODING_SEARCH_RESULTS_SUCCEEDED = "analytics/FETCH_GEOCODING_SEARCH_RESULTS_SUCCEEDED",
  FETCH_GEOCODING_SEARCH_RESULTS_FAILED = "analytics/FETCH_GEOCODING_SEARCH_RESULTS_FAILED",
  CLEAR_GEOCODING_SEARCH_RESULTS = "analytics/CLEAR_GEOCODING_SEARCH_RESULTS",

  SET_SELECTED_ZONE = "analytics/SET_SELECTED_ZONE",

  SET_SELECTED_ROAD_VOLUME = "analytics/SET_SELECTED_ROAD_VOLUME",

  SET_SELECTED_ROAD_VOLUME_ID = "analytics/SET_SELECTED_ROAD_VOLUME_ID",

  FETCH_FOCUS_AREAS = "analytics/FETCH_FOCUS_AREAS",
  FETCH_FOCUS_AREAS_SUCCEEDED = "analytics/FETCH_FOCUS_AREAS_SUCCEEDED",
  FETCH_FOCUS_AREAS_FAILED = "analytics/FETCH_FOCUS_AREAS_FAILED",

  FETCH_FOCUS_AREAS_AND_DATASETS = "analytics/FETCH_FOCUS_AREAS_AND_DATASETS",
  FETCH_FOCUS_AREAS_AND_DATASETS_SUCCEEDED = "analytics/FETCH_FOCUS_AREAS_AND_DATASETS_SUCCEEDED",
  FETCH_FOCUS_AREAS_AND_DATASETS_FAILED = "analytics/FETCH_DATASET_FOCUS_AREAS_FAILED",

  FETCH_OD_MEASURE_RANGE = "analytics/FETCH_OD_MEASURE_RANGE",
  FETCH_OD_MEASURE_RANGE_SUCCEEDED = "analytics/FETCH_OD_MEASURE_RANGE_SUCCEEDED",
  FETCH_OD_MEASURE_RANGE_FAILED = "analytics/FETCH_OD_MEASURE_RANGE_FAILED",

  FETCH_DATASET_MEASURE_RANGE = "analytics/FETCH_DATASET_MEASURE_RANGE",
  FETCH_DATASET_MEASURE_RANGE_SUCCEEDED = "analytics/FETCH_DATASET_MEASURE_RANGE_SUCCEEDED",
  FETCH_DATASET_MEASURE_RANGE_FAILED = "analytics/FETCH_DATASET_MEASURE_RANGE_FAILED",

  FETCH_ROADS_MEASURE_RANGE = "analytics/FETCH_ROADS_MEASURE_RANGE",
  FETCH_ROADS_MEASURE_RANGE_SUCCEEDED = "analytics/FETCH_ROADS_MEASURE_RANGE_SUCCEEDED",
  FETCH_ROADS_MEASURE_RANGE_FAILED = "analytics/FETCH_ROADS_MEASURE_RANGE_FAILED",

  SET_OD_RANGE = "analytics/SET_OD_RANGE",
  SET_DATASET_RANGE = "analytics/SET_DATASET_RANGE",
  SET_ROADS_RANGE = "analytics/SET_ROADS_RANGE",
  SET_OD_MEASURE_RANGE_BY_ZONE = "analytics/SET_OD_MEASURE_RANGE_BY_ZONE",
  SET_OD_RANGE_BY_ZONE = "analytics/SET_OD_RANGE_BY_ZONE",
  SET_MAP_VISUALIZATION_MODE = "analytics/SET_MAP_VISUALIZATION_MODE",
}

export enum ExportActionType {
  FETCH_EXPORT_JOBS = "export/FETCH_EXPORT_JOBS",
  FETCH_EXPORT_JOBS_SUCCEEDED = "export/FETCH_EXPORT_JOBS_SUCEEDED",
  FETCH_EXPORT_JOBS_FAILED = "export/FETCH_EXPORT_JOBS_FAILED",
  CLEAR_EXPORT_JOBS = "export/CLEAR_EXPORT_JOBS",

  ADD_OD_EXPORT_JOB = "export/ADD_OD_EXPORT_JOB",
  ADD_OD_EXPORT_JOB_SUCCEEDED = "export/ADD_OD_EXPORT_JOB_SUCCEEDED",
  ADD_OD_EXPORT_JOB_FAILED = "export/ADD_OD_EXPORT_JOB_FAILED",

  ADD_DATASET_EXPORT_JOB = "export/ADD_DATASET_EXPORT_JOB",
  ADD_DATASET_EXPORT_JOB_SUCCEEDED = "export/ADD_DATASET_EXPORT_JOB_SUCCEEDED",
  ADD_DATASET_EXPORT_JOB_FAILED = "export/ADD_DATASET_EXPORT_JOB_FAILED",

  ADD_ROADS_EXPORT_JOB = "export/ADD_ROADS_EXPORT_JOB",
  ADD_ROADS_EXPORT_JOB_SUCCEEDED = "export/ADD_ROADS_EXPORT_JOB_SUCCEEDED",
  ADD_ROADS_EXPORT_JOB_FAILED = "export/ADD_ROADS_EXPORT_JOB_FAILED",

  ADD_AOI_EXPORT_JOB = "export/ADD_AOI_EXPORT_JOB",
  ADD_AOI_EXPORT_JOB_SUCCEEDED = "export/ADD_AOI_EXPORT_JOB_SUCCEEDED",
  ADD_AOI_EXPORT_JOB_FAILED = "export/ADD_AOI_EXPORT_JOB_FAILED",

  CLEAR_NEW_EXPORT = "export/CLEAR_NEW_EXPORT",

  FETCH_NUM_ZONES = "export/FETCH_NUM_ZONES",
  FETCH_NUM_ZONES_SUCCEEDED = "export/FETCH_NUM_ZONES_SUCCEEDED",
  FETCH_NUM_ZONES_FAILED = "export/FETCH_NUM_ZONES_FAILED",

  FETCH_DATASET_METADATA = "export/FETCH_DATASET_METADATA",
  FETCH_DATASET_METADATA_SUCCEEDED = "export/FETCH_DATASET_METADATA_SUCCEEDED",
  FETCH_DATASET_METADATA_FAILED = "export/FETCH_DATASET_METADATA_FAILED",
}

export enum LicenseActionType {
  FETCH_USER = "license/FETCH_USER",
  FETCH_USER_SUCCEEDED = "license/FETCH_USER_SUCEEDED",
  FETCH_USER_FAILED = "license/FETCH_USER_FAILED",

  FETCH_ORGANIZATION = "license/FETCH_ORGANIZATION",
  FETCH_ORGANIZATION_SUCCEEDED = "license/FETCH_ORGANIZATION_SUCEEDED",
  FETCH_ORGANIZATION_FAILED = "license/FETCH_ORGANIZATION_FAILED",

  FETCH_NOTIFICATIONS_INBOX_STATE = "license/FETCH_NOTIFICATIONS_INBOX_STATE",
  FETCH_NOTIFICATIONS_INBOX_STATE_SUCCEEDED = "license/FETCH_NOTIFICATIONS_INBOX_STATE_SUCCEEDED",
  FETCH_NOTIFICATIONS_INBOX_STATE_FAILED = "license/FETCH_NOTIFICATIONS_INBOX_STATE_FAILED",

  READ_ALL_NOTIFICATIONS_FROM_INBOX = "license/READ_ALL_NOTIFICATIONS_FROM_INBOX",

  UPDATE_NOTIFICATIONS_INBOX_STATE = "license/UPDATE_NOTIFICATIONS_INBOX_STATE",
  UPDATE_NOTIFICATIONS_INBOX_STATE_SUCCEEDED = "license/UPDATE_NOTIFICATIONS_INBOX_STATE_SUCCEEDED",
  UPDATE_NOTIFICATIONS_INBOX_STATE_FAILED = "license/UPDATE_NOTIFICATIONS_INBOX_STATE_FAILED",

  FETCH_NOTIFICATIONS_INBOX = "license/FETCH_NOTIFICATIONS_INBOX",
  FETCH_NOTIFICATIONS_INBOX_SUCCEEDED = "license/FETCH_NOTIFICATIONS_INBOX_SUCCEEDED",
  FETCH_NOTIFICATIONS_INBOX_FAILED = "license/FETCH_NOTIFICATIONS_INBOX_FAILED",

  CHANGE_USER_ORGANIZATION = "license/CHANGE_USER_ORGANIZATION",

  SEND_FEEDBACK = "license/SEND_FEEDBACK",
  SEND_FEEDBACK_SUCCEEDED = "license/SEND_FEEDBACK_SUCCEEDED",
  SEND_FEEDBACK_FAILED = "license/SEND_FEEDBACK_FAILED",
  CLEAR_FEEDBACK = "license/CLEAR_FEEDBACK",

  FETCH_LICENSE_TYPES = "license/FETCH_LICENSE_TYPES",
  FETCH_LICENSE_TYPES_SUCCEEDED = "license/FETCH_LICENSE_TYPES_SUCEEDED",
  FETCH_LICENSE_TYPES_FAILED = "license/FETCH_LICENSE_TYPES_FAILED",

  CHANGE_USER_LICENSE_TYPE = "license/CHANGE_USER_LICENSE_TYPE",

  FETCH_PERMISSIONS = "license/FETCH_PERMISSIONS",
  FETCH_PERMISSIONS_SUCCEEDED = "license/FETCH_PERMISSIONS_SUCEEDED",
  FETCH_PERMISSIONS_FAILED = "license/FETCH_PERMISSIONS_FAILED",
}

export enum DatasetFoldersActionType {
  FETCH_FOLDERS_STRUCTURE = "datasetFolders/FETCH_FOLDERS_STRUCTURE",
  FETCH_FOLDERS_STRUCTURE_SUCCEEDED = "datasetFolders/FETCH_FOLDERS_STRUCTURE_SUCCEEDED",
  FETCH_FOLDERS_STRUCTURE_FAILED = "datasetFolders/FETCH_FOLDERS_STRUCTURE_FAILED",

  CREATE_DATASET_FOLDER = "datasetFolders/CREATE_DATASET_FOLDER",
  CREATE_DATASET_FOLDER_SUCCEEDED = "datasetFolders/CREATE_DATASET_FOLDER_SUCCEEDED",
  CREATE_DATASET_FOLDER_FAILED = "datasetFolders/CREATE_DATASET_FOLDER_FAILED",

  RENAME_DATASET_FOLDER = "datasetFolders/RENAME_DATASET_FOLDER",
  RENAME_DATASET_FOLDER_SUCCEEDED = "datasetFolders/RENAME_DATASET_FOLDER_SUCCEEDED",
  RENAME_DATASET_FOLDER_FAILED = "datasetFolders/RENAME_DATASET_FOLDER_FAILED",

  DELETE_DATASET_FOLDER = "datasetFolders/DELETE_DATASET_FOLDER",
  DELETE_DATASET_FOLDER_SUCCEEDED = "datasetFolders/DELETE_DATASET_FOLDER_SUCCEEDED",
  DELETE_DATASET_FOLDER_FAILED = "datasetFolders/DELETE_DATASET_FOLDER_FAILED",

  CHANGE_DATASET_FOLDER_INDEX = "datasetFolders/CHANGE_DATASET_FOLDER_INDEX",
  CHANGE_DATASET_FOLDER_INDEX_SUCCEEDED = "datasetFolders/CHANGE_DATASET_FOLDER_INDEX_SUCCEEDED",
  CHANGE_DATASET_FOLDER_INDEX_FAILED = "datasetFolders/CHANGE_DATASET_FOLDER_INDEX_FAILED",

  CREATE_DATASET_IN_FOLDER = "datasetFolders/CREATE_DATASET_IN_FOLDER",
  CREATE_DATASET_IN_FOLDER_SUCCEEDED = "datasetFolders/CREATE_DATASET_IN_FOLDER_SUCCEEDED",
  CREATE_DATASET_IN_FOLDER_FAILED = "datasetFolders/CREATE_DATASET_IN_FOLDER_FAILED",

  RENAME_DATASET_IN_FOLDER = "datasetFolders/RENAME_DATASET_IN_FOLDER",
  RENAME_DATASET_IN_FOLDER_SUCCEEDED = "datasetFolders/RENAME_DATASET_IN_FOLDER_SUCCEEDED",
  RENAME_DATASET_IN_FOLDER_FAILED = "datasetFolders/RENAME_DATASET_IN_FOLDER_FAILED",

  DELETE_DATASET_IN_FOLDER = "datasetFolders/DELETE_DATASET_IN_FOLDER",
  DELETE_DATASET_IN_FOLDER_SUCCEEDED = "datasetFolders/DELETE_DATASET_IN_FOLDER_SUCCEEDED",
  DELETE_DATASET_IN_FOLDER_FAILED = "datasetFolders/DELETE_DATASET_IN_FOLDER_FAILED",

  DELETE_CUSTOM_ZONING = "datasetFolders/DELETE_CUSTOM_ZONING",
  DELETE_CUSTOM_ZONING_SUCCEEDED = "datasetFolders/DELETE_CUSTOM_ZONING_SUCCEEDED",
  DELETE_CUSTOM_ZONING_FAILED = "datasetFolders/DELETE_CUSTOM_ZONING_FAILED",

  GET_CUSTOM_ZONING_SELECTOR_FOLDERS = "datasetFolders/GET_CUSTOM_ZONING_SELECTOR_FOLDERS",
  GET_CUSTOM_ZONING_SELECTOR_FOLDERS_SUCCEEDED = "datasetFolders/GET_CUSTOM_ZONING_SELECTOR_FOLDERS_SUCCEEDED",
  GET_CUSTOM_ZONING_SELECTOR_FOLDERS_FAILED = "datasetFolders/GET_CUSTOM_ZONING_SELECTOR_FOLDERS_FAILED",

  CHANGE_CATALOG_ITEM_IN_FOLDER_INDEX = "datasetFolders/CHANGE_CATALOG_ITEM_IN_FOLDER_INDEX",
  CHANGE_CATALOG_ITEM_IN_FOLDER_INDEX_SUCCEEDED = "datasetFolders/CHANGE_CATALOG_ITEM_IN_FOLDER_INDEX_SUCCEEDED",
  CHANGE_CATALOG_ITEM_IN_FOLDER_INDEX_FAILED = "datasetFolders/CHANGE_CATALOG_ITEM_IN_FOLDER_INDEX_FAILED",

  COPY_DATASET_IN_FOLDER = "datasetFolders/COPY_DATASET_IN_FOLDER",
  COPY_DATASET_IN_FOLDER_SUCCEEDED = "datasetFolders/COPY_DATASET_IN_FOLDER_SUCCEEDED",
  COPY_DATASET_IN_FOLDER_FAILED = "datasetFolders/COPY_DATASET_IN_FOLDER_FAILED",

  CLEAR_ERROR_MESSAGE = "datasetFolders/CLEAR_ERROR_MESSAGE",

  UPLOAD_ZONING_SHAPEFILES = "analytics/UPLOAD_ZONING_SHAPEFILES",
  UPLOAD_ZONING_SHAPEFILES_SUCCEEDED = "analytics/UPLOAD_ZONING_SHAPEFILES_SUCCEEDED",
  UPLOAD_ZONING_SHAPEFILES_FAILED = "analytics/UPLOAD_ZONING_SHAPEFILES_FAILED",

  PREPARE_ZONING = "analytics/PREPARE_ZONING",
  PREPARE_ZONING_SUCCEEDED = "analytics/PREPARE_ZONING_SUCCEEDED",
  PREPARE_ZONING_FAILED = "analytics/PREPARE_ZONING_FAILED",

  CREATE_ZONING = "analytics/CREATE_ZONING",
  CREATE_ZONING_SUCCEEDED = "analytics/CREATE_ZONING_SUCCEEDED",
  CREATE_ZONING_FAILED = "analytics/CREATE_ZONING_FAILED",

  DELETE_ZONING = "analytics/DELETE_ZONING",
  DELETE_ZONING_SUCCEEDED = "analytics/DELETE_ZONING_SUCCEEDED",
  DELETE_ZONING_FAILED = "analytics/DELETE_ZONING_FAILED",

  EDIT_ZONING = "analytics/EDIT_ZONING",
  EDIT_ZONING_SUCCEEDED = "analytics/EDIT_ZONING_SUCCEEDED",
  EDIT_ZONING_FAILED = "analytics/EDIT_ZONING_FAILED",

  GET_ZONING = "analytics/GET_ZONING",
  GET_ZONING_SUCCEEDED = "analytics/GET_ZONING_SUCCEEDED",
  GET_ZONING_FAILED = "analytics/GET_ZONING_FAILED",

  CLEAR_ZONING_INFO = "analytics/CLEAR_ZONING_INFO",
}

export enum SelectLinkActionType {
  FETCH_SELECT_LINK_SEGMENT_COUNTS = "selectLink/FETCH_SELECT_LINK_SEGMENT_COUNTS",
  FETCH_SELECT_LINK_SEGMENT_COUNTS_SUCCEEDED = "selectLink/FETCH_SELECT_LINK_SEGMENT_COUNTS_SUCCEEDED",
  FETCH_SELECT_LINK_SEGMENT_COUNTS_FAILED = "selectLink/FETCH_SELECT_LINK_SEGMENT_COUNTS_FAILED",
  CLEAR_SELECT_LINK_SEGMENT_COUNTS = "selectLink/CLEAR_SELECT_LINK_SEGMENT_COUNTS",
  LIST_SELECT_LINK_CONFIGS = "selectLink/LIST_SELECT_LINK_CONFIGS",
  LIST_SELECT_LINK_CONFIGS_SUCCEEDED = "selectLink/LIST_SELECT_LINK_CONFIGS_SUCCEEDED",
  LIST_SELECT_LINK_CONFIGS_FAILED = "selectLink/LIST_SELECT_LINK_CONFIGS_FAILED",
  CREATE_SELECT_LINK_CONFIG = "selectLink/CREATE_SELECT_LINK_CONFIG",
  CREATE_SELECT_LINK_CONFIG_SUCCEEDED = "selectLink/CREATE_SELECT_LINK_CONFIG_SUCCEEDED",
  CREATE_SELECT_LINK_CONFIG_FAILED = "selectLink/CREATE_SELECT_LINK_CONFIG_FAILED",
  FETCH_SELECT_LINK_CONFIG = "selectLink/FETCH_SELECT_LINK_CONFIG",
  FETCH_SELECT_LINK_CONFIG_SUCCEEDED = "selectLink/FETCH_SELECT_LINK_CONFIG_SUCCEEDED",
  FETCH_SELECT_LINK_CONFIG_FAILED = "selectLink/FETCH_SELECT_LINK_CONFIG_FAILED",
  UPDATE_SELECT_LINK_CONFIG = "selectLink/UPDATE_SELECT_LINK_CONFIG",
  UPDATE_SELECT_LINK_CONFIG_SUCCEEDED = "selectLink/UPDATE_SELECT_LINK_CONFIG_SUCCEEDED",
  UPDATE_SELECT_LINK_CONFIG_FAILED = "selectLink/UPDATE_SELECT_LINK_CONFIG_FAILED",
  DELETE_SELECT_LINK_CONFIG = "selectLink/DELETE_SELECT_LINK_CONFIG",
  DELETE_SELECT_LINK_CONFIG_SUCCEED = "selectLink/DELETE_SELECT_LINK_CONFIG_SUCCEED",
  DELETE_SELECT_LINK_CONFIG_FAILED = "selectLink/DELETE_SELECT_LINK_CONFIG_FAILED",
  CLEAR_NEW_SELECT_LINK_CONFIG = "selectLink/CLEAR_NEW_SELECT_LINK_CONFIG",
  CLEAR_SELECT_LINK_CONFIG_DETAILS = "selectLink/CLEAR_SELECT_LINK_CONFIG_DETAILS",
  CLEAR_ERROR_MESSAGES = "selectLink/CLEAR_ERROR_MESSAGES",
  SET_AVAILABLE_RANGE = "selectLink/SET_AVAILABLE_RANGE",
  SET_SELECT_LINK_ANALYSIS_MODE = "selectLink/SET_SELECT_LINK_ANALYSIS_MODE",
}

export enum GlobalActionType {
  FULL_RESET = "global/FULL_RESET",
  SET_SELECTED_FOCUS_AREA_ID = "global/SET_SELECTED_FOCUS_AREA_ID",
  SET_SELECTED_FOCUS_AREA_ID_SUCCEEDED = "global/SET_SELECTED_FOCUS_AREA_ID_SUCCEEDED",
  SET_SELECTED_FOCUS_AREA = "global/SET_SELECTED_FOCUS_AREA",
  SET_TIME_PERIOD = "global/SET_TIME_PERIOD",
  SET_TIME_PERIOD_SUCCEEDED = "global/SET_TIME_PERIOD_SUCCEEDED",
  SET_TOAST_MESSAGE = "global/SET_TOAST_MESSAGE",
  SET_REDIRECT_URL = "global/SET_REDIRECT_URL",
}

export enum MapActionType {
  SET_BASE_MAP_STYLE = "map/SET_BASE_MAP_STYLE",
  SET_COLOR_SCHEME = "map/SET_COLOR_SCHEME",
  SET_MAP_BOUNDS = "map/SET_MAP_BOUNDS",
  CLEAR_MAP_BOUNDS = "map/CLEAR_MAP_BOUNDS",
}

export enum CorridorActionType {
  FETCH_CORRIDOR_METADATA = "analytics/FETCH_CORRIDOR_METADATA",
  FETCH_CORRIDOR_METADATA_SUCCEEDED = "analytics/FETCH_CORRIDOR_METADATA_SUCCEEDED",
  FETCH_CORRIDOR_METADATA_FAILED = "analytics/FETCH_CORRIDOR_METADATA_FAILED",

  FETCH_CORRIDOR_EDGE_IDS = "analytics/FETCH_CORRIDOR_EDGE_IDS",
  FETCH_CORRIDOR_EDGE_IDS_SUCCEEDED = "analytics/FETCH_CORRIDOR_EDGE_IDS_SUCCEEDED",
  FETCH_CORRIDOR_EDGE_IDS_FAILED = "analytics/FETCH_CORRIDOR_EDGE_IDS_FAILED",

  FETCH_CORRIDOR_EDGE_COUNTS = "analytics/FETCH_CORRIDOR_EDGE_COUNTS",
  FETCH_CORRIDOR_EDGE_COUNTS_SUCCEEDED = "analytics/FETCH_CORRIDOR_EDGE_COUNTS_SUCCEEDED",
  FETCH_CORRIDOR_EDGE_COUNTS_FAILED = "analytics/FETCH_CORRIDOR_EDGE_COUNTS_FAILED",

  FETCH_CORRIDOR_EDGE_AVAILABLE_RANGE = "analytics/FETCH_CORRIDOR_EDGE_AVAILABLE_RANGE",
  FETCH_CORRIDOR_EDGE_AVAILABLE_RANGE_SUCCEEDED = "analytics/FETCH_CORRIDOR_EDGE_AVAILABLE_RANGE_SUCCEEDED",
  FETCH_CORRIDOR_EDGE_AVAILABLE_RANGE_FAILED = "analytics/FETCH_CORRIDOR_EDGE_AVAILABLE_RANGE_FAILED",

  FETCH_CORRIDOR_NODE_IDS = "analytics/FETCH_CORRIDOR_NODE_IDS",
  FETCH_CORRIDOR_NODE_IDS_SUCCEEDED = "analytics/FETCH_CORRIDOR_NODE_IDS_SUCCEEDED",
  FETCH_CORRIDOR_NODE_IDS_FAILED = "analytics/FETCH_CORRIDOR_NODE_IDS_FAILED",

  FETCH_CORRIDOR_NODE_COUNTS = "analytics/FETCH_CORRIDOR_NODE_COUNTS",
  FETCH_CORRIDOR_NODE_COUNTS_SUCCEEDED = "analytics/FETCH_CORRIDOR_NODE_COUNTS_SUCCEEDED",
  FETCH_CORRIDOR_NODE_COUNTS_FAILED = "analytics/FETCH_CORRIDOR_NODE_COUNTS_FAILED",

  FETCH_SERVICE_OVERLAY_LAYERS = "analytics/FETCH_SERVICE_OVERLAY_LAYERS",
  FETCH_SERVICE_OVERLAY_LAYERS_SUCCEEDED = "analytics/FETCH_SERVICE_OVERLAY_LAYERS_SUCCEEDED",
  FETCH_SERVICE_OVERLAY_LAYERS_FAILED = "analytics/FETCH_SERVICE_OVERLAY_LAYERS_FAILED",

  CLEAR_SERVICE_OVERLAY_LAYERS = "analytics/CLEAR_SERVICE_OVERLAY_LAYERS",

  FETCH_CORRIDOR_EDGE_DETAILS = "analytics/FETCH_CORRIDOR_EDGE_DETAILS",
  FETCH_CORRIDOR_EDGE_DETAILS_SUCCEEDED = "analytics/FETCH_CORRIDOR_EDGE_DETAILS_SUCCEEDED",
  FETCH_CORRIDOR_EDGE_DETAILS_FAILED = "analytics/FETCH_CORRIDOR_EDGE_DETAILS_FAILED",

  FETCH_CORRIDOR_HEATMAP_CONFIGURATION = "analytics/FETCH_CORRIDOR_HEATMAP_CONFIGURATION",
  FETCH_CORRIDOR_HEATMAP_CONFIGURATION_SUCCEEDED = "analytics/FETCH_CORRIDOR_HEATMAP_CONFIGURATION_SUCCEEDED",
  FETCH_CORRIDOR_HEATMAP_CONFIGURATION_FAILED = "analytics/FETCH_CORRIDOR_HEATMAP_CONFIGURATION_FAILED",

  SET_CORRIDOR_FILTERS = "analytics/SET_CORRIDOR_FILTERS",

  SET_CORRIDOR_EDGE_RANGE = "analytics/SET_CORRIDOR_EDGE_RANGE",

  SET_SELECTED_CORRIDOR_EDGE = "analytics/SET_SELECTED_CORRIDOR_EDGE",
  SET_SELECTED_CORRIDOR_EDGE_DIRECTION_ID = "analytics/SET_SELECTED_CORRIDOR_EDGE_DIRECTION_ID",

  CLEAR_CORRIDOR_METADATA = "analytics/CLEAR_CORRIDOR_METADATA",
}

export enum RoadVmtActionType {
  FETCH_ROAD_VMT_METADATA = "roadVmt/FETCH_ROAD_VMT_METADATA",
  FETCH_ROAD_VMT_METADATA_SUCCEEDED = "roadVmt/FETCH_ROAD_VMT_METADATA_SUCCEEDED",
  FETCH_ROAD_VMT_METADATA_FAILED = "roadVmt/FETCH_ROAD_VMT_METADATA_FAILED",
  CLEAR_ROAD_VMT_METADATA = "roadVmt/CLEAR_ROAD_VMT_METADATA",

  FETCH_ROAD_VMT_ZONE_COUNTS = "roadVmt/FETCH_ROAD_VMT_ZONE_COUNTS",
  FETCH_ROAD_VMT_ZONE_COUNTS_SUCCEEDED = "roadVmt/FETCH_ROAD_VMT_ZONE_COUNTS_SUCCEEDED",
  FETCH_ROAD_VMT_ZONE_COUNTS_FAILED = "roadVmt/FETCH_ROAD_VMT_ZONE_COUNTS_FAILED",

  FETCH_ROAD_MILEAGE_ZONE_COUNTS = "roadVmt/FETCH_ROAD_MILEAGE_ZONE_COUNTS",
  FETCH_ROAD_MILEAGE_ZONE_COUNTS_SUCCEEDED = "roadVmt/FETCH_ROAD_MILEAGE_ZONE_COUNTS_SUCCEEDED",
  FETCH_ROAD_MILEAGE_ZONE_COUNTS_FAILED = "roadVmt/FETCH_ROAD_MILEAGE_ZONE_COUNTS_FAILED",

  SET_ROAD_VMT_FILTERS = "roadVmt/SET_ROAD_VMT_FILTERS",

  SET_SELECTED_ZONE = "roadVmt/SET_SELECTED_ZONE",

  FETCH_ROAD_VMT_ZONE_DETAILS = "roadVmt/FETCH_ROAD_VMT_ZONE_DETAILS",
  FETCH_ROAD_VMT_ZONE_DETAILS_SUCCEEDED = "roadVmt/FETCH_ROAD_VMT_ZONE_DETAILS_SUCCEEDED",
  FETCH_ROAD_VMT_ZONE_DETAILS_FAILED = "roadVmt/FETCH_ROAD_VMT_ZONE_DETAILS_FAILED",

  FETCH_MILEAGE_ZONE_DETAILS = "roadVmt/FETCH_MILEAGE_ZONE_DETAILS",
  FETCH_MILEAGE_ZONE_DETAILS_SUCCEEDED = "roadVmt/FETCH_MILEAGE_ZONE_DETAILS_SUCCEEDED",
  FETCH_MILEAGE_ZONE_DETAILS_FAILED = "roadVmt/FETCH_MILEAGE_ZONE_DETAILS_FAILED",

  FETCH_ZONE_IDS = "roadVmt/FETCH_ZONE_IDS",
  FETCH_ZONE_IDS_SUCCEEDED = "roadVmt/FETCH_ZONE_IDS_SUCCEEDED",
  FETCH_ZONE_IDS_FAILED = "roadVmt/FETCH_ZONE_IDS_FAILED",
}
