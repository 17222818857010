import { ChangeEvent, FC } from "react";

import { CheckboxDropdown } from "components";

import { FiltersType, areAllItemsUnChecked } from "./Filters";

export interface FiltersPanelProps {
  filters: FiltersType | null;
  loading: boolean;
  handleChangeFilter: (event: ChangeEvent<HTMLInputElement>) => void;
  handleChangeAllFilters: (isChecked: boolean) => (groupName: string) => void;
}

export const FiltersPanel: FC<FiltersPanelProps> = ({
  filters,
  loading,
  handleChangeFilter,
  handleChangeAllFilters,
}) => {
  return (
    <>
      {filters &&
        Object.entries(filters).map(([groupKey, group], i) => (
          <CheckboxDropdown
            key={i}
            isGroupChecked={group.isChecked}
            groupName={groupKey}
            groupLabel={group.label}
            groupIcon={group.icon}
            items={group.items}
            placement="top"
            disabled={loading}
            error={areAllItemsUnChecked(group.items)}
            onChange={handleChangeFilter}
            selectAll={handleChangeAllFilters(true)}
            clearAll={handleChangeAllFilters(false)}
          />
        ))}
    </>
  );
};
