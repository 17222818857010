import arrowHover from "assets/svg/arrow-hover.svg";
import countiesArrow from "assets/svg/counties-arrow.svg";
import externalArrow from "assets/svg/external-arrow.svg";
import gateArrow from "assets/svg/gate-arrow.svg";
import internalArrow from "assets/svg/internal-arrow.svg";
import { scaleLog } from "d3-scale";
import { Feature, FeatureCollection, Geometry } from "geojson";
import uniqBy from "lodash/uniqBy";
import { Map, MapLayerMouseEvent, Popup } from "mapbox-gl";
import { Dispatch, RefObject, SetStateAction } from "react";

import { ODPopupProps } from "components";

import { Flow, FlowPattern, HoveredFlow, QueryType, ZoneDetails, ZoningLevel } from "types";

import { closePopup, getCustomFlowColor, getFlowInfo } from "./utils";

interface TopFlowsData {
  queryType: QueryType;
  zoneDetails: ZoneDetails;
  zoningLevels: ZoningLevel[] | undefined;
  topFlowPopupRef: RefObject<HTMLDivElement>;
  selectedZoneId: string;
  selectedZoneType: string;
  setHoveredFlow: Dispatch<SetStateAction<HoveredFlow | null>>;
  setTopFlowsPopupProps: Dispatch<SetStateAction<ODPopupProps | null>>;
}

const ZONE_DETAILS_ARROWS_SOURCE = "arrows-source";
const ZONE_DETAILS_CENTROIDS_SOURCE = "centroids-source";
const ZONE_DETAILS_ARROWS_LAYER = "arrows";
export const ZONE_DETAILS_ARROWS_HOVER_LAYER = "arrows-hover";
const ZONE_DETAILS_CENTROIDS_LAYER = "centroids";

export const getFlowPattern = (
  { external, isGate, id, level }: Flow,
  selectedZoneId: string,
  selectedZoneType: string,
) => {
  if (
    (!external && !isGate && selectedZoneId === id && selectedZoneType !== "gate") ||
    (external && isGate && id === selectedZoneId && selectedZoneType === "gate")
  ) {
    return FlowPattern.internalRound;
  } else if (!isGate && !external) {
    return FlowPattern.internal;
  } else if (isGate) {
    return FlowPattern.gate;
  } else if (!isGate && external && level === "County") {
    return FlowPattern.counties;
  } else {
    return FlowPattern.unknown;
  }
};

const getArrowsGeoJson = (
  zoneDetails: ZoneDetails,
  queryType: QueryType,
  selectedZoneId: string,
  selectedZoneType: string,
  getWidthInPixelsByVolume: (value: number) => number,
): FeatureCollection => {
  const flows = zoneDetails.topFlows[queryType].flows;

  return {
    type: "FeatureCollection",
    features: flows
      .filter((flow) => flow.lat && flow.lon)
      .map((flow, index) => {
        return {
          type: "Feature",
          id: flow.id,
          properties: {
            id: flow.id,
            value: flow.value,
            width: getWidthInPixelsByVolume(flow.value),
            pattern: getFlowPattern(flow, selectedZoneId, selectedZoneType),
            level: flow.level,
            areaName: flow.areaName,
            levelName: flow.levelName,
            countryName: flow.countryName,
            isGate: flow.isGate,
            index: index + 1,
            external: flow.external,
            selectedZoneId,
            selectedZoneType,
          },
          geometry: {
            type: "LineString",
            coordinates:
              queryType === QueryType.INCOMING
                ? [
                    [flow.lon, flow.lat],
                    [zoneDetails.lon, zoneDetails.lat],
                  ]
                : [
                    [zoneDetails.lon, zoneDetails.lat],
                    [flow.lon, flow.lat],
                  ],
          },
        };
      }) as Array<Feature<Geometry, any>>,
  };
};

const getCentroidsGeoJson = (
  zoneDetails: ZoneDetails,
  queryType: QueryType,
  selectedZoneId: string,
  selectedZoneType: string,
  getWidthInPixelsByVolume: (value: number) => number,
): FeatureCollection => {
  const flows = zoneDetails.topFlows[queryType].flows;

  return {
    type: "FeatureCollection",
    features: [
      {
        type: "Feature",
        properties: {
          id: `${zoneDetails.zoneId}-centroid`,
          radius: 10,
          color: "#6c2167",
        },
        geometry: {
          type: "Point",
          coordinates: [zoneDetails.lon, zoneDetails.lat],
        },
      },
      ...(uniqBy(flows, (flow) => [flow.lat, flow.lon].join("-"))
        .filter((flow) => flow.lat && flow.lon)
        .map((flow) => {
          return {
            type: "Feature",
            properties: {
              id: `${flow.id}-${flow.external ? "external" : "internal"}-centroid`,
              radius: getWidthInPixelsByVolume(flow.value) / 2,
              color: getCustomFlowColor(getFlowPattern(flow, selectedZoneId, selectedZoneType)),
            },
            geometry: {
              type: "Point",
              coordinates: [flow.lon, flow.lat],
            },
          };
        }) as Array<Feature<Geometry, any>>),
    ],
  };
};

const initSVG = (map: Map) => {
  if (!map.hasImage("internal-pattern")) {
    const arrowSVG = new Image(64, 64);
    arrowSVG.onload = () => map.addImage("internal-pattern", arrowSVG);
    arrowSVG.src = internalArrow;
  }

  if (!map.hasImage("gate-pattern")) {
    const arrowSVG = new Image(64, 64);
    arrowSVG.onload = () => map.addImage("gate-pattern", arrowSVG);
    arrowSVG.src = gateArrow;
  }

  if (!map.hasImage("counties-pattern")) {
    const arrowSVG = new Image(64, 64);
    arrowSVG.onload = () => map.addImage("counties-pattern", arrowSVG);
    arrowSVG.src = countiesArrow;
  }

  if (!map.hasImage("external-pattern")) {
    const arrowSVG = new Image(64, 64);
    arrowSVG.onload = () => map.addImage("external-pattern", arrowSVG);
    arrowSVG.src = externalArrow;
  }

  if (!map.hasImage("arrow-hover-pattern")) {
    const arrowHoverSVG = new Image(64, 64);
    arrowHoverSVG.onload = () => map.addImage("arrow-hover-pattern", arrowHoverSVG);
    arrowHoverSVG.src = arrowHover;
  }
};

export const updateTopFlows = (
  map: Map,
  {
    queryType,
    zoneDetails,
    setHoveredFlow,
    setTopFlowsPopupProps,
    topFlowPopupRef,
    zoningLevels,
    selectedZoneId,
    selectedZoneType,
  }: TopFlowsData,
) => {
  let popup: Popup = new Popup({
    closeButton: false,
    closeOnClick: false,
    offset: 15,
  });

  const handleArrowMousemove = (e: MapLayerMouseEvent) => {
    map.getCanvas().style.cursor = "pointer";
    if (e.features?.length) {
      const feature = e.features[0];
      const featureId = feature?.properties?.id;
      const properties = feature.properties;

      const count = properties?.value;

      if (featureId && count !== undefined && properties && zoningLevels && topFlowPopupRef.current) {
        const { level, id, areaName, levelName, countryName, isGate, index, external } = properties;
        setHoveredFlow({ id: featureId.toString(), external, isGate });

        setTopFlowsPopupProps({
          count,
          type: queryType,
          flowInfo: getFlowInfo(queryType, level, id, areaName, levelName, countryName, isGate, zoningLevels, index),
        });

        popup.setLngLat(e.lngLat).setDOMContent(topFlowPopupRef.current).addTo(map);
      } else {
        setHoveredFlow(null);
        closePopup(popup, setTopFlowsPopupProps);
      }
    }
  };

  const handleArrowMouseleave = () => {
    map.getCanvas().style.cursor = "";
    setHoveredFlow(null);
    closePopup(popup, setTopFlowsPopupProps);
  };

  initSVG(map);

  const flows: Flow[] = zoneDetails.topFlows[queryType].flows;

  const { min, max } = flows.reduce(
    (acc: { min: number; max: number }, { value }) => {
      return {
        min: value ? Math.min(acc.min, value) : acc.min,
        max: Math.max(acc.max, value),
      };
    },
    { min: Infinity, max: 0 },
  );

  const logScale = scaleLog().domain([min, max]).range([5, 30]);
  const getWidthInPixelsByVolume = (value: number) => logScale(value);

  const arrowsGeoJson = getArrowsGeoJson(
    zoneDetails,
    queryType,
    selectedZoneId,
    selectedZoneType,
    getWidthInPixelsByVolume,
  );
  const centroidsGeoJson = getCentroidsGeoJson(
    zoneDetails,
    queryType,
    selectedZoneId,
    selectedZoneType,
    getWidthInPixelsByVolume,
  );

  map.addSource(ZONE_DETAILS_ARROWS_SOURCE, {
    type: "geojson",
    data: arrowsGeoJson,
    lineMetrics: true,
    maxzoom: 15,
  });

  map.addSource(ZONE_DETAILS_CENTROIDS_SOURCE, {
    type: "geojson",
    data: centroidsGeoJson,
  });

  map.addLayer({
    id: ZONE_DETAILS_CENTROIDS_LAYER,
    type: "circle",
    source: ZONE_DETAILS_CENTROIDS_SOURCE,
    paint: {
      "circle-radius": ["get", "radius"],
      "circle-color": ["get", "color"],
      "circle-stroke-width": 2,
      "circle-stroke-color": "#ffffff",
    },
  });

  map.addLayer(
    {
      id: ZONE_DETAILS_ARROWS_LAYER,
      type: "line",
      source: ZONE_DETAILS_ARROWS_SOURCE,
      paint: {
        "line-width": ["get", "width"],
        "line-opacity": 1,
        "line-pattern": ["get", "pattern"],
      },
    },
    ZONE_DETAILS_CENTROIDS_LAYER,
  );

  map.addLayer(
    {
      id: ZONE_DETAILS_ARROWS_HOVER_LAYER,
      type: "line",
      source: ZONE_DETAILS_ARROWS_SOURCE,
      paint: {
        "line-width": ["get", "width"],
        "line-opacity": 1,
        "line-pattern": "arrow-hover-pattern",
      },
      filter: ["==", ["get", "id"], ""],
    },
    ZONE_DETAILS_CENTROIDS_LAYER,
  );

  map.on("mousemove", [ZONE_DETAILS_CENTROIDS_LAYER, ZONE_DETAILS_ARROWS_LAYER], handleArrowMousemove);
  map.on("mouseleave", [ZONE_DETAILS_CENTROIDS_LAYER, ZONE_DETAILS_ARROWS_LAYER], handleArrowMouseleave);

  return {
    clear: () => {
      setHoveredFlow(null);
      if (map.getLayer(ZONE_DETAILS_ARROWS_LAYER)) {
        map.removeLayer(ZONE_DETAILS_ARROWS_LAYER);
      }

      if (map.getLayer(ZONE_DETAILS_CENTROIDS_LAYER)) {
        map.removeLayer(ZONE_DETAILS_CENTROIDS_LAYER);
      }

      if (map.getLayer(ZONE_DETAILS_ARROWS_HOVER_LAYER)) {
        map.removeLayer(ZONE_DETAILS_ARROWS_HOVER_LAYER);
      }

      if (map.getSource(ZONE_DETAILS_ARROWS_SOURCE)) {
        map.removeSource(ZONE_DETAILS_ARROWS_SOURCE);
      }

      if (map.getSource(ZONE_DETAILS_CENTROIDS_SOURCE)) {
        map.removeSource(ZONE_DETAILS_CENTROIDS_SOURCE);
      }
      map.off("mousemove", [ZONE_DETAILS_CENTROIDS_LAYER, ZONE_DETAILS_ARROWS_LAYER], handleArrowMousemove);
      map.off("mouseleave", [ZONE_DETAILS_CENTROIDS_LAYER, ZONE_DETAILS_ARROWS_LAYER], handleArrowMouseleave);

      closePopup(popup, setTopFlowsPopupProps);
    },
  };
};
