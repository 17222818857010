import React, { FC, useState, ChangeEvent } from "react";
import styled from "@emotion/styled";
import { HTMLTable } from "@blueprintjs/core";

import { Input } from "components";

export interface DocsTableProps {
  data: Object[];
}

const TableContainer = styled.div`
  max-height: 400px;
  overflow-y: auto;
`;

const SelectContainer = styled.div`
  max-width: 250px;
  margin: 1rem 0;
`;

const StyledTable = styled(HTMLTable)`
  width: 100%;
  border-collapse: collapse;

  & thead tr th {
    position: sticky;
    top: 0;
    background-color: #ffffff;
  }
`;

const renderHeader = (data: Object[]) =>
  data.length ? (
    <thead>
      <tr>
        {Object.keys(data[0]).map((key, i) => (
          <th key={i}>{key}</th>
        ))}
      </tr>
    </thead>
  ) : null;

export const DocsTable: FC<DocsTableProps> = ({ data }) => {
  const [items, setItems] = useState(data);
  const [selectedItem, setSelectedItem] = useState("");

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    const filteredItems = data.filter((item) => {
      const values = Object.values(item);
      return values[0].toLowerCase().includes(value);
    });
    setItems(filteredItems);
    setSelectedItem(value);
  };

  return (
    <div>
      {data.length > 20 && (
        <SelectContainer>
          <Input placeholder="Search.." value={selectedItem} onChange={handleChange} />
        </SelectContainer>
      )}
      <TableContainer>
        <StyledTable condensed bordered={false}>
          {renderHeader(data)}
          <tbody>
            {items.map((obj, i) => (
              <tr key={i}>
                {Object.values(obj).map((v, i) => (
                  <td key={i}>{v}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </StyledTable>
      </TableContainer>
    </div>
  );
};
