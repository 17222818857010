import { Dialog as BpDialog, DialogProps as BpDialogProps, Icon } from "@blueprintjs/core";
import styled from "@emotion/styled";
import React, { FC } from "react";

export interface DialogProps extends BpDialogProps {}

export const DialogWrapper = styled(BpDialog)`
  position: relative;
  width: auto;
  min-width: 592px;
  border-radius: 8px;
  background-color: #ffffff;
`;

export const DialogBody = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 4rem 2rem 2rem 2rem;
`;

export const CloseButton = styled(Icon)`
  display: flex;
  position: absolute;
  top: 19px;
  right: 19px;
  cursor: pointer;
  width: 24px;
  height: 24px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  justify-content: center;
  align-items: center;
`;

export const Dialog: FC<DialogProps> = ({ children, ...props }) => {
  return (
    <DialogWrapper {...props}>
      {props.onClose && <CloseButton icon="cross" onClick={props.onClose} />}
      <DialogBody>{children}</DialogBody>
    </DialogWrapper>
  );
};
