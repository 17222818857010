import {
  CollisionDetection,
  DndContext,
  DragOverlay,
  DropAnimation,
  KeyboardSensor,
  MeasuringStrategy,
  MouseSensor,
  TouchSensor,
  UniqueIdentifier,
  closestCenter,
  defaultDropAnimationSideEffects,
  getFirstCollision,
  pointerWithin,
  rectIntersection,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { SortableContext, arrayMove, verticalListSortingStrategy } from "@dnd-kit/sortable";
import styled from "@emotion/styled";
import { DialogTitle } from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { useSearchParams } from "react-router-dom";

import { ZoningUploader } from "features";

import { CustomZoningInfo } from "features/zoning-uploader/CustomZoningInfo";

import { ConfirmDialog, MuiDialog } from "components";

import { useAppDispatch, useAppSelector } from "hooks";

import { DataState } from "store/interfaces";
import { analyticsActions } from "store/sections/analytics";
import { datasetFoldersActions } from "store/sections/userFolders";

import {
  CatalogItem,
  CatalogItemType,
  CreateDatasetPayload,
  CustomDataset,
  CustomDatasetRepository,
  DistanceTypes,
  ZoningItem,
} from "types";

import { Container } from "./Container";
import { DroppableContainer } from "./DroppableContainer";
import { EditZoningDialog } from "./EditZoningDialog";
import { FolderDatasetDialog } from "./FolderDatasetDialog";
import { Item } from "./Item";
import { SortableItem } from "./SortableItem";
import { coordinateGetter as multipleContainersCoordinateGetter } from "./multipleContainersKeyboardCoordinates";

const dropAnimation: DropAnimation = {
  sideEffects: defaultDropAnimationSideEffects({
    styles: {
      active: {
        opacity: "0.5",
      },
    },
  }),
};

export type Items = Record<UniqueIdentifier, UniqueIdentifier[]>;

interface DatasetContainersProps {
  loading: boolean;
  userFolders: CustomDatasetRepository;
  errorDialogMessage: string | null;
  onRenameFolder: (folderId: UniqueIdentifier, folderName: string) => void;
  onDeleteFolder: (folderId: UniqueIdentifier) => void;
  onChangeFolderIndex: (folderId: UniqueIdentifier, index: number) => void;
  onAddDataset: (config: CreateDatasetPayload, openEditor: boolean) => void;
  onRenameDataset: (datasetId: UniqueIdentifier, name: string, isComputed: boolean) => void;
  onDeleteDataset: (datasetId: UniqueIdentifier, computedDatasetId: UniqueIdentifier | undefined) => void;
  onChangeDatasetIndex: (folderId: UniqueIdentifier, datasetId: UniqueIdentifier, index: number) => void;
  onCopyDataset: (datasetId: UniqueIdentifier, config: CreateDatasetPayload, openEditor: boolean) => void;
  onEditZoning: (zoningItemId: string, name: string, description: string) => void;
  onDeleteCustomZoning: (itemId: UniqueIdentifier) => void;
}

const defaultZoningSettings = {
  loading: false,
  activeStep: 0,
  zoningName: "",
  zoningDescription: "",
  selectedIdField: "",
  selectedNameField: "",
  selectedAttributes: [],
  selectedSimplification: {
    sqrRootAreaFactor: "0",
    maxIntervalM: "0",
    intervalM: "0",
  },
  distanceType: DistanceTypes.AreaDependent,
  isSimplificationActive: false,
  setZoningName: () => {},
  setSelectedIdField: () => {},
  setSelectedNameField: () => {},
  setZoningDescription: () => {},
  setSelectedSimplification: () => {},
  setSelectedAttributes: () => {},
  setDistanceType: () => {},
  setIsSemplificationActive: () => {},
};

const DatasetContainersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;
  box-sizing: border-box;
`;

export const DatasetContainers = ({
  loading,
  userFolders,
  errorDialogMessage,
  onRenameFolder,
  onDeleteFolder,
  onChangeFolderIndex,
  onAddDataset,
  onRenameDataset,
  onDeleteDataset,
  onChangeDatasetIndex,
  onCopyDataset,
  onEditZoning,
  onDeleteCustomZoning,
}: DatasetContainersProps) => {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const getItemsFromDatasets = useCallback(() => {
    return Object.fromEntries(
      Object.entries(userFolders).map(([k, v]) => [
        k,
        v && v.items && typeof v.items.map === "function" ? v.items.map((item) => item.id) : [],
      ]),
    ) as Items;
  }, [userFolders]);

  const zoningInfo = useAppSelector((state) => state.datasetFolders.zoningInfo);

  const [items, setItems] = useState<Items>(getItemsFromDatasets());
  const [containers, setContainers] = useState(Object.keys(items) as UniqueIdentifier[]);
  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);
  const [isShowUploadZoningDialog, setIsShowUploadZoningDialog] = useState(false);
  const [isShowRenameFolderDialog, setIsShowRenameFolderDialog] = useState(false);
  const [isShowDeleteFolderConfirm, setIsShowDeleteFolderConfirm] = useState(false);
  const [isShowNewDatasetDialog, setIsShowNewDatasetDialog] = useState(false);
  const [isShowRenameDatasetDialog, setIsShowRenameDatasetDialog] = useState(false);
  const [isShowEditZoningDialog, setIsShowEditZoningDialog] = useState(false);
  const [folderToRename, setFolderToRename] = useState<{
    folderId: UniqueIdentifier;
    folderName: string;
  } | null>(null);
  const [folderToDelete, setFolderToDelete] = useState<UniqueIdentifier | null>(null);
  const [newDatasetFolder, setNewDatasetFolder] = useState<UniqueIdentifier | null>(null);
  const [newCustomZoning, setNewCustomZoning] = useState<{ id: string; name: string } | null>(null);
  const [datasetForRename, setDatasetForRename] = useState<CustomDataset | null>(null);
  const [isShowDeleteCatalogItemConfirm, setIsShowDeleteCatalogItemConfirm] = useState(false);
  const [catalogItemToDelete, setCatalogItemToDelete] = useState<CatalogItem | null>(null);
  const [datasetToCopy, setDatasetToCopy] = useState<{
    dataset: CustomDataset;
    folderId: UniqueIdentifier;
  } | null>(null);
  const [uploadZoningFolderId, setUploadZoningFolderId] = useState<UniqueIdentifier | null>(null);
  const [editZoningItem, setEditZoningItem] = useState<ZoningItem | null>(null);
  const [infoZoningItem, setInfoZoningItem] = useState<ZoningItem | null>(null);

  const lastOverId = useRef<UniqueIdentifier | null>(null);
  const recentlyMovedToNewContainer = useRef(false);

  useEffect(() => {
    setItems(getItemsFromDatasets());
    setContainers(Object.keys(userFolders) as UniqueIdentifier[]);
  }, [userFolders, getItemsFromDatasets]);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (!loading && !errorDialogMessage) {
      if (isShowRenameFolderDialog) {
        setIsShowRenameFolderDialog(false);
        setFolderToRename(null);
      } else if (isShowNewDatasetDialog) {
        setIsShowNewDatasetDialog(false);
        setNewDatasetFolder(null);
        setDatasetToCopy(null);
      } else if (isShowRenameDatasetDialog) {
        setIsShowRenameDatasetDialog(false);
        setDatasetForRename(null);
      } else if (isShowUploadZoningDialog) {
        setIsShowUploadZoningDialog(false);
        setUploadZoningFolderId(null);
      } else if (isShowEditZoningDialog) {
        setIsShowEditZoningDialog(false);
        setEditZoningItem(null);
      }
    }
  }, [loading, errorDialogMessage]);
  /* eslint-enable react-hooks/exhaustive-deps */

  const isSortingContainer = activeId ? containers.includes(activeId) : false;

  const collisionDetectionStrategy: CollisionDetection = useCallback(
    // this strategy was copied from the https://github.com/clauderic/dnd-kit/blob/master/stories/2%20-%20Presets/Sortable/MultipleContainers.tsx
    (args) => {
      if (activeId && activeId in items) {
        return closestCenter({
          ...args,
          droppableContainers: args.droppableContainers.filter((container) => container.id in items),
        });
      }

      const pointerIntersections = pointerWithin(args);
      const intersections = pointerIntersections.length > 0 ? pointerIntersections : rectIntersection(args);
      let overId = getFirstCollision(intersections, "id");

      if (overId != null) {
        if (overId in items) {
          const containerItems = items[overId];

          if (containerItems.length > 0) {
            overId = closestCenter({
              ...args,
              droppableContainers: args.droppableContainers.filter(
                (container) => container.id !== overId && containerItems.includes(container.id),
              ),
            })[0]?.id;
          }
        }

        lastOverId.current = overId;

        return [{ id: overId }];
      }

      if (recentlyMovedToNewContainer.current) {
        lastOverId.current = activeId;
      }

      return lastOverId.current ? [{ id: lastOverId.current }] : [];
    },
    [activeId, items],
  );
  const [clonedItems, setClonedItems] = useState<Items | null>(null);
  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: multipleContainersCoordinateGetter,
    }),
  );

  useEffect(() => {
    const folderId = searchParams.get("folderId");
    const zoningId = searchParams.get("zoningId");
    const zoningName = searchParams.get("zoningName");

    if (folderId && zoningId && zoningName) {
      searchParams.delete("folderId");
      searchParams.delete("zoningId");
      searchParams.delete("zoningName");
      setSearchParams(searchParams);
      handleAddNewDataset(folderId, { id: zoningId, name: zoningName });
    }
  }, [searchParams, setSearchParams]);

  useEffect(() => {
    requestAnimationFrame(() => {
      recentlyMovedToNewContainer.current = false;
    });
  }, [items]);

  const findContainer = (id: UniqueIdentifier) => {
    if (id in items) {
      return id;
    }

    return Object.keys(items).find((key) => items[key].includes(id));
  };

  const getIndex = (id: UniqueIdentifier) => {
    const container = findContainer(id);

    if (!container) {
      return -1;
    }

    const index = items[container].indexOf(id);

    return index;
  };

  const handleDragStart = ({ active }: { active: { id: UniqueIdentifier } }) => {
    setActiveId(active.id);
    setClonedItems(items);
  };

  const handleDragOver = ({ active, over }: any) => {
    const overId = over?.id;

    if (overId == null || active.id in items) {
      return;
    }

    const overContainer = findContainer(overId);
    const activeContainer = findContainer(active.id);

    if (!overContainer || !activeContainer) {
      return;
    }

    if (activeContainer !== overContainer) {
      setItems((items) => {
        const activeItems = items[activeContainer];
        const overItems = items[overContainer];
        const overIndex = overItems.indexOf(overId);
        const activeIndex = activeItems.indexOf(active.id);

        let newIndex: number;

        if (overId in items) {
          newIndex = overItems.length + 1;
        } else {
          const isBelowOverItem =
            over &&
            active.rect.current.translated &&
            active.rect.current.translated.top > over.rect.top + over.rect.height;

          const modifier = isBelowOverItem ? 1 : 0;

          newIndex = overIndex >= 0 ? overIndex + modifier : overItems.length + 1;
        }

        recentlyMovedToNewContainer.current = true;

        onChangeDatasetIndex(overContainer, active.id, newIndex);

        return {
          ...items,
          [activeContainer]: items[activeContainer].filter((item) => item !== active.id),
          [overContainer]: [
            ...items[overContainer].slice(0, newIndex),
            items[activeContainer][activeIndex],
            ...items[overContainer].slice(newIndex, items[overContainer].length),
          ],
        };
      });
    }
  };

  const handleDragEnd = ({ active, over }: any) => {
    if (active.id in items && over?.id) {
      setContainers((containers) => {
        const activeIndex = containers.indexOf(active.id);
        const overIndex = containers.indexOf(over.id);

        if (activeIndex !== overIndex) {
          onChangeFolderIndex(active.id, overIndex);
        }

        return arrayMove(containers, activeIndex, overIndex);
      });
    }

    const activeContainer = findContainer(active.id);

    if (!activeContainer) {
      setActiveId(null);
      return;
    }

    const overId = over?.id;

    if (overId == null) {
      setActiveId(null);
      return;
    }

    const overContainer = findContainer(overId);

    if (overContainer) {
      const activeIndex = items[activeContainer].indexOf(active.id);
      const overIndex = items[overContainer].indexOf(overId);

      if (activeIndex !== overIndex) {
        setItems((items) => ({
          ...items,
          [overContainer]: arrayMove(items[overContainer], activeIndex, overIndex),
        }));

        if (onChangeDatasetIndex) {
          onChangeDatasetIndex(activeContainer, active.id, overIndex);
        }
      }
    }

    setActiveId(null);
  };

  const handleDragCancel = () => {
    if (clonedItems) {
      setItems(clonedItems);
    }

    setActiveId(null);
    setClonedItems(null);
  };

  const handleRenameFolderSubmit = (folderName: string) => {
    if (folderToRename) {
      onRenameFolder(folderToRename.folderId, folderName);
    }
  };

  const handleCloseRenameFolderDialog = () => {
    setIsShowRenameFolderDialog(false);
    dispatch(datasetFoldersActions.clearErrorMessage());
    setFolderToRename(null);
  };

  const handleFolderRename = (folderId: UniqueIdentifier, folderName: string) => {
    setIsShowRenameFolderDialog(true);
    setFolderToRename({ folderId, folderName });
  };

  const handleFolderDelete = (folderId: UniqueIdentifier) => {
    setFolderToDelete(folderId);
    setIsShowDeleteFolderConfirm(true);
  };

  const handleDeleteFolderConfirm = () => {
    setIsShowDeleteFolderConfirm(false);

    if (folderToDelete) {
      onDeleteFolder(folderToDelete);
    }
  };

  const handleDeleteFolderCancel = () => {
    setIsShowDeleteFolderConfirm(false);
  };

  const handleAddNewDataset = (containerId: UniqueIdentifier, customZoning?: { id: string; name: string }) => {
    setIsShowNewDatasetDialog(true);
    setNewDatasetFolder(containerId);
    setNewCustomZoning(customZoning || null);
  };

  const handleAddNewDatasetSubmit = (
    datasetName: string,
    openEditor: boolean,
    configOptions: Omit<CreateDatasetPayload, "folderId" | "datasetName">,
  ) => {
    if (newDatasetFolder) {
      onAddDataset({ ...configOptions, datasetName, folderId: newDatasetFolder }, openEditor);
    }
  };

  const handleAddNewDatasetCancel = () => {
    setIsShowNewDatasetDialog(false);
    setNewDatasetFolder(null);
    setDatasetToCopy(null);
    dispatch(datasetFoldersActions.clearErrorMessage());
  };

  const handleRenameDatasetSubmit = (name: string) => {
    if (datasetForRename) {
      onRenameDataset(datasetForRename.id, name, !!datasetForRename.computedDatasetId);
    }
  };

  const handleCloseRenameDatasetDialog = () => {
    setDatasetForRename(null);
    setIsShowRenameDatasetDialog(false);
    dispatch(datasetFoldersActions.clearErrorMessage());
  };

  const handleDatasetRename = (dataset: CustomDataset) => {
    setIsShowRenameDatasetDialog(true);
    setDatasetForRename(dataset);
  };

  const handleCatalogItemDelete = (catalogItem: CatalogItem) => {
    setCatalogItemToDelete(catalogItem);
    setIsShowDeleteCatalogItemConfirm(true);
  };

  const handleDeleteCatalogItemCancel = () => {
    setIsShowDeleteCatalogItemConfirm(false);
  };

  const handleDeleteCatalogItemConfirm = () => {
    setIsShowDeleteCatalogItemConfirm(false);

    if (catalogItemToDelete && catalogItemToDelete.itemType === CatalogItemType.Dataset) {
      onDeleteDataset(catalogItemToDelete.id, catalogItemToDelete.computedDatasetId);
    } else if (catalogItemToDelete && catalogItemToDelete.itemType === CatalogItemType.Zoning) {
      onDeleteCustomZoning(catalogItemToDelete.id);
    }

    setCatalogItemToDelete(null);
  };

  const handleOpenZoningUploadDialog = (folderId: UniqueIdentifier) => {
    setUploadZoningFolderId(folderId);
    setIsShowUploadZoningDialog(true);
  };

  const handleCloseZoningUploadDialog = () => {
    setIsShowUploadZoningDialog(false);
    setUploadZoningFolderId(null);
  };

  const handleEditZoning = (zoning: ZoningItem) => {
    setEditZoningItem(zoning);
    setIsShowEditZoningDialog(true);
  };

  const handleCloseEditZoningDialog = () => {
    setIsShowEditZoningDialog(false);
    setEditZoningItem(null);
    dispatch(datasetFoldersActions.clearErrorMessage());
  };

  const renderSortableItemDragOverlay = (id: UniqueIdentifier) => {
    const containerId = findContainer(id);
    const index = getIndex(id);

    if (!containerId || index === -1) {
      return null;
    }

    return <Item value={id} data={findItemInAllContainers(userFolders, id)} handle dragOverlay />;
  };

  const handleCopyDataset = (dataset: CustomDataset, folderId: string) => {
    setIsShowNewDatasetDialog(true);
    setDatasetToCopy({ dataset, folderId });
  };

  const handleCopyDatasetSubmit = (
    name: string,
    openEditor: boolean,
    configOptions: Omit<CreateDatasetPayload, "folderId" | "datasetName">,
  ) => {
    if (datasetToCopy) {
      onCopyDataset(
        datasetToCopy.dataset.id,
        {
          datasetName: name,
          folderId: datasetToCopy.folderId,
          licensedAreaId: datasetToCopy.dataset.licensedAreaId.toString(),
          timePeriod: configOptions.timePeriod,
        },
        openEditor,
      );
    }
  };

  const handleStopComputing = (datasetId: string) => {
    dispatch(analyticsActions.cancelODDatasetComputation(datasetId));
  };

  const handleEditZoningSubmit = (name: string, description: string) => {
    if (editZoningItem) {
      onEditZoning(String(editZoningItem.id), name, description);
    }
  };

  const handleGetZoningInfo = (zoningItem: ZoningItem) => {
    setInfoZoningItem(zoningItem);
    dispatch(datasetFoldersActions.getZoning(String(zoningItem.zoningId)));
  };

  const handleCloseZoningInfoDialog = () => {
    setInfoZoningItem(null);
    dispatch(datasetFoldersActions.clearZoningInfo());
  };

  const findItemInAllContainers = (
    containers: CustomDatasetRepository,
    id: UniqueIdentifier,
  ): CatalogItem | undefined => {
    let item: CatalogItem | undefined;

    Object.keys(containers).forEach((containerId) => {
      const container = containers[containerId];
      const foundItem = container?.items?.find((item) => item.id === id);

      if (foundItem) {
        item = foundItem;
      }
    });

    return item;
  };

  const renderContainerDragOverlay = (containerId: UniqueIdentifier) => (
    <Container
      label={userFolders[containerId].folderName}
      permissions={userFolders[containerId].permissions}
      style={{
        height: "100%",
      }}
      shadow
    >
      {items[containerId].map((item, index) => (
        <Item key={item} value={item} data={findItemInAllContainers(userFolders, item)} handle />
      ))}
    </Container>
  );

  return (
    <div>
      <DndContext
        sensors={sensors}
        collisionDetection={collisionDetectionStrategy}
        measuring={{
          droppable: {
            strategy: MeasuringStrategy.Always,
          },
        }}
        onDragStart={handleDragStart}
        onDragOver={handleDragOver}
        onDragEnd={handleDragEnd}
        onDragCancel={handleDragCancel}
      >
        <DatasetContainersWrapper>
          <SortableContext items={[...containers]} strategy={verticalListSortingStrategy}>
            {containers.map((containerId) => (
              <DroppableContainer
                key={containerId}
                id={containerId}
                label={userFolders[containerId]?.folderName}
                permissions={userFolders[containerId]?.permissions}
                items={items[containerId]}
                onRename={() => handleFolderRename(containerId, userFolders[containerId]?.folderName)}
                onDelete={() => handleFolderDelete(containerId)}
                onAddNewDataset={() => handleAddNewDataset(containerId)}
                onUploadZoning={() => handleOpenZoningUploadDialog(containerId)}
              >
                <SortableContext items={items[containerId]} strategy={verticalListSortingStrategy}>
                  {items[containerId].map((value, index) => {
                    return (
                      <SortableItem
                        disabled={isSortingContainer}
                        key={value}
                        id={value}
                        index={index}
                        containerId={containerId}
                        data={findItemInAllContainers(userFolders, value) as CustomDataset}
                        getIndex={getIndex}
                        onDatasetRename={handleDatasetRename}
                        onCatalogItemDelete={handleCatalogItemDelete}
                        onCopyDataset={handleCopyDataset}
                        onStopComputing={handleStopComputing}
                        onEditZoning={handleEditZoning}
                        onGetZoningInfo={handleGetZoningInfo}
                        handle
                      />
                    );
                  })}
                </SortableContext>
              </DroppableContainer>
            ))}
          </SortableContext>
        </DatasetContainersWrapper>
        {createPortal(
          <DragOverlay adjustScale={false} dropAnimation={dropAnimation}>
            {activeId
              ? containers.includes(activeId)
                ? renderContainerDragOverlay(activeId)
                : renderSortableItemDragOverlay(activeId)
              : null}
          </DragOverlay>,
          document.body,
        )}
      </DndContext>
      {isShowRenameFolderDialog && folderToRename && (
        <FolderDatasetDialog
          title="Rename folder"
          buttonLabel="Rename"
          fieldLabel="Folder name"
          initialText={folderToRename.folderName}
          errorMessage={errorDialogMessage}
          onSubmit={handleRenameFolderSubmit}
          onClose={handleCloseRenameFolderDialog}
        />
      )}
      {isShowDeleteFolderConfirm && folderToDelete && (
        <ConfirmDialog
          title={`Delete "${userFolders[folderToDelete].folderName}" folder?`}
          description={`Are you sure you want to delete the "${userFolders[folderToDelete].folderName}" folder that contains ${userFolders[folderToDelete].items.length} dataset(s) inside?`}
          onClose={handleDeleteFolderCancel}
          onSubmit={handleDeleteFolderConfirm}
        />
      )}
      {isShowNewDatasetDialog && (newDatasetFolder || datasetToCopy) && (
        <FolderDatasetDialog
          areas
          optionsAvailable
          title={datasetToCopy ? "Copy dataset" : "Create new dataset"}
          buttonLabel="Add dataset"
          fieldLabel="Dataset name"
          initialText={datasetToCopy ? `${datasetToCopy.dataset.name} (copy)` : undefined}
          errorMessage={errorDialogMessage}
          isAreaOfFocusSelectDisabled={Boolean(datasetToCopy)}
          datasetToCopy={datasetToCopy?.dataset}
          customZoning={newCustomZoning}
          onSubmit={datasetToCopy ? handleCopyDatasetSubmit : handleAddNewDatasetSubmit}
          onClose={handleAddNewDatasetCancel}
        />
      )}
      {isShowRenameDatasetDialog && datasetForRename && (
        <FolderDatasetDialog
          title="Rename dataset"
          buttonLabel="Rename"
          fieldLabel="Dataset name"
          initialText={datasetForRename.name}
          errorMessage={errorDialogMessage}
          onSubmit={handleRenameDatasetSubmit}
          onClose={handleCloseRenameDatasetDialog}
        />
      )}
      {isShowDeleteCatalogItemConfirm && catalogItemToDelete && (
        <ConfirmDialog
          title={`Delete "${catalogItemToDelete.name}" ${
            catalogItemToDelete.itemType === CatalogItemType.Dataset ? "dataset" : "zoning"
          }?`}
          description={`Are you sure you want to delete the "${catalogItemToDelete.name}" ${
            catalogItemToDelete.itemType === CatalogItemType.Dataset ? "dataset" : "zoning"
          }?`}
          onClose={handleDeleteCatalogItemCancel}
          onSubmit={handleDeleteCatalogItemConfirm}
        />
      )}
      {isShowUploadZoningDialog && uploadZoningFolderId && (
        <ZoningUploader onClose={handleCloseZoningUploadDialog} folderId={String(uploadZoningFolderId)} />
      )}
      {isShowEditZoningDialog && editZoningItem && (
        <EditZoningDialog
          zoning={editZoningItem}
          errorMessage={errorDialogMessage}
          onClose={handleCloseEditZoningDialog}
          onSubmit={handleEditZoningSubmit}
        />
      )}
      {zoningInfo.state === DataState.AVAILABLE && infoZoningItem && (
        <MuiDialog onClose={handleCloseZoningInfoDialog} className="zoning-info-dialog" open>
          <DialogTitle>Zoning Info</DialogTitle>
          <CustomZoningInfo
            zoning={zoningInfo.data}
            settings={{
              ...defaultZoningSettings,
              zoningName: infoZoningItem.name,
              zoningDescription: infoZoningItem.description,
              selectedIdField: zoningInfo.data.schema.idField,
              selectedNameField: zoningInfo.data.schema.nameField || "",
            }}
            interactive={false}
          />
        </MuiDialog>
      )}
    </div>
  );
};
