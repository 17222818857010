import { ItemRenderer, Select2 as Select } from "@blueprintjs/select";
import styled from "@emotion/styled";
import { FC, useEffect } from "react";

import { MenuItem, SelectInput } from "components";

import { useAppSelector } from "hooks";

interface TimePeriodProps {
  timePeriods: string[];
  selectedTimePeriod: string;
  disabled: boolean;
  defaultTimePeriod?: string;
  setSelectedTimePeriod: (timePeriod: string) => void;
}

const Label = styled.label`
  font-size: 14px;
  margin: 10px 0;
  color: #2a2e33;
`;

export const TimePeriodSelector: FC<TimePeriodProps> = ({
  selectedTimePeriod,
  timePeriods,
  defaultTimePeriod,
  disabled,
  setSelectedTimePeriod,
}) => {
  const globalTimePeriod = useAppSelector((state) => state.global.timePeriod);

  useEffect(() => {
    if (globalTimePeriod && timePeriods.length) {
      const isGlobalTimePeriodAvailable = timePeriods.includes(globalTimePeriod);
      const timePeriod = defaultTimePeriod
        ? defaultTimePeriod
        : isGlobalTimePeriodAvailable
        ? globalTimePeriod
        : timePeriods?.[0];
      setSelectedTimePeriod(timePeriod);
    }
  }, [globalTimePeriod, timePeriods, defaultTimePeriod, setSelectedTimePeriod]);

  const renderTimePeriod: ItemRenderer<any> = (timePeriod, { handleClick, handleFocus, modifiers }) => {
    if (!modifiers.matchesPredicate) {
      return null;
    }

    return (
      <MenuItem
        active={modifiers.active}
        key={timePeriod}
        onClick={handleClick}
        onFocus={handleFocus}
        roleStructure="listoption"
        text={timePeriod}
      />
    );
  };
  return (
    <>
      <Label>Time period</Label>
      <Select
        items={timePeriods}
        itemRenderer={renderTimePeriod}
        activeItem={selectedTimePeriod}
        onItemSelect={(timePeriod) => setSelectedTimePeriod(timePeriod)}
        filterable={false}
        popoverProps={{
          matchTargetWidth: true,
          minimal: true,
        }}
      >
        <SelectInput disabled={timePeriods?.length <= 1 || disabled} value={selectedTimePeriod} />
      </Select>
    </>
  );
};
