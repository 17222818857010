import { Button, Classes, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { ItemRenderer, Select2 as Select } from "@blueprintjs/select";
import styled from "@emotion/styled";
import { interpolateRgbBasis } from "d3-interpolate";
import { scaleLinear, scaleSequential } from "d3-scale";
import React, { useLayoutEffect, useMemo, useRef } from "react";

import { MenuItem } from "components";

import { ColorScheme } from "../map-visualization";

export interface Props {
  availableSchemes: ColorScheme;
  selectedColorScheme: string;
  onChange: (colorScheme: string) => void;
}

const ColorRampCanvas = styled.canvas`
  display: flex;
  flex-grow: 1;
  border-radius: 3px;
`;

const ColorRamp: React.FC<{
  colorScheme: string[];
  width?: number;
  height?: number;
}> = ({ colorScheme, width = 180, height = 10 }) => {
  const colorScale = scaleSequential(interpolateRgbBasis(colorScheme));
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const xScale = useMemo(() => scaleLinear().range([0, width]), [width]);

  useLayoutEffect(() => {
    const { current } = canvasRef;
    if (current) {
      const ctx = current.getContext("2d");
      if (ctx) {
        ctx.clearRect(0, 0, width, height);
        for (let i = 0; i < width; i++) {
          ctx.fillStyle = colorScale(xScale.invert(i));
          ctx.fillRect(i, 0, 1, height);
        }
      }
    }
  });

  return <ColorRampCanvas ref={canvasRef} width={width} height={height} />;
};

const Outer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 1.3em;
  margin-bottom: 1.1em;
`;

const ColorSelector = styled(Button)`
  height: 40px;
  border-radius: 6px;
  background-color: #ffffff !important;
`;

const ColorSchemeSelector: React.FC<Props> = ({ availableSchemes, selectedColorScheme, onChange }) => {
  const itemRenderer: ItemRenderer<string> = (colorSchemeKey, { modifiers, handleClick }) => {
    const colorScheme = availableSchemes[colorSchemeKey];

    // checking for !colorScheme works around an issue in blueprint select where it renders old items
    // this would trigger a crash when you switch from one color scheme mode to the other
    if (!modifiers.matchesPredicate || !colorScheme) {
      return null;
    }

    const selected = selectedColorScheme === colorSchemeKey;
    return (
      <MenuItem
        key={colorSchemeKey}
        active={modifiers.active}
        disabled={modifiers.disabled}
        intent={selected ? Intent.PRIMARY : Intent.NONE}
        icon={<ColorRamp colorScheme={colorScheme} />}
        onClick={handleClick}
      />
    );
  };

  return (
    <Outer>
      <Select
        items={Object.keys(availableSchemes)}
        filterable={false}
        activeItem={selectedColorScheme}
        itemRenderer={itemRenderer}
        popoverProps={{
          minimal: true,
          captureDismiss: true,
          usePortal: false,
        }}
        onItemSelect={onChange}
      >
        <ColorSelector
          className={Classes.POPOVER_DISMISS_OVERRIDE}
          rightIcon={IconNames.CHEVRON_DOWN}
          fill={true}
          icon={<ColorRamp colorScheme={availableSchemes[selectedColorScheme]} />}
        />
      </Select>
    </Outer>
  );
};

export default ColorSchemeSelector;
