import { Icon } from "@blueprintjs/core";
import { Tooltip2 as Tooltip } from "@blueprintjs/popover2";

const SegmentNote = () => (
  <p>
    The counts for segments which are positioned in two zones are included proportional to the length that is in each
    zone.
  </p>
);

export const roadMileageFileColumns = [
  {
    column: "time_period",
    type: "Text",
    description: "Year i.e. “2019”, “2022” ",
    notes: "",
  },
  {
    column: "zone_id",
    type: "Integer",
    description: "County ID (FIPS Code)",
    notes: "",
  },
  {
    column: "road_urban_rural",
    type: "Text",
    description: (
      <div>
        Road type (“Urban”, “Rural”)<sup>1</sup>
      </div>
    ),
    notes: "",
  },
  {
    column: "road_ramp",
    type: "Text",
    description: "Ramp (“Ramp”, “Non-ramp”)",
    notes: "",
  },
  {
    column: "fhwa_class",
    type: "Integer",
    description: (
      <div>
        FHWA functional class<sup>2</sup>
      </div>
    ),
    notes: "",
  },
  {
    column: "mileage",
    type: "Long",
    description: (
      <div>
        Sum of each segment's length in miles for all segments touching a zone<sup>3</sup>{" "}
        <Tooltip content={<SegmentNote />}>
          <Icon icon="help" style={{ marginLeft: "10px" }} />
        </Tooltip>
      </div>
    ),
    notes: "",
  },
];
