import RestartAlt from "@mui/icons-material/RestartAlt";
import { Grid, IconButton, Skeleton, Slider, TextField, TextFieldProps, Typography, styled } from "@mui/material";
import { ChangeEvent, FC } from "react";

import { MeasureRange } from "types";

export interface RangeSliderProps {
  label?: string;
  loading: boolean;
  range: [number, number];
  availableRange: MeasureRange;
  errors: { min: string; max: string };
  disabled?: boolean;
  resetMin?: number;
  resetMax?: number;
  handleSetRange: (newRange: [number, number]) => void;
}

const RangeTextField = styled(TextField)<TextFieldProps>(({ theme }) => ({
  height: "48px",
  "& .MuiInputBase-root": {
    fontSize: "12px",
  },
  "& .MuiFormHelperText-root": {
    fontSize: "10px",
    margin: "0px",
    marginLeft: "2px",
    color: theme.palette.warning.main,
  },
}));

export const InlineGridItem = styled(Grid)(({ theme }) => ({
  "&.MuiGrid-item": {
    paddingTop: "0px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
}));

export const RangeSlider: FC<RangeSliderProps> = ({
  label,
  loading,
  range,
  availableRange,
  errors,
  disabled,
  resetMin = availableRange?.min,
  resetMax = availableRange?.max,
  handleSetRange,
}) => {
  const handleChangeSlider = (event: Event, newValue: number | number[]) => {
    handleSetRange(newValue as [number, number]);
  };

  const handleChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
    if (range) {
      const { name, value } = event.target;
      const parsedValue = value.replaceAll(",", "");
      if (/^[0-9]*$/.test(parsedValue) === false) return;

      if (name === "min") handleSetRange([+parsedValue, range[1]]);
      if (name === "max") handleSetRange([range[0], +parsedValue]);
    }
  };

  const handleResetRange = () => {
    handleSetRange([resetMin, resetMax]);
  };

  return (
    <>
      <Grid container spacing={1} alignItems="center">
        {label ? (
          <InlineGridItem minWidth={65} height={40} item>
            <Typography variant="caption" fontWeight={500}>
              {label}
            </Typography>
          </InlineGridItem>
        ) : null}
        <InlineGridItem height={40} item xs>
          <Slider
            color="secondary"
            size="small"
            value={range}
            disabled={loading || disabled}
            onChange={handleChangeSlider}
            valueLabelDisplay="off"
            min={availableRange?.min}
            max={availableRange?.max}
          />
        </InlineGridItem>
        <InlineGridItem height={40} item>
          <IconButton disabled={loading} size="small" onClick={handleResetRange}>
            <RestartAlt fontSize={"small"} />
          </IconButton>
        </InlineGridItem>
      </Grid>
      <Grid px={1} mb={0.5} container justifyContent="center"></Grid>
      <Grid container spacing={2}>
        <Grid item xs>
          {loading ? (
            <Skeleton variant="rounded" height={37} sx={{ marginBottom: "11px" }} />
          ) : (
            <RangeTextField
              name="min"
              variant="outlined"
              size="small"
              label="Min"
              value={range[0].toLocaleString()}
              disabled={loading || disabled}
              InputLabelProps={{ shrink: true }}
              onChange={handleChangeInput}
              helperText={errors.min}
              color={errors.min !== "" ? "warning" : "secondary"}
            />
          )}
        </Grid>
        <Grid item xs>
          {loading ? (
            <Skeleton variant="rounded" height={37} sx={{ marginBottom: "11px" }} />
          ) : (
            <RangeTextField
              name="max"
              variant="outlined"
              size="small"
              label="Max"
              value={range[1].toLocaleString()}
              disabled={loading || disabled}
              InputLabelProps={{ shrink: true }}
              onChange={handleChangeInput}
              helperText={errors.max}
              color={errors.max !== "" ? "warning" : "secondary"}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
};
