import { useAuth0 } from "@auth0/auth0-react";
import { Collapse, Icon } from "@blueprintjs/core";
import { Popover2 as Popover, Tooltip2 as Tooltip } from "@blueprintjs/popover2";
import styled from "@emotion/styled";
import { AccessTime, Check } from "@mui/icons-material";
import { Chip, Skeleton } from "@mui/material";
import { isoParse } from "d3-time-format";
import React, { ChangeEvent, FC, Fragment, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Link, useLocation, useSearchParams } from "react-router-dom";

import { ExportDetail, isExpired } from "features/export";

import { CardContainer, Checkbox, FlexContainer, IconButton, Input, PageContainer } from "components";

import { useAppDispatch, useAppSelector, usePageTracking } from "hooks";

import { DataState } from "store/interfaces";
import { exportActions } from "store/sections/export";

import { ExportJob, ExportJobStatus, ExportJobStatusLabels, ExportType, ExportTypeDict, ROUTES } from "types";

import { addCustomGAEvent } from "utils/addCustomGAEvent";
import { capitalize, formatCreatedTime } from "utils/format";

type SortOrder = "asc" | "desc";

const Header = styled(FlexContainer)`
  justify-content: space-between;
  margin: 2rem 0 1.5rem 0;
`;

const Title = styled.h1`
  font-size: 20px;
  margin: 0;
`;

const TableCard = styled(CardContainer)`
  height: 100%;
  padding: 1rem;
`;

const TableContainer = styled.div`
  min-height: 300px;
  max-height: 100%;
  overflow-y: auto;
`;

const Table = styled.table`
  width: 100%;
  color: var(--color-gray-600);
`;

const TableHead = styled.thead`
  position: sticky;
  top: 0;
  z-index: 2;
`;

const TableCell = styled.td`
  max-width: 260px;
  padding: 0.5rem 1rem;
`;

const HeadCell = styled.th`
  text-align: left;
  padding: 0 1rem;
  max-width: 260px;
  height: 36px;
  color: var(--color-textSecondary);
  font-size: 12px;
  font-weight: 500;
  background-color: var(--color-gray-50);
  border-top: 1px solid var(--color-gray-200);
  border-bottom: 1px solid var(--color-gray-200);
`;

const FirstHeadCell = styled(HeadCell)`
  border-left: 1px solid var(--color-gray-200);
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
`;

const LastHeadCell = styled(HeadCell)`
  border-right: 1px solid var(--color-gray-200);
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
`;

const StyledChip = styled(Chip)(() => ({
  width: "100%",
}));

const NoWrapText = styled.div<{ isRowOpen: boolean }>`
  max-height: 120px;
  max-width: 230px;
  overflow-y: auto;
  ${({ isRowOpen }) =>
    !isRowOpen &&
    `white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    `}
`;

const SortIcon = styled(Icon)`
  margin-left: 2px;
  cursor: pointer;
`;

const FilterIconButton = styled(IconButton)`
  background-color: transparent;
  border: none;
  color: var(--color-gray-500);
  width: 12px;
  height: 12px;
  box-shadow: none;
  margin-left: 0.25rem;
`;

const FilterMenu = styled.div`
  min-width: 150px;
  padding: 1rem 1rem 0.5rem 1rem;
  box-shadow: var(--box-shadow-sm);
`;

const StyledCheckbox = styled(Checkbox)`
  &.bp4-control input:checked ~ .bp4-control-indicator {
    background-color: #149eec;
  }
`;

const Placeholder = styled(FlexContainer)`
  justify-content: center;
  padding: 200px;
  font-size: 16px;
  font-weight: 500;
  color: var(--color-textSecondary);
`;

const CollapseWrapper = styled.td<{ isRowOpen: boolean }>`
  border-bottom: ${({ isRowOpen }) =>
    isRowOpen ? "2px solid var(--color-gray-300)" : "1px solid var(--color-gray-100)"};
`;

const getStatusCode = (status: ExportJobStatusLabels) => {
  switch (status) {
    case ExportJobStatusLabels.Queued:
      return [0];
    case ExportJobStatusLabels.Running:
      return [1, 2, 3];
    case ExportJobStatusLabels.Completed:
      return [4];
    case ExportJobStatusLabels.Failed:
      return [-1, -2];
    case ExportJobStatusLabels.Expired:
      return [-99];
    default:
      return [];
  }
};

const getJobStatusChip = (status: ExportJobStatus) => {
  switch (status) {
    case ExportJobStatus.Pending:
      return <StyledChip size={"small"} variant={"outlined"} color={"default"} label={"Queued"} />;
    case ExportJobStatus.Running:
    case ExportJobStatus.LocalExportComplete:
    case ExportJobStatus.Uploading:
      return <StyledChip size={"small"} variant={"outlined"} color={"info"} icon={<AccessTime />} label={"Running"} />;
    case ExportJobStatus.UploadComplete:
      return <StyledChip size={"small"} variant={"outlined"} color={"success"} icon={<Check />} label={"Completed"} />;
    case ExportJobStatus.LocalExportFailed:
    case ExportJobStatus.UploadFailed:
      return <StyledChip size={"small"} variant={"outlined"} color={"error"} label={"Failed"} />;
    case ExportJobStatus.Expired:
      return <StyledChip size={"small"} variant={"outlined"} color={"default"} label={"Expired"} />;
    default:
      return <StyledChip size={"small"} variant={"outlined"} color={"info"} icon={<AccessTime />} label={"Pending"} />;
  }
};

const getExportType = (exportJob: ExportJob): ExportType => {
  if (exportJob.selectLink) return ExportType.SELECT_LINK;
  else if (exportJob.roadVmt) return ExportType.ROAD_VMT;
  else return exportJob.exportType;
};

const getJobType = (exportJob: ExportJob): string => {
  return ExportTypeDict[getExportType(exportJob)];
};

function descendingComparator(a: number, b: number) {
  if (b < a) {
    return -1;
  }
  if (b > a) {
    return 1;
  }
  return 0;
}

const getComparator = (order: SortOrder): ((a: ExportJob, b: ExportJob) => number) => {
  return order === "desc"
    ? (a, b) => descendingComparator(Date.parse(a.createdAt), Date.parse(b.createdAt))
    : (a, b) => -descendingComparator(Date.parse(a.createdAt), Date.parse(b.createdAt));
};

export const ExportsPage: FC = () => {
  const { user } = useAuth0();
  const userOrganizationName = useAppSelector((state) => state.license.user.data?.organization?.name);

  usePageTracking();

  const dispatch = useAppDispatch();
  const location = useLocation();
  const [serchParams, setSearchParams] = useSearchParams();
  const [selectedExportId, setSelectedExportId] = useState<string | null>(null);
  const [sortOrder, setSortOrder] = useState<SortOrder>("desc");
  const [type, setType] = useState(
    Object.values(ExportType).map((v) => ({
      label: v,
      checked: true,
    })),
  );
  const [status, setStatus] = useState(
    Object.values(ExportJobStatusLabels).map((v) => ({
      label: v,
      checked: v === "Expired" ? false : true,
    })),
  );
  const [searchStr, setSearchStr] = useState("");

  const isTokenLoaded = useAppSelector((state) => state.analytics.authorizationTokenLoaded);
  const exports = useAppSelector((state) => state.export.exportJobs);

  const timer = useRef<ReturnType<typeof setInterval>>();

  const areExportsRunning = useMemo(
    () =>
      exports.data?.find(
        (e) =>
          !isExpired(e.createdAt) &&
          (e.status === ExportJobStatus.Running ||
            e.status === ExportJobStatus.LocalExportComplete ||
            e.status === ExportJobStatus.Uploading ||
            e.status === ExportJobStatus.Pending),
      ),
    [exports.data],
  );

  const isAsc = sortOrder === "asc";

  const filterExports = useCallback(
    (data: ExportJob[]) => {
      const activeTypes = type.filter((t) => t.checked).map((t) => t.label);
      const activeStatus = status
        .filter((s) => s.checked)
        .map((s) => getStatusCode(s.label))
        .flat();

      return data.filter(
        (job) =>
          activeTypes.includes(getExportType(job)) &&
          activeStatus.includes(job.status) &&
          (searchStr ? job.areaOfInterestName.toLowerCase().includes(searchStr.toLowerCase()) : true),
      );
    },
    [searchStr, status, type],
  );

  const handleRowClick = (id: string) => {
    setSelectedExportId(selectedExportId === id ? null : id);
  };

  const handleDownloadExport = (url: string) => {
    addCustomGAEvent("exports", "download_export", "export_actions", user, userOrganizationName);
    window.open(url, "_blank", "noopener noreferrer")?.focus();
  };

  useEffect(() => {
    if (isTokenLoaded) {
      dispatch(exportActions.fetchExportJobs());
    }
  }, [isTokenLoaded, dispatch]);

  useEffect(() => {
    if ((areExportsRunning || exports.state === DataState.ERROR) && !timer.current) {
      timer.current = setInterval(() => {
        dispatch(exportActions.fetchExportJobs());
      }, 15000);
    }

    if (!areExportsRunning && exports.state !== DataState.ERROR && timer.current) {
      clearInterval(timer.current);
      timer.current = undefined;
    }
  }, [areExportsRunning, exports.state, dispatch]);

  useEffect(() => {
    return () => {
      clearInterval(timer.current);
      dispatch(exportActions.clearExportJobs());
    };
  }, [dispatch]);

  useEffect(() => {
    const exportJobId = serchParams.get("exportJobId");
    if (exportJobId && exports.state === DataState.AVAILABLE) {
      setSelectedExportId(exportJobId);
      serchParams.delete("exportJobId");
      setSearchParams(serchParams);
    }
  }, [location, serchParams, exports.state, setSearchParams]);

  const exportJobs = useMemo(
    () => filterExports(exports.data || []).sort(getComparator(sortOrder)),
    [exports.data, sortOrder, filterExports],
  );

  const selectedExportJobIndex = useMemo(
    () => exportJobs.findIndex((job) => job.uuid === selectedExportId),
    [exportJobs, selectedExportId],
  );

  return (
    <PageContainer>
      <aside />
      <div>
        <Header>
          <Title>Exports</Title>
          <Input
            leftIcon="search"
            placeholder="Search..."
            value={searchStr}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setSearchStr(e.target.value)}
          />
        </Header>
        <TableCard>
          <TableContainer>
            <Table cellSpacing="0" cellPadding="0">
              <TableHead>
                <tr>
                  <FirstHeadCell style={{ width: "60px" }}></FirstHeadCell>
                  <HeadCell style={{ width: "240px" }}>Name</HeadCell>
                  <HeadCell style={{ width: "240px" }}>Description</HeadCell>
                  <HeadCell style={{ width: "120px" }}>Time period</HeadCell>
                  <HeadCell style={{ width: "120px" }}>
                    <FlexContainer>
                      Type
                      <Popover
                        minimal
                        placement="bottom"
                        content={
                          <FilterMenu>
                            {type.map(({ label, checked }, index) => (
                              <StyledCheckbox
                                key={index}
                                alignIndicator="right"
                                label={ExportTypeDict[label]}
                                checked={checked}
                                onClick={(e) =>
                                  setType(
                                    type.map((t, i) =>
                                      i === index
                                        ? {
                                            ...t,
                                            checked: !t.checked,
                                          }
                                        : t,
                                    ),
                                  )
                                }
                              />
                            ))}
                          </FilterMenu>
                        }
                        renderTarget={({ isOpen, ref, ...targetProps }) => (
                          <FilterIconButton size="xs" icon={"filter"} {...targetProps} ref={ref} />
                        )}
                      />
                    </FlexContainer>
                  </HeadCell>
                  <HeadCell style={{ width: "120px" }}>
                    <FlexContainer>
                      Status
                      <Popover
                        minimal
                        placement="bottom"
                        content={
                          <FilterMenu>
                            {status.map(({ label, checked }, index) => (
                              <StyledCheckbox
                                key={index}
                                alignIndicator="right"
                                label={capitalize(label)}
                                checked={checked}
                                onClick={(e) =>
                                  setStatus(
                                    status.map((t, i) =>
                                      i === index
                                        ? {
                                            ...t,
                                            checked: !t.checked,
                                          }
                                        : t,
                                    ),
                                  )
                                }
                              />
                            ))}
                          </FilterMenu>
                        }
                        renderTarget={({ isOpen, ref, ...targetProps }) => (
                          <FilterIconButton size="xs" icon={"filter"} {...targetProps} ref={ref} />
                        )}
                      />
                    </FlexContainer>
                  </HeadCell>
                  <HeadCell>
                    <FlexContainer>
                      Date
                      <SortIcon
                        icon={isAsc ? "chevron-down" : "chevron-up"}
                        onClick={() => setSortOrder(isAsc ? "desc" : "asc")}
                      />
                    </FlexContainer>
                  </HeadCell>
                  <LastHeadCell>Actions</LastHeadCell>
                </tr>
              </TableHead>
              <tbody>
                {(exports.state === DataState.LOADING || exports.state === DataState.ERROR) && !exports.data
                  ? [1, 2, 3].map((_, i) => (
                      <tr key={i}>
                        <TableCell>
                          <Skeleton variant="rounded" height={15} width={15} />
                        </TableCell>
                        <TableCell>
                          <Skeleton variant="text" />
                        </TableCell>
                        <TableCell>
                          <Skeleton variant="text" />
                        </TableCell>
                        <TableCell>
                          <Skeleton variant="rounded" height={25} sx={{ borderRadius: "9999px" }} />
                        </TableCell>
                        <TableCell>
                          <Skeleton variant="rounded" height={25} sx={{ borderRadius: "9999px" }} />
                        </TableCell>
                        <TableCell>
                          <Skeleton variant="text" width={200} />
                        </TableCell>
                        <TableCell>
                          <Skeleton variant="rounded" height={30} width={30} sx={{ my: 1, borderRadius: "6px" }} />
                        </TableCell>
                      </tr>
                    ))
                  : exportJobs.length
                  ? exportJobs.map((exportJob, i) => {
                      const {
                        uuid,
                        exportType,
                        datasetName,
                        areaOfInterestName,
                        exportDescription,
                        status,
                        completedAt,
                        url,
                        timePeriod,
                      } = exportJob;
                      const isRowOpen = selectedExportId === exportJob.uuid;
                      const isNextRowOpen = selectedExportJobIndex === i + 1;
                      const isDataset = exportType === ExportType.DATASET;

                      return (
                        <Fragment key={uuid}>
                          <tr onClick={() => handleRowClick(uuid)}>
                            <TableCell>
                              <Icon icon={selectedExportId === uuid ? "chevron-up" : "chevron-down"} />
                            </TableCell>
                            <TableCell>
                              {isRowOpen && isDataset && <h6>Dataset:</h6>}
                              {isDataset ? datasetName : areaOfInterestName}
                            </TableCell>
                            <TableCell rowSpan={2} style={{ verticalAlign: isRowOpen ? "top" : "inherit" }}>
                              <Tooltip
                                disabled={isRowOpen}
                                content={<div style={{ maxWidth: "300px" }}>{exportDescription}</div>}
                              >
                                <NoWrapText isRowOpen={isRowOpen} style={{ marginTop: isRowOpen ? "8px" : 0 }}>
                                  {exportDescription}
                                </NoWrapText>
                              </Tooltip>
                            </TableCell>
                            <TableCell>
                              <StyledChip size={"small"} variant={"outlined"} color={"default"} label={timePeriod} />
                            </TableCell>
                            <TableCell>
                              <StyledChip
                                color={"secondary"}
                                variant={"outlined"}
                                size={"small"}
                                label={getJobType(exportJob)}
                              />
                            </TableCell>
                            <TableCell>{getJobStatusChip(status)}</TableCell>
                            <TableCell>{completedAt && formatCreatedTime(isoParse(completedAt))}</TableCell>
                            <TableCell>
                              {completedAt && status === ExportJobStatus.UploadComplete && (
                                <Tooltip content="Download export" position="bottom">
                                  <IconButton
                                    size="xs"
                                    color="whiteShadow"
                                    variant="squared"
                                    icon="import"
                                    onClick={() => handleDownloadExport(url)}
                                  />
                                </Tooltip>
                              )}
                            </TableCell>
                          </tr>
                          {isRowOpen && (
                            <tr>
                              <td></td>
                              <TableCell>
                                {isDataset && (
                                  <>
                                    <h6>Area of intrest</h6>
                                    {selectedExportId === exportJob.uuid && exportJob.areaOfInterestName}
                                  </>
                                )}
                              </TableCell>
                            </tr>
                          )}
                          <tr>
                            <CollapseWrapper colSpan={8} isRowOpen={isRowOpen || isNextRowOpen}>
                              <Collapse isOpen={selectedExportId === exportJob.uuid}>
                                <ExportDetail exportJob={exportJob} />
                              </Collapse>
                            </CollapseWrapper>
                          </tr>
                        </Fragment>
                      );
                    })
                  : exports.state === DataState.AVAILABLE &&
                    exports.data?.length === 0 && (
                      <tr>
                        <td colSpan={8}>
                          <Placeholder>
                            You can create new exports on the
                            <Link style={{ marginLeft: "0.25rem" }} to={ROUTES.Map + location.search}>
                              Map
                            </Link>
                          </Placeholder>
                        </td>
                      </tr>
                    )}
              </tbody>
            </Table>
          </TableContainer>
        </TableCard>
      </div>
      <aside />
    </PageContainer>
  );
};
