import { FC } from "react";

import { Description, SubTitle, Table, TableHeadRow } from "./Table";
import { selectLinkQueries } from "./data/selectLinkQueries";

export const SelectLink: FC = () => (
  <>
    <SubTitle>Query Format Restrictions</SubTitle>
    <Description>Select Link currently only supports queries of the following format</Description>
    <Table compact bordered style={{ marginBottom: "1rem" }}>
      <thead>
        <TableHeadRow>
          <th>Query</th>
          <th>Interpretation</th>
        </TableHeadRow>
      </thead>
      <tbody>
        {selectLinkQueries.map((f) => (
          <tr key={f.id}>
            <td width={"450px"}>{f.query}</td>
            <td>{f.interpretation}</td>
          </tr>
        ))}
      </tbody>
    </Table>
    <Description style={{ marginBottom: "2rem" }}>
      The reason for such restriction is that such normal forms allow to express most queries while keeping the user
      interaction simple.
    </Description>

    <SubTitle>Sequence of Queried Segments</SubTitle>
    <Description style={{ marginBottom: "2rem" }}>
      Select Link currently does not support to define a sequence of road segment traversals. This means a Select Link
      query of “Segment 1 AND Segment 2” includes trips that traveled first through Segment 1 and then Segment 2 as well
      as trips that traveled first through Segment 2 and then Segment 1. The same holds true for groups of segments,
      where trips can fulfill criteria of different groups in arbitrary order.
    </Description>

    <SubTitle>Zone Filters</SubTitle>
    <Description>
      It is possible to narrow down Select Link results further by filtering by origins and destinations of trips. Trips
      need to satisfy at least one in each origin and destination zone filter list. The use of zone filters is optional.
      The attribute filters in the Zones filter configuration are only used to inform the choropleth coloring of the
      zones in the Configuration mode. They are not used to filter trips as part of the Select Link query.
    </Description>
  </>
);
