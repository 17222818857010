import type { DraggableSyntheticListeners, UniqueIdentifier } from "@dnd-kit/core";
import type { Transform } from "@dnd-kit/utilities";
import styled from "@emotion/styled";
import { AccessTime, Check, InsertDriveFileOutlined, ViewQuiltOutlined } from "@mui/icons-material";
import { EditOutlined, FileOpenOutlined, InfoOutlined, StopOutlined } from "@mui/icons-material";
import { Button, Chip, Grid, Tooltip, Typography } from "@mui/material";
import React, { useCallback, useEffect, useMemo } from "react";

import { Popover, PopoverMenuItem } from "components";

import { useAppDispatch, useAppSelector } from "hooks";

import { globalActions } from "store/sections/global";

import { themeColors } from "theme/themeConstants";

import { CatalogItem, CatalogItemType, CustomDataset, DatasetStatus, ROUTES, ZoningItem } from "types";

import { capitalize, formatCreatedTime, formatCreatedTimeShort } from "utils/format";

import { Handle } from "./Handle";
import { PopoverControlButton } from "./PopoverControlButton";

export interface Props {
  data?: CatalogItem;
  dragOverlay?: boolean;
  disabled?: boolean;
  dragging?: boolean;
  handle?: boolean;
  handleProps?: any;
  height?: number;
  index?: number;
  fadeIn?: boolean;
  transform?: Transform | null;
  listeners?: DraggableSyntheticListeners;
  sorting?: boolean;
  style?: React.CSSProperties;
  transition?: string | null;
  containerId?: UniqueIdentifier;
  value: React.ReactNode;
  onDatasetRename?(dataset: CustomDataset): void;
  onCatalogItemDelete?(catalogItem: CatalogItem): void;
  onCopyDataset?(dataset: CustomDataset, folderId: UniqueIdentifier): void;
  onStopComputing?(datasetId: UniqueIdentifier): void;
  onEditZoning?(zoning: ZoningItem): void;
  onGetZoningInfo?(zoning: ZoningItem): void;
}

const ItemWrapper = styled.li`
  display: flex;
  box-sizing: border-box;
  transform: translate3d(var(--translate-x, 0), var(--translate-y, 0), 0) scaleX(var(--scale-x, 1))
    scaleY(var(--scale-y, 1));
  transform-origin: 0 0;
  touch-action: manipulation;
  height: 50px;
`;

const ItemContent = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 20px 220px 1fr 80px 80px 80px 80px 110px 90px 30px;
  grid-template-rows: 1fr;
  column-gap: 24px;
  align-items: center;
  width: 100%;
  outline: none;
  box-sizing: border-box;
  list-style: none;
  transform-origin: 50% 50%;
  background-color: #ffffff;
  border-bottom: 1px solid var(--color-gray-100);

  -webkit-tap-highlight-color: transparent;

  color: var(--color-text);
  white-space: nowrap;

  transform: scale(var(--scale, 1));
  transition: box-shadow 200ms cubic-bezier(0.18, 0.67, 0.6, 1.22);

  &:focus-visible {
    box-shadow: var(--box-shadow-inner);
  }
`;

const ItemDate = styled.div`
  display: flex;
  align-items: flex-start;
  font-size: 14px;
  color: var(--color-gray-600);
  white-space: nowrap;
  flex-direction: column;
`;

const getDatasetStatusChip = (datasetStatus: DatasetStatus) => {
  switch (datasetStatus) {
    case DatasetStatus.Computing:
      return <Chip size={"small"} variant={"outlined"} color={"info"} icon={<AccessTime />} label={"Processing"} />;
    case DatasetStatus.Computed:
      return <Chip size={"small"} variant={"outlined"} color={"success"} icon={<Check />} label={"Computed"} />;
    default:
      return <Chip size={"small"} variant={"outlined"} color={"default"} label={"Draft"} />;
  }
};

export const Item = React.memo(
  React.forwardRef<HTMLLIElement, Props>(
    (
      {
        data,
        dragOverlay,
        dragging,
        disabled,
        fadeIn,
        handle,
        handleProps,
        height,
        index,
        listeners,
        sorting,
        style,
        transition,
        transform,
        value,
        containerId,
        onDatasetRename,
        onCatalogItemDelete,
        onCopyDataset,
        onStopComputing,
        onEditZoning,
        onGetZoningInfo,
        ...props
      },
      ref,
    ) => {
      const dispatch = useAppDispatch();
      const focusAreas = useAppSelector((state) => state.analytics.focusAreasAndDatasets);

      const focusArea = useMemo(
        () =>
          data?.itemType === CatalogItemType.Dataset &&
          focusAreas.data?.find((area) => area.id === data?.licensedAreaId.toString()),
        [data, focusAreas.data],
      );

      useEffect(() => {
        if (!dragOverlay) {
          return;
        }

        document.body.style.cursor = "grabbing";

        return () => {
          document.body.style.cursor = "";
        };
      }, [dragOverlay]);

      const handleRenameDataset = useCallback(() => {
        if (onDatasetRename) {
          onDatasetRename(data as CustomDataset);
        }
      }, [data, onDatasetRename]);

      const handleDeleteCatalogItem = useCallback(() => {
        if (onCatalogItemDelete) {
          onCatalogItemDelete(data as CatalogItem);
        }
      }, [data, onCatalogItemDelete]);

      const handleCopyDataset = useCallback(() => {
        if (onCopyDataset && containerId) {
          onCopyDataset(data as CustomDataset, containerId);
        }
      }, [data, containerId, onCopyDataset]);

      const handleStopComputing = () => {
        if (onStopComputing && data?.id) {
          onStopComputing(data.id);
        }
      };

      const handleOpenDataset = (id: string) => {
        dispatch(
          globalActions.setSelectedFocusAreaId({
            focusAreaId: id,
            redirectUrl: ROUTES.Map,
          }),
        );
      };

      const handleOpenDatasetConfig = () => {
        if (data?.id && data?.itemType === CatalogItemType.Dataset && data?.licensedAreaId) {
          dispatch(
            globalActions.setSelectedFocusAreaId({
              focusAreaId: data.licensedAreaId.toString(),
              timePeriod: data.timePeriod,
              redirectUrl: `${ROUTES.Datasets}/${data?.id}/edit`,
            }),
          );
        }
      };

      const handleEditZoning = useCallback(() => {
        if (onEditZoning && data?.itemType === CatalogItemType.Zoning) {
          onEditZoning(data as ZoningItem);
        }
      }, [data, onEditZoning]);

      const handleShowZoningInfo = useCallback(() => {
        if (onGetZoningInfo && data?.itemType === CatalogItemType.Zoning) {
          onGetZoningInfo(data);
        }
      }, [data, onGetZoningInfo]);

      const popoverMenuItems: PopoverMenuItem[] = useMemo(
        () => [
          {
            children: "Copy",
            disabled: !data?.permissions?.copy?.allow,
            onClick: handleCopyDataset,
            tooltipProps: {
              title: data?.permissions?.copy?.reason,
              disableHoverListener: data?.permissions?.copy?.allow,
            },
          },
          {
            children: "Rename",
            disabled: !data?.permissions?.edit?.allow,
            onClick: handleRenameDataset,
            tooltipProps: {
              title: data?.permissions?.edit?.reason,
              disableHoverListener: data?.permissions?.edit?.allow,
            },
          },
          {
            children: "Delete",
            disabled: !data?.permissions?.delete?.allow,
            onClick: handleDeleteCatalogItem,
            tooltipProps: {
              title: data?.permissions?.delete?.reason,
              disableHoverListener: data?.permissions?.delete?.allow,
            },
          },
        ],
        [data?.permissions, handleCopyDataset, handleRenameDataset, handleDeleteCatalogItem],
      );

      const popoverZoningMenuItems: PopoverMenuItem[] = useMemo(
        () => [
          {
            children: "Edit",
            onClick: handleEditZoning,
          },
          {
            children: "Delete",
            onClick: handleDeleteCatalogItem,
          },
        ],
        [handleEditZoning, handleDeleteCatalogItem],
      );

      return (
        <ItemWrapper
          style={
            {
              transition: [transition].filter(Boolean).join(", "),
              "--translate-x": transform ? `${Math.round(transform.x)}px` : undefined,
              "--translate-y": transform ? `${Math.round(transform.y)}px` : undefined,
              "--scale-x": transform?.scaleX ? `${transform.scaleX}` : undefined,
              "--scale-y": transform?.scaleY ? `${transform.scaleY}` : undefined,
              "--index": index,
            } as React.CSSProperties
          }
          ref={ref}
        >
          <ItemContent
            style={style}
            data-cypress="draggable-item"
            {...(!handle ? listeners : undefined)}
            {...props}
            tabIndex={!handle ? 0 : undefined}
          >
            <Handle color="primary" {...handleProps} {...listeners} />

            <Tooltip title={data?.name || value} placement={"bottom-start"}>
              <Grid container alignItems={"center"} wrap="nowrap" maxWidth={250}>
                {data?.itemType === CatalogItemType.Dataset ? (
                  <InsertDriveFileOutlined color="secondary" fontSize="small" />
                ) : (
                  <ViewQuiltOutlined color="secondary" fontSize="small" />
                )}

                <Typography fontSize={14} color={`${themeColors.gray600}`} noWrap ml={1}>
                  {data?.name || value}
                </Typography>
              </Grid>
            </Tooltip>
            <Tooltip title={focusArea && focusArea.label} placement={"bottom-start"}>
              <Typography fontSize={14} color={`${themeColors.gray600}`} noWrap>
                {focusArea && focusArea.label}
              </Typography>
            </Tooltip>
            <Chip
              color={"secondary"}
              variant={"outlined"}
              size={"small"}
              label={capitalize(data?.itemType)}
              sx={{ width: "100%" }}
            />
            {data?.itemType === CatalogItemType.Dataset ? (
              <Chip size={"small"} variant={"outlined"} color={"default"} label={data.timePeriod} />
            ) : (
              <div />
            )}
            <ItemDate>
              {data?.createdAt ? (
                <Tooltip
                  placement="left"
                  title={
                    <>
                      Created by {data.createdBy?.email || "<anonymous>"} <br /> at{" "}
                      {formatCreatedTime(new Date(data.createdAt))}
                    </>
                  }
                >
                  <Typography fontSize={"inherit"} color={"inherit"}>
                    {formatCreatedTimeShort(new Date(data.createdAt))}
                  </Typography>
                </Tooltip>
              ) : (
                "-"
              )}
            </ItemDate>
            <ItemDate>
              {data?.updatedAt ? (
                <Tooltip
                  placement="left"
                  title={
                    <>
                      Modified by {data.updatedBy?.email || "<anonymous>"} <br /> at{" "}
                      {formatCreatedTime(new Date(data.updatedAt))}
                    </>
                  }
                >
                  <Typography fontSize={"inherit"} color={"inherit"}>
                    {formatCreatedTimeShort(new Date(data.updatedAt))}
                  </Typography>
                </Tooltip>
              ) : (
                "-"
              )}
            </ItemDate>
            {data?.itemType === CatalogItemType.Dataset && getDatasetStatusChip(data.datasetStatus)}
            {data?.itemType === CatalogItemType.Zoning && <div />}
            {data?.itemType === CatalogItemType.Dataset && (
              <div>
                {!data?.computedDatasetId && data?.datasetStatus === DatasetStatus.Computing && onStopComputing && (
                  <Button
                    fullWidth
                    variant="outlined"
                    size="small"
                    onClick={handleStopComputing}
                    startIcon={<StopOutlined />}
                  >
                    Stop
                  </Button>
                )}
                {!data?.computedDatasetId && data?.datasetStatus !== DatasetStatus.Computing && (
                  <Tooltip
                    placement="left"
                    title={data?.permissions?.edit?.reason}
                    disableHoverListener={Boolean(data?.permissions?.edit?.allow)}
                  >
                    <span>
                      <Button
                        fullWidth
                        variant="outlined"
                        size="small"
                        disabled={!Boolean(data?.permissions?.edit?.allow)}
                        onClick={handleOpenDatasetConfig}
                        startIcon={<EditOutlined />}
                      >
                        Edit
                      </Button>
                    </span>
                  </Tooltip>
                )}
                {data?.computedDatasetId && (
                  <Button
                    fullWidth
                    variant="outlined"
                    size="small"
                    onClick={() => handleOpenDataset(data.computedDatasetId as string)}
                    startIcon={<FileOpenOutlined />}
                  >
                    Open
                  </Button>
                )}
              </div>
            )}
            {data?.itemType === CatalogItemType.Zoning && (
              <Button
                fullWidth
                variant="outlined"
                size="small"
                onClick={handleShowZoningInfo}
                startIcon={<InfoOutlined />}
              >
                Info
              </Button>
            )}
            {data?.itemType === CatalogItemType.Dataset && data?.permissions && (
              <Popover control={PopoverControlButton} menuItems={popoverMenuItems} />
            )}
            {data?.itemType === CatalogItemType.Zoning && (
              <Popover control={PopoverControlButton} menuItems={popoverZoningMenuItems} />
            )}
          </ItemContent>
        </ItemWrapper>
      );
    },
  ),
);
