import { Dialog as DialogBP, Icon } from "@blueprintjs/core";
import styled from "@emotion/styled";
import area from "@turf/area";
import { multiPolygon, polygon } from "@turf/turf";
import attentionSVG from "assets/svg/attention.svg";
import warningInvertedSVG from "assets/svg/warning-inverted.svg";
import warningSVG from "assets/svg/warning.svg";
import React, { useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { Button, Checkbox, CloseButton, InfoPane } from "components";

import { useAppDispatch, useAppSelector } from "hooks";

import { DataState } from "store/interfaces";
import { analyticsActions } from "store/sections/analytics";

import { convertSquareMetersToSquareMiles } from "utils/format";

const DialogWrapper = styled(DialogBP)`
  position: relative;
  width: 755px;
  height: 510px;
  border-radius: 8px;
  background-color: #ffffff;
`;

const DialogBody = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px 25px;
`;

const DialogTitle = styled.h2`
  margin: 24px 16px 0 16px;
  padding-bottom: 20px;
  font-size: 16px;
  font-weight: 700;
  color: var(--color-text);
  font-family: var(--font-family);
  border-bottom: 1px solid var(--color-border);
`;

const DatasetSummary = styled.div`
  display: flex;
  flex-direction: row;
  margin: 32px 100px 24px 100px;
  justify-content: space-between;
`;

const ValidationSummary = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  border: 1px solid var(--color-border);
  height: 170px;
`;

const ValidationSummaryHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 37px;
  border-radius: 8px 8px 0px 0px;
  background-color: var(--color-text-field-gray);
  padding: 0 1rem;
  font-weight: 600;
  font-size: 12px;

  & > div {
    margin-left: 0.5rem;
  }
`;

const ValidationMessages = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  height: 100%;
  overflow-y: auto;

  & > ul {
    list-style: none;

    & > li {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 0.5rem;
      font-size: 12px;
      color: var(--color-textSecondary);

      & > img {
        margin-right: 0.5rem;
      }
    }
  }
`;

const DialogButtonsWrapper = styled.div`
  display: flex;
  position: absolute;
  bottom: 0;
  right: 0;
  flex-direction: row;
  justify-content: flex-end;
  padding: 1rem;

  & > button {
    margin: 0 0.5rem;
  }
`;

const NotificationByEmail = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  font-size: 12px;
  color: var(--color-textSecondary);

  & > span {
    margin-bottom: 0.5rem;
  }
`;

export const ComputeDatasetDialog = ({ ...props }: any) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [isSetEmailNotification, setIsSetEmailNotification] = React.useState(true);

  const ODDatasetConfiguration = useAppSelector((state) => state.analytics.ODDatasetConfig);
  const ODDatasetConfigValidation = useAppSelector((state) => state.analytics.ODDatasetConfigValidation);
  const subareaState = useAppSelector((state) => state.analytics.subareaState);

  useEffect(() => {
    if (
      ODDatasetConfiguration.state === DataState.AVAILABLE &&
      typeof ODDatasetConfiguration.data?.datasetId === "string" &&
      props.isOpen
    ) {
      dispatch(analyticsActions.validateODDatasetConfig(ODDatasetConfiguration.data.datasetId));
    }
  }, [ODDatasetConfiguration.state, ODDatasetConfiguration.data?.datasetId, props.isOpen, dispatch]);

  const getSubAreaDescription = useMemo(() => {
    if (ODDatasetConfiguration.data?.subAreaGeometry) {
      const polygonCoordinates = (ODDatasetConfiguration.data.subAreaGeometry as any)?.coordinates;
      const turfPolygon =
        ODDatasetConfiguration.data.subAreaGeometry.type === "MultiPolygon"
          ? multiPolygon(polygonCoordinates)
          : polygon(polygonCoordinates);
      const areaInSquareMeters = area(turfPolygon);

      return Math.floor(convertSquareMetersToSquareMiles(areaInSquareMeters));
    }

    return "0";
  }, [ODDatasetConfiguration.data?.subAreaGeometry]);

  const getZoneTypeDescription = () => {
    if (ODDatasetConfiguration.data?.zoningLevel) {
      const isOne = subareaState.data?.zoneIds.length && subareaState.data.zoneIds.length === 1;

      switch (ODDatasetConfiguration.data.zoningLevel) {
        case "Tract":
          return `${isOne ? "Tract" : "Tracts"}`;
        case "County":
          return `${isOne ? "County" : "Counties"}`;
        case "BlockGroup":
          return `${isOne ? "Block Group" : "Block Groups"}`;
      }
    }

    return "";
  };

  const handleStartComputation = () => {
    if (ODDatasetConfiguration.data?.datasetId) {
      dispatch(
        analyticsActions.computeODDataset(ODDatasetConfiguration.data.datasetId, isSetEmailNotification, navigate),
      );

      props.onClose();
    }
  };

  const errors = ODDatasetConfigValidation.data?.messages.filter((message) => message.severity === "error");
  const warnings = ODDatasetConfigValidation.data?.messages.filter((message) => message.severity === "warning");

  const handleChangeEmailNotification = () => {
    setIsSetEmailNotification(!isSetEmailNotification);
  };

  return (
    <DialogWrapper {...props}>
      {props.onClose && <CloseButton icon="cross" onClick={props.onClose} />}
      <DialogTitle>{`Compute dataset - ${ODDatasetConfiguration.data?.datasetName}`}</DialogTitle>
      <DialogBody>
        <DatasetSummary>
          <InfoPane
            value={subareaState.data?.zoneIds.length || "0"}
            label={getZoneTypeDescription()}
            description="Zones"
          />
          <InfoPane value={getSubAreaDescription || ""} label="Square Miles" description="Subarea" />
          <InfoPane
            value={ODDatasetConfiguration.data?.gates?.length || "0"}
            label={`External Gate${ODDatasetConfiguration.data?.gates?.length === 1 ? "" : "s"}`}
            description="Gates"
          />
        </DatasetSummary>
        <ValidationSummary>
          <ValidationSummaryHeader>
            {ODDatasetConfigValidation.state === DataState.LOADING && <Icon icon="time" color="#6b7280" />}
            {ODDatasetConfigValidation.state === DataState.AVAILABLE &&
              (errors?.length !== 0 || warnings?.length !== 0) && <img src={warningSVG} alt="" width={16} />}
            {ODDatasetConfigValidation.state === DataState.AVAILABLE &&
              errors?.length === 0 &&
              warnings?.length === 0 && <Icon icon="tick" color="#34d399" />}
            {ODDatasetConfigValidation.state === DataState.AVAILABLE && (
              <div>{`${errors?.length} error${errors?.length === 1 ? "" : "s"} | ${warnings?.length} warning${
                warnings?.length === 1 ? "" : "s"
              }`}</div>
            )}
            {ODDatasetConfigValidation.state === DataState.LOADING && <div>validating...</div>}
          </ValidationSummaryHeader>
          <ValidationMessages>
            <ul>
              {ODDatasetConfigValidation.data?.messages &&
                ODDatasetConfigValidation.data?.messages.map((item, i) => (
                  <li key={i}>
                    {item.severity === "error" && <img src={warningInvertedSVG} alt="" width={16} />}
                    {item.severity === "warning" && <img src={attentionSVG} alt="" width={16} />}
                    {item.text}
                  </li>
                ))}
            </ul>
          </ValidationMessages>
        </ValidationSummary>
        <NotificationByEmail>
          <span>
            Billions of trip data points will be processed to compute the dataset. It can take up to a few hours
          </span>
          <Checkbox
            label="Notify me by email when the dataset is ready"
            checked={isSetEmailNotification}
            onChange={handleChangeEmailNotification}
          />
        </NotificationByEmail>
      </DialogBody>
      <DialogButtonsWrapper>
        <Button
          color="primaryLight"
          disabled={
            ODDatasetConfigValidation.state === DataState.LOADING || ODDatasetConfigValidation.data?.isValid === false
          }
          onClick={handleStartComputation}
        >
          Start Computation
        </Button>
      </DialogButtonsWrapper>
    </DialogWrapper>
  );
};
