import styled from "@emotion/styled";
import { MenuItem as BpMenuItem, MenuItemProps as BpMenuItemProps } from "@blueprintjs/core";

export interface MenuItemProps extends BpMenuItemProps {}

export const MenuItem = styled(BpMenuItem)`
  color: var(--color-text);

  &.bp4-selected {
    color: var(--color-text);
    background-color: var(--color-secondaryLight);
    font-weight: 500;
    &:hover {
      color: var(--color-text);
      background-color: var(--color-secondaryLight);
    }
  }

  &:hover {
    cursor: pointer;
    background-color: var(--color-secondaryLight);
  }
`;
