import { FC, useCallback, useEffect, useMemo, useState } from "react";

import { RangeSlider } from "components";

import { useAppDispatch, useAppSelector, useDebounce } from "hooks";

import { DataState } from "store/interfaces";
import { analyticsActions } from "store/sections/analytics";

import { MeasureRange, SelectedArea } from "types";

export interface RangeFilterProps {
  mapLoaded: boolean;
  showZones: boolean;
  showRoadVolumes: boolean;
  colorScheme: string;
  loading: boolean;
  zoningLevel: string | undefined;
  selectedZone: SelectedArea | null;
  isOD: boolean;
  isDataset: boolean;
  isRoads: boolean;
  measureRange: [number, number] | null;
  availableRange: MeasureRange | null;
  disabled: boolean;
  setMeasureRange: (newRange: [number, number]) => void;
  filterSegments: () => void;
  filterZones: () => void;
}

const isMaxRangeAvailable = (availableRange: MeasureRange, range: [number, number]) => {
  if (availableRange.min === range[0] && availableRange.max === range[1]) return true;

  return false;
};

export const RangeFilter: FC<RangeFilterProps> = ({
  mapLoaded,
  showZones,
  showRoadVolumes,
  colorScheme,
  loading,
  zoningLevel,
  selectedZone,
  isOD,
  isDataset,
  isRoads,
  measureRange,
  availableRange,
  disabled,
  setMeasureRange,
  filterSegments,
  filterZones,
}) => {
  const dispatch = useAppDispatch();
  const baseMapStyle = useAppSelector((state) => state.map.baseMapStyle);

  //OD
  const ODMeasureRange = useAppSelector((state) => state.analytics.ODMeasureRange);
  // const ODRangeByZone = useAppSelector((state) => state.analytics.ODRangeByZone);

  //Dataset
  const datasetMeasureRange = useAppSelector((state) => state.analytics.datasetMeasureRange);

  //Roads
  const roadsMeasureRange = useAppSelector((state) => state.analytics.roadsMeasureRange);

  const [errors, setErrors] = useState({
    min: "",
    max: "",
  });

  const rangeLoading = useMemo(
    () =>
      loading ||
      ODMeasureRange.state === DataState.LOADING ||
      datasetMeasureRange.state === DataState.LOADING ||
      roadsMeasureRange.state === DataState.LOADING,
    [loading, ODMeasureRange.state, datasetMeasureRange.state, roadsMeasureRange.state],
  );

  const debouncedFilterZonesByRange = useDebounce(filterZones, 250);

  const debouncedFilterSegmentsByRange = useDebounce(filterSegments, 250);

  const validateRange = useCallback((newRange: [number, number], availableRange: MeasureRange) => {
    const min = newRange[0];
    const max = newRange[1];
    setErrors({ min: "", max: "" });

    if (min < availableRange.min) {
      setErrors((prevState) => ({ ...prevState, min: "Less than min value" }));
    }
    if (min > max) {
      setErrors((prevState) => ({ ...prevState, min: "Min > max" }));
    }
    if (max > availableRange.max) {
      setErrors((prevState) => ({ ...prevState, max: "Exceeds max value" }));
    }
    if (max < min) {
      setErrors((prevState) => ({ ...prevState, max: "Max < min" }));
    }
  }, []);

  const handleSetRange = (newRange: [number, number]) => {
    if (availableRange) {
      setMeasureRange(newRange);

      if (selectedZone) {
        dispatch(analyticsActions.setODRangeByZone(newRange));
        return;
      }

      if (isOD && zoningLevel) {
        dispatch(
          analyticsActions.setODRange(
            isMaxRangeAvailable(availableRange, newRange) ? null : { [zoningLevel]: newRange },
          ),
        );
      }

      if (isDataset && zoningLevel) {
        dispatch(
          analyticsActions.setDatasetRange(
            isMaxRangeAvailable(availableRange, newRange) ? null : { [zoningLevel]: newRange },
          ),
        );
      }

      if (isRoads) {
        dispatch(analyticsActions.setRoadsRange(isMaxRangeAvailable(availableRange, newRange) ? null : newRange));
      }
    }
  };

  useEffect(() => {
    if (mapLoaded && measureRange && availableRange) {
      validateRange(measureRange, availableRange);

      if ((isOD || isDataset || selectedZone) && zoningLevel) {
        debouncedFilterZonesByRange();
      }

      if (isRoads) {
        debouncedFilterSegmentsByRange();
      }
    }
  }, [
    mapLoaded,
    measureRange,
    debouncedFilterSegmentsByRange,
    debouncedFilterZonesByRange,
    isDataset,
    isOD,
    isRoads,
    selectedZone,
    zoningLevel,
    baseMapStyle,
    colorScheme,
    showRoadVolumes,
    showZones,
    availableRange,
    validateRange,
  ]);

  useEffect(() => {
    if (availableRange) {
      setMeasureRange([availableRange.min, availableRange.max]);
    }
  }, [availableRange, setMeasureRange]);

  useEffect(() => {
    return () => {
      dispatch(analyticsActions.setODRange(null));
      dispatch(analyticsActions.setDatasetRange(null));
      dispatch(analyticsActions.setRoadsRange(null));
    };
  }, [dispatch]);

  return (
    <RangeSlider
      label={isRoads ? "Volumes" : "Counts"}
      loading={rangeLoading}
      range={measureRange || [0, 0]}
      availableRange={availableRange || { min: 0, max: 0 }}
      errors={errors}
      disabled={disabled}
      handleSetRange={handleSetRange}
    />
  );
};
