import { MoreHoriz } from "@mui/icons-material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Box, IconButton, IconButtonProps, Tooltip, Typography, styled } from "@mui/material";
import React from "react";

import { Popover } from "components";

import { CircleMapSymbol, LineMapSymbol, ServiceOverlay } from "types";

const LegendItem = styled(Box)`
  width: 100%;
  display: grid;
  grid-template-columns: 20px 1fr;
  column-gap: 10px;
  align-items: center;
  margin-left: 35px;
`;

const PopoverControlButton = (props: IconButtonProps) => (
  <IconButton {...props}>
    <MoreHoriz fontSize="small" />
  </IconButton>
);

const LegendIcon = ({ symbol }: { symbol: CircleMapSymbol | LineMapSymbol }) => {
  switch (symbol.style) {
    case "circle":
      return (
        <Box
          sx={{ width: "20px", height: "15px", backgroundColor: symbol.color, clipPath: `circle(${symbol.size}px)` }}
        />
      );
    case "dashed":
      return (
        <svg width="20px" height="15px" style={{ fill: "#ffffff", strokeWidth: `${symbol.width}px` }}>
          <line x1="0" y1="8" x2="20" y2="8" style={{ stroke: symbol.color, strokeDasharray: "5 2" }} />
        </svg>
      );
    default:
      return <Box sx={{ width: "20px", height: `${symbol.width}px`, backgroundColor: symbol.color }}></Box>;
  }
};

export const LayersList = ({
  overlays,
  removeOverlay,
  toggleVisibility,
  showOverlayInfo,
}: {
  overlays: (ServiceOverlay & { index: number; invisible: boolean })[];
  toggleVisibility: (index: number) => void;
  removeOverlay: ({ checked, index }: { checked: boolean; index: number }) => void;
  showOverlayInfo: (index: number) => void;
}) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", mb: 1 }}>
      {overlays.length === 0 ? (
        <Typography variant="body2" color="text.secondary" sx={{ paddingTop: "20px", textAlign: "center" }}>
          No overlays selected
        </Typography>
      ) : null}
      {overlays.map((overlay, index) => {
        return (
          <Box key={index} sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
            <Box sx={{ width: "100%", display: "grid", gridTemplateColumns: "36px 1fr 36px", columnGap: "1px" }}>
              <IconButton
                onClick={() => {
                  toggleVisibility(overlay.index);
                }}
              >
                {overlay.invisible ? <VisibilityOffIcon fontSize="small" /> : <VisibilityIcon fontSize="small" />}
              </IconButton>
              <Tooltip title={overlay.layerName} placement={"right"}>
                <Typography
                  color="text.primary"
                  sx={{
                    padding: "10px 0",
                    fontWeight: 600,
                    fontSize: "12px",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                  }}
                >
                  {overlay.layerName}
                </Typography>
              </Tooltip>
              <Popover
                control={PopoverControlButton}
                menuItems={[
                  {
                    children: "Info",
                    onClick: () => {
                      showOverlayInfo(overlay.index);
                    },
                  },
                  {
                    children: "Remove",
                    onClick: () => {
                      removeOverlay({
                        checked: false,
                        index: overlay.index,
                      });
                    },
                  },
                ]}
              />
            </Box>
            {overlay.renderer.symbol ? (
              <LegendItem key="main">
                <LegendIcon symbol={overlay.renderer.symbol} />
                <Typography color="text.secondary" sx={{ fontSize: "12px" }}>
                  {overlay.renderer.label ?? ""}
                </Typography>
              </LegendItem>
            ) : null}
            {overlay.renderer.uniqueValueInfos
              ? overlay.renderer.uniqueValueInfos.map((info, index) => (
                  <LegendItem key={index}>
                    <LegendIcon symbol={info.symbol} />
                    <Typography color="text.secondary" sx={{ fontSize: "12px" }}>
                      {info.value ?? ""}
                    </Typography>
                  </LegendItem>
                ))
              : null}
          </Box>
        );
      })}
    </Box>
  );
};
