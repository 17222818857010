import styled from "@emotion/styled";
import React, { FC } from "react";
import ReactPlayer from "react-player/vimeo";

import { usePageTracking } from "hooks";

import { PageContent } from "./PageContent";
import { PageHeader } from "./PageHeader";

const TutorialsContainer = styled(PageContent)`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

const VideoContainer = styled.div`
  margin: 1rem 1rem 4rem 1rem;
  width: 512px;
  height: 288px;
`;

const VideoTitle = styled.h3`
  margin: 1rem 0 0 1.5rem;
`;

const videos = [
  {
    title: "Overview",
    url: "https://vimeo.com/827801706",
  },
  {
    title: "OD Matrix",
    url: "https://vimeo.com/818408877",
  },
  {
    title: "Volumes",
    url: "https://vimeo.com/818408938",
  },
  {
    title: "Export OD Data",
    url: "https://vimeo.com/818796794",
  },
  {
    title: "Datasets",
    url: "https://vimeo.com/866411240",
  },
  {
    title: "Custom Zones",
    url: "https://vimeo.com/878396396",
  },
  {
    title: "Corridor Discovery",
    url: "https://vimeo.com/907684995",
  },
  {
    title: "Select Link",
    url: "https://vimeo.com/917657533",
  },
  {
    title: "Roadway VMT",
    url: "https://vimeo.com/946441711",
  },
];

export const Tutorials: FC = () => {
  usePageTracking();

  return (
    <>
      <PageHeader title="Teralytics Studio: Tutorials" subtitle="Introduction to Teralytics Studio" />
      <TutorialsContainer>
        {videos.map(({ title, url }, index) => (
          <VideoContainer key={index}>
            <ReactPlayer title={title} url={url} controls width="100%" height="100%" />
            <VideoTitle>{title}</VideoTitle>
          </VideoContainer>
        ))}
      </TutorialsContainer>
    </>
  );
};
