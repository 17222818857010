import AddIcon from "@mui/icons-material/Add";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Box, Button, Chip, Divider, Link, Tooltip, Typography, styled } from "@mui/material";
import * as DOMPurify from "dompurify";
import React from "react";

import { themeColors } from "theme/themeConstants";

import { ServiceOverlay } from "types";

const LegendItemInfoHeader = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 30px 70px;
  column-gap: 10px;
  align-items: center;
`;

// add prop to change the color of the text
const LegendItemInfoFooter = styled(Box)<{ twobuttons: string }>`
  display: grid;
  grid-template-columns: ${({ twobuttons }) => (twobuttons === "true" ? "1fr 100px 70px" : "1fr 100px")};
  column-gap: 10px;
  align-items: center;
`;

function sanitizeAndRenderAsHtml(overlayAttribute: string | undefined): React.ReactNode {
  return <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(overlayAttribute ?? "") }} />;
}

export const OverlayLegendItemInfo = ({
  overlay,
  index,
  invalidOverlayIndex,
  isAdded,
  loading,
  withAdding,
  addOverlay,
}: {
  overlay: ServiceOverlay;
  index: number;
  invalidOverlayIndex?: number;
  isAdded?: boolean;
  loading?: boolean;
  withAdding?: boolean;
  addOverlay?: ({ checked, index }: { checked: boolean; index: number }) => void;
}) => {
  return (
    <Box
      sx={{
        width: "100%",
        mb: "12px",
        p: "10px 20px",
        background: invalidOverlayIndex === index ? themeColors.warningStatus : themeColors.gray100,
      }}
    >
      <LegendItemInfoHeader>
        <Box>
          <Typography style={{ fontSize: "14px", fontWeight: "600" }}>{overlay.layerName}</Typography>
        </Box>
        {overlay.where ? (
          <Tooltip title={`Condition: ${overlay.where}`} placement={"top"}>
            <FilterAltIcon color="action" />
          </Tooltip>
        ) : (
          <Box />
        )}
        <Chip size={"small"} variant={"outlined"} color={"info"} label={overlay.geometryType} />
      </LegendItemInfoHeader>
      <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start", p: 0, mt: 1 }}>
        <Typography style={{ fontSize: "12px" }}>{sanitizeAndRenderAsHtml(overlay.description)}</Typography>
      </Box>
      <Divider sx={{ mt: 1, mb: 1 }} />
      <LegendItemInfoFooter twobuttons={Boolean(withAdding).toString()}>
        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
          {overlay.owner && overlay.owner.url ? (
            <Link
              href={overlay.owner.url}
              target="_blank"
              sx={{ mr: 2, color: themeColors.secondary, textDecoration: "none !important" }}
            >
              {overlay.owner.name}
            </Link>
          ) : null}
          <Typography style={{ fontSize: "12px", color: themeColors.gray600 }}>
            {sanitizeAndRenderAsHtml(overlay.copyrightText)}
          </Typography>
        </Box>
        <Link href={overlay.layerInfoUrl ?? overlay.getLayerMetadataUrl} target="_blank">
          <Button fullWidth variant="outlined" size="small" startIcon={<OpenInNewIcon />}>
            Metadata
          </Button>
        </Link>
        {withAdding && addOverlay ? (
          <Button
            disabled={isAdded || loading}
            fullWidth
            variant="outlined"
            size="small"
            startIcon={loading ? <HourglassEmptyIcon fontSize="small" /> : <AddIcon fontSize="small" />}
            onClick={() => addOverlay({ checked: true, index })}
          >
            Add
          </Button>
        ) : (
          <Box />
        )}
      </LegendItemInfoFooter>
    </Box>
  );
};
