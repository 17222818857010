import { useEffect, useRef } from "react";

import { GateSegment } from "types";
import { EditorRoadsHighlightStatus } from "features";

export const useHighlightGateSegments = (
  selectedSegment: GateSegment | null,
  selectedGateSegments: GateSegment[] | null,
  setSegmentFeatureState: (id: string, state: { [key: string]: boolean }) => void,
  setGateSegmentsFeatureState: (segments: GateSegment[], state: { [key: string]: boolean }) => void,
) => {
  const highlightedGateSegments = useRef<GateSegment[] | null>(null);
  const highlightedSegment = useRef<GateSegment | null>(null);

  useEffect(() => {
    if (highlightedSegment.current) {
      setSegmentFeatureState(highlightedSegment.current.id, {
        [EditorRoadsHighlightStatus.SelectedSegment]: false,
      });
    }
    if (selectedSegment && selectedSegment.id !== highlightedSegment.current?.id) {
      setSegmentFeatureState(selectedSegment.id, {
        [EditorRoadsHighlightStatus.SelectedSegment]: true,
      });
    }
    highlightedSegment.current = selectedSegment;
  }, [selectedSegment, selectedGateSegments, setSegmentFeatureState]);

  useEffect(() => {
    if (highlightedGateSegments.current) {
      setGateSegmentsFeatureState(highlightedGateSegments.current, {
        [EditorRoadsHighlightStatus.SelectedGateSegments]: false,
      });
    }

    if (selectedGateSegments && selectedGateSegments !== highlightedGateSegments.current) {
      setGateSegmentsFeatureState(selectedGateSegments, {
        [EditorRoadsHighlightStatus.SelectedGateSegments]: true,
      });
    }
    highlightedGateSegments.current = selectedGateSegments;
  }, [selectedGateSegments, setGateSegmentsFeatureState]);
};
