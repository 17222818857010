import styled from "@emotion/styled";
import logo from "assets/svg/studio-logo.svg";
import React, { FC, useMemo } from "react";
import { Link } from "react-router-dom";

import { FlexContainer, NavItem, NavMenu, UserControls } from "components";

import { useAppSelector } from "hooks";

import { DataState } from "store/interfaces";
import {
  selectAtLeastOneCorridorDataPermission,
  selectAtLeastOneRoadVmtDataPermission,
  selectAtLeastOneSelectLinkPermission,
} from "store/permissionsSelectors";

import { ROUTES, UserPermissions } from "types";

interface Props {
  permissions: UserPermissions | null;
}

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;

  &:focus {
    outline: none;
  }
`;

const NavbarContainer = styled(FlexContainer)`
  width: 100%;
  padding: 0 0.5rem 0 1rem;
  background-color: var(--color-text);
  justify-content: space-between;

  @media only screen and (min-width: 992px) {
    padding: 0 1.5rem 0 2rem;
  }
`;

export const Navbar: FC<Props> = ({ permissions }) => {
  const isODMetadataLoading = useAppSelector((state) => state.analytics.ODMetadata.state) === DataState.LOADING;
  const isRoadsMetadataLoading = useAppSelector((state) => state.analytics.roadsMetadata.state) === DataState.LOADING;
  const isDatasetMetadataLoading =
    useAppSelector((state) => state.analytics.datasetMetadata.state) === DataState.LOADING;

  const isCorridorAllowed = useAppSelector(selectAtLeastOneCorridorDataPermission);
  const isRoadVmtAllowed = useAppSelector(selectAtLeastOneRoadVmtDataPermission);
  const isSelectLinkAllowed = useAppSelector(selectAtLeastOneSelectLinkPermission);

  const navItems: NavItem[] = useMemo(
    () => [
      {
        label: "Dashboard",
        url: ROUTES.Dashboard,
      },
      {
        label: "Map",
        url: ROUTES.Map,
        disabled: isODMetadataLoading || isDatasetMetadataLoading || isRoadsMetadataLoading,
      },
      {
        label: "Datasets",
        url: ROUTES.Datasets,
      },
      {
        label: "Analytics",
        url: ROUTES.Analytics,
        subItems: [
          ...(isSelectLinkAllowed ? [{ label: "Select Link", url: ROUTES.SelectLink }] : []),
          {
            label: "Corridor Discovery",
            url: ROUTES.CorridorDiscovery,
            disabled: !isCorridorAllowed,
          },
          ...(isRoadVmtAllowed
            ? [
                {
                  label: "Road VMT",
                  url: ROUTES.RoadVmt,
                  disabled: !isRoadVmtAllowed,
                },
              ]
            : []),
        ],
      },
      {
        label: "Exports",
        url: ROUTES.Exports,
      },
      {
        label: "Documentation",
        url: ROUTES.Documentation,
      },
    ],
    [
      isODMetadataLoading,
      isDatasetMetadataLoading,
      isRoadsMetadataLoading,
      isSelectLinkAllowed,
      isCorridorAllowed,
      isRoadVmtAllowed,
    ],
  );

  return (
    <NavbarContainer>
      <FlexContainer>
        <StyledLink to={ROUTES.Dashboard}>
          <img src={logo} alt="logo" />
        </StyledLink>
        <NavMenu navItems={navItems} />
      </FlexContainer>
      <UserControls />
    </NavbarContainer>
  );
};
