import { LicenseType, LicenseTypeResponse, Organization, OrganizationResponse, User, UserPermissions } from "types";

import RestHandler from "./RestHandler";

export interface LicenseApiType {
  getUser(): Promise<User>;
  getOrganization(): Promise<Organization[]>;
  changeUserOrganization(code: string): Promise<User>;
  getSoftwareLicenseTypes(): Promise<LicenseType[]>;
  changeUserLicenseType(code: string): Promise<User>;
  getPermissions(): Promise<UserPermissions>;
}

export default function LicenseApi(restHandler: RestHandler) {
  return {
    async getUser() {
      const body = await restHandler.get<User>("user/me");
      return body;
    },

    async getOrganization() {
      const body = await restHandler.get<OrganizationResponse>("organization?status-in=active");
      return body.data;
    },

    async changeUserOrganization(code: string) {
      const body = await restHandler.put("user/me", {
        organization: {
          code: code,
        },
      });
      return body as User;
    },

    async getSoftwareLicenseTypes() {
      const body = await restHandler.get<LicenseTypeResponse>("software-license-type");

      return body.data;
    },

    async changeUserLicenseType(code: string) {
      const body = await restHandler.put("user/me", {
        softwareLicense: {
          softwareLicenseType: code,
        },
      });
      return body as User;
    },

    async getPermissions() {
      const body = await restHandler.get("user/me/permissions");
      return body as UserPermissions;
    },
  };
}
