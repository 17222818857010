import { reportAboutCustomEventInfo, reportAboutErrorState } from "utils/reports";

const ONE_MINUTE_IN_MS = 60 * 1000;

function getBodyOrThrow<T>(res: Response, duration: number): Promise<T> {
  const responseInfo = {
    duration,
    url: res?.url,
    status: res?.status,
    statusText: res?.statusText,
    headers: res?.headers,
    type: res?.type,
    redirected: res?.redirected,
  };

  if (duration > ONE_MINUTE_IN_MS) {
    reportAboutCustomEventInfo("Long response time", responseInfo);
  }

  if (!res.ok) {
    reportAboutErrorState(responseInfo, `Invalid response status: ${res.status}`);

    if (res.status === 413) {
      return Promise.reject({ status: res.status, body: { what: "File size is too large" } });
    } else if (res.status >= 500 && res.status < 600) {
      return Promise.reject({ status: res.status, body: { what: "An error occurred, please try again later" } });
    }

    return res.json().then((err) => {
      return Promise.reject({ status: res.status, body: err });
    });
  }

  return res.json();
}

function getCSVBodyOrThrow(res: Response, duration: number): Promise<unknown> {
  const responseInfo = {
    duration,
    url: res?.url,
    status: res?.status,
    statusText: res?.statusText,
    headers: res?.headers,
    type: res?.type,
    redirected: res?.redirected,
  };

  if (duration > ONE_MINUTE_IN_MS) {
    reportAboutCustomEventInfo("Long response time", responseInfo);
  }

  if (!res.ok) {
    reportAboutErrorState(responseInfo, `Invalid response status: ${res.status}`);

    throw new Error(`invalid response status: ${res.status}`);
  }

  return res.text();
}

function getAccessToken(): string {
  // TBD: Throw an error if no accessToken or send to login
  return sessionStorage.getItem("accessToken") ?? "";
}

function getLicensedAreaId(): string {
  return sessionStorage.getItem("licensedAreaId") ?? "";
}

function writeAuthHeader(token: string, init: RequestInit): void {
  if (!init.headers) {
    init.headers = new Headers({ Authorization: token });
  } else if (init.headers instanceof Headers) {
    init.headers.set("Authorization", token);
  } else if (Array.isArray(init.headers)) {
    init.headers.push(["Authorization", token]);
  } else {
    init.headers.Authorization = token;
  }
}

function writeLicensedAreaIdHeader(licensedAreaId: string, init: RequestInit): void {
  if (!init.headers) {
    init.headers = new Headers({ "licensed-area-id": licensedAreaId });
  } else if (init.headers instanceof Headers) {
    init.headers.set("licensed-area-id", licensedAreaId);
  } else if (Array.isArray(init.headers)) {
    init.headers.push(["licensed-area-id", licensedAreaId]);
  } else {
    init.headers["licensed-area-id"] = licensedAreaId;
  }
}

export default class RestHandler {
  constructor(private readonly apiPath?: string) {
    this.apiPath = apiPath ? `${apiPath + "/"}` : "";
  }

  fetchWithToken(input: RequestInfo, init?: RequestInit): Promise<Response> {
    const token = getAccessToken();
    const licensedAreaId = getLicensedAreaId();
    if (token) {
      const bearerToken = `Bearer ${token}`;
      if (typeof input === "string") {
        const requestInit = init || {};
        writeAuthHeader(bearerToken, requestInit);

        if (licensedAreaId) {
          writeLicensedAreaIdHeader(licensedAreaId, requestInit);
        }
        return fetch(input, requestInit);
      }

      writeAuthHeader(bearerToken, input);
      if (licensedAreaId) {
        writeLicensedAreaIdHeader(licensedAreaId, input);
      }
      return fetch(input);
    }

    return fetch(input, init);
  }

  private async fetchBody<T>(path: string, init?: RequestInit): Promise<T> {
    const startTime = performance.now();
    const res = await this.fetchWithToken(`${this.apiPath}${path}`, init);
    const endTime = performance.now();

    return getBodyOrThrow(res, endTime - startTime);
  }

  private async fetchCSVBody(path: string, init?: RequestInit): Promise<unknown> {
    const startTime = performance.now();
    const res = await this.fetchWithToken(`${this.apiPath}${path}`, init);
    const endTime = performance.now();

    return getCSVBodyOrThrow(res, endTime - startTime);
  }

  private async fetchBodyWithPayload<T>(
    path: string,
    init: RequestInit,
    payload: T,
    isCSV: boolean = false,
  ): Promise<unknown> {
    const options: any = { ...init };

    if (payload instanceof FormData || payload instanceof Blob) {
      options.body = payload;
    } else {
      options.headers = {
        "Content-Type": "application/json",
      };
      options.body = JSON.stringify(payload);
    }

    return isCSV ? this.fetchCSVBody(path, options) : this.fetchBody(path, options);
  }

  get<T>(path: string, init?: RequestInit): Promise<T> {
    return this.fetchBody(path, { ...init, method: "GET" });
  }

  delete(path: string, init?: RequestInit): Promise<unknown> {
    return this.fetchBody(path, { ...init, method: "DELETE" });
  }

  put<T>(path: string, body: T, init?: RequestInit): Promise<unknown> {
    return this.fetchBodyWithPayload<T>(path, { ...init, method: "PUT" }, body);
  }

  post<T>(path: string, body: T, init?: RequestInit): Promise<unknown> {
    return this.fetchBodyWithPayload<T>(path, { ...init, method: "POST" }, body);
  }

  postForCSV<T>(path: string, body: T, init?: RequestInit): Promise<unknown> {
    return this.fetchBodyWithPayload<T>(path, { ...init, method: "POST" }, body, true);
  }

  async postForBinary<T>(path: string, body: T): Promise<any> {
    const token = getAccessToken();
    const licensedAreaId = getLicensedAreaId();
    const pathLink = `${this.apiPath}${path}`;

    return new Promise(function (resolve, reject) {
      var xhr = new XMLHttpRequest();
      xhr.open("POST", pathLink);
      xhr.setRequestHeader("Authorization", "Bearer " + token);
      xhr.setRequestHeader("licensed-area-id", licensedAreaId);
      xhr.responseType = "arraybuffer";
      xhr.setRequestHeader("Content-type", "text/plain;charset=UTF-8");
      xhr.onreadystatechange = (e) => {
        if (xhr.readyState !== 4) {
          return;
        }
        if (xhr.status === 200) {
          resolve(xhr.response);
        } else {
          reportAboutErrorState(
            {
              body: xhr?.response,
              responseText: xhr?.responseText,
              statusText: xhr?.statusText,
              status: xhr?.status,
              headers: xhr?.getAllResponseHeaders(),
              type: xhr?.responseType,
            },
            `Invalid response status: ${xhr.status}`,
          );

          reject(`invalid response status: ${xhr.status}`);
        }
      };
      xhr.onerror = reject;
      xhr.send(JSON.stringify({ ...body, format: "protobuf" }));
    });
  }
}
