import { ReactNode, Dispatch, SetStateAction } from "react";

import { DocsTable } from "./DocsTable";
import { DocsCharts, Chart } from "./DocsCharts";

import trip_shares from "./data/trip-purpose-shares.json";
import trips_rates from "./data/trips-rates.json";

import tod_patterns_hbo from "assets/png/tod-patterns-hbo.png";
import tod_patterns_hbw from "assets/png/tod-patterns-hbw.png";
import tod_patterns_nhb from "assets/png/tod-patterns-nhb.png";
import tlf_distribution_hbo from "assets/png/tlf-distribution-hbo.png";
import tlf_distribution_hbw from "assets/png/tlf-distribution-hbw.png";
import tlf_distribution_nhb from "assets/png/tlf-distribution-nhb.png";
import volumes_vs_aadts from "assets/png/volumes-vs-aadts.png";

export interface QualityPointType {
  title: string;
  subtitle: string;
  children: ReactNode;
  isOpen: boolean;
}

export const generateQualityPoints = (
  setSelectedChart: Dispatch<SetStateAction<Chart | undefined>>,
): QualityPointType[] => [
  {
    title: "Trip production rates",
    subtitle: "The average number of trips made by each person or household.",
    children: <DocsTable data={trips_rates} />,
    isOpen: false,
  },
  {
    title: "Trip purposes",
    subtitle: "Share of trips by trip purpose",
    children: <DocsTable data={trip_shares} />,
    isOpen: false,
  },
  {
    title: "Trip length distribution",
    subtitle: "The share of trips falling into trip distance ranges.",
    children: (
      <DocsCharts
        charts={[
          { title: "HBW", image: tlf_distribution_hbw },
          { title: "HBO", image: tlf_distribution_hbo },
          { title: "NHB", image: tlf_distribution_nhb },
        ]}
        setSelectedChart={setSelectedChart}
      />
    ),
    isOpen: false,
  },
  {
    title: "Time of day distribution",
    subtitle:
      "The share of trips occurring in periods of the day - Daily trips roughly equal in each direction between two neighborhoods.",
    children: (
      <DocsCharts
        charts={[
          { title: "HBW", image: tod_patterns_hbw },
          { title: "HBO", image: tod_patterns_hbo },
          { title: "NHB", image: tod_patterns_nhb },
        ]}
        setSelectedChart={setSelectedChart}
      />
    ),
    isOpen: false,
  },
  {
    title: "Traffic volumes vs. observed counts",
    subtitle:
      "The number of vehicles or pedestrians compared to traffic counts collected by state transportation departments, other government agencies, or other sources at selected locations.",
    children: <DocsCharts charts={[{ title: "", image: volumes_vs_aadts }]} setSelectedChart={setSelectedChart} />,
    isOpen: false,
  },
];
