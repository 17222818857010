import React, { FC, ReactNode } from "react";
import { Icon } from "@blueprintjs/core";
import { Popover2 as Popover } from "@blueprintjs/popover2";

import { PopoverContainer, PopoverHeader, PopoverActions, PopoverContent } from "components";

export interface PopoverFormProps {
  children?: ReactNode;
  content?: ReactNode;
  contentMaxHeight?: number;
  title: string;
  matchTargetWidth?: boolean;
  displayCloseIcon?: boolean;
  isOpen: boolean;
  placement?: "bottom" | "top";
  primaryAction: ReactNode;
  secondaryAction: ReactNode;
  handleInteraction: (state: boolean) => void;
}

export const PopoverForm: FC<PopoverFormProps> = ({
  children,
  content,
  contentMaxHeight,
  title,
  placement,
  primaryAction,
  secondaryAction,
  isOpen,
  handleInteraction,
  matchTargetWidth,
  displayCloseIcon,
}) => (
  <Popover
    fill
    content={
      <PopoverContainer>
        <PopoverHeader>
          <h4>{title}</h4>
          {displayCloseIcon && <Icon icon="cross" onClick={() => handleInteraction(false)} />}
        </PopoverHeader>
        <PopoverContent maxHeight={contentMaxHeight}>{content}</PopoverContent>
        <PopoverActions>
          {secondaryAction}
          {primaryAction}
        </PopoverActions>
      </PopoverContainer>
    }
    interactionKind="click"
    isOpen={isOpen}
    onInteraction={(state) => handleInteraction(state)}
    placement={placement || "bottom"}
    minimal
    matchTargetWidth={matchTargetWidth}
  >
    {children}
  </Popover>
);
