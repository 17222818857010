import styled from "@emotion/styled";
import React, { FC } from "react";

import { BarChart } from "components";

import { BarChartEmptyState } from "components/molecules/bar-chart/BarChartEmptyState";

import { Measure, RoadVmtZoneDetails } from "types";

import { getLabelByCategories, getLabelByDimension } from "./ODAnalytics";

interface RoadVmtAnalyticsProps {
  zoneDetails: RoadVmtZoneDetails[];
  roadVmtDimensions: Measure[] | undefined;
}

const AnalyticsContainerWrapper = styled.div`
  overflow-y: auto;
  height: calc(100% - 60px);
`;

const AnalyticsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 var(--padding-lg);
`;

const GraphContainer = styled.div`
  display: flex;
  gap: 1rem;
  flex-direction: column;
  overflow-y: hide;
`;

const ChartWrapper = styled.div`
  border-radius: 4px;
  border: 1px solid var(--color-border);
`;

const DirectionGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: var(--padding-lg) 0;
`;

const DirectionLabel = styled.div`
  font-size: var(--font-size-sm);
  font-weight: bold;
  margin-right: var(--padding-md);
  line-height: 21px;
`;

const RoadVmtAnalytics: FC<RoadVmtAnalyticsProps> = ({ zoneDetails, roadVmtDimensions }) => {
  return (
    <AnalyticsContainerWrapper>
      {zoneDetails.map((details) => (
        <AnalyticsContainer key={`${details.measure}_${details.id}`}>
          <DirectionGroup>
            <DirectionLabel>{details?.name || "Attributes"}</DirectionLabel>
          </DirectionGroup>
          <GraphContainer>
            {details?.summary?.breakdowns.map((breakdown, index) => {
              const dimension = breakdown.dimensions[0];
              const data = breakdown.rows.map((row) => ({
                name: getLabelByCategories(row.categories[0], dimension, roadVmtDimensions || []),
                value: row.value,
              }));

              const title = getLabelByDimension(dimension, roadVmtDimensions || []);

              return (
                <ChartWrapper key={index}>
                  {data.length ? (
                    <BarChart key={index} data={data} layout="vertical" title={title} />
                  ) : (
                    <BarChartEmptyState title={title} message="No data available" />
                  )}
                </ChartWrapper>
              );
            })}
          </GraphContainer>
        </AnalyticsContainer>
      ))}
    </AnalyticsContainerWrapper>
  );
};

export { RoadVmtAnalytics };
