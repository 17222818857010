import { FC, Dispatch, SetStateAction } from "react";
import styled from "@emotion/styled";

export interface Chart {
  title: string;
  image: string;
}

export interface DocsChartsProps {
  charts: Chart[];
  setSelectedChart: Dispatch<SetStateAction<Chart | undefined>>;
}

const ChartsWrapper = styled.div`
  display: grid;
  justify-items: center;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(3, 1fr);

  @media only screen and (min-width: 600px) {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr;
  }
`;

export const ChartContainer = styled.div`
  background-color: #ffffff;
  border-radius: 8px;
`;

export const ChartTitle = styled.h4`
  color: var(--color-text);
  font-weight: 500;
  margin-bottom: 0.25rem;
  text-align: center;
`;

const ChartImg = styled.img`
  width: 100%;
  height: 100%;
  max-width: 400px;
  max-height: 250px;
  cursor: zoom-in;
`;

export const DocsCharts: FC<DocsChartsProps> = ({ charts, setSelectedChart }) => {
  return (
    <ChartsWrapper>
      {charts.map((chart, i) => (
        <ChartContainer>
          <ChartTitle>{chart.title}</ChartTitle>
          <ChartImg key={i} src={chart.image} alt="" onClick={() => setSelectedChart(chart)} />
        </ChartContainer>
      ))}
    </ChartsWrapper>
  );
};
