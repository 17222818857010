import React, { FC } from "react";
import styled from "@emotion/styled";
import { Button as BpButton, IconName } from "@blueprintjs/core";

import { FormGroup as BpFormGroup, Input, InputColor, InputProps } from "components";
import { getOpenChevronIcon } from "utils/ui";

export interface SelectInputProps extends InputProps {
  value?: string;
  placement?: "bottom" | "top";
  error?: boolean;
  helperText?: string;
  color?: InputColor;
  icon?: IconName;
  isOpen?: boolean;
  disabled?: boolean;
}

const FormGroup = styled(BpFormGroup)`
  margin: 0;
`;

export const SelectInput: FC<SelectInputProps> = ({
  value,
  color,
  error,
  icon,
  placement,
  isOpen,
  disabled,
  ...props
}) => (
  <FormGroup error={!!error} helperText={error}>
    <Input
      color={color}
      error={!!error}
      leftIcon={icon}
      disabled={disabled}
      rightElement={
        <BpButton disabled={disabled} minimal={true} rightIcon={getOpenChevronIcon(!!isOpen, placement || "bottom")} />
      }
      value={value}
      readOnly
      {...props}
    />
  </FormGroup>
);
