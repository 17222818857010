import { WarningAmber } from "@mui/icons-material";
import { Grid, Typography } from "@mui/material";
import { FC } from "react";

import { Button, Dialog } from "components";

export interface ConfirmDeleteGroupDialogProps {
  isOpen: boolean;
  segmentsCount: number;
  onClose: () => void;
  deleteSegmentsGroup: () => void;
}

export const ConfirmDeleteGroupDialog: FC<ConfirmDeleteGroupDialogProps> = ({
  isOpen,
  segmentsCount,
  onClose,
  deleteSegmentsGroup,
}) => (
  <Dialog isOpen={isOpen} onClose={onClose}>
    <Grid container alignItems={"center"} marginTop={2} marginBottom={3}>
      <WarningAmber color="warning" />
      <Typography marginLeft={1}>
        {`This group contains ${segmentsCount} segment${
          segmentsCount > 1 ? "s" : ""
        }. Are you sure you want to delete it?`}
      </Typography>
    </Grid>
    <Grid container justifyContent={"flex-end"}>
      <Button color="white" onClick={onClose}>
        No
      </Button>
      <Button color="primaryLight" style={{ marginLeft: "8px" }} onClick={deleteSegmentsGroup}>
        Yes
      </Button>
    </Grid>
  </Dialog>
);
