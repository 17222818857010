import styled from "@emotion/styled";
import { MapboxGeoJSONFeature } from "mapbox-gl";

import { Badge } from "components";

export interface ZonePopupProps {
  id: string;
  feature: MapboxGeoJSONFeature;
  vmt: number;
  vmtPerSqmi: number;
  vmtPerCapita?: number;
  mileage: number;
}

const CountContainer = styled.div`
  min-width: 150px;
  gap: 0.4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0.5rem 1rem;
`;

const TripsCount = styled.h3`
  font-weight: 400;
  margin-right: 1rem;
`;

const Info = styled.div`
  text-align: right;
  padding: 0.25rem 1rem;
  border-top: 1px solid var(--color-border);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`;

const Header = styled.div`
  padding: 0.6rem 0.8rem 0.4rem;
  border-bottom: 1px solid var(--color-border);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`;

export const ZonePopupContent = ({ id, feature, vmt, vmtPerSqmi, mileage }: ZonePopupProps) => {
  return (
    <>
      <Header>
        <h3>
          {feature.properties?.cnty_name} ({id})
        </h3>
      </Header>
      <CountContainer>
        <TripsCount>Vehicle Miles Traveled</TripsCount>
        <Badge color="secondary">{vmt}</Badge>
      </CountContainer>
      <CountContainer>
        <TripsCount>Road Mileage</TripsCount>
        <Badge color="secondary">{mileage}</Badge>
      </CountContainer>
      <Info>{`${vmtPerSqmi} VMT per square mile`}</Info>
    </>
  );
};
