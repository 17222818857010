import { Api } from "api";
import { produce } from "immer";
import { Reducer } from "redux";
import { all, call, getContext, put, takeLatest } from "redux-saga/effects";

import { isExpired } from "features/export";

import {
  AoiExportRequest,
  ExportJob,
  ExportJobStatus,
  ExportJobs,
  NumZones,
  NumZonesResponse,
  ODDatasetExportRequest,
  OdNumZonesRequest,
} from "types";

import { reportAboutErrorState } from "utils/reports";

import { Action, ActionsUnion, createAction } from "../actionHelpers";
import { DataState, LoadingErrorData, ResponseError } from "../interfaces";
import { ExportActionType } from "./actionTypes";

export interface ExportState {
  exportJobs: LoadingErrorData<ExportJob[]>;
  numZones: LoadingErrorData<NumZones[]>;
  newExport: LoadingErrorData<boolean>;
}

export type ExportAction = ActionsUnion<typeof exportActions>;

export const exportActions = {
  // List of export jobs
  fetchExportJobs: () => createAction(ExportActionType.FETCH_EXPORT_JOBS),
  fetchExportJobsSucceeded: (exportJobs: ExportJobs) =>
    createAction(ExportActionType.FETCH_EXPORT_JOBS_SUCCEEDED, { exportJobs }),
  fetchExportJobsFailed: (error: ResponseError) => createAction(ExportActionType.FETCH_EXPORT_JOBS_FAILED, error),
  clearExportJobs: () => createAction(ExportActionType.CLEAR_EXPORT_JOBS),
  addDatasetExportJob: (datasetId: string, request: ODDatasetExportRequest) =>
    createAction(ExportActionType.ADD_DATASET_EXPORT_JOB, {
      datasetId,
      request,
    }),
  addDatasetExportJobSucceeded: (uuid: string) =>
    createAction(ExportActionType.ADD_DATASET_EXPORT_JOB_SUCCEEDED, { uuid }),
  addDatasetExportJobFailed: (error: ResponseError) =>
    createAction(ExportActionType.ADD_DATASET_EXPORT_JOB_FAILED, error),
  addAOIExportJob: (request: AoiExportRequest) => createAction(ExportActionType.ADD_AOI_EXPORT_JOB, request),
  addAOIExportJobSucceeded: (uuid: string) => createAction(ExportActionType.ADD_AOI_EXPORT_JOB_SUCCEEDED, { uuid }),
  addAOIExportJobFailed: (error: ResponseError) => createAction(ExportActionType.ADD_AOI_EXPORT_JOB_FAILED, error),
  clearNewExport: () => createAction(ExportActionType.CLEAR_NEW_EXPORT),
  fetchNumZones: (request: OdNumZonesRequest) => createAction(ExportActionType.FETCH_NUM_ZONES, request),
  fetchNumZonesSucceeded: (numZones: NumZonesResponse) =>
    createAction(ExportActionType.FETCH_NUM_ZONES_SUCCEEDED, { numZones }),
  fetchNumZonesFailed: (error: ResponseError) => createAction(ExportActionType.FETCH_NUM_ZONES_FAILED, error),
};

const initialState: ExportState = {
  exportJobs: {
    state: DataState.EMPTY,
    data: null,
    error: null,
  },
  numZones: {
    state: DataState.EMPTY,
    data: null,
    error: null,
  },
  newExport: {
    state: DataState.EMPTY,
    data: null,
    error: null,
  },
};

const exportReducer: Reducer<ExportState, ExportAction> = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case ExportActionType.FETCH_EXPORT_JOBS: {
        draft.exportJobs.state = DataState.LOADING;
        draft.exportJobs.error = null;
        return;
      }
      case ExportActionType.FETCH_EXPORT_JOBS_SUCCEEDED: {
        const expiryCheckedExports = action.payload.exportJobs.jobs.map((job) =>
          isExpired(job.createdAt) ? { ...job, status: ExportJobStatus.Expired } : job,
        );

        draft.exportJobs = {
          state: DataState.AVAILABLE,
          data: expiryCheckedExports,
          error: null,
        };
        return;
      }
      case ExportActionType.FETCH_EXPORT_JOBS_FAILED: {
        draft.exportJobs = {
          state: DataState.ERROR,
          error: action.payload,
          data: null,
        };
        return;
      }
      case ExportActionType.CLEAR_EXPORT_JOBS: {
        draft.exportJobs = {
          state: DataState.EMPTY,
          error: null,
          data: null,
        };
        return;
      }
      case ExportActionType.ADD_DATASET_EXPORT_JOB:
      case ExportActionType.ADD_AOI_EXPORT_JOB: {
        draft.newExport.state = DataState.LOADING;
        return;
      }
      case ExportActionType.ADD_DATASET_EXPORT_JOB_SUCCEEDED:
      case ExportActionType.ADD_AOI_EXPORT_JOB_SUCCEEDED: {
        draft.exportJobs.state = DataState.EMPTY;
        draft.newExport.state = DataState.AVAILABLE;
        draft.newExport.data = true;
        return;
      }
      case ExportActionType.ADD_DATASET_EXPORT_JOB_FAILED:
      case ExportActionType.ADD_AOI_EXPORT_JOB_FAILED: {
        draft.newExport.state = DataState.ERROR;
        draft.newExport.error = action.payload;
        return;
      }
      case ExportActionType.CLEAR_NEW_EXPORT: {
        draft.newExport = {
          state: DataState.EMPTY,
          data: null,
          error: null,
        };
        return;
      }
      case ExportActionType.FETCH_NUM_ZONES: {
        draft.numZones.state = DataState.LOADING;
        draft.numZones.error = null;
        return;
      }
      case ExportActionType.FETCH_NUM_ZONES_SUCCEEDED: {
        draft.numZones = {
          state: DataState.AVAILABLE,
          data: action.payload.numZones.counts,
          error: null,
        };
        return;
      }
      case ExportActionType.FETCH_NUM_ZONES_FAILED: {
        draft.numZones = {
          state: DataState.ERROR,
          error: action.payload,
          data: null,
        };
        return;
      }
      default:
        return state;
    }
  });

export default exportReducer;

function* fetchExportJobs(): Generator {
  try {
    const api = yield getContext("api");
    const {
      analyticsApi: { getExportJobs },
    } = api as Api;
    const exportJobs = yield call(getExportJobs);
    yield put({
      type: ExportActionType.FETCH_EXPORT_JOBS_SUCCEEDED,
      payload: { exportJobs },
    });
  } catch (e) {
    reportAboutErrorState(e, ExportActionType.FETCH_EXPORT_JOBS_FAILED);

    yield put({
      type: ExportActionType.FETCH_EXPORT_JOBS_FAILED,
      payload: e,
    });
  }
}

function* addDatasetExportJob(
  action: Action<string, { datasetId: string; request: ODDatasetExportRequest }>,
): Generator {
  try {
    const api = yield getContext("api");
    const {
      analyticsApi: { addDatasetExportJob },
    } = api as Api;
    yield call(addDatasetExportJob, action.payload.datasetId, action.payload.request);
    yield put({
      type: ExportActionType.ADD_DATASET_EXPORT_JOB_SUCCEEDED,
    });
    yield call(fetchExportJobs);
  } catch (e) {
    reportAboutErrorState(e, ExportActionType.ADD_DATASET_EXPORT_JOB_FAILED);

    yield put({
      type: ExportActionType.ADD_DATASET_EXPORT_JOB_FAILED,
      payload: e,
    });
  }
}

function* addAoiExportJob(action: Action<string, AoiExportRequest>): Generator {
  try {
    const api = yield getContext("api");
    const {
      analyticsApi: { addAoiExportJob },
    } = api as Api;
    yield call(addAoiExportJob, action.payload);
    yield put({
      type: ExportActionType.ADD_AOI_EXPORT_JOB_SUCCEEDED,
    });
    yield call(fetchExportJobs);
  } catch (e) {
    reportAboutErrorState(e, ExportActionType.ADD_AOI_EXPORT_JOB_FAILED);

    yield put({
      type: ExportActionType.ADD_AOI_EXPORT_JOB_FAILED,
      payload: e,
    });
  }
}

function* fetchNumZones(action: Action<string, OdNumZonesRequest>): Generator {
  try {
    const api = yield getContext("api");
    const {
      analyticsApi: { getNumZones },
    } = api as Api;
    const numZones = yield call(getNumZones, action.payload);
    yield put({
      type: ExportActionType.FETCH_NUM_ZONES_SUCCEEDED,
      payload: { numZones },
    });
  } catch (e) {
    reportAboutErrorState(e, ExportActionType.FETCH_NUM_ZONES_FAILED);

    yield put({
      type: ExportActionType.FETCH_NUM_ZONES_FAILED,
      payload: e,
    });
  }
}

function* fetchDatasetMetadata(action: Action<string, string>): Generator {
  try {
    const api = yield getContext("api");
    const {
      analyticsApi: { getDatasetMetadata },
    } = api as Api;
    const datasetId = action.payload;
    const datasetMetadata = yield call(getDatasetMetadata, datasetId);
    yield put({
      type: ExportActionType.FETCH_DATASET_METADATA_SUCCEEDED,
      payload: { [datasetId]: datasetMetadata },
    });
  } catch (e) {
    reportAboutErrorState(e, ExportActionType.FETCH_DATASET_METADATA_FAILED);

    yield put({
      type: ExportActionType.FETCH_DATASET_METADATA_FAILED,
      payload: e,
    });
  }
}

export function* exportSaga() {
  yield all([
    takeLatest(ExportActionType.FETCH_EXPORT_JOBS, fetchExportJobs),
    takeLatest(ExportActionType.ADD_DATASET_EXPORT_JOB, addDatasetExportJob),
    takeLatest(ExportActionType.ADD_AOI_EXPORT_JOB, addAoiExportJob),
    takeLatest(ExportActionType.FETCH_NUM_ZONES, fetchNumZones),
    takeLatest(ExportActionType.FETCH_DATASET_METADATA, fetchDatasetMetadata),
  ]);
}
