import { IconNames } from "@blueprintjs/icons";
import { Map } from "mapbox-gl";
import { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from "react";

import { CheckboxDropdown, CheckboxDropdownItem } from "components";

import { SelectLinkAnalysisMode } from "components/pages/analytics/select-link/types";

import { useAppSelector, usePrevious } from "hooks";

import { DataState } from "store/interfaces";

import { ODTileLayer, RoadClassCategory, SelectedArea } from "types";

import { GATES_LAYER_ID, MainRoadsLayerIds, getLayerFromZoom } from "../map-visualization";
import { SelectLinkLayerIds } from "../select-link/selectLinkMap";
import { RangeFilter } from "./RangeFilter";

interface RoadClass extends CheckboxDropdownItem {
  value: string;
}

type RoadClassItems = {
  [key: string]: RoadClass;
};

interface RoadClassGroup {
  items: RoadClassItems;
}

export interface MapLayersFiltersProps {
  map: Map | null;
  mapLoaded: boolean;
  showZones: boolean;
  showRoadVolumes: boolean;
  colorScheme: string;
  disabled?: boolean;
  loading: boolean;
  zoningLevel: string | undefined;
  selectedZone: SelectedArea | null;
  isAnalysis?: boolean;
  isOD: boolean;
  isDataset: boolean;
  isRoads: boolean;
}

export const MapLayersFilters: FC<MapLayersFiltersProps> = ({
  map,
  mapLoaded,
  showZones,
  showRoadVolumes,
  colorScheme,
  disabled,
  loading,
  zoningLevel,
  selectedZone,
  isAnalysis,
  isOD,
  isDataset,
  isRoads,
}) => {
  const [measureRange, setMeasureRange] = useState<[number, number] | null>(null);
  const [roadClasses, setRoadClasses] = useState<RoadClassGroup>({ items: {} });
  const [blockedZoomLevel, setBlockedZoomLevel] = useState<number | null>(null);

  //OD
  const ODMetadata = useAppSelector((state) => state.analytics.ODMetadata);
  const ODZoneIds = useAppSelector((state) => state.analytics.ODIds);
  const ODCounts = useAppSelector((state) => state.analytics.ODCounts);
  const ODCountsByZone = useAppSelector((state) => state.analytics.ODCountsByZoneId);
  const ODMeasureRange = useAppSelector((state) => state.analytics.ODMeasureRange);
  const ODMeasureRangeByZone = useAppSelector((state) => state.analytics.ODMeasureRangeByZone);
  const ODRange = useAppSelector((state) => state.analytics.ODRange);

  //Dataset
  const datasetMetadata = useAppSelector((state) => state.analytics.datasetMetadata);
  const datasetIds = useAppSelector((state) => state.analytics.datasetIds);
  const gatesIds = useAppSelector((state) => state.analytics.datasetGates.data)?.map((gate) => gate.identifier);
  const datasetCounts = useAppSelector((state) => state.analytics.datasetCounts);
  const datasetCountsByZone = useAppSelector((state) => state.analytics.datasetCountsByZoneId);
  const datasetMeasureRange = useAppSelector((state) => state.analytics.datasetMeasureRange);
  const datasetRange = useAppSelector((state) => state.analytics.datasetRange);

  const roadsVolumes = useAppSelector((state) => state.analytics.roadsVolumes);
  const roadsMetadata = useAppSelector((state) => state.analytics.roadsMetadata);
  const roadSegmentIds = useAppSelector((state) => state.analytics.roadSegmentIds);
  const reverseRoadSegmentIds = useAppSelector((state) => state.analytics.reverseRoadSegmentIds);
  const roadSegmentIdsByRoadClass = useAppSelector((state) => state.analytics.roadSegmentIdsByRoadClass);
  const roadsMeasureRange = useAppSelector((state) => state.analytics.roadsMeasureRange);

  //Select Link
  const selectLinkAnalysisMode = useAppSelector((state) => state.selectLink.selectLinkAnalysisMode);
  const selectLinkSegmentCounts = useAppSelector((state) => state.selectLink.selectLinkSegmentCounts);
  const selectLinkAvailableRange = useAppSelector((state) => state.selectLink.selectLinkAvailableRange);

  const activeRoadClasses = useMemo(
    () =>
      Object.values(roadClasses.items)
        .filter(({ isChecked }) => isChecked)
        .map(({ value }) => Number(value)),
    [roadClasses],
  );
  const previousActiveRoadClasses = usePrevious(activeRoadClasses);

  const roadsLayersWithSegmentsId = useMemo(() => {
    if (!roadSegmentIds.data || !roadSegmentIdsByRoadClass) return [];

    const allSegmentsIds = Object.keys(roadSegmentIds.data);

    return isAnalysis && selectLinkAnalysisMode === SelectLinkAnalysisMode.Results
      ? [
          { id: SelectLinkLayerIds.roadsHairlinesLayerId, segments: allSegmentsIds },
          { id: SelectLinkLayerIds.roadsHighlightedVolumesLayerId, segments: allSegmentsIds },
          { id: SelectLinkLayerIds.roadsSegmentsLayerId, segments: allSegmentsIds },
          { id: SelectLinkLayerIds.roadsVolumesLayerId, segments: roadSegmentIdsByRoadClass[RoadClassCategory.OTHER] },
          {
            id: SelectLinkLayerIds.limitedAccessRoadsVolumesLayerId,
            segments: roadSegmentIdsByRoadClass[RoadClassCategory.LIMITED_ACCESS],
          },
        ]
      : [
          { id: MainRoadsLayerIds.roadsHairlinesLayerId, segments: allSegmentsIds },
          { id: MainRoadsLayerIds.roadsHighlightedVolumesLayerId, segments: allSegmentsIds },
          { id: MainRoadsLayerIds.roadsSegmentsLayerId, segments: allSegmentsIds },
          { id: MainRoadsLayerIds.roadsVolumesLayerId, segments: roadSegmentIdsByRoadClass[RoadClassCategory.OTHER] },
          {
            id: MainRoadsLayerIds.limitedAccessRoadsVolumesLayerId,
            segments: roadSegmentIdsByRoadClass[RoadClassCategory.LIMITED_ACCESS],
          },
        ];
  }, [roadSegmentIds, roadSegmentIdsByRoadClass, isAnalysis, selectLinkAnalysisMode]);

  const availableRange = useMemo(() => {
    if (isAnalysis && selectLinkAnalysisMode === SelectLinkAnalysisMode.Results) return selectLinkAvailableRange;
    if (selectedZone && ODMeasureRangeByZone) return ODMeasureRangeByZone;
    if (isOD && ODMeasureRange.data && zoningLevel) return ODMeasureRange.data?.[zoningLevel];
    if (isDataset && datasetMeasureRange.data && zoningLevel) return datasetMeasureRange.data?.[zoningLevel];
    if (isRoads && roadsMeasureRange.data) return roadsMeasureRange.data;
    return null;
  }, [
    isOD,
    ODMeasureRange.data,
    isDataset,
    datasetMeasureRange.data,
    isRoads,
    roadsMeasureRange.data,
    zoningLevel,
    selectedZone,
    ODMeasureRangeByZone,
    selectLinkAvailableRange,
    selectLinkAnalysisMode,
    isAnalysis,
  ]);

  const isZoningLevelBlocked: boolean = useMemo(
    () => (ODRange || datasetRange || selectedZone ? true : false),
    [ODRange, datasetRange, selectedZone],
  );

  useEffect(() => {
    const zoom = map?.getZoom();
    if (isZoningLevelBlocked && zoom !== undefined) {
      setBlockedZoomLevel(zoom);
    } else {
      setBlockedZoomLevel(null);
    }
  }, [map, isZoningLevelBlocked]);

  const setRoadsFilters = useCallback(
    (featureIds: { [key: string]: boolean }) => {
      if (map && roadsMetadata.data) {
        roadsLayersWithSegmentsId.forEach((layer) => {
          if (map.getLayer(layer.id))
            map.setFilter(layer.id, [
              "all",
              ["in", roadsMetadata.data?.tileService.facilityTypeField, ...activeRoadClasses],
              [
                "in",
                roadsMetadata.data?.tileService.fromToSegmentIdField,
                ...layer.segments.filter((segment) => featureIds[segment]),
              ],
            ]);
        });
      }
    },
    [map, roadsMetadata.data, roadsLayersWithSegmentsId, activeRoadClasses],
  );

  const unsetRoadsFilters = useCallback(() => {
    if (map && roadsMetadata.data) {
      roadsLayersWithSegmentsId.forEach((layer) => {
        if (map.getLayer(layer.id))
          map.setFilter(layer.id, [
            "all",
            [
              "in",
              roadsMetadata.data?.tileService.facilityTypeField,
              ...Object.values(roadClasses.items).map(({ value }) => Number(value)),
            ],

            ["in", roadsMetadata.data?.tileService.fromToSegmentIdField, ...layer.segments],
          ]);
      });
    }
  }, [map, roadsLayersWithSegmentsId, roadsMetadata.data, roadClasses.items]);

  const filterSegments = useCallback(() => {
    if (map && roadsVolumes.data && roadsMetadata.data && measureRange && availableRange && roadSegmentIds.data) {
      if (showRoadVolumes) {
        const featureIds: { [key: string]: boolean } = {};
        const volumes =
          isAnalysis && selectLinkAnalysisMode === SelectLinkAnalysisMode.Results
            ? selectLinkSegmentCounts.data?.segmentVolumes
            : roadsVolumes.data?.segmentVolumes;
        const [minRange, maxRange] = measureRange;

        if (volumes && volumes.size > 0) {
          volumes.forEach((value, volumeSegmentId) => {
            const reverseSegmentId = (roadSegmentIds.data?.[volumeSegmentId] || reverseRoadSegmentIds[volumeSegmentId])
              ?.reverseSegmentId;
            const reverseSegmentValue = reverseSegmentId ? volumes.get(reverseSegmentId) : null;

            if (
              (value >= minRange && value <= maxRange) ||
              (reverseSegmentValue && reverseSegmentValue >= minRange && reverseSegmentValue <= maxRange)
            ) {
              const reverseVolumeSegmentId = reverseRoadSegmentIds[volumeSegmentId]?.reverseSegmentId;

              featureIds[reverseVolumeSegmentId ?? volumeSegmentId] = true;
            }
          });
        }

        setRoadsFilters(featureIds);
      } else {
        unsetRoadsFilters();
      }
    }
  }, [
    map,
    measureRange,
    availableRange,
    roadsMetadata.data,
    roadsVolumes.data,
    roadSegmentIds.data,
    reverseRoadSegmentIds,
    isAnalysis,
    selectLinkAnalysisMode,
    selectLinkSegmentCounts.data,
    showRoadVolumes,
    setRoadsFilters,
    unsetRoadsFilters,
  ]);

  const getODLayer = useCallback(() => {
    const metadata = isOD ? ODMetadata.data : datasetMetadata.data;
    if (metadata) {
      return getLayerFromZoom(
        isZoningLevelBlocked ? (blockedZoomLevel as number) : map?.getZoom(),
        metadata.tileService.layers,
      );
    }
  }, [ODMetadata.data, datasetMetadata.data, isOD, map, blockedZoomLevel, isZoningLevelBlocked]);

  const getODData = useCallback(() => {
    if (isOD) {
      return selectedZone ? ODCountsByZone.data?.counts.zones : ODCounts.data?.zones;
    }
    if (isDataset) {
      return selectedZone
        ? { ...datasetCountsByZone.data?.counts.zones, ...datasetCountsByZone.data?.counts.gates }
        : { ...datasetCounts.data?.zones, ...datasetCounts.data?.gates };
    }
  }, [ODCounts.data, ODCountsByZone.data, datasetCounts.data, datasetCountsByZone.data, isDataset, isOD, selectedZone]);

  const setOdFilters = useCallback(
    (featureIds: string[], layer: ODTileLayer) => {
      if (map) {
        if (map.getLayer(layer.name)) map.setFilter(layer.name, ["in", layer.idField, ...featureIds]);

        if (isDataset) map.setFilter(GATES_LAYER_ID, ["in", "identifier", ...featureIds]);
      }
    },
    [map, isDataset],
  );

  const unsetODFilters = useCallback(
    (layer: ODTileLayer) => {
      if (map && zoningLevel) {
        const ids = isDataset
          ? Object.keys(datasetIds.data?.[zoningLevel] || {})
          : Object.keys(ODZoneIds.data?.[zoningLevel] || {});
        if (map.getLayer(layer.name)) map.setFilter(layer.name, ["in", layer.idField, ...ids]);

        if (isDataset && map.getLayer(GATES_LAYER_ID))
          map.setFilter(GATES_LAYER_ID, ["in", "identifier", ...(gatesIds || [])]);
      }
    },
    [map, ODZoneIds.data, datasetIds.data, zoningLevel, isDataset, gatesIds],
  );

  const filterZones = useCallback(() => {
    const data = getODData();
    const layer = getODLayer();

    if (map && data && measureRange && availableRange) {
      if (layer) {
        if ((measureRange[0] > availableRange.min || measureRange[1] < availableRange.max) && showZones) {
          const featureIds: any = [];

          Object.entries(data).forEach(([key, value]) => {
            if (value >= measureRange[0] && value <= measureRange[1]) {
              featureIds.push(key);
            }
          });
          setOdFilters(featureIds, layer);
        } else {
          unsetODFilters(layer);
        }
      }
    }
  }, [getODData, getODLayer, map, measureRange, showZones, availableRange, setOdFilters, unsetODFilters]);

  //TODO move this logic in a separate component (RoadClassesSelector)
  const handleMultiSelectAll = (isChecked: boolean) => () => {
    if (roadClasses) {
      setRoadClasses({
        items: Object.entries(roadClasses.items).reduce((newItems, [itemKey, itemValue]) => {
          newItems[itemKey] = {
            ...itemValue,
            isChecked: isChecked,
          };
          return newItems;
        }, {} as RoadClassItems),
      });
    }
  };

  const handleMultiSelectChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (roadClasses) {
      const namesArr = event.target.name.split("-");
      const itemName = namesArr[1];

      setRoadClasses({
        items: {
          ...roadClasses.items,
          [itemName]: {
            ...roadClasses.items[itemName],
            isChecked: !roadClasses.items[itemName].isChecked,
          },
        },
      });
    }
  };

  useEffect(() => {
    if (roadsMetadata.state === DataState.AVAILABLE) {
      const datasetRoadClasses = roadsMetadata.data?.roadClasses
        .map((roadClass) => ({
          value: roadClass.id.toString(),
          label: roadClass.label,
          isChecked: true,
        }))
        .reduce((map, roadClass) => {
          return {
            ...map,
            [roadClass.value]: roadClass,
          };
        }, {});
      setRoadClasses({
        items: datasetRoadClasses,
      });
    }
  }, [roadsMetadata.state, roadsMetadata.data?.roadClasses]);

  useEffect(() => {
    if (previousActiveRoadClasses && previousActiveRoadClasses !== activeRoadClasses) filterSegments();
  }, [activeRoadClasses, previousActiveRoadClasses, filterSegments]);

  return (
    <>
      {isRoads && (
        <CheckboxDropdown
          isGroupChecked={false}
          groupName="Road Classes"
          groupLabel="Road Classes"
          groupIcon={IconNames.DRIVE_TIME}
          items={roadClasses.items}
          placement="top"
          error={false}
          disabled={!showRoadVolumes}
          onChange={handleMultiSelectChange}
          selectAll={handleMultiSelectAll(true)}
          clearAll={handleMultiSelectAll(false)}
        />
      )}
      {isAnalysis && isOD ? null : ( //TODO temporarily hiding range filters for select link in OD mode
        <RangeFilter
          mapLoaded={mapLoaded}
          showZones={showZones}
          showRoadVolumes={showRoadVolumes}
          colorScheme={colorScheme}
          loading={loading}
          zoningLevel={zoningLevel}
          selectedZone={selectedZone}
          isOD={Boolean(isOD)}
          isDataset={Boolean(isDataset)}
          isRoads={Boolean(isRoads)}
          measureRange={measureRange}
          availableRange={availableRange}
          disabled={Boolean(disabled)}
          setMeasureRange={setMeasureRange}
          filterSegments={filterSegments}
          filterZones={filterZones}
        />
      )}
    </>
  );
};
