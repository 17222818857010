import styled from "@emotion/styled";
import React, { FC } from "react";
import ReactPlayer from "react-player/vimeo";

import { usePageTracking } from "hooks";

import { PageContent } from "./PageContent";
import { PageHeader } from "./PageHeader";

const WebinarsContainer = styled(PageContent)`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

const VideoContainer = styled.div`
  margin: 1rem 1rem 4rem 1rem;
  width: 512px;
  height: 288px;
`;

const VideoTitle = styled.h3`
  margin: 1rem 0 0 1.5rem;
`;

const videos = [
  {
    title: "Extract Special Generator Trip Tables",
    // File name: Meet Up 2023 11 08 (Public)
    url: "https://vimeo.com/882667157",
  },
  {
    title: "Methods and Validations",
    url: "https://vimeo.com/894266253",
  },
  {
    title: "2024 Features Announcement",
    url: "https://vimeo.com/903939409",
  },
  {
    title: "Corridor Discovery",
    url: "https://vimeo.com/922156623",
  },
  {
    title: "Select Link",
    url: "https://vimeo.com/920199575",
  },
];

export const Webinars: FC = () => {
  usePageTracking();

  return (
    <>
      <PageHeader title="Teralytics Studio: Webinars" subtitle="Learn more about Teralytics Studio" />
      <WebinarsContainer>
        {videos.map(({ title, url }, index) => (
          <VideoContainer key={index}>
            <ReactPlayer title={title} url={url} controls width="100%" height="100%" />
            <VideoTitle>{title}</VideoTitle>
          </VideoContainer>
        ))}
      </WebinarsContainer>
    </>
  );
};
