export const themeColors = {
  primary: "#111827",
  primaryLight: "#139eec",
  secondary: "#3b82f6",
  secondaryLight: "#eff6ff",
  text: "#1d1e22",
  textSecondary: "#6b7280",
  textLight: "#bababa",
  textContrast: "#ffffff",
  border: "#e2e1ec",
  warningStatus: "#f2994b",

  /* Color gray */
  gray100: "#f9fafb",
  gray300: "#d1d5db",
  gray600: "#4b5563",

  /* Color red */
  red300: "#fca5a5",
  red400: "#f87171",
  red800: "#991b1b",
  redRose: "f43f5e",
};

export const themePaddings = {
  xs: "0.25rem",
  sm: "0.5rem",
  md: "0.75rem",
  lg: "1rem",
  xl: "1.25rem",
  xxl: "1.5rem",
};
