import styled from "@emotion/styled";
import { InputGroup as BpInputGroup, InputGroupProps as BpInputProps, TextArea as BpTextArea } from "@blueprintjs/core";

export type InputColor = "gray" | "secondary";

export interface InputProps extends BpInputProps {
  error?: boolean;
  color?: InputColor;
}

export const Input = styled(BpInputGroup, {
  shouldForwardProp: (prop) => prop !== "error" && prop !== "color",
})<InputProps>(({ error, color = "gray" }) => ({
  "& .bp4-input": {
    height: "38px",
    cursor: "pointer",
    fontWeight: 500,
    boxShadow: color === "gray" ? "var(--box-shadow-xs)" : "none",
    border: error
      ? "1px solid red"
      : color === "gray"
      ? `1px solid var(--color-gray-300)`
      : "1px solid var(--color-secondary)",
    borderRadius: "6px",
    color: error ? "red" : color === "gray" ? "var(--color-gray-700)" : "var(--color-primary)",
    backgroundColor: color === "gray" || error ? "#ffffff" : "var(--color-secondaryLight)",
  },

  "& .bp4-input:disabled": {
    backgroundColor: "var(--color-gray-100) ",
    borderColor: "var(--color-border) ",
    cursor: "not-allowed",
  },

  "& .bp4-input:not(:first-of-type)": {
    paddingLeft: "35px",
  },

  "& .bp4-icon > svg:not([fill])": {
    fill: error ? "red" : color === "gray" ? "var(--color-gray-500)" : "var(--color-primary)",
  },

  "& .bp4-icon": {
    padding: "4px",
  },

  "& .bp4-input-action": {
    padding: "4px",
  },

  "& .bp4-button:hover": {
    background: "none",
  },
}));

export const TextArea = styled(BpTextArea)({
  "&.bp4-input": {
    cursor: "pointer",
    fontWeight: 500,
    boxShadow: "var(--box-shadow-xs)",
    border: "1px solid var(--color-gray-300)",
    borderRadius: "6px",
    color: "var(--color-primary)",
    backgroundColor: "#ffffff",
  },

  "&.bp4-input:disabled": {
    backgroundColor: "var(--color-gray-200) ",
    borderColor: "var(--color-gray-500) ",
    cursor: "not-allowed",
  },
});
