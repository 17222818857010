import { FC } from "react";

import { BluePoint, BottomBorderRow, HeadCellAlignCenter, Table, TableHeadRow } from "./Table";

export const LicenseDataTable: FC = () => {
  return (
    <Table compact bordered>
      <thead>
        <BottomBorderRow>
          <th rowSpan={2} style={{ borderTopLeftRadius: "8px" }}>
            Data Content
          </th>
          <HeadCellAlignCenter colSpan={2}>Traffic</HeadCellAlignCenter>
          <HeadCellAlignCenter colSpan={4} style={{ borderTopRightRadius: "8px" }}>
            Traffic & Patterns
          </HeadCellAlignCenter>
        </BottomBorderRow>
        <BottomBorderRow>
          <HeadCellAlignCenter style={{ borderLeft: "1px solid rgba(17, 20, 24, 0.15)" }}>
            Essentials
          </HeadCellAlignCenter>
          <HeadCellAlignCenter>All Access</HeadCellAlignCenter>
          <HeadCellAlignCenter>Essentials</HeadCellAlignCenter>
          <HeadCellAlignCenter>Essentials+</HeadCellAlignCenter>
          <HeadCellAlignCenter>Advanced</HeadCellAlignCenter>
          <HeadCellAlignCenter>All Access</HeadCellAlignCenter>
        </BottomBorderRow>
        <TableHeadRow>
          <th>Subscription Length</th>
          <HeadCellAlignCenter>1 year</HeadCellAlignCenter>
          <HeadCellAlignCenter>1 year</HeadCellAlignCenter>
          <HeadCellAlignCenter>1 year</HeadCellAlignCenter>
          <HeadCellAlignCenter>1 year</HeadCellAlignCenter>
          <HeadCellAlignCenter>1 year</HeadCellAlignCenter>
          <HeadCellAlignCenter>1 year</HeadCellAlignCenter>
        </TableHeadRow>
      </thead>
      <tbody>
        <tr>
          <th colSpan={7}>&nbsp;</th>
        </tr>
      </tbody>
      <thead>
        <tr>
          <th colSpan={7}>AADT Volumes</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>All Roads AADT by Main Purpose (HBW, HBO, NHB)</td>
          <td>
            <BluePoint />
          </td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>
            <BluePoint />
          </td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
        </tr>
        <tr>
          <td>Major Roads by Hour (midnight - 1am, …) & Main Purpose (HBW, HBO, NHB)</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>
            <BluePoint />
          </td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
        </tr>
        <tr>
          <td>All Roads by Hour (midnight - 1am, …) & Detailed Purpose (H-W, W-H, W-O, O-W, O-H, H-O, NHB)</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>
            <BluePoint />
          </td>
          <td>&nbsp;</td>
        </tr>
        <BottomBorderRow>
          <td>
            All Roads by Hour (midnight - 1am, …) & Day Type (Monday - Thursday, Friday, Saturday, Sunday) & Detailed
            Purpose (H-W, W-H, W-O, O-W, O-H, H-O, NHB)
          </td>
          <td>&nbsp;</td>
          <td>
            <BluePoint />
          </td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>
            <BluePoint />
          </td>
        </BottomBorderRow>
        <tr>
          <th colSpan={7}>&nbsp;</th>
        </tr>
      </tbody>

      <thead>
        <tr>
          <th colSpan={7}>AADT & Road Network Export</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>AADT & Routable OpenStreetMap Network </td>
          <td>&nbsp;</td>
          <td>
            <BluePoint />
          </td>
          <td>
            <BluePoint />
          </td>
          <td>
            <BluePoint />
          </td>
          <td>
            <BluePoint />
          </td>
          <td>
            <BluePoint />
          </td>
        </tr>
        <BottomBorderRow>
          <td>
            AADT & Routable OpenStreetMap Network with modeling attributes (Lanes, Capacity, Speed, Facility Type, Area
            Type){" "}
          </td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>
            <BluePoint />
          </td>
        </BottomBorderRow>
        <tr>
          <th colSpan={7}>&nbsp;</th>
        </tr>
      </tbody>
      <thead>
        <tr>
          <th colSpan={7}>AADT Origin-Destinations</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Census Tract by Main Purpose (HBO, HBW, NHB)</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>
            <BluePoint />
          </td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
        </tr>
        <tr>
          <td>Block Group by Main Purpose (HBO, HBW, NHB)</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>
            <BluePoint />
          </td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
        </tr>
        <tr>
          <td>
            Block Group by Part of Day (midnight - 6am, 6am - 10am, 10am - 3pm, 3pm - 7pm, 7pm - midnight) and detailed
            purpose (H-W, W-H, W-O, O-W, O-H, H-O, NHB)
          </td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>
            <BluePoint />
          </td>
          <td>&nbsp;</td>
        </tr>
        <BottomBorderRow>
          <td>
            Block Group by Part of Day (midnight - 6am, 6am - 10am, 10am - 3pm, 3pm - 7pm, 7pm - midnight), Day Type
            (Monday - Thursday, Friday, Saturday, Sunday), Detailed Purpose (H-W, W-H, W-O, O-W, O-H, H-O, NHB)
          </td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>
            <BluePoint />
          </td>
        </BottomBorderRow>
        <tr>
          <th colSpan={7}>&nbsp;</th>
        </tr>
      </tbody>
      <thead>
        <tr>
          <th colSpan={7}>Origin-Destination Export </th>
        </tr>
      </thead>
      <tbody>
        <BottomBorderRow>
          <td>OD matrix export</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>
            <BluePoint />
          </td>
          <td>
            <BluePoint />
          </td>
          <td>
            <BluePoint />
          </td>
          <td>
            <BluePoint />
          </td>
        </BottomBorderRow>
        <tr>
          <th colSpan={7}>&nbsp;</th>
        </tr>
      </tbody>
    </Table>
  );
};
