import styled from "@emotion/styled";

import { Card } from "components";

export const LeftSidebar = styled(Card)`
  min-width: var(--sidebar-width);
  max-width: var(--sidebar-width);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  border-radius: 16px;
  margin: 1rem;
  padding: var(--padding-xl);
`;
