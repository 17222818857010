import { useAuth0 } from "@auth0/auth0-react";
import styled from "@emotion/styled";
import { Close } from "@mui/icons-material";
import { Box, Collapse, Grid, IconButton, Typography } from "@mui/material";
import background from "assets/svg/dashboard/image.svg";
import React, { FC, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";

import { BookTraining } from "features/dashboard/BookTraining";
import { FeatureHighlightItem } from "features/dashboard/FeatureHighlight";
import { FocusAreaPreview } from "features/dashboard/FocusAreaPreview";
import { LearnMoreCard } from "features/dashboard/LearnModeCard";
import { MeetUsEvent } from "features/dashboard/MeetUsEvent";

import { HighligthPageContainer } from "components/atoms/page-container/HighlightPageContainer";
import { PageContainerPanel } from "components/atoms/page-container/PageContainerPanel";
import Carousel from "components/molecules/carousel/Carrousel";

import { useAppDispatch, useAppSelector, usePageTracking } from "hooks";

import { selectAtLeastOneRoadVmtDataPermission } from "store/permissionsSelectors";
import { analyticsActions } from "store/sections/analytics";

import { addCustomGAEvent } from "utils/addCustomGAEvent";

import { getFeatureHighlightsPanel, learnMorePanel, meetUsPanel } from "./DashboardConfigs";

const DashboardPageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: grid;
  margin-top: 2rem;
  grid-template-columns: 1fr 460px;
  grid-auto-rows: min-content;
  gap: 2rem;
  row-gap: 3rem;
`;

const LearnMoreContiner = styled(Box)`
  display: grid;
  height: 100%;
  grid-template-columns: repeat(auto-fit, minmax(11rem, 1fr));
  gap: 1rem;
`;

const MeetUsContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  gap: 1.5rem;
`;

export const DashboardPage: FC = () => {
  const dispatch = useAppDispatch();
  const { user } = useAuth0();
  const userOrganizationName = useAppSelector((state) => state.license.user.data?.organization?.name);

  const [isAnnouncementOpen, setIsAnnouncementOpen] = useState(true);
  const isTokenLoaded = useAppSelector((state) => state.analytics.authorizationTokenLoaded);

  const userMe = useAppSelector((state) => state.license.user);
  const licenseTypes = useAppSelector((state) => state.license.licenseTypes);
  const isRoadVmtAllowed = useAppSelector(selectAtLeastOneRoadVmtDataPermission);

  const featureHighlights = useMemo(() => getFeatureHighlightsPanel(isRoadVmtAllowed), [isRoadVmtAllowed]);

  const permissions = useMemo(
    () =>
      licenseTypes.data?.find(({ id }) => id === userMe.data?.softwareLicense.softwareLicenseType.id)
        ?.softwarePermissions || null,

    [userMe, licenseTypes],
  );

  const dashboardTriggerGAEvent = (event: string) => {
    addCustomGAEvent("dashboard", "click", event, user, userOrganizationName);
  };

  const handleCloseAnnouncement = () => {
    setIsAnnouncementOpen(false);
    localStorage.setItem("isAnnouncementSeen", "true");
  };

  usePageTracking();

  useEffect(() => {
    if (isTokenLoaded) {
      dispatch(analyticsActions.fetchFocusAreasAndDatasets());
    }
  }, [isTokenLoaded, dispatch]);

  return (
    <HighligthPageContainer>
      {permissions?.zoning?.allowZoningUpload.Allowed && !localStorage.getItem("isAnnouncementSeen") && (
        <Collapse in={isAnnouncementOpen}>
          <Grid
            container
            columnSpacing={1}
            justifyContent={"space-between"}
            alignItems={"center"}
            mb={-2}
            pl={2}
            py={1}
            sx={{ backgroundColor: "secondary.main" }}
          >
            <Grid item>
              <p style={{ display: "inline" }}>&#128640;</p>
            </Grid>
            <Grid item xs>
              <Typography fontSize={14} color={"white"}>
                <b> Announcing Custom Zones: </b> The power of Teralytics Studio has been greatly expanded by supporting
                custom zones. You can now upload your own zoning system and calculate a dataset with it. Give it a try
                in the <Link to="/datasets">Datasets</Link> tab
              </Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={handleCloseAnnouncement}>
                <Close fontSize="small" />
              </IconButton>
            </Grid>
          </Grid>
        </Collapse>
      )}
      <DashboardPageContainer>
        <PageContainerPanel title={featureHighlights.title} titleColor="white" backgroundImage={`url(${background})`}>
          <Carousel>
            {featureHighlights.carousel
              .filter((item) => item.enabled)
              .map((item, index) => (
                <FeatureHighlightItem
                  key={`carousel-slide-${index}`}
                  item={item}
                  triggerGAEvent={dashboardTriggerGAEvent}
                />
              ))}
          </Carousel>
        </PageContainerPanel>

        <PageContainerPanel title="Focus area" titleColor="white" compact={true}>
          <FocusAreaPreview triggerGAEvent={dashboardTriggerGAEvent} />
        </PageContainerPanel>

        <PageContainerPanel title={learnMorePanel.title} compact={true}>
          <LearnMoreContiner>
            {learnMorePanel.cards.map((item, index) => (
              <LearnMoreCard key={`learn-more-${index}`} item={item} triggerGAEvent={dashboardTriggerGAEvent} />
            ))}
          </LearnMoreContiner>
        </PageContainerPanel>

        <PageContainerPanel title={meetUsPanel.title} compact={true} noRoundCorners={true}>
          <MeetUsContainer>
            {meetUsPanel.events.map((item, idx) => (
              <MeetUsEvent key={`events-${idx}`} item={item} triggerGAEvent={dashboardTriggerGAEvent}></MeetUsEvent>
            ))}
            <BookTraining item={meetUsPanel.gettingTrained} triggerGAEvent={dashboardTriggerGAEvent} />
          </MeetUsContainer>
        </PageContainerPanel>
      </DashboardPageContainer>
    </HighligthPageContainer>
  );
};
