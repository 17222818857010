import React, { FC } from "react";
import styled from "@emotion/styled";
import { Menu as BpMenu } from "@blueprintjs/core";

import { MenuItem, MenuItemProps } from "components";

interface Props {
  menuItems: MenuItemProps[];
}

const StyledMenu = styled(BpMenu)`
  border-radius: 4px;
  box-shadow: var(--box-shadow-md);
`;

export const Menu: FC<Props> = ({ menuItems }) => (
  <StyledMenu>
    {menuItems.map(({ children, ...props }, i) => (
      <MenuItem key={i} {...props}>
        {children}
      </MenuItem>
    ))}
  </StyledMenu>
);
