import styled from "@emotion/styled";
import React, { FC } from "react";

import { Button, ButtonVariant } from "components";

export interface ToggleButtonsProps {
  leftButtonLabel: string;
  rightButtonLabel: string;
  leftButtonDisabled?: boolean;
  rightButtonDisabled?: boolean;
  activeIndex: string;
  leftButtonIndex: string;
  rightButtonIndex: string;
  leftButtonVariant?: ButtonVariant;
  rightButtonVariant?: ButtonVariant;
  isMapToggle?: boolean;
  onChangeIndex: (activeIndex: string) => void;
}

const ToggleButtonsContainer = styled.div`
  border: 1px solid var(--color-gray-300);
  border-radius: 8px;
  padding: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
`;

const MapToggleButtonsContainer = styled.div`
  position: absolute;
  top: 18px;
  left: 50%;
  transform: translate(0%, -50%);
  border: 0;
  border-radius: 0;
  padding: 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0;
  z-index: 3;
  margin-top: 1rem;
`;

export const ToggleButtons: FC<ToggleButtonsProps> = ({
  leftButtonLabel,
  rightButtonLabel,
  leftButtonDisabled,
  rightButtonDisabled,
  activeIndex,
  onChangeIndex,
  leftButtonIndex,
  rightButtonIndex,
  leftButtonVariant,
  rightButtonVariant,
  isMapToggle,
}) => {
  const handleLeftButtonClick = () => {
    onChangeIndex(leftButtonIndex);
  };

  const handleRightButtonClick = () => {
    onChangeIndex(rightButtonIndex);
  };

  const buttons = (
    <>
      <Button
        size="sm"
        variant={leftButtonVariant}
        disabled={leftButtonDisabled}
        color={activeIndex === "0" ? "secondary" : "white"}
        fullWidth
        onClick={handleLeftButtonClick}
      >
        {leftButtonLabel}
      </Button>
      <Button
        size="sm"
        variant={rightButtonVariant}
        disabled={rightButtonDisabled}
        color={activeIndex === "1" ? "secondary" : "white"}
        fullWidth
        onClick={handleRightButtonClick}
      >
        {rightButtonLabel}
      </Button>
    </>
  );

  return (
    <>
      {isMapToggle && <MapToggleButtonsContainer>{buttons}</MapToggleButtonsContainer>}
      {!isMapToggle && <ToggleButtonsContainer>{buttons}</ToggleButtonsContainer>}
    </>
  );
};
