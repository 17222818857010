import React, { FC } from "react";
import styled from "@emotion/styled";
import { ItemRenderer, Select2 as Select } from "@blueprintjs/select";

import { DividerWithText, MenuItem, SelectInput } from "components";
import { MAPBOX_STYLE_OPTIONS } from "features/map-visualization";
import { IconName } from "@blueprintjs/core";

export interface BaseMapStylesProps {
  baseMapStyle: any;
  onChangeStyle: (style: any) => void;
}

const BaseMapStylesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const BaseMapStyles: FC<BaseMapStylesProps> = ({ baseMapStyle, onChangeStyle }) => {
  const renderThemeItem: ItemRenderer<any> = (
    item: { label: string; value: any; icon: IconName },
    { handleClick, modifiers },
  ) => {
    if (!modifiers.matchesPredicate) {
      return null;
    }

    return (
      <MenuItem
        text={item.label}
        roleStructure="listoption"
        active={modifiers.active}
        key={item.label}
        onClick={handleClick}
        icon={item.icon}
      />
    );
  };
  const activeItem = MAPBOX_STYLE_OPTIONS.find((item) => item.value === baseMapStyle);

  return (
    <BaseMapStylesWrapper>
      <DividerWithText>Base Map Styles</DividerWithText>
      <Select
        items={MAPBOX_STYLE_OPTIONS}
        activeItem={activeItem}
        itemRenderer={renderThemeItem}
        filterable={false}
        onItemSelect={(theme) => {
          onChangeStyle(theme.value);
        }}
      >
        <SelectInput value={activeItem?.label} icon={activeItem?.icon as IconName} />
      </Select>
    </BaseMapStylesWrapper>
  );
};
