import { FC } from "react";

import { SubTitle, Description } from "./Table";
import { LicenseDataTable } from "./LicenseDataTable";
import { LicenseSoftwareTable } from "./LicenseSoftwareTable";

export const LicenseTier: FC = () => (
  <>
    <SubTitle>License Tiers - Data</SubTitle>
    <Description>
      Studio is offered in the following different data license tiers, differentiated by the depth and richness of the
      available data.
    </Description>
    <LicenseDataTable />

    <SubTitle>License Tiers - Software</SubTitle>
    <Description>
      Studio can be licensed at two different software license tiers. Studio is offering viewing-only capabilities,
      Studio Pro offers viewing, dataset creation and exporting capabilities.{" "}
    </Description>
    <LicenseSoftwareTable />
  </>
);
