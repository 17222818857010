import styled from "@emotion/styled";
import { Map } from "mapbox-gl";
import { FC, useMemo } from "react";

import { sequentialSchemes } from "features/map-visualization";

import { DividerWithText, Switch } from "components";

import { useAppSelector } from "hooks";

import { MapVisualizationMode, MapVisualizationType, SelectedArea } from "types";

import ColorSchemeSelector from "./ColorSchemeSelector";
import { MapLayersFilters } from "./MapLayersFilters";

export interface MapLayersProps {
  map: Map | null;
  mapLoaded: boolean;
  mode: MapVisualizationMode | null;
  showZones: boolean;
  showRoadVolumes: boolean;
  colorScheme: string;
  disabled?: boolean;
  loading: boolean;
  zoningLevel: string | undefined;
  selectedZone: SelectedArea | null;
  isAnalysis?: boolean;
  isAnalysisResults?: boolean;
  changeShowRoadVolumes: () => void;
  changeShowZones: () => void;
  changeColorScheme: (scheme: string) => void;
}

const MapLayersWrapper = styled.div`
  margin-top: 1em;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const MapLayers: FC<MapLayersProps> = ({
  map,
  mapLoaded,
  mode,
  showZones,
  showRoadVolumes,
  colorScheme,
  disabled,
  loading,
  zoningLevel,
  selectedZone,
  isAnalysis,
  isAnalysisResults,
  changeShowRoadVolumes,
  changeShowZones,
  changeColorScheme,
}) => {
  const selectedFocusArea = useAppSelector((state) => state.global.selectedFocusArea);

  const isOD = useMemo(
    () => mode === MapVisualizationType.OD && selectedFocusArea && !selectedFocusArea?.datasetId,
    [mode, selectedFocusArea],
  );
  const isDataset = useMemo(
    () => mode === MapVisualizationType.OD && selectedFocusArea?.datasetId,
    [mode, selectedFocusArea?.datasetId],
  );
  const isRoads = useMemo(() => mode === MapVisualizationType.ROADS, [mode]);

  return (
    <MapLayersWrapper>
      <DividerWithText>Map Layers</DividerWithText>
      {(isOD || isDataset) && (
        <>
          <Switch
            checked={showZones}
            label="OD Counts"
            style={{ margin: 0 }}
            onChange={changeShowZones}
            disabled={disabled}
          />
          <ColorSchemeSelector
            availableSchemes={sequentialSchemes}
            selectedColorScheme={colorScheme}
            onChange={changeColorScheme}
          />
        </>
      )}
      {isRoads && (
        <Switch
          label="Volumes"
          style={{ margin: "0 0 8px 0" }}
          checked={showRoadVolumes}
          onChange={changeShowRoadVolumes}
          disabled={disabled}
        />
      )}
      <MapLayersFilters
        map={map}
        mapLoaded={mapLoaded}
        showZones={showZones}
        showRoadVolumes={showRoadVolumes}
        colorScheme={colorScheme}
        loading={loading}
        zoningLevel={zoningLevel}
        selectedZone={selectedZone}
        isOD={Boolean(isOD)}
        isDataset={Boolean(isDataset)}
        isRoads={Boolean(isRoads)}
        isAnalysis={isAnalysis}
        disabled={isAnalysisResults}
      />
    </MapLayersWrapper>
  );
};
