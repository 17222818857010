import throttle from "lodash/throttle";
import { EventData, LngLat, MapDataEvent, MapMouseEvent, MapboxGeoJSONFeature, Popup } from "mapbox-gl";
import { Dispatch, MutableRefObject, RefObject, SetStateAction } from "react";

import { getUniqueFeatures } from "features/map-visualization";

import { CorridorEdge, HighlightedFeature } from "types";

import { CORRIDOR_EDGE_LAYER_ID, CORRIDOR_NODE_LAYER_ID } from "./layers";
import { CORRIDOR_EDGE_SOURCE_ID, CORRIDOR_NODE_SOURCE_ID } from "./sources";

const getMetersPerPixelAtLatitude = (map: mapboxgl.Map) => {
  const { lat } = map.getCenter();
  const zoom = map.getZoom();

  return (40075016.686 * Math.abs(Math.cos((lat * Math.PI) / 180))) / Math.pow(2, zoom + 8);
};

export const getCorridorHandlers = (
  mapRef: any,
  corridorLevelsRef: any,
  volumesRef: any,
  maxVolumeRef: any,
  corridorLevelRef: any,
  nodeCountsRef: any,
  nodeMaxCountRef: any,
  edgePopupRef: any,
  setEdgePopupRef: any,
  mapboxEdgeCountsHoverPopupRef: any,
  closeEdgeAnalyticsPanelRef: any,
  overlayPopupRef: MutableRefObject<HTMLDivElement | null>,
  setOverlayPopupRef: RefObject<Dispatch<SetStateAction<any | null>>>,
  overlayLayerIds: MutableRefObject<string[]>,
  setSelectedEdge: (edge: CorridorEdge | null) => void,
) => {
  const edgeFeatureStateSet = new Set<string>();
  const nodeFeatureStateSet = new Set<string>();
  let zoom = mapRef.current.getZoom();
  let hoveredEdge: null | MapboxGeoJSONFeature = null;
  let selectedEdge: null | MapboxGeoJSONFeature = null;
  let filteredEdgeIds: number[] = [];

  //Overlays

  let hoveredFeatures: HighlightedFeature[] | null = null;
  let selectedFeatures: HighlightedFeature[] | null = null;
  let overlayPopup: Popup | null = null;

  closeEdgeAnalyticsPanelRef.current = () => {
    if (selectedEdge !== null) {
      mapRef.current.setFeatureState(
        {
          source: `${CORRIDOR_EDGE_SOURCE_ID}_${corridorLevelRef.current}`,
          sourceLayer: corridorLevelsRef.current[corridorLevelRef.current].edgeTileServiceLayer.name,
          id: selectedEdge.id,
        },
        { selected: false },
      );

      selectedEdge = null;
      setSelectedEdge(null);
    }
  };

  const updateFeatureStatesByCounts = (isZoomChanged: boolean) => {
    if (!mapRef.current || !corridorLevelsRef.current || !volumesRef.current || !corridorLevelRef.current) return;

    const layerName = corridorLevelsRef.current[corridorLevelRef.current].edgeTileServiceLayer.name;

    const visibleFeatures = getUniqueFeatures(
      mapRef.current.queryRenderedFeatures(undefined, {
        layers: [`${CORRIDOR_EDGE_LAYER_ID}_${corridorLevelRef.current}`],
      }),
    ).filter((f) => {
      if (
        filteredEdgeIds.length > 0 &&
        !filteredEdgeIds.includes(Number(f.id)) &&
        !filteredEdgeIds.includes(-Number(f.id))
      ) {
        return false;
      }

      if (isZoomChanged) {
        return true;
      }

      const states = mapRef.current.getFeatureState({
        source: `${CORRIDOR_EDGE_SOURCE_ID}_${corridorLevelRef.current}`,
        sourceLayer: layerName,
        id: f.id,
      });

      if (typeof states?.volumeWeight === "number") {
        return false;
      }

      return true;
    });

    if (visibleFeatures.length === 0) {
      return;
    }

    const metersPerPixelAtLatitude = getMetersPerPixelAtLatitude(mapRef.current);
    const MIN_WIDTH_IN_PIXELS = 0.1;
    const MAX_WIDTH_IN_PIXELS = 1500;
    const features: any = {};
    const getWidthInPixelsByVolume = (volume: number) => {
      return (
        MIN_WIDTH_IN_PIXELS +
        ((volume / (maxVolumeRef.current * 2)) * (MAX_WIDTH_IN_PIXELS - MIN_WIDTH_IN_PIXELS)) / metersPerPixelAtLatitude
      );
    };

    visibleFeatures.forEach((feature: any) => {
      const segmentId = feature.id;
      const toFromSegmentId = -segmentId;

      edgeFeatureStateSet.add(segmentId);

      const fromToSegmentWidth =
        (volumesRef.current.get(segmentId) || 0) > 0 && filteredEdgeIds.includes(Number(segmentId))
          ? getWidthInPixelsByVolume(volumesRef.current.get(segmentId) || 0)
          : 0;
      const toFromSegmentWidth =
        (volumesRef.current.get(toFromSegmentId) || 0) > 0 && filteredEdgeIds.includes(toFromSegmentId)
          ? getWidthInPixelsByVolume(volumesRef.current.get(toFromSegmentId) || 0)
          : 0;

      const isDifferentNonZeroValues =
        (volumesRef.current.get(segmentId) || 0) > 0 && (volumesRef.current.get(toFromSegmentId) || 0) > 0;

      const sumVolumeWidth = fromToSegmentWidth + toFromSegmentWidth;
      const signFactor = fromToSegmentWidth - toFromSegmentWidth >= 0 ? 1 : -1;
      const offset =
        isDifferentNonZeroValues ||
        (filteredEdgeIds.includes(Number(segmentId)) && !filteredEdgeIds.includes(toFromSegmentId)) ||
        (!filteredEdgeIds.includes(Number(segmentId)) && filteredEdgeIds.includes(toFromSegmentId))
          ? Number((signFactor * (sumVolumeWidth / 2 - Math.min(fromToSegmentWidth, toFromSegmentWidth))).toFixed(1))
          : 0;

      features[segmentId] = {
        volumeOffset: offset,
        volumeWeight: sumVolumeWidth > 0 ? Number(sumVolumeWidth.toFixed(1)) : MIN_WIDTH_IN_PIXELS,
      };
    });

    Object.entries(features).forEach(([id, state]) => {
      const { volumeOffset, volumeWeight } = state as any;

      mapRef.current.setFeatureState(
        {
          source: `${CORRIDOR_EDGE_SOURCE_ID}_${corridorLevelRef.current}`,
          sourceLayer: layerName,
          id,
        },
        {
          volumeOffset,
          volumeWeight,
        },
      );
    });

    const edgeLineWidthPaintProperty = mapRef.current.getPaintProperty(
      `${CORRIDOR_EDGE_LAYER_ID}_${corridorLevelRef.current}`,
      "line-width",
    );

    if (edgeLineWidthPaintProperty === 0) {
      mapRef.current.setPaintProperty(`${CORRIDOR_EDGE_LAYER_ID}_${corridorLevelRef.current}`, "line-width", [
        "number",
        ["feature-state", "volumeWeight"],
        0,
      ]);
      mapRef.current.setPaintProperty(`${CORRIDOR_EDGE_LAYER_ID}_${corridorLevelRef.current}`, "line-offset", [
        "number",
        ["feature-state", "volumeOffset"],
        0,
      ]);
    }
  };

  const removeFeatureState = (id: string, featureName: string) => {
    const layerName = corridorLevelsRef.current[corridorLevelRef.current].edgeTileServiceLayer.name;

    mapRef.current.removeFeatureState(
      {
        source: `${CORRIDOR_EDGE_SOURCE_ID}_${corridorLevelRef.current}`,
        sourceLayer: layerName,
        id,
      },
      featureName,
    );
  };

  const clearFeatureStates = () => {
    edgeFeatureStateSet.forEach((featureStateId) => {
      removeFeatureState(featureStateId, "volumeOffset");
      removeFeatureState(featureStateId, "volumeWeight");
    });

    nodeFeatureStateSet.forEach((featureStateId) => {
      removeFeatureState(featureStateId, "nodeWeight");
    });

    edgeFeatureStateSet.clear();
    nodeFeatureStateSet.clear();
  };

  const updateCounts = (forceUpdate: boolean = false, filteredIds: number[] = []) => {
    if (filteredIds.length > 0) {
      filteredEdgeIds = filteredIds;
    }

    if (
      !mapRef.current ||
      !corridorLevelsRef.current ||
      !volumesRef.current ||
      !corridorLevelRef.current ||
      mapRef.current.getLayer(`${CORRIDOR_EDGE_LAYER_ID}_${corridorLevelRef.current}`) === undefined
    )
      return;

    const isZoomDiff = Math.abs(mapRef.current.getZoom() - zoom) > 0.25;

    if (isZoomDiff || forceUpdate) {
      clearFeatureStates();
    }

    updateFeatureStatesByCounts(isZoomDiff || forceUpdate);

    if (isZoomDiff) {
      zoom = mapRef.current.getZoom();
    }
  };

  const updateNodeCounts = () => {
    if (
      !mapRef.current ||
      !corridorLevelsRef.current ||
      !nodeCountsRef.current ||
      !corridorLevelRef.current ||
      mapRef.current.getLayer(`${CORRIDOR_NODE_LAYER_ID}_${corridorLevelRef.current}`) === undefined
    )
      return;

    const layerName = corridorLevelsRef.current[corridorLevelRef.current].nodeTileServiceLayer.name;

    const visibleFeatures = mapRef.current.queryRenderedFeatures(undefined, {
      layers: [`${CORRIDOR_NODE_LAYER_ID}_${corridorLevelRef.current}`],
    });

    if (visibleFeatures.length === 0) {
      return;
    }

    const features: any = {};

    visibleFeatures.forEach((feature: any) => {
      const nodeId = feature.id;

      const nodeCounts = nodeCountsRef.current.get(nodeId) || 0;
      const nodeWeight = nodeCounts / nodeMaxCountRef.current;

      features[nodeId] = {
        nodeWeight,
      };

      nodeFeatureStateSet.add(nodeId);
    });

    Object.entries(features).forEach(([id, state]) => {
      const { nodeWeight } = state as any;

      mapRef.current.setFeatureState(
        {
          source: `${CORRIDOR_NODE_SOURCE_ID}_${corridorLevelRef.current}`,
          sourceLayer: layerName,
          id,
        },
        {
          nodeWeight,
        },
      );
    });

    const heatmapWeightPaintProperty = mapRef.current.getPaintProperty(
      `${CORRIDOR_NODE_LAYER_ID}_${corridorLevelRef.current}`,
      "heatmap-weight",
    );

    if (heatmapWeightPaintProperty === 0) {
      mapRef.current.setPaintProperty(`${CORRIDOR_NODE_LAYER_ID}_${corridorLevelRef.current}`, "heatmap-weight", [
        "interpolate",
        ["linear"],
        ["number", ["feature-state", "nodeWeight"], 0],
        0,
        0,
        1,
        1,
      ]);
    }
  };

  const handleUpdateCounts = throttle((e: any) => {
    updateCounts();
    updateNodeCounts();
  }, 300);

  const handleEdgeCountsMousemove = (e: any) => {
    const feature = e.features[0];

    if (!feature) {
      return;
    }

    const { id } = feature;

    if (mapboxEdgeCountsHoverPopupRef.current) {
      mapboxEdgeCountsHoverPopupRef.current.remove();
    }

    if (hoveredEdge !== null) {
      mapRef.current.setFeatureState(
        {
          source: `${CORRIDOR_EDGE_SOURCE_ID}_${corridorLevelRef.current}`,
          sourceLayer: corridorLevelsRef.current[corridorLevelRef.current].edgeTileServiceLayer.name,
          id: hoveredEdge.id,
        },
        { hover: false },
      );
    }

    hoveredEdge = feature;

    mapRef.current.setFeatureState(
      {
        source: `${CORRIDOR_EDGE_SOURCE_ID}_${corridorLevelRef.current}`,
        sourceLayer: corridorLevelsRef.current[corridorLevelRef.current].edgeTileServiceLayer.name,
        id,
      },
      { hover: true },
    );

    if (typeof setEdgePopupRef.current === "function") {
      setEdgePopupRef.current({
        id: feature.id,
        feature: feature,
        fromToCounts: volumesRef.current.get(id) || 0,
        toFromCounts: volumesRef.current.get(-id) || 0,
      });
    }

    mapboxEdgeCountsHoverPopupRef.current = new Popup({
      closeButton: false,
      closeOnClick: false,
      offset: 15,
    })
      .setLngLat(e.lngLat)
      .setDOMContent(edgePopupRef.current as Node)
      .addTo(mapRef.current);

    mapRef.current.getCanvas().style.cursor = "default";
  };

  const handleEdgeCountsMouseleave = () => {
    mapRef.current.getCanvas().style.cursor = "";

    if (hoveredEdge !== null) {
      mapRef.current.setFeatureState(
        {
          source: `${CORRIDOR_EDGE_SOURCE_ID}_${corridorLevelRef.current}`,
          sourceLayer: corridorLevelsRef.current[corridorLevelRef.current].edgeTileServiceLayer.name,
          id: hoveredEdge.id,
        },
        { hover: false },
      );
    }

    if (mapboxEdgeCountsHoverPopupRef.current) {
      mapboxEdgeCountsHoverPopupRef.current.remove();
    }

    hoveredEdge = null;
    setEdgePopupRef.current(null);
  };

  const handlePopupClose = () => {
    if (selectedFeatures) {
      selectedFeatures.forEach(({ id, source }) => {
        if (mapRef.current.getSource(source)) {
          mapRef.current.setFeatureState(
            {
              source,
              id,
            },
            { select: false, highlightSelect: false },
          );
        }
      });
      selectedFeatures = null;
    }
  };

  const handleOverlayMove = (
    e: MapMouseEvent & {
      features?: MapboxGeoJSONFeature[] | undefined;
    } & EventData,
  ) => {
    if (mapRef.current && e.features?.length) {
      if (hoveredFeatures) {
        hoveredFeatures.forEach(({ id, source }) => {
          mapRef.current.setFeatureState(
            {
              source,
              id,
            },
            { hover: false },
          );
        });
        hoveredFeatures = null;
      }

      mapRef.current.getCanvas().style.cursor = "default";

      const hoveredFeaturesIds: HighlightedFeature[] = [];

      e.features.forEach((feature) => {
        if (feature.id) {
          mapRef.current.setFeatureState(
            {
              source: feature.source,
              id: feature.id,
            },
            { hover: true },
          );
          hoveredFeaturesIds.push({ id: feature.id, source: feature.source, layerId: feature.layer.id });
        }
      });

      hoveredFeatures = hoveredFeaturesIds;
    }
  };

  const handleOverlayLeave = () => {
    if (mapRef.current) {
      mapRef.current.getCanvas().style.cursor = "";

      if (hoveredFeatures) {
        hoveredFeatures.forEach(({ id, source }) => {
          mapRef.current.setFeatureState(
            {
              source,
              id,
            },
            { hover: false },
          );
        });
        hoveredFeatures = null;
      }
    }
  };

  const handleEdgeClick = (feature: MapboxGeoJSONFeature) => {
    const { id } = feature;

    if (selectedEdge !== null) {
      mapRef.current.setFeatureState(
        {
          source: `${CORRIDOR_EDGE_SOURCE_ID}_${corridorLevelRef.current}`,
          sourceLayer: corridorLevelsRef.current[corridorLevelRef.current].edgeTileServiceLayer.name,
          id: selectedEdge.id,
        },
        { selected: false },
      );

      const isSameEdge = selectedEdge.id === id;

      selectedEdge = null;
      setSelectedEdge(null);

      if (isSameEdge) {
        return;
      }
    }

    mapRef.current.setFeatureState(
      {
        source: `${CORRIDOR_EDGE_SOURCE_ID}_${corridorLevelRef.current}`,
        sourceLayer: corridorLevelsRef.current[corridorLevelRef.current].edgeTileServiceLayer.name,
        id,
      },
      { selected: true },
    );

    selectedEdge = feature;
    setSelectedEdge({
      id: feature.id as number,
      ft_dir: feature.properties?.ft_dir,
      tf_dir: feature.properties?.tf_dir,
    });
  };

  const handleOverlayClick = (features: MapboxGeoJSONFeature[], lngLat: LngLat) => {
    if (!features?.length || !overlayPopupRef.current) return;

    const selectedFeaturesIds: HighlightedFeature[] = [];
    features.forEach((feature: MapboxGeoJSONFeature, index: number) => {
      if (feature.id) {
        mapRef.current.setFeatureState(
          {
            source: feature.source,
            id: feature.id,
          },
          index === 0 ? { highlightSelect: true } : { select: true },
        );
        selectedFeaturesIds.push({ id: feature.id, source: feature.source, layerId: feature.layer.id });
      }
    });

    selectedFeatures = selectedFeaturesIds;

    overlayPopup = new Popup({ offset: 8 })
      .setLngLat(lngLat)
      .setDOMContent(overlayPopupRef.current)
      .addTo(mapRef.current);
    overlayPopup.on("close", handlePopupClose);
    setOverlayPopupRef.current?.(features);
  };

  const handleMapClick = (
    e: MapMouseEvent & {
      features?: MapboxGeoJSONFeature[] | undefined;
    } & EventData,
  ) => {
    const features = mapRef.current.queryRenderedFeatures(e.point, {
      layers: [
        `${CORRIDOR_EDGE_LAYER_ID}_${corridorLevelRef.current}_hightlighted_hairline`,
        ...overlayLayerIds.current,
      ],
    });

    deselectEdge();
    deselectOverlay();

    const isFirstFeatureEdge =
      features?.[0]?.layer.id === `${CORRIDOR_EDGE_LAYER_ID}_${corridorLevelRef.current}_hightlighted_hairline`;

    if (isFirstFeatureEdge) {
      handleEdgeClick(features[0]);
    }

    if (!isFirstFeatureEdge && overlayPopupRef.current) {
      const overlayFeatures =
        features.filter((f: MapboxGeoJSONFeature) => overlayLayerIds.current.includes(f.layer.id)) || [];
      handleOverlayClick(overlayFeatures, e.lngLat);
    }
  };

  const deselectOverlay = () => {
    overlayPopup?.remove();
    overlayPopup = null;
    setOverlayPopupRef.current?.(null);
  };

  const deselectEdge = () => {
    if (selectedEdge !== null) {
      mapRef.current.setFeatureState(
        {
          source: `${CORRIDOR_EDGE_SOURCE_ID}_${corridorLevelRef.current}`,
          sourceLayer: corridorLevelsRef.current[corridorLevelRef.current].edgeTileServiceLayer.name,
          id: selectedEdge.id,
        },
        { selected: false },
      );

      selectedEdge = null;
      setSelectedEdge(null);
    }
  };

  mapRef.current.on("move", handleUpdateCounts);
  mapRef.current.on("sourcedata", handleUpdateCounts);
  mapRef.current.on("click", handleMapClick);

  mapRef.current.on(
    "mousemove",
    `${CORRIDOR_EDGE_LAYER_ID}_${corridorLevelRef.current}_hightlighted_hairline`,
    handleEdgeCountsMousemove,
  );
  mapRef.current.on(
    "mouseleave",
    `${CORRIDOR_EDGE_LAYER_ID}_${corridorLevelRef.current}_hightlighted_hairline`,
    handleEdgeCountsMouseleave,
  );

  mapRef.current.on("mousemove", overlayLayerIds.current, handleOverlayMove);
  mapRef.current.on("mouseleave", overlayLayerIds.current, handleOverlayLeave);

  mapRef.current.on("styledata", (e: MapDataEvent) => {
    hoveredFeatures?.filter((f) => {
      return Boolean(e.target.getSource(f.source));
    });
    selectedFeatures?.filter((f) => {
      const featureSource = e.target.getSource(f.source);
      const isFeatureVisible =
        Boolean(featureSource) && f.layerId && e.target.getLayoutProperty(f.layerId, "visibility") !== "none";

      if (overlayPopup && !isFeatureVisible) {
        overlayPopup.remove();
        overlayPopup = null;
        setOverlayPopupRef.current?.(null);
      }
      return Boolean(featureSource);
    });
  });

  return {
    updateCounts,
    updateNodeCounts,
    deselectEdge,
  };
};
