import { ItemRenderer, Select2 as Select } from "@blueprintjs/select";
import styled from "@emotion/styled";
import findIndex from "lodash/findIndex";
import React, { useEffect, useState } from "react";

import { MenuItem, SelectInput } from "components";

import { FocusAreaItem } from "types";

interface Props {
  areas: FocusAreaItem[];
  defaultSelectedAreaId: string;
  onAreaChange: (areaId: string) => void;
  disabled: boolean;
}

const Label = styled.label`
  font-size: 14px;
  margin: 10px 0;
  color: #2a2e33;
`;

interface AreaDetails {
  id: string;
  text: string;
  areaIds: string[];
}

export const AreaOfFocusSelector = ({ areas, defaultSelectedAreaId, onAreaChange, disabled }: Props) => {
  const selectedIndex = areas.findIndex(({ id }) => id === defaultSelectedAreaId);

  const [activeSubareaIndex, setActiveSubareaIndex] = useState(selectedIndex);
  const [subareas, setSubareas] = React.useState<AreaDetails[]>([]);

  useEffect(() => {
    if (areas) {
      setSubareas(
        areas.map((area) => ({
          id: area.id,
          text: area.label,
          areaIds: area?.areas || [],
        })),
      );
    }
  }, [areas]);

  const handleItemChange = (area: AreaDetails) => {
    const index = findIndex(subareas, { id: area.id });
    setActiveSubareaIndex(index);
    onAreaChange(area.id);
  };

  const renderSubarea: ItemRenderer<any> = (subarea, { handleClick, handleFocus, modifiers }) => {
    if (!modifiers.matchesPredicate) {
      return null;
    }
    return (
      <MenuItem
        active={modifiers.active}
        key={subarea.id}
        onClick={handleClick}
        onFocus={handleFocus}
        roleStructure="listoption"
        text={subarea.text}
      />
    );
  };

  return (
    <>
      {subareas.length > 0 && (
        <>
          <Label>Area of interest</Label>
          <Select
            disabled={subareas.length <= 1}
            items={subareas}
            itemRenderer={renderSubarea}
            activeItem={subareas[activeSubareaIndex]}
            onItemSelect={handleItemChange}
            filterable={false}
            popoverProps={{
              matchTargetWidth: true,
              minimal: true,
            }}
            fill
          >
            <SelectInput disabled={disabled || subareas.length <= 1} value={subareas[activeSubareaIndex]?.text} />
          </Select>
        </>
      )}
    </>
  );
};
