import RestHandler from "./RestHandler";
import { NotificationsInboxState, NotificationsInboxResponse, FeedbackPayload } from "types";

export interface AccountApiType {
  getNotificationsInboxState(): Promise<NotificationsInboxState>;
  updateNotificationsInboxState(lastAccessed: string): Promise<void>;
  getNotificationsInbox(): Promise<NotificationsInboxResponse>;
  sendFeedback(payload: FeedbackPayload): Promise<any>;
}

export default function AccountApi(restHandler: RestHandler) {
  return {
    async getNotificationsInboxState() {
      const body = await restHandler.get<NotificationsInboxState>("my-inbox");
      return body;
    },

    async updateNotificationsInboxState(lastAccessed: string) {
      await restHandler.put("my-inbox", {
        lastAccessed,
      });
    },

    async getNotificationsInbox() {
      const body = await restHandler.get<NotificationsInboxResponse>("my-inbox/notification");
      return body;
    },

    async sendFeedback(payload: FeedbackPayload) {
      const body = await restHandler.post("feedback", payload);
      return body;
    },
  };
}
