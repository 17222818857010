import styled from "@emotion/styled";

import { Card, Spinner } from "components";

const SpinnerContainer = styled(Card)`
  display: flex;
  border-radius: 4px;
  background: #fff;
  width: 28px;
  height: 28px;

  align-items: center;
  justify-content: center;
  position: absolute;
  top: 7rem;
  left: calc(var(--sidebar-width) + 1rem);
  margin: 0.9rem;

  box-shadow: 0 0 0 2px rgb(0 0 0/10%);
`;

export const SpinnerOverlay = () => (
  <SpinnerContainer>
    <Spinner size={20} />
  </SpinnerContainer>
);
