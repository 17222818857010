import { Icon, IconName } from "@blueprintjs/core";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import React, { ButtonHTMLAttributes, DetailedHTMLProps, forwardRef } from "react";

import { BaseButton, BaseButtonProps } from "../button/Button";

interface IconButtonProps
  extends BaseButtonProps,
    Omit<DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>, "children" | "color"> {
  icon: IconName;
  rotate?: string;
}

const iconSizeStyles = {
  xxs: css`
    height: 20px;
    width: 20px;

    & svg {
      height: 10px;
      width: 10px;
    }
  `,
  xs: css`
    height: 30px;
    width: 30px;

    & svg {
      height: 12px;
      width: 12px;
    }
  `,
  sm: css`
    height: 34px;
    width: 34px;
    & svg {
      height: 12px;
      width: 12px;
    }
  `,
  md: css`
    height: 38px;
    width: 38px;
    & svg {
      height: 14px;
      width: 14px;
    }
  `,
  lg: css`
    height: 42px;
    width: 42px;
    & svg {
      height: 14px;
      width: 14px;
    }
  `,
  xl: css`
    height: 50px;
    width: 50px;
    & svg {
      height: 16px;
      width: 16px;
    }
  `,
};

const StyledIconButton = styled(BaseButton)<Omit<IconButtonProps, "icon">>`
  ${({ size = "md" }) => iconSizeStyles[size]};
`;

export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  ({ icon, rotate, variant = "rounded", ...props }, ref) => (
    <StyledIconButton ref={ref} variant={variant} {...props}>
      <Icon icon={icon} style={{ rotate: rotate || undefined }} />
    </StyledIconButton>
  ),
);
