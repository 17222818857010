import { MenuDivider } from "@blueprintjs/core";
import { ItemRenderer, Select2 as Select } from "@blueprintjs/select";
import styled from "@emotion/styled";
import { ViewQuiltOutlined, WarningAmberOutlined } from "@mui/icons-material";
import FolderOpenOutlinedIcon from "@mui/icons-material/FolderOpenOutlined";
import { Tooltip } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import { MenuItem, SelectInput } from "components";

import { useAppSelector } from "hooks";

import { DataState } from "store/interfaces";
import { datasetFoldersActions } from "store/sections/userFolders";

interface Props {
  activeItem: string | undefined;
  zoningOptions: string[];
  onItemSelect: (zoning: string, customZoningId?: string) => void;
  customZoningId?: string;
}

interface ZoningSelectorItem {
  id: string;
  name: string;
  folderName?: string;
  zoningId?: string;
  enabled?: boolean;
  disabledReason?: string;
}

const FolderMenuItem = styled(MenuDivider)`
  display: list-item;
  border-top: none;
  margin: 0;
  padding: 0;

  & .bp4-heading {
    padding: 0;
    margin: 0;
  }
`;

const ItemRowWithIcon = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 8px;
  font-weight: normal;
  color: var(--color-textSecondary);

  .MuiSvgIcon-root {
    margin-right: 5px;
  }
`;

export const ZoningSelector = ({ activeItem, zoningOptions, customZoningId, onItemSelect }: Props) => {
  const dispatch = useDispatch();

  const [options, setOptions] = React.useState<ZoningSelectorItem[]>([]);

  const defaultZoningLevels = useAppSelector((state) => state.analytics.ODMetadata.data?.zoningLevels);

  const customZoningSelectorFoldersState = useAppSelector(
    (state) => state.datasetFolders.customZoningSelectorFolders.state,
  );
  const customZoningSelectorFolders = useAppSelector((state) => state.datasetFolders.customZoningSelectorFolders.data);

  useEffect(() => {
    dispatch(datasetFoldersActions.getCustomZoningSelectorFolders());
  }, [dispatch]);

  useEffect(() => {
    if (customZoningSelectorFoldersState === DataState.AVAILABLE && zoningOptions.length > 0 && options.length === 0) {
      const optionsList: ZoningSelectorItem[] = [];

      zoningOptions.forEach((zoningOption) => {
        const zoningName = defaultZoningLevels?.find(
          (zoningLevel) => zoningLevel.id === zoningOption,
        )?.shortNameSingular;
        optionsList.push({
          id: zoningOption,
          name: zoningName || zoningOption,
          enabled: true,
        });
      });

      customZoningSelectorFolders?.folders.forEach((folder) => {
        optionsList.push({
          id: folder.folderId,
          name: folder.folderName,
          folderName: folder.folderName,
        });

        folder.customZonings.forEach((customZoning) => {
          optionsList.push({
            id: customZoning.customZoningId,
            name: customZoning.name,
            zoningId: customZoning.zoningId,
            enabled: customZoning.enabled,
            disabledReason: customZoning.disabledReason,
          });
        });
      });

      setOptions(optionsList);
    }
  }, [
    dispatch,
    options,
    customZoningSelectorFoldersState,
    customZoningSelectorFolders,
    zoningOptions,
    defaultZoningLevels,
  ]);

  const renderZoningItem: ItemRenderer<ZoningSelectorItem> = (item, { handleClick, modifiers }) =>
    item.folderName ? (
      <FolderMenuItem
        title={
          <ItemRowWithIcon>
            <FolderOpenOutlinedIcon fontSize="small" />
            {item.name}
          </ItemRowWithIcon>
        }
        key={item.id}
      />
    ) : (
      <MenuItem
        text={item.name}
        roleStructure="listoption"
        active={modifiers.active}
        key={item.id}
        onClick={handleClick}
        icon={
          item.enabled ? (
            <ViewQuiltOutlined fontSize="small" sx={item.zoningId ? { ml: "10px" } : { color: "#60a5fa" }} />
          ) : (
            <Tooltip
              title={item.enabled ? "" : "Custom zoning is not available for Essentials-tier entitlement"}
              placement="right"
            >
              <WarningAmberOutlined fontSize="small" color={"warning"} />
            </Tooltip>
          )
        }
        disabled={!item.enabled}
      />
    );

  return (
    <Select
      disabled={
        customZoningSelectorFoldersState === DataState.LOADING ||
        customZoningSelectorFoldersState !== DataState.AVAILABLE
      }
      items={options}
      itemRenderer={renderZoningItem}
      activeItem={options.find((option) =>
        activeItem === "Custom" ? option.id === customZoningId : option.id === activeItem,
      )}
      onItemSelect={(item) => {
        item?.zoningId ? onItemSelect("Custom", item.id) : onItemSelect(item.id);
      }}
      filterable={false}
      popoverProps={{
        minimal: true,
      }}
      fill
    >
      <SelectInput
        disabled={
          customZoningSelectorFoldersState === DataState.LOADING ||
          customZoningSelectorFoldersState !== DataState.AVAILABLE
        }
        value={
          options.find((option) => (activeItem === "Custom" ? option.id === customZoningId : option.id === activeItem))
            ?.name || ""
        }
      />
    </Select>
  );
};
