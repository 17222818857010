import styled from "@emotion/styled";
import React, { FC, useState } from "react";
import {
  Bar,
  CartesianGrid,
  Cell,
  BarChart as RechartsBarChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

interface DataChart {
  name: string;
  value: number;
}

interface BarChartProps {
  data: DataChart[];
  layout: "vertical" | "horizontal";
  title?: string;
  getCustomColor?: (pattern: string) => string;
}

const ChartTitle = styled.div`
  position: absolute;
  top: 10px;
  left: 20px;
  font-size: var(--font-size-sm);
  font-weight: bold;
`;

const ChartWrapper = styled.div`
  width: 100%;
  padding: 35px 15px 0 5px;
`;

const TooltipWrapper = styled.div`
  padding: 0.5rem;
  background-color: #ffffff;
  border: 1px solid var(--color-border);
  border-radius: 4px;
`;

const CustomToolTip = ({ active, payload, label }: any) => {
  if (active) {
    return (
      <TooltipWrapper>
        <p>{label}</p>
        <b>{payload[0].value.toLocaleString("en-US")}</b>
      </TooltipWrapper>
    );
  }
  return null;
};

const BLUE_CHART_COLOR = "#4584D1";
const CHART_FOCUS_COLOR = "#139EEC";
const BAR_DEFAULT_HEIGHT = 18;
const BAR_CATEGORY_GAP = 4;
const CHART_VERTICAL_PADDING = 70;
const DEFAULT_HORIZONATAL_LAYOUT_HEIGHT = "170";

export const BarChart: FC<BarChartProps> = ({ data, layout, title = "", getCustomColor }) => {
  const [focusBar, setFocusBar] = useState(null);

  const isVerticalLayout = layout === "vertical";

  const getCellFill = (entry: DataChart, index: number) => {
    if (getCustomColor) {
      return getCustomColor("hover");
    }

    return focusBar !== null ? (focusBar === index ? CHART_FOCUS_COLOR : BLUE_CHART_COLOR) : BLUE_CHART_COLOR;
  };

  return (
    <div style={{ position: "relative" }}>
      <ChartTitle>{title}</ChartTitle>
      <ChartWrapper
        style={{
          height: `${
            isVerticalLayout
              ? (BAR_DEFAULT_HEIGHT + BAR_DEFAULT_HEIGHT / 2) * data.length + CHART_VERTICAL_PADDING
              : DEFAULT_HORIZONATAL_LAYOUT_HEIGHT
          }px`,
        }}
      >
        <ResponsiveContainer width="100%" height="100%">
          <RechartsBarChart
            data={data}
            layout={layout}
            barSize={BAR_DEFAULT_HEIGHT}
            barCategoryGap={BAR_CATEGORY_GAP}
            onMouseMove={(state: any) => {
              if (state.isTooltipActive) {
                setFocusBar(state.activeTooltipIndex);
              } else {
                setFocusBar(null);
              }
            }}
            onMouseLeave={() => {
              setFocusBar(null);
            }}
          >
            <CartesianGrid horizontal={!isVerticalLayout} vertical={isVerticalLayout} style={{ opacity: 0.5 }} />
            <XAxis
              type={isVerticalLayout ? "number" : "category"}
              dataKey={isVerticalLayout ? "value" : "name"}
              padding={{ right: 20 }}
              axisLine={false}
              tickLine={false}
              style={{
                fontSize: "0.62rem",
                fontFamily: "Inter",
              }}
            />
            <YAxis
              type={isVerticalLayout ? "category" : "number"}
              dataKey={isVerticalLayout ? "name" : "value"}
              axisLine={false}
              tickLine={false}
              style={{
                fontSize: "0.62rem",
                fontFamily: "Inter",
              }}
              width={isVerticalLayout ? 100 : 50}
            />
            <Bar dataKey="value" stackId="a" radius={isVerticalLayout ? [0, 5, 5, 0] : [5, 5, 0, 0]}>
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={getCellFill(entry, index)} />
              ))}
            </Bar>
            <Tooltip cursor={false} content={<CustomToolTip />} />
          </RechartsBarChart>
        </ResponsiveContainer>
      </ChartWrapper>
    </div>
  );
};
