import { useAuth0 } from "@auth0/auth0-react";
import styled from "@emotion/styled";
import RestartAlt from "@mui/icons-material/RestartAlt";
import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Slider,
  Switch,
  Typography,
} from "@mui/material";
import { Map } from "mapbox-gl";
import React, { ChangeEvent, FC, MutableRefObject, memo, useCallback, useEffect, useMemo } from "react";

import { AreaName, DividerWithText, FocusAreaDropdown, InlineGridItem, LeftSidebar } from "components";

import { useAppDispatch, useAppSelector } from "hooks";

import { DataState } from "store/interfaces";
import { corridorActions } from "store/sections/corridor";
import { globalActions } from "store/sections/global";

import { addCustomGAEvent } from "utils/addCustomGAEvent";

import { Filters } from "./Filters";
import { RangeFilter } from "./RangeFilter";
import {
  CORRIDOR_EDGE_LAYER_ID,
  CORRIDOR_NODE_LAYER_ID,
  getHeatmapIntensityExpression,
  getHeatmapRadiusExpression,
  layerIdsEndings,
} from "./map-data/corridor/layers";

interface MapControlsPanelProps {
  mapController: MutableRefObject<null | any>;
  map: Map | null;
}

const AreaNameContainer = styled.div`
  margin: 1rem 0;
  display: flex;
`;

const FiltersContainer = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100% - 120px);
  margin: 0.5em -1rem 0 0;
  padding: 0 1rem 0 2px;
`;

function valueLabelFormat(value: number) {
  return `x ${Math.round(value * 100) / 100}`;
}

function calculateHeatmapIntensityFactor(value: number) {
  return value ** 2;
}

function calculateHeatmapRadiusFactor(value: number) {
  return value ** 2;
}

function calculateEdgeWidthFactor(value: number) {
  return value ** 3;
}

export const MapControlsPanel: FC<MapControlsPanelProps> = memo(({ mapController, map }) => {
  const dispatch = useAppDispatch();
  const { user } = useAuth0();

  const [heatmapVisible, setHeatmapVisible] = React.useState(true);
  const [corridorEdgesVisible, setCorridorEdgesVisible] = React.useState(true);
  const [corridorEdgeWidth, setCorridorEdgeWidth] = React.useState(1);
  const [heatmapIntensity, setHeatmapIntensity] = React.useState(1);
  const [heatmapRadius, setHeatmapRadius] = React.useState(1);

  const focusAreas = useAppSelector((state) => state.analytics.focusAreasAndDatasets);
  const selectedFocusAreaId = useAppSelector((state) => state.global.selectedFocusAreaId);
  const timePeriod = useAppSelector((state) => state.global.timePeriod);
  const permissions = useAppSelector((state) => state.license.permissions);

  const corridorMetadata = useAppSelector((state) => state.corridor.corridorMetadata);
  const corridorEdgeIds = useAppSelector((state) => state.corridor.corridorEdgeIds);
  const corridorEdgeCounts = useAppSelector((state) => state.corridor.corridorEdgeCounts);
  const corridorNodeIds = useAppSelector((state) => state.corridor.corridorNodeIds);
  const corridorNodeCounts = useAppSelector((state) => state.corridor.corridorNodeCounts);
  const corridorHeatmapConfiguration = useAppSelector((state) => state.corridor.corridorHeatmapConfiguration);
  const serviceOverlayLayers = useAppSelector((state) => state.corridor.serviceLayers);

  const userOrganizationName = useAppSelector((state) => state.license.user.data?.organization?.name);

  const filterLoading = useMemo(() => {
    return (
      corridorMetadata.state === DataState.LOADING ||
      corridorEdgeIds.state === DataState.LOADING ||
      corridorEdgeCounts.state === DataState.LOADING ||
      corridorNodeIds.state === DataState.LOADING ||
      corridorNodeCounts.state === DataState.LOADING ||
      corridorHeatmapConfiguration.state === DataState.LOADING ||
      serviceOverlayLayers.state === DataState.LOADING
    );
  }, [
    corridorMetadata.state,
    corridorEdgeIds.state,
    corridorEdgeCounts.state,
    corridorNodeIds.state,
    corridorNodeCounts.state,
    corridorHeatmapConfiguration.state,
    serviceOverlayLayers.state,
  ]);

  const selectedFocusArea = useMemo(
    () => focusAreas.data?.find((area) => area.id === selectedFocusAreaId) || null,
    [focusAreas.data, selectedFocusAreaId],
  );

  const isDataset = useMemo(() => Boolean(selectedFocusArea?.datasetId), [selectedFocusArea?.datasetId]);

  const handleToggleCorridorEdges = (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    if (mapController.current) {
      setCorridorEdgesVisible(checked);

      Object.keys(corridorMetadata.data?.corridorLevels || {}).forEach((zoningLevelId) => {
        layerIdsEndings.forEach((ending) => {
          mapController.current.layerManager.updateLayerLayout(
            `${CORRIDOR_EDGE_LAYER_ID}_${zoningLevelId}${ending}`,
            "visibility",
            checked ? "visible" : "none",
          );
        });
      });

      addCustomGAEvent("corridor", "edges", checked ? "visible" : "invisible", user, userOrganizationName);
    }
  };

  const handleChangeCorridorEdgeWidth = useCallback(
    (_: any, value: number | number[]) => {
      setCorridorEdgeWidth(value as number);

      const widthFactor = calculateEdgeWidthFactor(value as number);

      Object.keys(corridorMetadata.data?.corridorLevels || {}).forEach((zoningLevelId) => {
        mapController.current.layerManager.updateLayerPaint(
          `${CORRIDOR_EDGE_LAYER_ID}_${zoningLevelId}`,
          "line-width",
          ["*", ["number", ["feature-state", "volumeWeight"], 0], widthFactor],
        );

        mapController.current.layerManager.updateLayerPaint(
          `${CORRIDOR_EDGE_LAYER_ID}_${zoningLevelId}`,
          "line-offset",
          ["*", ["number", ["feature-state", "volumeOffset"], 0], widthFactor],
        );

        mapController.current.layerManager.updateLayerPaint(
          `${CORRIDOR_EDGE_LAYER_ID}_${zoningLevelId}_hightlighted_volume`,
          "line-width",
          ["*", ["number", ["feature-state", "volumeWeight"], 0], widthFactor],
        );

        mapController.current.layerManager.updateLayerPaint(
          `${CORRIDOR_EDGE_LAYER_ID}_${zoningLevelId}_hightlighted_volume`,
          "line-offset",
          ["*", ["number", ["feature-state", "volumeOffset"], 0], widthFactor],
        );
      });

      addCustomGAEvent("corridor", "edges", "change_width", user, userOrganizationName);
    },
    [user, userOrganizationName, mapController, corridorMetadata.data],
  );

  const handleToggleHeatMap = (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    if (mapController.current) {
      setHeatmapVisible(checked);

      Object.keys(corridorMetadata.data?.corridorLevels || {}).forEach((zoningLevelId) => {
        mapController.current.layerManager.updateLayerLayout(
          `${CORRIDOR_NODE_LAYER_ID}_${zoningLevelId}`,
          "visibility",
          checked ? "visible" : "none",
        );
      });

      addCustomGAEvent("corridor", "heatmap", checked ? "visible" : "invisible", user, userOrganizationName);
    }
  };

  const handleChangeHeatmapIntensity = useCallback(
    (_: any, value: number | number[]) => {
      setHeatmapIntensity(value as number);

      Object.keys(corridorMetadata.data?.corridorLevels || {}).forEach((zoningLevelId) => {
        mapController.current.layerManager.updateLayerPaint(
          `${CORRIDOR_NODE_LAYER_ID}_${zoningLevelId}`,
          "heatmap-intensity",
          getHeatmapIntensityExpression(
            calculateHeatmapIntensityFactor(value as number),
            corridorHeatmapConfiguration.data,
          ),
        );
      });

      addCustomGAEvent("corridor", "heatmap", "change_intensity", user, userOrganizationName);
    },
    [mapController, corridorMetadata.data, corridorHeatmapConfiguration.data, user, userOrganizationName],
  );

  const handleChangeHeatmapRadius = useCallback(
    (_: any, value: number | number[]) => {
      setHeatmapRadius(value as number);

      Object.keys(corridorMetadata.data?.corridorLevels || {}).forEach((zoningLevelId) => {
        mapController.current.layerManager.updateLayerPaint(
          `${CORRIDOR_NODE_LAYER_ID}_${zoningLevelId}`,
          "heatmap-radius",
          getHeatmapRadiusExpression(calculateHeatmapRadiusFactor(value as number), corridorHeatmapConfiguration.data),
        );
      });

      addCustomGAEvent("corridor", "heatmap", "change_radius", user, userOrganizationName);
    },
    [mapController, corridorMetadata.data, corridorHeatmapConfiguration.data, user, userOrganizationName],
  );

  const handleChangeFocusArea = (focusAreaId: string) => {
    if (focusAreaId) {
      dispatch(globalActions.setSelectedFocusAreaId({ focusAreaId }));
      dispatch(corridorActions.clearServiceOverlayLayers());

      addCustomGAEvent("corridor", "aoi", "change", user, userOrganizationName);
    }
  };

  const handleChangeTimePeriod = (event: SelectChangeEvent) => {
    dispatch(globalActions.setTimePeriod(event.target.value));

    addCustomGAEvent("corridor", "time_period", "change", user, userOrganizationName);
  };

  useEffect(() => {
    setCorridorEdgeWidth(1);
    setHeatmapIntensity(1);
    setHeatmapRadius(1);
    setCorridorEdgesVisible(true);
    setHeatmapVisible(true);

    return () => {
      dispatch(corridorActions.clearServiceOverlayLayers());
    };
  }, [selectedFocusAreaId, dispatch]);

  useEffect(() => {
    if (corridorHeatmapConfiguration.state === DataState.AVAILABLE && mapController.current) {
      handleChangeCorridorEdgeWidth(undefined, corridorEdgeWidth);
      handleChangeHeatmapIntensity(undefined, heatmapIntensity);
      handleChangeHeatmapRadius(undefined, heatmapRadius);
    }
  }, [
    corridorHeatmapConfiguration,
    mapController,
    corridorEdgeWidth,
    heatmapIntensity,
    heatmapRadius,
    handleChangeCorridorEdgeWidth,
    handleChangeHeatmapIntensity,
    handleChangeHeatmapRadius,
  ]);

  useEffect(() => {
    return () => {
      dispatch(corridorActions.clearCorridorMetadata());
      dispatch(corridorActions.updateCurrentFilters(null));
    };
  }, [dispatch]);

  return (
    <>
      <LeftSidebar>
        <FocusAreaDropdown
          sx={{ mb: 2 }}
          loading={focusAreas.state === DataState.LOADING}
          disabled={focusAreas.state === DataState.EMPTY || focusAreas.state === DataState.ERROR}
          options={
            focusAreas.data?.filter(
              (area) =>
                !area.datasetId &&
                permissions.data?.licensedAreas.find(
                  (a) => a.licensedAreaId.toString() === area.licensedAreaId && a.dataDetail.corridorDetail,
                ),
            ) || []
          }
          value={selectedFocusArea}
          onChange={handleChangeFocusArea}
        />
        {isDataset && (
          <AreaNameContainer>
            <AreaName>{selectedFocusArea?.region}</AreaName>
          </AreaNameContainer>
        )}
        <FormControl fullWidth sx={{ mb: 2 }}>
          <InputLabel id="time-period-select">Time Period</InputLabel>
          <Select
            labelId="time-period-select"
            id="time-period-select"
            value={timePeriod || ""}
            label="Time Period"
            disabled={(selectedFocusArea?.timePeriods?.length ?? 1) <= 1}
            onChange={handleChangeTimePeriod}
          >
            {selectedFocusArea?.timePeriods?.map((timePeriod) => (
              <MenuItem key={timePeriod} value={timePeriod}>
                {timePeriod}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FiltersContainer>
          <Filters loading={filterLoading} />
          <DividerWithText>Corridor discovery</DividerWithText>

          <Box py={1}>
            <FormControlLabel
              control={
                <Switch
                  sx={{ ml: 1 }}
                  size="small"
                  color="secondary"
                  checked={corridorEdgesVisible}
                  onChange={handleToggleCorridorEdges}
                />
              }
              label="Corridor edges"
              sx={{ mb: 1 }}
            />
            <RangeFilter label="Counts" mapController={mapController} map={map} />
            <Grid container spacing={1} alignItems="center">
              <InlineGridItem minWidth={65} height={40} item>
                <Typography variant="caption" fontWeight={500}>
                  Width
                </Typography>
              </InlineGridItem>
              <InlineGridItem height={40} item xs>
                <Slider
                  disabled={filterLoading}
                  sx={{
                    marginTop: "0",
                    "& .MuiSlider-mark, .MuiSlider-markActive": {
                      width: "6px",
                      height: "6px",
                      borderRadius: "50%",
                    },
                  }}
                  color="secondary"
                  size="small"
                  value={corridorEdgeWidth}
                  min={0}
                  max={2}
                  step={0.1}
                  marks={[
                    {
                      value: 1,
                      label: "",
                    },
                  ]}
                  scale={calculateEdgeWidthFactor}
                  getAriaValueText={valueLabelFormat}
                  valueLabelFormat={valueLabelFormat}
                  valueLabelDisplay="auto"
                  onChange={handleChangeCorridorEdgeWidth}
                />
              </InlineGridItem>
              <InlineGridItem height={40} item>
                <IconButton
                  disabled={filterLoading}
                  size="small"
                  onClick={() => handleChangeCorridorEdgeWidth(undefined, 1)}
                >
                  <RestartAlt fontSize={"small"} />
                </IconButton>
              </InlineGridItem>
            </Grid>
            <FormControlLabel
              control={
                <Switch
                  sx={{ ml: 1 }}
                  size="small"
                  color="secondary"
                  checked={heatmapVisible}
                  onChange={handleToggleHeatMap}
                />
              }
              label="Heatmap"
              sx={{ mt: 2, mb: 1 }}
            />
            <Grid container spacing={1} alignItems="center">
              <InlineGridItem minWidth={65} height={40} item>
                <Typography variant="caption" fontWeight={500}>
                  Intensity
                </Typography>
              </InlineGridItem>
              <InlineGridItem height={40} item xs>
                <Slider
                  disabled={filterLoading}
                  sx={{
                    marginTop: "0",
                    "& .MuiSlider-mark, .MuiSlider-markActive": {
                      width: "6px",
                      height: "6px",
                      borderRadius: "50%",
                    },
                  }}
                  color="secondary"
                  size="small"
                  value={heatmapIntensity}
                  min={0}
                  max={2}
                  step={0.1}
                  marks={[
                    {
                      value: 1,
                      label: "",
                    },
                  ]}
                  scale={calculateHeatmapIntensityFactor}
                  getAriaValueText={valueLabelFormat}
                  valueLabelFormat={valueLabelFormat}
                  valueLabelDisplay="auto"
                  onChange={handleChangeHeatmapIntensity}
                />
              </InlineGridItem>
              <InlineGridItem height={40} item>
                <IconButton
                  disabled={filterLoading}
                  size="small"
                  onClick={() => handleChangeHeatmapIntensity(undefined, 1)}
                >
                  <RestartAlt fontSize={"small"} />
                </IconButton>
              </InlineGridItem>
            </Grid>
            <Grid container spacing={1} alignItems="center">
              <InlineGridItem minWidth={65} height={40} item>
                <Typography variant="caption" fontWeight={500}>
                  Radius
                </Typography>
              </InlineGridItem>
              <InlineGridItem height={40} item xs>
                <Slider
                  disabled={filterLoading}
                  sx={{
                    marginTop: "0",
                    "& .MuiSlider-mark, .MuiSlider-markActive": {
                      width: "6px",
                      height: "6px",
                      borderRadius: "50%",
                    },
                  }}
                  color="secondary"
                  size="small"
                  value={heatmapRadius}
                  min={0}
                  max={2}
                  step={0.1}
                  marks={[
                    {
                      value: 1,
                      label: "",
                    },
                  ]}
                  scale={calculateHeatmapRadiusFactor}
                  getAriaValueText={valueLabelFormat}
                  valueLabelFormat={valueLabelFormat}
                  valueLabelDisplay="auto"
                  onChange={handleChangeHeatmapRadius}
                />
              </InlineGridItem>
              <InlineGridItem height={40} item>
                <IconButton
                  disabled={filterLoading}
                  size="small"
                  onClick={() => handleChangeHeatmapRadius(undefined, 1)}
                >
                  <RestartAlt fontSize={"small"} />
                </IconButton>
              </InlineGridItem>
            </Grid>
          </Box>
        </FiltersContainer>
      </LeftSidebar>
    </>
  );
});
