import { Map } from "mapbox-gl";

import { EditorRoads } from "features/map-visualization";

import { ODTileLayer, ZoneIds } from "types";

import {
  CHOROPLETH_FILL_COLOR,
  COUNTY_BORDER_LINE_COLOR,
  COUNTY_BORDER_LINE_OPACITY,
  COUNTY_BORDER_LINE_WIDTH,
} from "./layerColors";

interface EditorODData {
  ids: ZoneIds;
  layer: ODTileLayer & { type?: string };
  outerLayer: ODTileLayer;
}

export enum EditorOD {
  ZonesSourceId = "zones",
  ZonesBounds = "zones-bounds",
  ZonesFillHighlighted = "zones-fill-highlighted",
  OuterZonesSourceId = "out-zones",
  OuterZonesBoundsLayerId = "outer-zones-bounds",
  HighlightedSegmentsLayerId = "road-segments-highlighted",
}

const defaultLayers = ["admin_country", "road-label"];

export const initEditorOD = (map: Map, { ids, layer, outerLayer }: EditorODData) => {
  const existingDefaultLayers = defaultLayers.filter((layer) => !!map.getLayer(layer));
  const overlappingLayers = [
    EditorRoads.SegmentsLayerId,
    EditorRoads.HighlightedSegmentsLayerId,
    ...existingDefaultLayers,
  ];

  map.addSource(EditorOD.ZonesSourceId, {
    type: "vector",
    tiles: [layer.url + "/{z}/{x}/{y}.pbf"],
    promoteId: layer.type === "CustomZoning" ? layer.idField : { [layer.name]: layer.idField },
    minzoom: 0,
    maxzoom: layer.maxzoom,
  });

  map.addSource(EditorOD.OuterZonesSourceId, {
    type: "vector",
    tiles: [outerLayer.url + "/{z}/{x}/{y}.pbf"],
    promoteId: { [outerLayer.name]: outerLayer.idField },
    minzoom: 0,
    maxzoom: outerLayer.maxzoom,
  });

  map.addLayer(
    {
      id: EditorOD.OuterZonesBoundsLayerId,
      type: "line",
      source: EditorOD.OuterZonesSourceId,
      "source-layer": outerLayer.name,
      paint: {
        "line-color": COUNTY_BORDER_LINE_COLOR,
        "line-opacity": COUNTY_BORDER_LINE_OPACITY,
        "line-width": COUNTY_BORDER_LINE_WIDTH,
      },
      filter: ["!in", outerLayer.idField, ...Object.keys(ids[outerLayer.level])],
    },
    ...existingDefaultLayers,
  );

  map.addLayer(
    {
      id: layer.name,
      type: "fill",
      "source-layer": layer.name,
      source: EditorOD.ZonesSourceId,
      paint: {
        "fill-color": CHOROPLETH_FILL_COLOR,
        "fill-opacity": 0.2,
      },
      filter: layer.type === "CustomZoning" ? ["all", true] : ["in", layer.idField, ...Object.keys(ids[layer.level])],
    },
    ...overlappingLayers,
  );

  map.addLayer(
    {
      id: EditorOD.ZonesFillHighlighted,
      type: "fill",
      source: EditorOD.ZonesSourceId,
      "source-layer": layer.name,
      paint: {
        "fill-color": "yellow",
        "fill-opacity": 0.15,
      },
      filter: ["in", ["get", layer.idField], ""],
    },
    ...overlappingLayers,
  );

  map.addLayer(
    {
      id: `${EditorOD.ZonesBounds}-${layer.name}`,
      type: "line",
      source: EditorOD.ZonesSourceId,
      "source-layer": layer.name,
      paint: {
        "line-color": "#9e9e9e",
        "line-opacity": 1,
        "line-width": ["interpolate", ["exponential", 1.6], ["zoom"], 6, 0.4, 10, 0.6],
      },
      filter: layer.type === "CustomZoning" ? ["all", true] : ["in", layer.idField, ...Object.keys(ids[layer.level])],
    },
    ...overlappingLayers,
  );

  return {
    clear: () => {
      if (map.getLayer(`${EditorOD.ZonesBounds}-${layer.name}`)) {
        map.removeLayer(`${EditorOD.ZonesBounds}-${layer.name}`);
      }

      if (map.getLayer(layer.name)) {
        map.removeLayer(layer.name);
      }

      if (map.getLayer(EditorOD.OuterZonesBoundsLayerId)) map.removeLayer(EditorOD.OuterZonesBoundsLayerId);

      if (map.getLayer(EditorOD.ZonesFillHighlighted)) map.removeLayer(EditorOD.ZonesFillHighlighted);

      if (map.getSource(EditorOD.ZonesSourceId)) map.removeSource(EditorOD.ZonesSourceId);

      if (map.getSource(EditorOD.OuterZonesSourceId)) map.removeSource(EditorOD.OuterZonesSourceId);
    },
  };
};
